import React from 'react';
import { Text, Button, Form, Spacer } from 'components';
import { createContact } from 'data-graphql';
import { yes_no_options } from 'helpers';
import { toast } from 'react-toastify';

export default class CreateContactForm extends React.Component {
  constructor() {
    super();
    this.initialState = {
      firstName: '',
      lastName: '',
      position: '',
      email: '',
      phone: '',
      phone_2: '',
      is_account_admin: '',
      creating: false
    };
    this.state = this.initialState;
  }

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value });
  };

  close = () => {
    this.setState(this.initialState);
    this.props.onChange();
  };

  submit = async () => {
    if (
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.email ||
      !this.state.position ||
      !this.state.phone
    ) {
      return toast.error('Name, email, position, and phone are required.', {
        autoclose: false
      });
    }

    const payload = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      position: this.state.position,
      email: this.state.email,
      phone_number: this.state.phone,
      phone_number_two: this.state.phone_2,
      organization_id: this.props.organization_id,
      is_account_admin: this.state.is_account_admin.value
    };
    const VARS = { input: payload };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    this.setState(prevState => ({
      creating: !prevState.creating
    }));

    const RESULT = await createContact(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.close();
    } else {
      this.setState(prevState => ({
        creating: !prevState.creating
      }));
    }
  };

  handleAdminChange = is_account_admin => {
    this.setState({ is_account_admin });
  };

  render() {
    return (
      <Form>
        <Text red large>
          Add Contact
        </Text>
        <Spacer size="10px" />
        <Form.InputWrapper>
          <Form.Input
            name="firstName"
            onChange={this.handleChange}
            value={this.state.firstName}
            placeholder="Contact First Name"
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Input
            name="lastName"
            onChange={this.handleChange}
            value={this.state.lastName}
            placeholder="Contact Last Name"
          />
        </Form.InputWrapper>
        <Spacer size="10px" />
        <Form.InputWrapper>
          <Form.Input
            name="position"
            onChange={this.handleChange}
            value={this.state.position}
            placeholder="Position"
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Input
            name="email"
            onChange={this.handleChange}
            value={this.state.email}
            placeholder="Email (to send account info)"
          />
        </Form.InputWrapper>
        <Spacer size="10px" />
        <Form.InputWrapper>
          <Form.Input
            name="phone"
            onChange={this.handleChange}
            value={this.state.phone}
            placeholder="Phone"
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Input
            name="phone_2"
            onChange={this.handleChange}
            value={this.state.phone_2}
            placeholder="Secondary Phone"
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Label small>Is Account Admin?</Form.Label>
          <Spacer size="5px" />
          <Form.StyledSelect
            styles={Form.CustomStyles}
            value={this.state.is_account_admin}
            onChange={this.handleAdminChange}
            options={yes_no_options}
            isSearchable={false}
          />
        </Form.InputWrapper>

        <Spacer size="5px" />
        <Button onClick={this.submit}>
          {this.state.creating ? 'Creating...' : 'Create'}
        </Button>
      </Form>
    );
  }
}
