import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AllOrgs from './AllOrgs';
import OrgDetails from './OrgDetails';

export default props => {
  return (
    <Switch>
      <Route exact path={`${props.match.url}`} component={AllOrgs} />
      <Route exact path={`${props.match.url}/:id`} component={OrgDetails} />
    </Switch>
  );
};
