import React from 'react';

import { Icon, Table } from 'components';

export default class Documents extends React.Component {
  constructor() {
    super();
    this.state = {
      show: undefined,
      tracks: [],
      editDocsOpen: false
    };
  }

  render() {
    const { show } = this.props;
    //const { editDocsOpen } = this.state;

    return (
      <div>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell>Document</Table.HeadCell>
              <Table.HeadCell>Download</Table.HeadCell>
            </Table.Row>
          </Table.Head>
          {show && (
            <Table.Body>
              <Table.Row>
                <Table.Cell>{show.name} Edits</Table.Cell>
                <Table.Cell>
                  <a
                    href={show.edits_doc_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      name="download-harddrive"
                      style={{
                        color: 'black',
                        cursor: 'pointer',
                        fontSize: '23px',
                        fontWeight: '800'
                      }}
                    />
                  </a>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{show.name} Cues</Table.Cell>
                <Table.Cell>
                  <a
                    href={show.cue_doc_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      name="download-harddrive"
                      style={{
                        color: 'black',
                        cursor: 'pointer',
                        fontSize: '23px',
                        fontWeight: '800'
                      }}
                    />
                  </a>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
        </Table>
      </div>
    );
  }
}
