import React from 'react';
import styled from 'styled-components';
import COLORS from './colors';
import { default as media } from './screenSize';

export default props => {
  const Wrapper = styled.div`
    padding: ${props.subMenu ? '0px' : '0 40px'};
    ${props.subMenu &&
      `
      margin: 10px 40px 10px;
      background-color: white;
      box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.12);
      `}
    display: flex;
    ${media.phone`
      padding: 0 12px;
      flex-direction: column;
      justify-content: center;
    `};
  `;
  const parent_props = props;
  return (
    <Wrapper>
      {props.children.map((link, idx) => {
        if (link.type && link.props) {
          const ItemWrapper = styled(link.type)`
            text-decoration: none;
            cursor: pointer;
            border-right: none;
            border-left: none;
            border-top: none;
            font-size: 16px;
            text-align: left;
            margin-right: 50px;
            ${parent_props.subMenu ? `padding: 10px;` : `padding: 5px 3px;`}
            color: ${props => (props.active ? COLORS.black : COLORS.grey)};
            border-bottom: ${props =>
              props.active ? `3px solid ${COLORS.black}` : 'none'};
            background: white;
            &:hover {
              border-bottom: 3px solid ${COLORS.black};
              color: ${COLORS.black};
            }
            ${media.tablet`
            margin: 10px 16px;
            font-size: 16px;
          `};
          ${media.phone`
            margin: 10px 0;
            font-size: 16px;
          `};
          `;
          return (
            <ItemWrapper {...link.props} key={idx}>
              {link.props.children}
            </ItemWrapper>
          );
        } else {
          return null;
        }
      })}
    </Wrapper>
  );
};
