import React from 'react';
import { toast } from 'react-toastify';
import { Button, StyledTable, Spacer } from 'components';
import { removeAuthorization } from 'data-graphql';
import { windowSize } from 'helpers';

export default class Documents extends React.Component {
  constructor() {
    super();
    this.state = {
      auths: []
    };
  }

  deauthorize = async auth => {
    const VARS = {
      bookingId: this.props.booking_id,
      userId: auth.user.id,
      deviceId: auth.device.id
    };
    const REQ_OBJECT = `{
      code
      message
      success
    }`;

    const RESULT = await removeAuthorization(VARS, REQ_OBJECT);

    if (RESULT.data)
      toast.success(`${RESULT.data.removeAuthorization.message}`);

    this.props.onChange();
  };

  componentDidMount() {}

  render() {
    const { auths } = this.props;
    const paginateAuths = auths.length > 10 ? true : false;
    const style = windowSize === 'phone' ? { fontSize: '0.85em' } : {};
    return (
      <div style={style}>
        <Spacer size="20px" />
        <StyledTable
          data={auths}
          noDataText="No current authorizations."
          columns={[
            {
              Header: 'Personal Username',
              accessor: 'user.username'
            },
            {
              Header: 'Device',
              accessor: 'device.description'
            },
            {
              Header: 'Actions',
              Cell: row => (
                <Button
                  style={style}
                  onClick={() => {
                    this.deauthorize(row.original);
                  }}
                >
                  Deauthorize
                </Button>
              )
            }
          ]}
          resizable={false}
          sortable={false}
          showPagination={paginateAuths}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          defaultPageSize={10}
        />
      </div>
    );
  }
}
