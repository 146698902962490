// import React from 'react'
import styled from 'styled-components';
import COLORS from './colors';
import { media, isMTI } from 'helpers';
import { Link } from 'react-router-dom';

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 220px;
  padding: 20px 0;
  background: ${isMTI ? COLORS.darkBlue : COLORS.dark};
  color: ${COLORS.white};
  overflow-y: auto;
  flex-shrink: 0;
  position: relative;
  transition: 0.2s ease-in-out;
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${COLORS.lightGrey};
  }
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${COLORS.grey};
  }
  ${media.desktop`
    width: 300px
  `}
`;

Sidebar.Item = styled(Link)`
  color: ${props => (props.active ? COLORS.yellow : COLORS.white)};
  text-decoration: none;
  font-size: 16px;
  padding: 20px 0 20px 30px;
  &:hover {
    color: ${COLORS.yellow};
  }
  &:first-of-type {
    margin-top: 30px;
  }
  &:last-of-type {
    margin-bottom: 50px;
  }
  & * {
    color: inherit;
    text-decoration: none;
    vertical-align: sub;
  }
  ${media.tablet`
    padding: 15px 0px 15px 15px
  `}
  ${media.phone`
    padding: 10px 0px 10px 15px
  `}
`;

Sidebar.External = styled.a`
  color: ${props => (props.active ? COLORS.yellow : COLORS.white)};
  text-decoration: none;
  font-size: 16px;
  padding: 20px 0 20px 30px;
  &:hover {
    color: ${COLORS.yellow};
  }
  &:first-of-type {
    margin-top: 30px;
  }
  &:last-of-type {
    margin-bottom: 50px;
  }
  & * {
    color: inherit;
    text-decoration: none;
    vertical-align: sub;
  }
  ${media.tablet`
    padding: 15px 0px 15px 15px
  `}
  ${media.phone`
    padding: 10px 0px 10px 15px
  `}
`;

Sidebar.Wrapper = styled.div`
  display: flex;
`;

Sidebar.Text = styled.span`
  display: hidden;
`;

Sidebar.Content = styled.div`
  max-height: 100vh;
  overflow-y: auto;
  width: 100%;
`;

export default Sidebar;
