import React from 'react';
import { Form, Button, Text, FormWrapper, Spacer } from 'components';
import { sendBroadcast } from 'data-graphql';
import { toast } from 'react-toastify';
// import { sendShowEmail } from 'helpers';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default class Email extends React.Component {
  constructor() {
    super();
    this.state = {
      message: undefined
    };
  }

  send = async () => {
    const VARS = {
      showId: this.props.show.id,
      message: this.state.message
    };
    const REQ_OBJECT = `{
      code
      success
      message
      email {
        address
      }
    }`;

    const RESULT = await sendBroadcast(VARS, REQ_OBJECT);

    if (RESULT.data.success && RESULT.data.message) {
      toast.success(RESULT.data.message);
      this.setState({ message: '' });
    }
  };

  handleChange = value => {
    //const value = e.target.value;
    this.setState({ message: value });
  };

  render() {
    return (
      <div>
        <FormWrapper>
          <Form>
            <Text large red>
              Send message to Organization Contacts?
            </Text>
            <Spacer size="10px" />
            <Form.InputWrapper full>
              {/* <Form.TextArea
                value={this.state.message}
                onChange={this.handleChange}
                placeHolder="Type your message here..."
              /> */}
              <ReactQuill
                value={this.state.message}
                onChange={this.handleChange}
              />
              <Spacer size="5px" />
              <Text>
                *Any messages sent from here will be emailed to each contact for
                every organization currently authorized to this show.
              </Text>
              <Spacer size="5px" />
              <Text red>
                A total of {this.props.total_contacts} contacts over{' '}
                {this.props.org_count} organizations.
              </Text>
            </Form.InputWrapper>

            <Spacer size="10px" />
            <Button negative onClick={this.send}>
              Send
            </Button>
          </Form>
        </FormWrapper>
      </div>
    );
  }
}
