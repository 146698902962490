import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AllUsers from './AllUsers';
import UserDetails from './UserDetails';

export default props => {
  return (
    <Switch>
      <Route exact path={`${props.match.url}`} component={AllUsers} />
      <Route exact path={`${props.match.url}/:id`} component={UserDetails} />
    </Switch>
  );
};
