import gql from 'graphql-tag';
import graphql from './graphql';

export async function getOrganizations(VARS, REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
          query GetOrganizations(
            $page: Int!
            $search: String
            $withExpiringShows: Boolean
            $withBookedShows: Boolean
            $firstLetter: String
          ) {
            getOrganizations(
              input: {
                page: $page
                search: $search
                withExpiringShows: $withExpiringShows
                withBookedShows: $withBookedShows
                firstLetter: $firstLetter
              }
            ) 
            ${REQ_OBJECT}
          }
        `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function getOrganization(VARS, REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
        query GetOrganization($id: UUID!) {
          getOrganization(id: $id) 
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function createOrganization(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation CreateOrganization($input: CreateOrgInput!) {
          createOrganization(input: $input)
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function updateOrganization(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation UpdateOrganization($id: UUID!, $input: UpdateOrgInput!) {
          updateOrganization(id: $id, input: $input)
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function mergeOrganizations(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation mergeOrganizations($fromOrgId: UUID!, $toOrgId: UUID!) {
          mergeOrganizations(fromOrgId: $fromOrgId, toOrgId: $toOrgId)
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function resetOrganization(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation ResetOrganization($orgId: UUID!, $newEmail: String!) {
          resetOrganization(orgId: $orgId, newEmail: $newEmail)
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}
