import React, { Component } from 'react';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import { Icon, Text, Spacer, Button } from 'components';
import styled from 'styled-components';

const CloseCircle = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 24px;
  right: 24px;
`;

export default class ModalContent extends Component {
  constructor() {
    super();
    this.state = {
      scrolled: false
    };
  }

  enableButton = () => {
    this.setState({ scrolled: true });
  };

  render() {
    return (
      <>
        <CloseCircle onClick={this.props.closeModal}>
          <Icon name="close" darkBlue />
        </CloseCircle>
        <Text huge white>
          Accept Terms and Conditions
        </Text>
        <Spacer size="24px" />
        <Text large white>
          Before you can access your PAR Production Tracks for your production
          of {this.props.bookingName}, you must accept our Terms and Conditions:
        </Text>
        <Spacer size="24px" />
        <BottomScrollListener
          onBottom={this.enableButton}
          triggerOnNoScroll={true}
          offset={20}
        >
          {scrollRef => (
            <div
              style={{
                background: 'white',
                color: 'black',
                width: '90%',
                height: '60%',
                overflow: 'scroll',
                padding: '16px'
              }}
              ref={scrollRef}
            >
              1. Licensee shall NOT have the right to print, copy, arrange,
              adapt or edit any of the PAR without the aid, or permission from
              MTI. COPYING, RECORDING, OR TAMPERING WITH PAR IS A VIOLATION OF
              THE TERMS OF LICENSEE'S PRODUCTION CONTRACT. Licensee understands
              that its failure to follow the above requirements, even if
              inadvertent, will incur liability for statutory copyright
              infringement under federal law. Licensee agrees that, without
              limiting any other recovery that MTI may obtain against Licensee,
              whether at law or at equity, for its breach of this Agreement,
              Licensee shall, at a minimum, reimburse MTI for its out-of-pocket
              legal fees and shall pay to MTI damages equal to three times the
              total license royalty fees paid or payable to MTI by Licensee for
              its production of the Play. 2. Licensee agrees and acknowledges
              that any violation of the foregoing Paragraph 1 of these Terms and
              Conditions may result in the immediate termination of the
              Licensee's license to use PAR, as well as the associated
              Production Contract. MTI shall not be liable to Licensee or any
              third party for any direct or indirect costs arising from such
              termination(s). 3. Licensee may cancel its PAR order for a full
              refund of the rental fee at any point prior to the date Rehearsal
              Tracks will be available for download. HOWEVER, if Licensee
              cancels its order on or after that date, the rental fee is
              non-refundable. 4. Playbill/Program - The Licensee's program for
              the Play must include the following billing: Recorded Music
              provided by special arrangement with Music Theatre International
              (MTI). 5. The rights granted herein are personal and unique to
              Licensee's organization and may not beassigned or transferred.
              These Terms and Conditions identify the totality of the rights
              granted to Licensee with respect to PAR and any related media and
              technology; all other rights in PAR and any related media and
              technology are reserved to MTI. 6. Licensee's PAR rental order
              includes performance and rehearsal tracks, which are delivered
              digitally or can be downloaded through The MTI App. Production
              tracks can only be downloaded through the MTI App on Apple devices
              (iPad, iTouch, iPhone) operating iOS 7 or later. For Licensee's
              production, production tracks can only be authorized and
              downloaded onto two (2) devices. 7. Provided Licensee has accepted
              these Terms and Conditions and MTI has received the full rental
              fee, as well as the applicable royalty, rental and security fees
              for Licensee's production, Licensee's Rehearsal Tracks will be
              available approximately two (2) months prior to the first
              performance. Licensee's Production Tracks will be available
              approximately two (2) weeks prior to the first performance. Make
              the capitalization of Performance and Rehearsal Tracks consistent
              throughout. 8. While MTI has gone to great lengths to insure
              stable operation of the MTI App, no warranty is offered or implied
              as to the suitability of the MTI App for Licensee's particular
              purposes. 9. Licensee agrees to indemnify and hold harmless MTI
              and its affiliates, and the authors/owners ofthe Play against and
              from any and all charges, costs, expenses and liabilities that MTI
              may incur as a result of any demands, claims or actions brought by
              reason of the making of, production, shipping, operation, and/or
              use of the tracks. 10. Licensee may not use or allow the use of
              PAR for any production other than the one licensed to Licensee by
              MTI identified under the "PRODUCTION" heading of its Production
              Contract. 11. As consideration for its rental and use of PAR,
              Licensee agrees to pay the fees set forth in the Production
              Contract. 12. Alterations and/or modifications and edits are
              available upon request and are billed at a rate of $60 per hour
              with a 1/2 hour minimum for each instance edits are submitted. All
              edits and modifications are requested to be submitted no later
              than three (3) weeks prior to Licensee's opening date. Alterations
              will be delivered for approval approximately five (5) days after
              the latest submission request. 13. These Terms and Conditions
              shall be governed by the laws of the State of New York. 14.
              Licensee must accept these Terms and Conditions of this Agreement
              and make manifest suchacceptance by clicking "I Accept." MTI shall
              be under no obligation to take any action pursuant to Licensee's
              PAR order unless these Terms and Conditions have been accepted.
              CLICKING "I ACCEPT"WILL CONSTITUTE A BINDING AGREEMENT PURSUANT TO
              WHICH MUSIC THEATRE INTERNATIONAL, LLC D/B/A MUSIC THEATRE
              INTERNATIONAL ("MTI") SHALL RENT TO THE LICENSEE NAMED IN THE MTI
              PRODUCTION CONTRACT FOR YOUR ORGANIZATION'S PRODUCTION OF THE PLAY
              ("PRODUCTION CONTRACT") PERFORMANCE ACCOMPANIMENT RECORDINGS AND
              REHEARSAL TRACKS SOLELY FOR USE IN CONNECTION WITH THE PRODUCTION
              OF THE PLAY LICENSED THROUGH MTI
            </div>
          )}
        </BottomScrollListener>
        <Spacer size="24px" />
        <Button
          {...(!this.state.scrolled && { disabled: true })}
          noHover
          onClick={this.props.acceptTerms}
          large
        >
          I Accept
        </Button>
      </>
    );
  }
}
