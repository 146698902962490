import React from 'react';
import {
  Text,
  Button,
  StyledTable,
  ExpanderSpan,
  Spacer,
  StyledBullet
} from 'components';
import EditsForm from './EditsForm.js';
import { getTrPropsFn, capitalize, isMedia, isMTI } from 'helpers';
import Popover from 'react-awesome-popover';
import 'react-awesome-popover/build/index.css';

export default class FutureShowsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  handleSubComponentChange = () => {
    this.props.onChange();
  };

  navTracks = row => {
    this.props.showTracks(row.original.id);
  };

  render() {
    const { organization } = this.props;

    const paginateBookedBookings =
      organization.bookings.filter(booking => {
        return booking.state === 'booked';
      }).length > 3
        ? true
        : false;
    const paginateQuotedBookings =
      organization.bookings.filter(booking => {
        return booking.state === 'quoted' || booking.state === 'approved';
      }).length > 3
        ? true
        : false;

    return (
      <div>
        <br />
        <Text large bold>
          Booked
        </Text>
        <br />
        <StyledTable
          data={organization.bookings.filter(booking => {
            return booking.state === 'booked';
          })}
          noDataText="No booked shows."
          columns={[
            {
              Header: 'Title',
              accessor: 'show.name',
              minWidth: 100,
              Cell: row => {
                return (
                  <div
                    onClick={() => {
                      this.navTracks(row);
                    }}
                  >
                    <Text style={{ display: 'inline-block' }}>{row.value}</Text>
                    <Spacer size="2px" />
                    {row.original.notes && (
                      <ul>
                        <Popover arrow={false} position="right" action="hover">
                          <StyledBullet color={row.original.notes_color}>
                            <Text small>
                              {'  '}
                              {row.original.notes}
                            </Text>
                          </StyledBullet>

                          <Text small>{row.original.notes}</Text>
                        </Popover>
                      </ul>
                    )}
                  </div>
                );
              }
            },
            {
              Header: 'Rehearsal From',
              accessor: 'rehearsal_active_date',
              minWidth: 100,
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Production From',
              accessor: 'production_active_date',
              minWidth: 100,
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Opening',
              accessor: 'opening',
              minWidth: 100,
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Closing',
              accessor: 'expiration',
              minWidth: 100,
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Edits',
              accessor: 'edits_status',
              minWidth: 122,
              show: isMTI ? false : true,
              Cell: row => (
                <Button noHover>
                  {row.value ? capitalize(row.value).replace('_', ' ') : 'None'}
                </Button>
              )
            },
            {
              Header: 'Rate',
              accessor: 'rate',
              minWidth: 91,
              width: 100,
              show: isMTI ? false : true,
              Cell: row => (
                <Button noHover status={row.original.rate_status || 'default'}>
                  {row.value || `Rate`}
                </Button>
              )
            },
            {
              Header: 'Edit',
              expander: true,
              width: 65,
              maxWidth: 65,
              minWidth: 65,
              Expander: ({ isExpanded }) => (
                <ExpanderSpan
                  {...(isExpanded ? { open: true } : { closed: true })}
                />
              )
            }
          ]}
          resizable={false}
          sortable={false}
          showPagination={paginateBookedBookings}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          defaultPageSize={10}
          getTrProps={getTrPropsFn}
          SubComponent={row => {
            return (
              <EditsForm
                row={row}
                organization={this.props.organization}
                onChange={this.handleSubComponentChange}
              />
            );
          }}
        />
        <br />
        <Text large bold>
          Quoted
        </Text>
        <br />
        <StyledTable
          data={organization.bookings.filter(booking => {
            return booking.state === 'quoted' || booking.state === 'approved';
          })}
          noDataText="No quoted shows."
          columns={[
            {
              Header: 'Title',
              accessor: 'show.name',
              minWidth: 90,
              Cell: row => {
                return (
                  <div
                    onClick={() => {
                      this.navTracks(row);
                    }}
                  >
                    <Text style={{ display: 'inline-block' }}>{row.value}</Text>
                    <Spacer size="2px" />
                    {row.original.notes && (
                      <ul>
                        <Popover arrow={false} position="right" action="hover">
                          <StyledBullet color={row.original.notes_color}>
                            <Text small>
                              {'  '}
                              {row.original.notes}
                            </Text>
                          </StyledBullet>

                          <Text small>{row.original.notes}</Text>
                        </Popover>
                      </ul>
                    )}
                  </div>
                );
              }
            },
            {
              Header: 'Opening',
              accessor: 'opening',
              minWidth: 90,
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Closing',
              accessor: 'expiration',
              minWidth: 90,
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Status/Action',
              //accessor: 'state',
              minWidth: 183,
              width: 183,
              maxWidth: 200
            },
            {
              Header: 'Rate',
              accessor: 'rate',
              // maxWidth: 100,
              minWidth: 91,
              width: 100,
              show: isMTI ? false : true,
              Cell: row => (
                <Button noHover status={row.original.rate_status || 'default'}>
                  {row.value || `Rate`}
                </Button>
              )
            },
            {
              Header: 'Edit',
              expander: true,
              width: 65,
              maxWidth: 65,
              minWidth: 65,
              Expander: ({ isExpanded }) => (
                <ExpanderSpan
                  {...(isExpanded ? { open: true } : { closed: true })}
                />
              )
            }
          ]}
          resizable={false}
          sortable={false}
          showPagination={paginateQuotedBookings}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          defaultPageSize={10}
          getTrProps={getTrPropsFn}
          SubComponent={row => {
            return (
              <EditsForm
                row={row}
                organization={this.props.organization}
                onChange={this.handleSubComponentChange}
              />
            );
          }}
        />
      </div>
    );
  }
}
