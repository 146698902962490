import React from 'react';
import { Header, FormWrapper, Text, Button, Spacer } from 'components';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import styled from 'styled-components';
import { getShows, getOrganizations, http } from 'data-graphql';
import {
  CheckWrapper,
  DateCard,
  Select,
  CheckSelect,
  Input
} from './components';

import {
  rate_status_options,
  edits_status_options,
  booking_state_options
} from 'helpers';
import { toast } from 'react-toastify';

const ButtonWrapper = styled(FormWrapper)`
  padding: 30px;
  margin: 10px auto;
  text-align: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
`;

const type_options = [
  { value: 'rehearsal', label: 'Rehearsal' },
  { value: 'production', label: 'Production' }
];

const booking_req_options = [
  { value: 'contract_ready', label: 'Contract Ready' },
  { value: 'contract_missing', label: 'Contract Missing' },
  { value: 'license_ready', label: 'License Ready' },
  { value: 'license_missing', label: 'License Missing' },
  { value: 'payment_ready', label: 'Payment Ready' },
  { value: 'payment_missing', label: 'Payment Missing' }
];

export default class Reporting extends React.Component {
  constructor() {
    super();
    this.initialState = {
      // created date
      include_created: true,
      createDateStart: '',
      createDateEnd: '',

      // approved date
      include_approved: true,
      approveDateStart: '',
      approveDateEnd: '',

      // opening date
      include_opening: true,
      openingDateStart: '',
      openingDateEnd: '',

      // closing date
      include_closing: true,
      closingDateStart: '',
      closingDateEnd: '',

      // show
      show: null,
      include_show: true,

      // org
      organization: null,
      include_org: true,

      // booking date
      include_booked_at: true,
      bookingDateStart: '',
      bookingDateEnd: '',

      // auth type
      include_auth_type: true,
      auth_type_rehearsal: false,
      auth_type_production: false,

      // state
      include_booking_state: true,
      booking_state_quoted: false,
      booking_state_approved: false,
      booking_state_booked: false,
      booking_state_active: false,
      booking_state_expired: false,
      booking_state_cancelled: false,

      // rate status
      include_rate_status: true,
      rate_status_default: false,
      rate_status_purchase_order: false,
      rate_status_partial_payment: false,
      rate_status_paid_in_full: false,

      // booking requirements
      include_booking_requirements: true,
      is_contract_ready: false,
      is_contract_missing: false,
      is_license_ready: false,
      is_license_missing: false,
      is_payment_ready: false,
      is_payment_missing: false,

      // edit status
      include_edits_status: true,
      edits_status_none: false,
      edits_status_not_submitted: false,
      edits_status_pending: false,
      edits_status_completed: false,
      edits_status_approved: false,
      edits_status_default: false,

      // notes
      include_notes: true,

      // rate
      include_rate: true,

      submitting: false,
      reset: false
    };
    this.state = this.initialState;
  }

  componentDidMount() {}

  resetState = () => {
    this.setState({ reset: true }, this.setNew);
  };
  setNew = () => {
    this.setState(this.initialState);
  };

  submit = async () => {
    let payload = {
      // created date
      ...(this.state.include_created && {
        include_created: this.state.include_created,
        ...(this.state.createDateStart && {
          created_start: this.state.createDateStart
        }),
        ...(this.state.createDateEnd && {
          created_end: this.state.createDateEnd
        })
      }),

      // approved date
      ...(this.state.include_approved && {
        include_approved: this.state.include_approved,
        ...(this.state.approveDateStart && {
          approved_start: this.state.approveDateStart
        }),
        ...(this.state.approveDateEnd && {
          approved_end: this.state.approveDateEnd
        })
      }),

      // opening date
      ...(this.state.include_opening && {
        include_opening: this.state.include_opening,
        ...(this.state.openingDateStart && {
          opening_start: this.state.openingDateStart
        }),
        ...(this.state.openingDateEnd && {
          opening_end: this.state.openingDateEnd
        })
      }),

      // closing date
      ...(this.state.include_closing && {
        include_closing: this.state.include_closing,
        ...(this.state.closingDateStart && {
          closing_start: this.state.closingDateStart
        }),
        ...(this.state.closingDateEnd && {
          closing_end: this.state.closingDateEnd
        })
      }),

      // show
      ...(this.state.include_show && {
        include_show: this.state.include_show,
        ...(this.state.show && { show_id: this.state.show.value })
      }),

      // org
      ...(this.state.include_org && {
        include_org: this.state.include_org,
        ...(this.state.organization && {
          organization_id: this.state.organization.value
        })
      }),

      // booking date
      ...(this.state.include_booked_at && {
        include_booked_at: this.state.include_booked_at,
        ...(this.state.bookingDateStart && {
          booked_start: this.state.bookingDateStart
        }),
        ...(this.state.bookingDateEnd && {
          booked_end: this.state.bookingDateEnd
        })
      }),

      // auth type
      ...(this.state.include_auth_type && {
        include_auth_type: this.state.include_auth_type,
        ...(this.state.auth_type_rehearsal && {
          auth_type_rehearsal: this.state.auth_type_rehearsal
        }),
        ...(this.state.auth_type_production && {
          auth_type_production: this.state.auth_type_production
        })
      }),

      // state
      ...(this.state.include_booking_state && {
        include_booking_state: this.state.include_booking_state,
        ...(this.state.booking_state_quoted && {
          booking_state_quoted: this.state.booking_state_quoted ? true : false
        }),
        ...(this.state.booking_state_approved && {
          booking_state_approved: this.state.booking_state_approved
            ? true
            : false
        }),
        ...(this.state.booking_state_booked && {
          booking_state_booked: this.state.booking_state_booked ? true : false
        }),
        ...(this.state.booking_state_active && {
          booking_state_active: this.state.booking_state_active ? true : false
        }),
        ...(this.state.booking_state_expired && {
          booking_state_expired: this.state.booking_state_expired ? true : false
        }),
        ...(this.state.booking_state_cancelled && {
          booking_state_cancelled: this.state.booking_state_cancelled
            ? true
            : false
        })
      }),

      // rate
      ...(this.state.include_rate && {
        include_rate: this.state.include_rate,
        ...(this.state.rate_low && {
          rate_low: this.state.rate_low
        }),
        ...(this.state.rate_high && {
          rate_high: this.state.rate_high
        })
      }),

      // rate status
      ...(this.state.include_rate_status && {
        include_rate_status: this.state.include_rate_status,
        ...(this.state.rate_status_default && {
          rate_status_default: this.state.rate_status_default ? true : false
        }),
        ...(this.state.rate_status_purchase_order && {
          rate_status_purchase_order: this.state.rate_status_purchase_order
            ? true
            : false
        }),
        ...(this.state.rate_status_partial_payment && {
          rate_status_partial_payment: this.state.rate_status_partial_payment
            ? true
            : false
        }),
        ...(this.state.rate_status_paid_in_full && {
          rate_status_paid_in_full: this.state.rate_status_paid_in_full
            ? true
            : false
        })
      }),

      // edit status
      ...(this.state.include_edits_status && {
        include_edits_status: this.state.include_edits_status,
        ...(this.state.edits_status_none && {
          edits_status_none: this.state.edits_status_none ? true : false
        }),
        ...(this.state.edits_status_not_submitted && {
          edits_status_not_submitted: this.state.edits_status_not_submitted
            ? true
            : false
        }),
        ...(this.state.edits_status_pending && {
          edits_status_pending: this.state.edits_status_pending ? true : false
        }),
        ...(this.state.edits_status_completed && {
          edits_status_completed: this.state.edits_status_completed
            ? true
            : false
        }),
        ...(this.state.edits_status_approved && {
          edits_status_approved: this.state.edits_status_approved ? true : false
        }),
        ...(this.state.edits_status_default && {
          edits_status_default: this.state.edits_status_default ? true : false
        })
      }),

      // booking requirements
      ...(this.state.include_booking_requirements && {
        include_booking_requirements: this.state.include_booking_requirements,
        ...(this.state.booking_req_contract_ready && {
          is_contract_ready: this.state.booking_req_contract_ready
            ? true
            : false
        }),
        ...(this.state.booking_req_contract_missing && {
          is_contract_missing: this.state.booking_req_contract_missing
            ? true
            : false
        }),
        ...(this.state.booking_req_license_ready && {
          is_license_ready: this.state.booking_req_license_ready ? true : false
        }),
        ...(this.state.booking_req_license_missing && {
          is_license_missing: this.state.booking_req_license_missing
            ? true
            : false
        }),
        ...(this.state.booking_req_payment_ready && {
          is_payment_ready: this.state.booking_req_payment_ready ? true : false
        }),
        ...(this.state.booking_req_payment_missing && {
          is_payment_missing: this.state.booking_req_payment_missing
            ? true
            : false
        })
      }),

      // notes
      ...(this.state.include_notes && {
        include_notes: this.state.include_notes,
        ...(this.state.notes && {
          notes: this.state.notes
        })
      }),

      // limit
      ...(this.state.limit && {
        limit: this.state.limit
      })
    };

    this.setState(prevState => ({
      submitting: !prevState.submitting
    }));

    http()
      .post('/reports/booking', payload)
      .then(res => {
        if (res.result) {
          this.downloadCsvFile(res.result);
          this.setState(prevState => ({
            submitting: !prevState.submitting
          }));
        }
      })
      // eslint-disable-next-line
      .catch(e => {
        this.setState(prevState => ({
          submitting: !prevState.submitting
        }));
      });
  };

  downloadCsvFile = csvText => {
    const element = document.createElement('a');
    const file = new Blob([csvText], { type: 'text/csv' });
    element.href = URL.createObjectURL(file);
    element.download = `data_export_${moment().format('YYYY-MM-DD')}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    toast.success('Downloading report!');
  };

  handleCheckboxChange = (name, checked) => {
    this.setState({ [name]: checked });
  };

  handleDateChange = (selectedDay, modifiers, dayPickerInput) => {
    let returnObj = {};
    returnObj[dayPickerInput.props.name] = selectedDay
      ? moment(selectedDay).format('YYYY-MM-DD')
      : null;
    this.setState(returnObj);
  };

  searchShows = async inputValue => {
    const VARS = {
      search: inputValue,
      page: 1
    };
    const REQ_OBJECT = `{
      shows{
        name
        id
      }
    }`;
    const RESULT = await getShows(VARS, REQ_OBJECT);
    const options = RESULT.data.getShows.shows.map(item => ({
      value: item.id,
      label: item.name
    }));
    return options;
  };

  searchOrgs = async inputValue => {
    const VARS = {
      search: inputValue,
      page: 1
    };
    const REQ_OBJECT = `{
      organizations{
        name
        id
      }
    }`;
    const RESULT = await getOrganizations(VARS, REQ_OBJECT);
    const options = RESULT.data.getOrganizations.organizations.map(item => {
      return { value: item.id, label: item.name };
    });
    return options;
  };

  handleShowChange = show => {
    this.setState({ show: show });
  };

  handleNotesChange = e => {
    this.setState({
      notes: e.target.value
    });
  };

  handleRateLowChange = e => {
    this.setState({
      rate_low: e.target.value
    });
  };

  handleRateHighChange = e => {
    this.setState({
      rate_high: e.target.value
    });
  };

  handleLimitChange = e => {
    this.setState({
      limit: e.target.value
    });
  };

  handleOrgChange = org => {
    this.setState({ organization: org });
  };

  handleAssignInput = newValue => {
    const inputValue = newValue;
    this.setState({ inputValue });
    return inputValue;
  };

  addKeys = array => {
    return array.map(item => {
      item.key = `${item.value}${Math.random()}`;
      return item;
    });
  };

  render() {
    return (
      <>
        <Header>
          <Header.Head bold huge>
            Reporting
          </Header.Head>
        </Header>
        <FormWrapper style={{ boxShadow: '0px 1px 1px rgba(0,0,0,0.06)' }}>
          <Text large red>
            Quote/Booking
          </Text>
          <Spacer size="5px" />
          <Text>
            Use the filters below to narrow the scope of the report. Checkboxes
            indicate which values will be included on the report. Filters may
            still be applied, even if a checkbox is unselected. To not use a
            filter, leave it empty.
          </Text>
        </FormWrapper>
        <CheckWrapper
          reset={this.state.reset}
          name="include_created"
          onCheck={this.handleCheckboxChange}
          title="Created Date"
        >
          <DateCard
            startDate="createDateStart"
            endDate="createDateEnd"
            startDateValue={this.state.startDateValue}
            endDateValue={this.state.endDateValue}
            handleDateChange={this.handleDateChange}
          />
        </CheckWrapper>

        <CheckWrapper
          reset={this.state.reset}
          name="include_approved"
          onCheck={this.handleCheckboxChange}
          title="Approved Date"
        >
          <DateCard
            startDate="approveDateStart"
            endDate="approveDateEnd"
            startDateValue={this.state.startDateValue}
            endDateValue={this.state.endDateValue}
            handleDateChange={this.handleDateChange}
          />
        </CheckWrapper>
        <CheckWrapper
          reset={this.state.reset}
          onCheck={this.handleCheckboxChange}
          title="Opening Date"
          name="include_opening"
        >
          <DateCard
            startDate="openingDateStart"
            endDate="openingDateEnd"
            startDateValue={this.state.startDateValue}
            endDateValue={this.state.endDateValue}
            handleDateChange={this.handleDateChange}
          />
        </CheckWrapper>
        <CheckWrapper
          reset={this.state.reset}
          name="include_closing"
          onCheck={this.handleCheckboxChange}
          title="Closing Date"
        >
          <DateCard
            startDate="closingDateStart"
            endDate="closingDateEnd"
            startDateValue={this.state.startDateValue}
            endDateValue={this.state.endDateValue}
            handleDateChange={this.handleDateChange}
          />
        </CheckWrapper>
        <CheckWrapper
          reset={this.state.reset}
          name="include_show"
          onCheck={this.handleCheckboxChange}
          title="Title"
        >
          <Select
            search={this.searchShows}
            handleAssignInput={this.handleAssignInput}
            value={this.state.show}
            handleChange={this.handleShowChange}
            placeholder="Start typing a show's title..."
          />
        </CheckWrapper>
        <CheckWrapper
          reset={this.state.reset}
          name="include_org"
          onCheck={this.handleCheckboxChange}
          title="Organization"
        >
          <Select
            search={this.searchOrgs}
            handleAssignInput={this.handleAssignInput}
            value={this.state.org}
            handleChange={this.handleOrgChange}
            placeholder="Start typing an organization's name..."
          />
        </CheckWrapper>
        <CheckWrapper
          reset={this.state.reset}
          name="include_booked_at"
          onCheck={this.handleCheckboxChange}
          title="Booking Date"
        >
          <DateCard
            startDate="bookingDateStart"
            endDate="bookingDateEnd"
            startDateValue={this.state.startDateValue}
            endDateValue={this.state.endDateValue}
            handleDateChange={this.handleDateChange}
          />
        </CheckWrapper>
        <CheckWrapper
          reset={this.state.reset}
          name="include_auth_type"
          onCheck={this.handleCheckboxChange}
          title="Type"
        >
          <CheckSelect
            reset={this.state.reset}
            prefix="auth_type_"
            options={this.addKeys(type_options)}
            onCheck={this.handleCheckboxChange}
          />
        </CheckWrapper>
        <CheckWrapper
          reset={this.state.reset}
          name="include_booking_state"
          onCheck={this.handleCheckboxChange}
          title="Status"
        >
          <CheckSelect
            reset={this.state.reset}
            prefix="booking_state_"
            options={this.addKeys(booking_state_options)}
            onCheck={this.handleCheckboxChange}
          />
        </CheckWrapper>
        <CheckWrapper
          reset={this.state.reset}
          name="include_rate"
          onCheck={this.handleCheckboxChange}
          title="Rate"
        >
          <Input
            name="low_rate"
            type="number"
            handleChange={this.handleRateLowChange}
            form_label="Filter for rates between: "
          />
          <Input
            name="high_rate"
            type="number"
            handleChange={this.handleRateHighChange}
            form_label="And: "
          />
        </CheckWrapper>
        <CheckWrapper
          reset={this.state.reset}
          name="include_rate_status"
          onCheck={this.handleCheckboxChange}
          title="Rate Status"
        >
          <CheckSelect
            reset={this.state.reset}
            prefix="rate_status_"
            options={this.addKeys(rate_status_options)}
            onCheck={this.handleCheckboxChange}
          />
        </CheckWrapper>

        <CheckWrapper
          reset={this.state.reset}
          name="include_edits_status"
          onCheck={this.handleCheckboxChange}
          title="Edits Status"
        >
          <CheckSelect
            reset={this.state.reset}
            prefix="edits_status_"
            options={this.addKeys(edits_status_options)}
            onCheck={this.handleCheckboxChange}
          />
        </CheckWrapper>
        <CheckWrapper
          reset={this.state.reset}
          name="include_booking_requirements"
          onCheck={this.handleCheckboxChange}
          title="Booking Requirements"
        >
          <CheckSelect
            reset={this.state.reset}
            prefix="booking_req_"
            options={this.addKeys(booking_req_options)}
            onCheck={this.handleCheckboxChange}
          />
        </CheckWrapper>
        <CheckWrapper
          reset={this.state.reset}
          name="include_notes"
          onCheck={this.handleCheckboxChange}
          title="Notes"
        >
          <Input
            name="notes"
            type="text"
            handleChange={this.handleNotesChange}
            form_label="Enter text to filter notes. Leave this blank to not filter any notes."
          />
        </CheckWrapper>
        <CheckWrapper
          reset={this.state.reset}
          name="limit"
          onCheck={this.handleCheckboxChange}
          title="Limit"
          hideCheckbox
        >
          <Input
            name="limit"
            type="number"
            handleChange={this.handleLimitChange}
            form_label="The max number of rows to be in the report. Defaults to 2000."
          />
        </CheckWrapper>

        <ButtonWrapper>
          <Button large onClick={this.submit}>
            {this.state.submitting ? 'Submitting...' : 'Submit'}
          </Button>
          <Button
            style={{ marginLeft: '20px' }}
            large
            negative
            onClick={this.resetState}
          >
            Reset Form
          </Button>
        </ButtonWrapper>
      </>
    );
  }
}
