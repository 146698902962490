import React from 'react';
import {
  Wrapper,
  Icon,
  Button,
  StyledTable,
  StyledSlideDown,
  Spacer,
  FileUpload,
  Menu,
  Text,
  Form
} from 'components';
import CreateSetlistForm from './CreateSetlistForm';
import { formatDate, windowSize } from 'helpers';
import {
  deleteTrack,
  deleteSetlist,
  makeTrackInactive,
  changeSetlistOfTrack
} from 'data-graphql';
import { toast } from 'react-toastify';
import EditTrack from './EditTrack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
export default class Tracks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: undefined,
      tracks: [],
      addTracksOpen: false,
      createSetlistOpen: false,
      menu: this.props.show.master_setlist.name,
      selectedSetlist: this.props.show.master_setlist,
      edit: false
    };
  }

  close = () => {
    this.setState({
      createSetlistOpen: false,
      addTracksOpen: false
    });
    this.props.onChange();
  };

  UNSAFE_componentWillReceiveProps() {
    const selectedSetlist = this.props.setlists.filter(
      setlist => setlist.name === this.state.menu
    )[0];
    selectedSetlist !== this.state.selectedSetlist &&
      this.setState({ selectedSetlist: selectedSetlist });
  }

  selectSetlist = e => {
    const id = e.target.id;
    const setlist = this.props.setlists.filter(setlist => setlist.id === id)[0];
    this.setState({ menu: setlist.name, selectedSetlist: setlist });
  };

  removeTrack = async row => {
    const confirm = window.confirm(
      'Deleting this track is permanent and cannot be undone, it will delete this track for all users. Are you sure you want to continue?'
    );
    if (!confirm) {
      return false;
    } else {
      const VARS = { id: row.original.id };
      const REQ_OBJECT = `{
        code
        success
        message
      }`;
      const RESULT = await deleteTrack(VARS, REQ_OBJECT);

      if (RESULT.data && RESULT.data.message) {
        toast.success(RESULT.data.message);
        this.props.onChange();
      }
    }
  };

  deleteThisSetlist = async () => {
    const VARS = { id: this.state.selectedSetlist.id };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    const RESULT = await deleteSetlist(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.message) {
      toast.success(RESULT.data.message);
      this.setState(
        {
          selectedSetlist: this.props.show.master_setlist,
          menu: this.props.show.master_setlist.name
        },
        this.props.onChange
      );
    }
  };

  createSetlistOpen = () => {
    this.setState({
      createSetlistOpen: this.state.createSetlistOpen === true ? false : true
    });
  };

  addTracksOpen = () => {
    this.setState({
      addTracksOpen: this.state.addTracksOpen === true ? false : true
    });
  };

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const name = e.target.name;
    this.state.selectedSetlist.tracks.map(item => {
      if (item.id === e.target.dataset.rowid) {
        item[name] = value;
      }
      return item;
    });
    this.setState({ selectedSetlist: this.state.selectedSetlist });
  };

  deactivateTrack = async row => {
    const VARS = {
      track_id: row.original.id,
      setlist_id: this.state.selectedSetlist.id
    };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;
    const RESULT = await makeTrackInactive(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.message) {
      toast.success(RESULT.data.message);
      this.props.onChange();
    }
  };

  moveTrack = async row => {
    const VARS = {
      track_id: row.original.id,
      current_setlist_id: this.state.selectedSetlist.id,
      target_setlist_id: this.state.targetSetlist.id
    };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;
    const RESULT = await changeSetlistOfTrack(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.message) {
      toast.success(RESULT.data.message);
      this.props.onChange();
    }
  };

  handleSetlistChange = async (setlist, meta) => {
    const VARS = {
      track_id: meta.name,
      current_setlist_id: this.state.selectedSetlist.id,
      target_setlist_id: setlist.id
    };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;
    const RESULT = await changeSetlistOfTrack(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.message) {
      toast.success(RESULT.data.message);
      this.props.onChange();
    }
  };

  render() {
    const { setlists } = this.props;
    const { addTracksOpen } = this.state;
    if (!this.state.selectedSetlist) return null;
    return (
      <div>
        <Menu subMenu={true}>
          {setlists.map(setlist => {
            return (
              <button
                key={setlist.id}
                id={setlist.id}
                onClick={this.selectSetlist}
                active={this.state.menu === `${setlist.name}`}
              >
                {setlist.name}
              </button>
            );
          })}
        </Menu>

        <Wrapper>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'start',
              alignContent: 'center'
            }}
          >
            <Button
              onClick={this.createSetlistOpen}
              style={{ verticalAlign: '40%' }}
            >
              Create Setlist +
            </Button>
            <Button
              onClick={this.addTracksOpen}
              {...(windowSize === 'phone'
                ? { style: { marginTop: '8px' } }
                : { style: { verticalAlign: '40%', marginLeft: '50px' } })}
            >
              Add Tracks to {this.state.menu} {addTracksOpen ? '-' : '+'}
            </Button>
            {this.state.selectedSetlist.type !== 'master' &&
              this.state.selectedSetlist.name !== 'Inactive Tracks' && (
                <Button
                  negative
                  onClick={this.deleteThisSetlist}
                  style={{ marginLeft: 'auto' }}
                >
                  Delete {this.state.selectedSetlist.name}
                </Button>
              )}
          </div>

          <StyledSlideDown>
            {this.state.addTracksOpen && (
              <FileUpload
                show={this.props.show}
                setlist={this.state.selectedSetlist.id}
                onChange={this.close}
              />
            )}
            {this.state.createSetlistOpen && (
              <CreateSetlistForm show={this.props.show} onChange={this.close} />
            )}
          </StyledSlideDown>

          <Spacer size="20px" />

          <StyledTable
            noShadow
            data={this.state.selectedSetlist.tracks}
            resizable={false}
            sortable={false}
            showPagination={false}
            showPaginationTop={false}
            showPaginationBottom={false}
            showPageSizeOptions={false}
            defaultPageSize={1000}
            resizeable={false}
            minRows={1}
            columns={[
              {
                Header: 'Move to',
                maxWidth: 200,
                minWidth: 200,
                show:
                  windowSize !== 'phone' &&
                  this.state.selectedSetlist.name !== 'Inactive Tracks',
                Cell: row => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '90%',
                      justifyContent: 'center'
                    }}
                  >
                    <Form.InputWrapper full style={{ fontSize: '12px' }}>
                      <Form.StyledSelect
                        value={this.state.targetSetlist}
                        onChange={this.handleSetlistChange}
                        options={setlists.filter(
                          setlist => !setlist.name.includes('Inactive')
                        )}
                        isSearchable={false}
                        name={row.original.id}
                        placeholder="Choose Setlist"
                        menuPosition="fixed"
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        styles={Form.CustomStyles}
                      />
                    </Form.InputWrapper>
                  </div>
                )
              },
              {
                Header: 'Cue',
                accessor: 'cue_number',
                maxWidth: 150,
                minWidth: 75
              },
              {
                Header: 'Name',
                accessor: 'track_name',
                maxWidth: 400,
                minWidth: 200,
                Cell: props => (
                  <div>
                    <Text>{props.value}</Text>
                  </div>
                )
              },
              {
                Header: 'Date Uploaded',
                id: 'updated_at',
                show:
                  windowSize !== 'phone' &&
                  this.state.selectedSetlist.name !== 'Inactive Tracks',
                accessor: row => formatDate(row.updated_at, 'MMM D, YYYY')
              },
              {
                Header: 'Date Replaced',
                id: 'deleted_at',
                show: this.state.selectedSetlist.name === 'Inactive Tracks',
                accessor: row => formatDate(row.deleted_at, 'MMM D, YYYY')
              },
              {
                Header: 'RT',
                maxWidth: 60,
                minWidth: 60,
                accessor: 'has_rehearsal',
                show: windowSize !== 'phone',
                Cell: props =>
                  props.value === true && (
                    <Icon
                      name="check-box"
                      style={{
                        fontSize: '23px',
                        fontWeight: '800'
                      }}
                    />
                  )
              },
              {
                Header: 'PT',
                maxWidth: 60,
                minWidth: 60,
                accessor: 'has_production',
                show: windowSize !== 'phone',
                Cell: props =>
                  props.value === true && (
                    <Icon
                      name="check-box"
                      style={{
                        fontSize: '23px',
                        fontWeight: '800'
                      }}
                    />
                  )
              },
              {
                Header: 'MID',
                maxWidth: 60,
                minWidth: 60,
                accessor: 'midi_location',
                show: windowSize !== 'phone',
                Cell: props =>
                  props.value && (
                    <Icon
                      name="check-box"
                      style={{
                        fontSize: '23px',
                        fontWeight: '800'
                      }}
                    />
                  )
              },
              {
                Header: 'Active setlists',
                accessor: 'active_setlist_count',
                show: this.state.selectedSetlist.name === 'Inactive Tracks'
              },
              {
                Header: 'Duration',
                accessor: 'time_formatted',
                show: this.state.selectedSetlist.name !== 'Inactive Tracks'
              },
              {
                Header: 'Edit',
                width: 75,
                expander: true,
                show: this.state.selectedSetlist.name !== 'Inactive Tracks',
                Expander: () => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '90%',
                      justifyContent: 'center'
                    }}
                  >
                    <Icon
                      name="pencil-1"
                      style={{ cursor: 'pointer', color: '#333' }}
                    />
                  </div>
                )
              },
              {
                Header: 'Deactivate',
                show: this.state.selectedSetlist.name !== 'Inactive Tracks',
                Cell: row => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '90%',
                      justifyContent: 'center'
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ cursor: 'pointer', color: '#333' }}
                      icon={faSignOutAlt}
                      onClick={() => this.deactivateTrack(row)}
                    />
                  </div>
                )
              },
              {
                Header: 'Delete',
                Cell: row => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '90%',
                      justifyContent: 'center'
                    }}
                  >
                    <Icon
                      name="bin"
                      onClick={() => this.removeTrack(row)}
                      style={{ cursor: 'pointer', color: 'red' }}
                    />
                  </div>
                )
              }
            ]}
            SubComponent={row => (
              <div
                style={{
                  margin: '-12px 10px 15px 10px',
                  textAlign: 'left',
                  backgroundColor: 'white',
                  padding: '20px'
                }}
              >
                <EditTrack
                  track={row.original}
                  show={this.props.show}
                  setlist={this.state.selectedSetlist.id}
                  onChange={this.props.onChange}
                />
              </div>
            )}
          />
        </Wrapper>
      </div>
    );
  }
}
