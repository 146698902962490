import React from 'react';
import { http, getShow, getBooking } from 'data-graphql';
import axios from 'axios';
import {
  FormWrapper,
  Spacer,
  FileLabel,
  Text,
  FlexBox,
  Button
} from 'components';
import styled from 'styled-components';
import { COLORS } from 'components';
import { debounce } from 'lodash';
import { replaceTrack } from '../data-graphql';

const ProgressBar = props => {
  const ProgWrapper = styled.div`
    background: ${COLORS.lightGrey};
    position: relative;
    height: 20px;
    width: 100%;
    border-radius: 50px;
    border: 1x solid #333;
  `;

  return (
    <ProgWrapper>
      <Filler percentage={props.progress} />
    </ProgWrapper>
  );
};

const Filler = props => {
  const FillerWrapper = styled.div`
    background: ${COLORS.darkRed};
    height: 100%;
    border-radius: inherit;
    transition: width 0.3s ease-in;
  `;

  return <FillerWrapper style={{ width: `${props.percentage}%` }} />;
};

export default class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progressBars: [],
      change: debounce(this.props.onChange, 1000),
      uploadError: false
    };
  }

  uploadFiles = async event => {
    const files = [];
    // eslint-disable-next-line
    for (const file of event.target.files) {
      if (file.size > 286000000) {
        window.alert(
          `The selected file: ${file.name} is too large for this application.`
        );
        return false;
      } else {
        files.push(file);
      }
    }

    const uploads = await Promise.all(
      files.map(async file => {
        const res = await http().get('/tracks/signed-url', {
          params: {
            filename: file.name,
            filetype: file.type,
            setlistId: this.props.setlist
          }
        });
        return {
          originalName: file.name,
          name: file.name,
          url: res.result.signedUrl,
          progress: 0,
          file: file
        };
      })
    );
    this.uploadToAWS(uploads);
  };

  uploadReplacementFiles = async event => {
    const files = [];
    const file_names = [];
    // eslint-disable-next-line
    for (const file of event.target.files) {
      if (file.size > 286000000) {
        window.alert(
          `The selected file: ${file.name} is too large for this application.`
        );
        return false;
      } else {
        file_names.push({ file_name: file.name });
        files.push(file);
      }
    }
    const VARS = {
      id: this.props.track.id,
      setlist_id: this.props.setlist,
      input: file_names
    };

    const REQ_OBJECT = `{
      success
      message
      code
      signed_urls 
      track {
        id
        cue_number
        track_name
      }
    }`;
    const RESULT = await replaceTrack(VARS, REQ_OBJECT);
    if (RESULT.data && RESULT.data.success) {
      //toast.success(`${RESULT.data.message}`);
      const uploads = files.map((file, index) => {
        return {
          originalName: file.name,
          name: file.name,
          url: RESULT.data.replaceTrack.signed_urls[index],
          progress: 0,
          file: file
        };
      });
      this.uploadToAWS(uploads);
    }
  };

  uploadToAWS = async uploads => {
    this.setState({ progressBars: uploads });
    let shouldUpdate = false;
    await Promise.all(
      uploads.map(async upload => {
        let type = upload.file.type;
        if (upload.file.type === 'audio/mp3') type = 'audio/mpeg';
        return axios.put(upload.url, upload.file, {
          headers: {
            'Content-Type': type
          },
          onUploadProgress: event => {
            const newProg = Math.floor((event.loaded * 100) / event.total);
            if (upload.progress !== newProg) {
              shouldUpdate = true;
            }
            upload.progress = newProg;
            if (shouldUpdate) {
              this.setState({ progressBars: uploads });
            }
            return upload;
          }
        });
      })
    );
    let check = setInterval(this.checkProgress, 2500);
    this.setState({ check: check });
    setTimeout(() => {
      clearInterval(this.state.check);
      this.setState({ uploadError: true });
    }, 60000);
  };

  removeFile(file) {
    const updatedFiles = this.props.files.filter(f => f.file !== file.file);
    this.props.updateFiles(updatedFiles);
  }

  checkProgress = async () => {
    let setlist;
    if (this.props.booking) {
      const VARS = { id: this.props.booking };
      const REQ_OBJECT = `{
				custom_setlist {
					id
					tracks {
						id
						production_location
            rehearsal_location
            midi_location
					}
				}
			}`;
      const RESULT = await getBooking(VARS, REQ_OBJECT);
      setlist = RESULT.data.getBooking.custom_setlist;
    } else {
      const VARS = { id: this.props.show.id };
      const REQ_OBJECT = `{
				master_setlist {
					id
					tracks {
						id
						production_location
            rehearsal_location
            midi_location
					}
				}
				alt_setlists {
					id
					tracks {
						id
						production_location
            rehearsal_location
            midi_location
					}
				}
			}`;
      const RESULT = await getShow(VARS, REQ_OBJECT);
      setlist =
        RESULT.data.getShow.master_setlist.id === this.props.setlist
          ? RESULT.data.getShow.master_setlist
          : RESULT.data.getShow.alt_setlists.filter(
              item => item.id === this.props.setlist
            )[0];
    }
    const tracks = setlist.tracks.length;
    const completed_tracks = setlist.tracks.filter(track => {
      if (
        track.production_location ||
        track.rehearsal_location ||
        track.midi_location
      ) {
        return track;
      }
    });
    if (tracks === completed_tracks.length) {
      clearInterval(this.state.check);
      this.props.onChange();
    }
  };

  render() {
    return (
      <FormWrapper>
        {this.props.track && (
          <>
            <Text>
              To replace this track select either just the production track or
              both the production and the rehearsal track.
            </Text>
            <Spacer size="20px" />
          </>
        )}
        <FileLabel htmlFor="docInput">
          {this.props.track ? 'Replace this track' : 'Upload'}
          <input
            type="file"
            id="docInput"
            multiple
            accept=".wav,.mp3,.mid"
            onChange={
              this.props.track ? this.uploadReplacementFiles : this.uploadFiles
            }
            style={{
              opacity: '0',
              position: 'absolute',
              pointerEvents: 'none'
            }}
          />
        </FileLabel>
        {this.state.uploadError && (
          <FlexBox
            direction="column"
            justify="center"
            align="center"
            style={{ margin: '30px 0px' }}
          >
            <Text large red>
              Something went wrong!
            </Text>
            <Spacer size="20px" />
            <Button onClick={this.props.onChange}>Close</Button>
          </FlexBox>
        )}
        {this.state.progressBars.length > 0 && !this.state.uploadError && (
          <React.Fragment>
            <Spacer size={'10px'} />
            {this.state.progressBars.map(pBar => {
              return (
                <React.Fragment key={pBar.url}>
                  <Spacer size="20px" />
                  <div>
                    <Text
                      style={{
                        textAlign: 'left',
                        display: 'block',
                        float: 'left'
                      }}
                    >
                      {pBar.originalName}
                    </Text>
                    <Text style={{ textAlign: 'right', display: 'block' }}>
                      {pBar.progress === '100'
                        ? `Processing...`
                        : `${pBar.progress}%`}
                    </Text>
                  </div>

                  <ProgressBar progress={pBar.progress} />
                </React.Fragment>
              );
            })}
          </React.Fragment>
        )}
      </FormWrapper>
    );
  }
}
