import React from 'react';
import { Text, Button, Form, Spacer, FlexBox, Icon } from 'components';
import { toast } from 'react-toastify';
import {
  createBooking,
  getAllQuotableShows,
  getOrganizations
} from 'data-graphql';
import { yes_no_options } from 'helpers';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default class CreateQuote extends React.Component {
  constructor() {
    super();
    this.initialState = {
      show: '',
      organization: '',
      state: 'quoted',
      is_production_access: '',
      opening: '',
      expiration: '',
      rate: '',
      notes: '',
      quote_avg_price: '',
      quote_avg_attend: '',
      quote_performances: '',
      quote_organization_name: '',
      quote_city: '',
      quote_state: '',
      quote_country: '',
      quote_first_name: '',
      quote_last_name: '',
      quote_phone_number: '',
      quote_email: '',
      educational: '',
      existingOrg: true,
      booking: false
    };
    this.state = this.initialState;
  }

  componentDidMount() {
    this.getShows();
  }

  close = () => {
    this.setState(this.initialState);
    this.props.onChange();
  };

  submit = async () => {
    const payload = {
      state: 'quoted',
      ...(this.state.show && { show_id: this.state.show.id }),
      ...(this.state.organization && {
        organization_id: this.state.organization.value
      }),
      ...(this.state.is_production_access && {
        is_production_access: this.state.is_production_access.value
      }),
      ...(this.state.educational && {
        educational: this.state.educational.value
      }),
      ...(this.state.opening && {
        opening: this.state.opening
      }),
      ...(this.state.expiration && {
        expiration: this.state.expiration
      }),
      ...(this.state.quote_avg_attend && {
        quote_avg_attend: parseFloat(this.state.quote_avg_attend)
      }),
      ...(this.state.quote_avg_price && {
        quote_avg_price: parseFloat(this.state.quote_avg_price)
      }),
      ...(this.state.quote_city && {
        quote_city: this.state.quote_city
      }),
      ...(this.state.quote_state && {
        quote_state: this.state.quote_state
      }),
      ...(this.state.quote_country && {
        quote_country: this.state.quote_country
      }),
      ...(this.state.quote_email && {
        quote_email: this.state.quote_email
      }),
      ...(this.state.quote_first_name && {
        quote_first_name: this.state.quote_first_name
      }),
      ...(this.state.quote_last_name && {
        quote_last_name: this.state.quote_last_name
      }),
      ...(this.state.quote_organization_name && {
        quote_organization_name: this.state.quote_organization_name
      }),
      ...(this.state.quote_performances && {
        quote_performances: parseFloat(this.state.quote_performances)
      }),
      ...(this.state.quote_phone_number && {
        quote_phone_number: this.state.quote_phone_number
      }),

      notes: this.state.notes || ''
    };

    if (!this.state.show.id || !this.state.opening || !this.state.expiration) {
      return toast.error('All fields must be completed.', { autoclose: false });
    }

    const VARS = {
      input: payload
    };

    const REQ_OBJECT = `{
      code
      success
      message
      booking {
        state
        id
      }
    }`;

    this.setState(prevState => ({
      booking: !prevState.booking
    }));

    const RESULT = await createBooking(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.message) {
      toast.success(`${RESULT.data.createBooking.message}`);
      this.close();
    } else {
      this.setState(prevState => ({
        booking: !prevState.booking
      }));
    }
  };

  getShows = async () => {
    const REQ_OBJECT = `{
      name
      id
    }`;

    const RESULT = await getAllQuotableShows(REQ_OBJECT);
    this.setState({ shows: RESULT.data.getAllQuotableShows });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleShowChange = show => {
    this.setState({ show });
  };

  handleEducationalChange = educational => {
    this.setState({ educational });
  };

  handleProdTracksChange = prodSelect => {
    this.setState({
      is_production_access: prodSelect
    });
  };

  handleNotesChange = e => {
    const value = e.target.value;
    this.setState({ notes: value });
  };

  handleDateChange = (selectedDay, modifiers, dayPickerInput) => {
    let returnObj = {};

    returnObj[dayPickerInput.props.name] = selectedDay
      ? moment(selectedDay).format('YYYY-MM-DD')
      : null;

    const closingSetting =
      dayPickerInput.props.name === 'opening'
        ? moment(returnObj.opening)
        : null;
    closingSetting &&
      this.setState({ expirationDate: new Date(closingSetting) });

    this.setState(returnObj);
  };

  searchOrganizations = async inputValue => {
    const VARS = {
      search: inputValue,
      page: 1
    };
    const REQ_OBJECT = `{
      organizations{
        name
        id
      }
     
    }`;
    const RESULT = await getOrganizations(VARS, REQ_OBJECT);
    if (RESULT.data) {
      return RESULT.data.getOrganizations.organizations.map(item => {
        return { value: item.id, label: item.name };
      });
    }
  };

  handleAssignOrgInput = newValue => {
    const inputValue = newValue;
    this.setState({ inputValue });

    return inputValue;
  };

  handleOrgChange = organization => {
    this.setState({ organization: organization });
  };

  existingOrg = () => {
    this.setState({
      existingOrg: this.state.existingOrg === true ? false : true
    });
  };

  onTabChange = index => {
    if (index === 1) {
      this.setState({ organization: null });
    } else {
      this.setState({
        quote_organization_name: '',
        quote_city: '',
        quote_state: '',
        quote_country: '',
        quote_first_name: '',
        quote_last_name: '',
        quote_phone_number: '',
        quote_email: ''
      });
    }
  };

  render() {
    if (!this.state.shows) return null;
    return (
      <Form>
        <FlexBox justify="flex-end">
          <Icon onClick={this.close} name="close" />
        </FlexBox>
        <Text huge red>
          Create a Quote
        </Text>
        <Spacer size="20px" />
        <div style={{ textAlign: 'left' }}>
          <Text large>Quote Info</Text>
        </div>
        <Spacer size="20px" />
        <Tabs onSelect={this.onTabChange} style={{ textAlign: 'left' }}>
          <TabList>
            <Tab value="assign">Assign to Existing Organization</Tab>
            <Tab value="new">Create with quote organization</Tab>
          </TabList>
          <TabPanel>
            <Spacer size="20px" />
            <Form.InputWrapper>
              <Form.Label red>Assign to Organization</Form.Label>
              <Form.StyledAsyncSelect
                cacheOptions
                loadOptions={this.searchOrganizations}
                onInputChange={this.handleAssignOrgInput}
                defaultOptions={true}
                value={this.state.organization}
                onChange={this.handleOrgChange}
                placeholder="Start typing..."
                styles={Form.CustomStyles}
              />
            </Form.InputWrapper>
          </TabPanel>
          <TabPanel>
            <Form.InputWrapper>
              <Form.Label>Quote Organization Name</Form.Label>
              <Form.Input
                name="quote_organization_name"
                value={this.state.quote_organization_name}
                onChange={this.handleChange}
              />
            </Form.InputWrapper>
            <Form.InputWrapper>
              <Form.Label>Quote City</Form.Label>
              <Form.Input
                name="quote_city"
                value={this.state.quote_city}
                onChange={this.handleChange}
              />
            </Form.InputWrapper>
            <Form.InputWrapper>
              <Form.Label>Quote State</Form.Label>
              <Form.Input
                name="quote_state"
                value={this.state.quote_state}
                onChange={this.handleChange}
              />
            </Form.InputWrapper>
            <Form.InputWrapper>
              <Form.Label>Quote Country</Form.Label>
              <Form.Input
                name="quote_country"
                value={this.state.quote_country}
                onChange={this.handleChange}
              />
            </Form.InputWrapper>
            <Form.InputWrapper>
              <Form.Label>Quote First Name</Form.Label>
              <Form.Input
                name="quote_first_name"
                value={this.state.quote_first_name}
                onChange={this.handleChange}
              />
            </Form.InputWrapper>
            <Form.InputWrapper>
              <Form.Label>Quote Last Name</Form.Label>
              <Form.Input
                name="quote_last_name"
                value={this.state.quote_last_name}
                onChange={this.handleChange}
              />
            </Form.InputWrapper>
            <Form.InputWrapper>
              <Form.Label>Quote Phone Number</Form.Label>
              <Form.Input
                name="quote_phone_number"
                value={this.state.quote_phone_number}
                onChange={this.handleChange}
              />
            </Form.InputWrapper>
            <Form.InputWrapper>
              <Form.Label>Quote Email</Form.Label>
              <Form.Input
                name="quote_email"
                value={this.state.quote_email}
                onChange={this.handleChange}
              />
            </Form.InputWrapper>
          </TabPanel>
        </Tabs>
        <Spacer size="20px" />
        <div style={{ textAlign: 'left' }}>
          <Text large>Show Info</Text>
        </div>

        <Spacer size="10px" />
        {this.state.shows.length > 0 && (
          <Form.InputWrapper>
            <Form.Label>Show&apos;s Name</Form.Label>
            <Form.StyledSelect
              value={this.state.show}
              onChange={this.handleShowChange}
              options={this.state.shows}
              isSearchable={true}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              placeholder="Start typing..."
              styles={Form.CustomStyles}
            />
          </Form.InputWrapper>
        )}
        <Spacer size="5px" />

        <Form.InputWrapper>
          <Form.Label>Include Production Tracks?</Form.Label>
          <Form.StyledSelect
            styles={Form.CustomStyles}
            value={this.state.is_production_access}
            onChange={this.handleProdTracksChange}
            options={yes_no_options}
            isSearchable={false}
            placeholder="Include Production Tracks?"
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Label>Educational?</Form.Label>
          <Form.StyledSelect
            styles={Form.CustomStyles}
            value={this.state.educational}
            onChange={this.handleEducationalChange}
            options={yes_no_options}
            isSearchable={false}
            placeholder="Educational?"
          />
        </Form.InputWrapper>

        <Form.InputWrapper>
          {/* <Text red> Opening Date </Text> */}
          <Form.Label>Opening Date</Form.Label>
          <Spacer size="5px" />
          <Form.StyleDate>
            <DayPickerInput
              value={this.state.opening}
              onDayChange={this.handleDateChange}
              name="opening"
              placeholder="Opening Date"
            />
          </Form.StyleDate>
        </Form.InputWrapper>
        <Form.InputWrapper>
          {/* <Text red> Closing/Expiration Date </Text> */}
          <Form.Label>Closing/Expiration Date</Form.Label>
          <Spacer size="5px" />
          <Form.StyleDate>
            <DayPickerInput
              value={this.state.expiration}
              dayPickerProps={{
                month: this.state.expirationDate,
                disabledDays: {
                  before: this.state.expirationDate
                }
              }}
              onDayChange={this.handleDateChange}
              name="expiration"
              placeholder="Closing/Expiration Date"
            />
          </Form.StyleDate>
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Label>Average Ticket Price</Form.Label>
          <Form.Input
            name="quote_avg_price"
            value={this.state.quote_avg_price}
            onChange={this.handleChange}
            type="number"
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Label>Average Attendance</Form.Label>
          <Form.Input
            name="quote_avg_attend"
            value={this.state.quote_avg_attend}
            onChange={this.handleChange}
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Label>Number of Performances</Form.Label>
          <Form.Input
            name="quote_performances"
            value={this.state.quote_performances}
            onChange={this.handleChange}
          />
        </Form.InputWrapper>

        <Spacer size="10px" />
        <Form.InputWrapper full>
          <Form.TextArea
            placeholder="Notes"
            name="notes"
            onChange={this.handleNotesChange}
          />
        </Form.InputWrapper>

        <Spacer size="10px" />

        <Button onClick={this.submit} style={{ margin: 'auto' }}>
          {this.state.booking ? 'Submiting...' : 'Submit'}
        </Button>
      </Form>
    );
  }
}
