import React from 'react';
import { Input, Text, Button } from 'components';
import styled from 'styled-components';
import { resetOrganization } from 'data-graphql';
import { toast } from 'react-toastify';

const InputWrapper = styled.form`
  width: 300px;
`;

export default class ResetOrgTable extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  reset = async () => {
    const VARS = {
      orgId: this.props.organization.id,
      newEmail: this.state.email
    };

    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    const RESULT = await resetOrganization(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(RESULT.data.message);
      this.setState({ email: '' });
      this.props.onChange();
    }
    // TODO: trigger reloading of contacts; old (now null) contacts are still being displayed
  };

  handleEmailChange = e => {
    this.setState({
      email: e.target.value
    });
  };

  render() {
    return (
      <div>
        <p>
          <Text large bold>
            Are you sure you want to reset this organization?
          </Text>
        </p>
        <br />
        <p>
          <Text>This will delete all contacts, and reset the password.</Text>
        </p>
        <p>
          <Text>Authorizations will be untouched.</Text>
        </p>
        <br />
        <InputWrapper>
          <Input
            placeholder="Email to send new password"
            onChange={this.handleEmailChange}
          />
        </InputWrapper>
        <br />
        <Button onClick={this.reset} large negative style={{ width: '300px' }}>
          Reset Account
        </Button>
      </div>
    );
  }
}
