import React from 'react';
import { Button, Form, FlexBox, Icon } from 'components';
import { toast } from 'react-toastify';
import { updateTrack, deleteTrack } from 'data-graphql';

export default class CreateOrgForm extends React.Component {
  constructor() {
    super();
    this.initialState = {
      track_name: '',
      cue_number: '',
      saving: false
    };
    this.state = this.initialState;
  }

  componentDidMount() {
    this.setState({
      track_name: this.props.track.track_name || '',
      cue_number: this.props.track.cue_number || ''
    });
  }

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value });
  };

  close = () => {
    this.setState(this.initialState);
    this.props.onChange();
  };

  submit = async () => {
    const payload = {};
    if (this.state.track_name) payload.track_name = this.state.track_name;
    if (this.state.cue_number) payload.cue_number = this.state.cue_number;

    const VARS = {
      id: this.props.track.id,
      input: payload
    };

    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    this.setState(prevState => ({
      saving: !prevState.saving
    }));

    const RESULT = await updateTrack(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.close();
    } else {
      this.setState(prevState => ({
        saving: !prevState.saving
      }));
    }
  };

  removeTrack = async e => {
    e.stopPropagation();
    const confirm = window.confirm(
      'Deleting this track is permanent and cannot be undone. Are you sure you want to continue?'
    );
    if (!confirm) {
      return false;
    } else {
      const VARS = { id: e.target.dataset.value };
      const REQ_OBJECT = `{
        code
        success
        message
      }`;
      const RESULT = await deleteTrack(VARS, REQ_OBJECT);

      if (RESULT.data && RESULT.data.message) {
        toast.success(RESULT.data.message);
        this.props.onChange();
      }
    }
  };

  render() {
    return (
      <Form>
        <FlexBox justify="flex-end">
          <Icon
            name="close"
            onClick={this.close}
            style={{ marginRight: '35px' }}
          />
        </FlexBox>
        <FlexBox justify="start" align="center">
          <Form.InputWrapper style={{ width: '20%', margin: '0' }}>
            <Form.Label>Track Cue</Form.Label>
            <Form.Input
              name="cue_number"
              onChange={this.handleChange}
              value={this.state.cue_number}
            />
          </Form.InputWrapper>
          <Form.InputWrapper style={{ width: '60%', margin: '0' }}>
            <Form.Label>Track Name</Form.Label>
            <Form.Input
              type="text"
              name="track_name"
              onChange={this.handleChange}
              value={this.state.track_name}
            />
          </Form.InputWrapper>

          <Button onClick={this.submit} style={{ marginTop: '15px' }}>
            {this.state.saving ? 'Saving...' : 'Save'}
          </Button>
          {this.props.custom && (
            <Button
              style={{ marginTop: '15px', marginLeft: '10px' }}
              negative
              data-value={this.props.track.id}
              onClick={this.removeTrack}
            >
              <Icon
                name="bin"
                data-value={this.props.track.id}
                onClick={this.removeTrack}
              />
            </Button>
          )}
        </FlexBox>
      </Form>
    );
  }
}
