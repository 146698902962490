// import React from 'react'
import styled from 'styled-components';
import COLORS from './colors';

export default styled.div`
  width: ${props => (props.width ? props.width : '100%')};
  background: ${COLORS.white};
  padding: 35px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
`;
