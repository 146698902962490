import React from 'react';
import { Text, Button, Form, Spacer } from 'components';
import { createUserFromContact } from 'data-graphql';
import { toast } from 'react-toastify';

export default class CreateUserForm extends React.Component {
  constructor() {
    super();
    this.initialState = {
      contact: '',
      creating: false
    };
    this.state = this.initialState;
  }

  close = () => {
    this.setState(this.initialState);
    this.props.onChange();
  };

  submit = async () => {
    if (!this.state.contact) {
      return toast.error('Select a contact.', { autoclose: false });
    }
    const payload = {
      contactId: this.state.contact.id
    };
    const VARS = payload;
    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    this.setState(prevState => ({
      creating: !prevState.creating
    }));

    const RESULT = await createUserFromContact(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.close();
    } else {
      this.setState(prevState => ({
        creating: !prevState.creating
      }));
    }
  };

  handleContactChange = contact => {
    this.setState({ contact });
  };

  render() {
    return (
      <Form>
        <Text red large>
          Create User Account
        </Text>
        <Spacer size="10px" />
        <Text>
          This will automatically create a user account for this contact, and
          email them their account details.
        </Text>
        <Spacer size="5px" />
        <Text soft small>
          A username and random password will be generated and included in the
          email.
        </Text>

        <Spacer size="10px" />
        <Form.InputWrapper>
          <Form.StyledSelect
            name="email"
            getOptionLabel={option => option.email}
            getOptionValue={option => option.id}
            onChange={this.handleContactChange}
            options={this.props.activeContacts}
            isSearchable={false}
            placeholder="Select Contact"
            styles={Form.CustomStyles}
          />
        </Form.InputWrapper>

        <Spacer size="5px" />
        <Button onClick={this.submit}>
          {this.state.creating ? 'Creating...' : 'Create User'}
        </Button>
      </Form>
    );
  }
}
