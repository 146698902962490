import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Transition } from 'react-transition-group';
import { isMTI } from 'helpers';
import styled from 'styled-components';
import { Button, FlexBox, Text, Spacer } from 'components';
import theatre from '../../assets/mti-macapp-theatre.png';
import mti from '../../assets/mti-macapp-icon.png';
import mtpit from '../../assets/mtpit-macapp-icon.png';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #090e14;
  overflow: hidden;
  text-rendering: geometricPrecision;
  color: rgba(250, 250, 250, 0.9);
  font-family: 'Proxima', sans-serif;
`;

const BGImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 270px;
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-size: contain;
`;

const Container = styled(FlexBox)`
  position: absolute;
  top: -150px;
`;

const Header = styled.p`
  font-size: 41px;
  font-weight: 400;
`;

const Description = styled.p`
  margin: 20px;
  font-size: 15px;
  font-weight: 300;
`;

const Disclaimer = styled.p`
  margin: 20px;
  font-size: 12px;
  font-weight: 300;
  max-width: 360px;
  opacity: .7;
  text-align: center;
`;

const Link = styled.a`
  cursor: pointer;
  color: ${isMTI ? '#3a6fa1' : '#da3732'};
  text-decoration: none;
  margin: ${props => props.margin && props.margin};
  &:hover {
    opacity: 0.8;
  }
`;
const LinkP = styled.p`
  cursor: pointer;
  color: ${isMTI ? '#3a6fa1' : '#da3732'};
  text-decoration: none;
  margin: ${props => props.margin && props.margin};
  &:hover {
    opacity: 0.8;
  }
`;
const Icon = styled.img`
  width: auto;
  height: 96px;
  margin: 30px 0;
`;

const ModalContainer = styled.div`
  box-sizing: content-box;
  background-color: white;
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 50px 30px 50px;
  width: 60%;
  min-width: 480px;
  max-width: 560px;
  height: 80vh;
  overflow-y: auto;
`;

const Fade = styled.div`
  transition: 0.3s;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
`;

const Buttons = styled(Button)`
  background-color: ${props =>
  isMTI
    ? props.negative
      ? 'white'
      : '#3495ce'
    : props.negative
      ? 'white'
      : 'e5a02'};
  color: ${props =>
  isMTI
    ? props.negative
      ? '#3495ce'
      : 'white'
    : props.negative
      ? '#e5a021'
      : 'white'};
  box-shadow: ${props =>
  isMTI
    ? props.negative && '0px 0px 0px 2px #3495ce inset'
    : props.negative && '0px 0px 0px 2px #e5a021 inset'};
  padding: 16px 20px;
  margin: ${props => props.margin && props.margin};
  &:hover {
    background-color: ${props =>
  isMTI
    ? props.negative
      ? 'white'
      : '#3495ce'
    : props.negative
      ? 'white'
      : '#e5a021'};
    opacity: 0.8;
  }
`;

const StyledText = styled(Text)`
  font-size: 18px;
  line-height: 1.75;
`;

export default function MacApp() {
  const [showModal, setShowModal] = useState(false);
  const [versionData, setVersionData] = useState([]);
  const [downloadURL, setDownloadURL] = useState('');
  const [oldVersion, setOldVersion] = useState(false);

  useEffect(() => {
    let getVersionData = async () => {
      let res = await axios.get(
        'https://s3-us-west-2.amazonaws.com/mtpit-apps/MacVersions.json'
      );
      setVersionData(
        isMTI
          ? res.data['com.mtishows.mtimac']
          : res.data['com.themtpit.TheMTPit']
      );
      setDownloadURL(
        isMTI
          ? 'https://s3-us-west-2.amazonaws.com/mti-apps/MTI+Player.zip'
          : 'https://s3-us-west-2.amazonaws.com/mtpit-apps/The+MT+Pit.zip'
      );
    };

    getVersionData();
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const getCurrentVersion = () => {
    setOldVersion(false);
    setDownloadURL(
      isMTI
        ? 'https://s3-us-west-2.amazonaws.com/mti-apps/MTI+Player.zip'
        : 'https://s3-us-west-2.amazonaws.com/mtpit-apps/The+MT+Pit.zip'
    );
    openModal();
  };

  const getOldVersion = () => {
    setOldVersion(true);
    setDownloadURL(
      isMTI
        ? 'https://s3-us-west-2.amazonaws.com/mti-apps/MTI+Player+Old.zip'
        : 'https://s3-us-west-2.amazonaws.com/mtpit-apps/The+MT+Pit+Old.zip'
    );
    openModal();
  };

  let { version, requirements } = versionData;

  return (
    <Overlay>
      <BGImage img={theatre}>
        <Container direction="column" justify="center" align="center">
          <Icon src={isMTI ? mti : mtpit} alt="" />
          <Header>
            Download {isMTI ? 'MTI' : 'The MT Pit'} Player Mac App
          </Header>
          <Description>
            Version {version}, {requirements}
          </Description>
          <Buttons margin="20px 30px" onClick={getCurrentVersion}>
            DOWNLOAD
          </Buttons>
          <LinkP onClick={getOldVersion} margin="0 0 20px 0">
            Download Old Version (for macOS Sierra or later)
          </LinkP>
          <Link
            href={
              isMTI
                ? 'https://mtpit-files--production.s3.us-west-2.amazonaws.com/MTI_QLAB_Production_Track_Access_Instructions_october.pdf'
                : 'https://mtpit-files--production.s3.us-west-2.amazonaws.com/QLAB_Production_Track_Access_Instructions_october.pdf'
            }
          >
            View Instructions
          </Link>
          <Disclaimer>
            QLab® is a registered trademark of Figure53, LLC and is used solely 
            to identify their software. {isMTI ? 'MTI Player' : 'MT Pit'} is not 
            affiliated with, an authorized reseller of, or otherwise associated 
            with, Figure 53
          </Disclaimer>
        </Container>
      </BGImage>
      <Transition in={showModal} timeout={300} unmountOnExit mountOnEnter>
        {state => (
          <Fade state={state}>
            <ModalContainer>
              {oldVersion && (
                <>
                  <StyledText large bold>
                    I acknowledge that this version of the{' '}
                    {isMTI ? 'MTI Player' : 'MT Pit'} Mac application is not the
                    most recent version and may be subject to errors as this
                    version is no longer fully supported
                  </StyledText>
                  <Spacer size="30px" />
                </>
              )}
              <StyledText large>
                I acknowledge that by using the{' '}
                {isMTI ? 'MTI Player' : 'MT Pit'} Mac application I have a basic
                knowledge of the QLab software. Any issues or troubleshooting
                for the application of QLab is not provided by{' '}
                {isMTI ? 'MTI' : 'The MT Pit'}. I also acknowledge that I have
                legally obtained the QLab software.
              </StyledText>
              <Spacer size="30px" />
              <StyledText large>
                The {isMTI ? 'MTI Player' : 'MT Pit'} Mac application, its
                software, and all content are proprietary information and direct
                ownership of {isMTI ? 'MTI' : 'The MT Pit'}. Any tampering,
                mis-use, or theft of any content will be grounds for penalties,
                fines, and/or legal action.
              </StyledText>
              <Spacer size="30px" />
              <StyledText large>
                {isMTI ? 'MTI' : 'The MT Pit'} will not be liable for any loss,
                whether such loss is direct, indirect, special, or
                consequential, suffered by any party as a result of their use of
                the {isMTI ? 'MTI Player' : 'The MT Pit'} Mac application, its
                software, or its content. Any downloading of material from the
                application is done at the user’s own risk and the user will be
                solely responsible for any damage to any computer system or loss
                of data that results from such activities.
              </StyledText>
              <Spacer size="50px" />
              <FlexBox justify="flex-end">
                <Buttons negative margin="0 16px 0 0" onClick={closeModal}>
                  DISAGREE AND CANCEL
                </Buttons>
                <a href={downloadURL}>
                  <Buttons large onClick={closeModal}>
                    AGREE AND DOWNLOAD
                  </Buttons>
                </a>
              </FlexBox>
            </ModalContainer>
          </Fade>
        )}
      </Transition>
    </Overlay>
  );
}
