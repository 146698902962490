import React from 'react';
import {
  Text,
  StyledTable,
  ExpanderSpan,
  Button,
  Spacer,
  StyledBullet,
  COLORS
} from 'components';
import EditsForm from './EditsForm.js';
import { updateBooking } from 'data-graphql';
import { getTrPropsFn, formatDate, isMTI } from 'helpers';
import { toast } from 'react-toastify';
import moment from 'moment';
import Popover from 'react-awesome-popover';
import 'react-awesome-popover/build/index.css';
export default class PastShowsTable extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  handleSubComponentChange = () => {
    this.props.onChange();
  };

  restoreBooking = async e => {
    const today = moment().format();

    const { opening, closing, id } = e.target.dataset;

    const status = moment(closing).isBefore(today, 'day')
      ? 'expired'
      : moment(closing).isSameOrAfter(today, 'day') &&
        moment(opening).isSameOrBefore(today, 'day')
      ? 'active'
      : 'booked';

    const VARS = { id: id, input: { state: status } };
    const REQ_OBJECT = `{
      code
      success
      message
      booking {
        state
      }
    }`;

    const RESULT = await updateBooking(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(
        `${RESULT.data.updateBooking.message}:  Restored to ${RESULT.data.updateBooking.booking.state}`
      );
      this.props.onChange();
    }
  };

  render() {
    const { organization } = this.props;

    const paginateCancelledBookings =
      organization.bookings.filter(booking => {
        return booking.state === 'cancelled';
      }).length > 3
        ? true
        : false;
    const paginateExpiredBookings =
      organization.bookings.filter(booking => {
        return booking.state === 'expired';
      }).length > 3
        ? true
        : false;

    return (
      <div>
        <br />
        <Text large bold>
          Cancelled
        </Text>
        <br />
        <StyledTable
          data={organization.bookings
            .filter(booking => {
              return booking.state === 'cancelled';
            })
            .sort((a, b) =>
              b.cancelled_at > a.cancelled_at
                ? 1
                : a.cancelled_at > b.cancelled_at
                ? -1
                : 0
            )}
          noDataText="No cancelled shows."
          columns={[
            {
              Header: 'Title',
              accessor: 'show.name',
              minWidth: 100
            },
            {
              Header: 'Cancellation',
              accessor: 'cancelled_at',
              minWidth: 100,
              Cell: row => <div>{formatDate(row.value, 'YYYY-MM-DD')}</div>
            },
            {
              Header: 'Opening',
              accessor: 'opening',
              minWidth: 100
            },
            {
              Header: 'Closing',
              accessor: 'expiration',
              minWidth: 100
            },
            {
              accessor: 'state',
              Cell: row => (
                <Button
                  data-id={row.original.id}
                  data-opening={row.original.opening}
                  data-closing={row.original.expiration}
                  onClick={this.restoreBooking}
                  negative
                >
                  Restore
                </Button>
              )
            }
          ]}
          showPagination={paginateCancelledBookings}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          defaultPageSize={10}
        />

        <br />
        <Text large bold>
          Expired
        </Text>
        <br />
        <StyledTable
          data={organization.bookings
            .filter(booking => {
              return booking.state === 'expired';
            })
            .sort((a, b) =>
              b.expiration > a.expiration
                ? 1
                : a.expiration > b.expiration
                ? -1
                : 0
            )}
          noDataText="No expired shows."
          columns={[
            {
              Header: 'Title',
              accessor: 'show.name',
              minWidth: 100,
              Cell: row => {
                return (
                  <>
                    <div>
                      <Text style={{ display: 'inline-block' }}>
                        {row.value}
                      </Text>
                      <Spacer size="2px" />
                      {row.original.notes && (
                        <>
                          <ul>
                            <Popover
                              arrow={false}
                              position="right"
                              action="hover"
                            >
                              <StyledBullet color={row.original.notes_color}>
                                <Text small>
                                  {'  '}
                                  {row.original.notes}
                                </Text>
                              </StyledBullet>

                              <Text small>{row.original.notes}</Text>
                            </Popover>
                          </ul>
                        </>
                      )}
                    </div>
                  </>
                );
              }
            },
            {
              Header: 'Type',
              accessor: 'booked_at',
              minWidth: 100,
              show: isMTI ? false : true,
              Cell: row => (
                <Button
                  style={
                    row.value
                      ? { backgroundColor: COLORS.yellow }
                      : { backgroundColor: COLORS.darkRed }
                  }
                  noHover
                >
                  {row.value ? 'Booking' : 'Quote'}
                </Button>
              )
            },
            {
              Header: 'Expired',
              accessor: 'expiration',
              minWidth: 100
            },
            {
              Header: 'Actions',
              expander: true,
              Expander: ({ isExpanded }) => (
                <ExpanderSpan
                  {...(isExpanded ? { open: true } : { closed: true })}
                />
              ),
              maxWidth: 150,
              minWidth: 146,
              width: 146
            }
          ]}
          resizable={false}
          sortable={false}
          showPagination={paginateExpiredBookings}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          defaultPageSize={10}
          getTrProps={getTrPropsFn}
          SubComponent={row => {
            return (
              <EditsForm
                row={row}
                organization={this.props.organization}
                onChange={this.handleSubComponentChange}
              />
            );
          }}
        />
      </div>
    );
  }
}
