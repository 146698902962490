import React from 'react';
import { toast } from 'react-toastify';
import { FormWrapper, Form, Button, Text, Spacer } from 'components';
import { resetUserPassword } from 'data-graphql';

export default class Password extends React.Component {
  constructor() {
    super();
    this.initialState = {
      password: ''
    };
    this.state = this.initialState;
  }

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value });
  };

  close = () => {
    this.setState(this.initialState);
    this.props.onPassChange();
  };

  resetPassword = async () => {
    if (this.state.password && this.state.password.length < 8) {
      return toast.error('Password must be at least 8 characters long.', {
        autoclose: false
      });
    }

    const VARS = {
      id: this.props.user.id,
      input: {
        password: this.state.password || undefined
      }
    };

    const REQ_OBJECT = `{
      code
      message
      success
    }`;

    const RESULT = await resetUserPassword(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.close();
    }
  };

  render() {
    return (
      <div>
        <FormWrapper>
          <Form>
            <Text>
              This user will receive an email with a temporary password.
            </Text>
            <Spacer size="15px" />
            <Text>
              They will be asked to change their password on next sign-in.
            </Text>
            <Spacer size="20px" />

            <Form.InputWrapper>
              <Form.Input
                name="password"
                onChange={this.handleChange}
                value={this.state.password}
                placeholder="Temporary Password (random if empty)"
              />
            </Form.InputWrapper>
            <Spacer size="20px" />
            <Button large negative onClick={this.resetPassword}>
              Reset Password
            </Button>
          </Form>
        </FormWrapper>
      </div>
    );
  }
}
