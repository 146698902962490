import React from 'react';
import styled from 'styled-components';
import {
  Wrapper,
  Header,
  Menu,
  Button,
  Content,
  FlexBox,
  COLORS, Text, Spacer
} from 'components'
import { getBookingsForOrg, getMe } from 'data-graphql';
import 'react-slidedown/lib/slidedown.css';
import { windowSize } from 'helpers';
import Spinner from 'react-spinkit';

import {
  CurrentShowsTable,
  BookedShowsTable,
  QuotedShowsTable,
  CancelledShowsTable,
  ExpiredShowsTable
} from './components';

const StyledDiv = styled.div`
  backgroundcolor: #f1efef;
  z-index: 10;
`;

export default class AllShows extends React.Component {
  constructor() {
    super();
    this.state = {
      menu: 'Current Shows',
      bookings: [],
      refreshBookings: false,
      dataLoaded: false,
      menuOpen: false,
      account_number: null,
    };
  }

  async componentDidMount() {
    await this.getCurrentUser();
    await this.getComponentOrganizationBookings();
  }

  getCurrentUser = async () => {
    const REQ_OBJECT = `{
      email
      first_name
      last_name
      organization {
        id
        account_number
      }
    }`;

    const RESULT = await getMe(REQ_OBJECT);
    this.setState({
      firstName: RESULT.data.getMe.first_name,
      lastName: RESULT.data.getMe.last_name || '',
      email: RESULT.data.getMe.email,
      organization_id: RESULT.data.getMe.organization.id,
      account_number: RESULT.data.getMe.organization.account_number
    });
  };

  getComponentOrganizationBookings = async () => {
    const VARS = { orgId: this.state.organization_id };
    const REQ_OBJECT = `{
      id
      show {
        name
      }
      opening
      expiration
      rehearsal_active_date
      production_active_date
      rate
      is_production_access
      edits_status
      rate_status
      notes
      notes_color
      rate
      state
      is_downloaded
      rehearsal_code
      rehearsal_edit_code
    }`;

    const RESULT = await getBookingsForOrg(VARS, REQ_OBJECT);

    this.setState({
      bookings: RESULT.data.getBookingsForOrg,
      dataLoaded: true
    });
  };

  handleChildChange = id => {
    this.props.history.push(`/org/shows/${id}`);
  };

  menuSelect = e => {
    this.setState({
      menu: e.target.value,
      menuOpen: this.state.menuOpen === 'open' ? false : 'open'
    });
  };

  openMenu = () => {
    this.setState({
      menuOpen: this.state.menuOpen === 'create' ? false : 'create'
    });
  };

  render() {
    return (
      <div>
        <Header>
          <Header.Head bold huge style={{ textAlign: 'left', paddingRight: '45px' }}>
            <FlexBox justify="space-between">
              <div>
                Shows
              </div>
              <div>
                <FlexBox direction="column" style={{ textAlign: 'right' }}>
                  <Text large red>
                    Account number
                  </Text>
                  <Spacer size="5px" />
                  <Text>{this.state.account_number}</Text>
                </FlexBox>
              </div>
            </FlexBox>
          </Header.Head>
          {windowSize === 'phone' ? (
            <>
              <Button darkRed menu onClick={this.openMenu}>
                {this.state.menu} {this.state.menuOpen === 'create' ? '-' : '+'}
              </Button>
              <Content
                pose={this.state.menuOpen === 'create' ? 'open' : 'closed'}
                style={{
                  overflow:
                    this.state.menuOpen === 'create' ? 'visible' : 'hidden'
                }}
              >
                <Menu>
                  <button
                    value="Current Shows"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'Current Shows'}
                  >
                    Current Shows
                  </button>
                  <button
                    value="Booked Shows"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'Booked Shows'}
                  >
                    Booked Shows
                  </button>
                  <button
                    value="Quoted Shows"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'Quoted Shows'}
                  >
                    Quoted Shows
                  </button>
                  <button
                    value="Cancelled Shows"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'Cancelled Shows'}
                  >
                    Cancelled Shows
                  </button>
                  <button
                    value="Expired Shows"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'Expired Shows'}
                  >
                    Expired Shows
                  </button>
                </Menu>
              </Content>
            </>
          ) : (
            <Menu>
              <button
                value="Current Shows"
                onClick={this.menuSelect}
                active={this.state.menu === 'Current Shows'}
              >
                Current Shows
              </button>
              <button
                value="Booked Shows"
                onClick={this.menuSelect}
                active={this.state.menu === 'Booked Shows'}
              >
                Booked Shows
              </button>
              <button
                value="Quoted Shows"
                onClick={this.menuSelect}
                active={this.state.menu === 'Quoted Shows'}
              >
                Quoted Shows
              </button>
              <button
                value="Cancelled Shows"
                onClick={this.menuSelect}
                active={this.state.menu === 'Cancelled Shows'}
              >
                Cancelled Shows
              </button>
              <button
                value="Expired Shows"
                onClick={this.menuSelect}
                active={this.state.menu === 'Expired Shows'}
              >
                Expired Shows
              </button>
            </Menu>
          )}
        </Header>

        {!this.state.bookings.length > 0 && (
          <Wrapper>
            <FlexBox justify="space-evenly" style={{ alignContent: 'center' }}>
              <Spinner
                name="three-bounce"
                color={COLORS.darkRed}
                fadeIn="none"
              />
            </FlexBox>
          </Wrapper>
        )}

        {this.state.dataLoaded && this.state.bookings && (
          <Wrapper>
            <StyledDiv>
              {this.state.menu === 'Current Shows' && (
                <CurrentShowsTable
                  bookings={this.state.bookings}
                  onClick={this.handleChildChange}
                />
              )}
              {this.state.menu === 'Booked Shows' && (
                <BookedShowsTable
                  onClick={this.handleChildChange}
                  bookings={this.state.bookings}
                />
              )}
              {this.state.menu === 'Quoted Shows' && (
                <QuotedShowsTable bookings={this.state.bookings} />
              )}
              {this.state.menu === 'Cancelled Shows' && (
                <CancelledShowsTable bookings={this.state.bookings} />
              )}
              {this.state.menu === 'Expired Shows' && (
                <ExpiredShowsTable bookings={this.state.bookings} />
              )}
            </StyledDiv>
          </Wrapper>
        )}
      </div>
    );
  }
}
