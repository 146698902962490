import React from 'react';
import { Form, Spacer } from 'components';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import styled from 'styled-components';

const StyledForm = styled(Form)`
  width: 90%;
  text-align: left;
  margin-top: -20px;
`;

export default props => (
  <StyledForm>
    <Spacer size="10px" />
    <Form.InputWrapper>
      <Form.Label small>Filter for Dates between:</Form.Label>
      <Form.StyleDate>
        <DayPickerInput
          value={props.startDateValue}
          onDayChange={props.handleDateChange}
          name={props.startDate}
          placeholder="Start"
        />
      </Form.StyleDate>
    </Form.InputWrapper>
    <Spacer size="10px" />
    <Form.InputWrapper>
      <Form.Label small>And:</Form.Label>

      <Form.StyleDate>
        <DayPickerInput
          value={props.endDateValue}
          onDayChange={props.handleDateChange}
          name={props.endDate}
          placeholder="End"
        />
      </Form.StyleDate>
    </Form.InputWrapper>
    <Spacer size="20px" />
  </StyledForm>
);
