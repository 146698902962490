export default (state, rowInfo, col, instance) => {
  return {
    onClick: () => {
      const { expanded } = state;

      const path = rowInfo ? rowInfo.nestingPath[0] : 0;

      const diff = { [path]: expanded[path] ? false : true };

      instance.setState({
        expanded: {
          ...expanded,
          ...diff
        }
      });
    },
    style: {
      boxShadow: instance.state.expanded[rowInfo ? rowInfo.nestingPath[0] : 0]
        ? '0 2px 6px rgba(0, 0, 0, 0.21)'
        : null
    }
  };
};
