import React from 'react';
import { FormWrapper, Text, Form, Content } from 'components';
import styled from 'styled-components';

const StyledFormWrapper = styled(FormWrapper)`
  padding: 0px !important;
  margin: 10px auto;
  text-align: left;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  &:hover {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.21);
  }
`;

export default class Reporting extends React.Component {
  constructor() {
    super();
    this.initialState = {
      formOpen: false,
      checked: true
    };
    this.state = this.initialState;
  }
  UNSAFE_componentWillUpdate() {
    this.props.reset && this.setState(this.initialState);
  }

  handleCheckboxChange = event => {
    this.setState({ checked: event.target.checked });
    this.props.onCheck(event.target.name, event.target.checked);
  };

  formOpen = () => {
    this.setState({
      formOpen: this.state.formOpen === true ? false : true
    });
  };

  render() {
    if (this.props.reset) return null;
    return (
      <StyledFormWrapper>
        <div
          style={{ cursor: 'pointer', width: '100%', padding: '30px' }}
          onClick={this.formOpen}
        >
          <label
            name={this.props.name}
            style={{
              visibility: this.props.hideCheckbox ? 'hidden' : 'visible'
            }}
          >
            <Form.CheckBox
              name={this.props.name}
              checked={this.state.checked}
              onChange={this.handleCheckboxChange}
            />
          </label>

          <Text large red style={{ textAlign: 'left' }} onClick={this.formOpen}>
            {this.props.title} {`${this.state.formOpen ? '-' : '+'}`}
          </Text>
        </div>

        <Content
          pose={this.state.formOpen === true ? 'open' : 'closed'}
          style={{
            overflow: this.state.formOpen === true ? 'visible' : 'hidden'
          }}
        >
          {this.props.children}
        </Content>
      </StyledFormWrapper>
    );
  }
}
