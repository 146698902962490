import ApolloClient from 'apollo-boost';
import { toast } from 'react-toastify';

export default () => {
  const client = new ApolloClient({
    uri: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GQL_PATH}`,
    fetchOptions: { fetchPolicy: 'no-cache' },
    headers: {
      ...(window.localStorage.getItem('token') && {
        'x-auth-token': window.localStorage.getItem('token')
      }),
      'x-platform-id': process.env.REACT_APP_API_TOKEN,
      'x-client-id': process.env.REACT_APP_CLIENT_TOKEN
    },
    onError: ({ graphQLErrors }) => {
      //other arguments might include {graphQLErrors, networkError, operation, forward}
      if (
        graphQLErrors[0].message.includes(
          'Your account is on probation and domains are limited to 100 messages / hour'
        )
      ) {
        toast.error('Something went wrong, please try again later.');
      } else {
        toast.error(graphQLErrors[0].message, { autoClose: false });
        // for (let err of graphQLErrors) {
        // handle errors differently based on its error code
        // switch (err.extensions.code) {
        //   case 'UNAUTHENTICATED':
        // old token has expired throwing AuthenticationError,
        // one way to handle is to obtain a new token and
        // add it to the operation context
        // const headers.getContext().headers;
        // operation.setContext({
        //   headers: {
        //     ...headers,
        //     authorization: getNewToken()
        //   }
        // });
        // Now, pass the modified operation to the next link
        // in the chain. This effectively intercepts the old
        // failed request, and retries it with a new token
        // return forward(operation);

        // handle other errors
        // case 'ANOTHER_ERROR_CODE':
        // ...
        // }
        // }
      }
    }
  });

  return client;
};
