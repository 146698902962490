import React from 'react';
import ReactDOM from 'react-dom';
import Router from './Router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'core-js';
import GlobalStyle from './components';
import * as smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

//want to reset this on any reload from any component
window.addEventListener('unload', function() {
  sessionStorage.removeItem('hideTutorial');
});

const App = () => {
  return (
    <div>
      <Router />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        pauseOnFocusLoss={true}
      />
      <GlobalStyle />
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
