const updateWindowWidth = () => {
  return window.innerWidth > 998
    ? 'desktop'
    : window.innerWidth > 768
    ? 'tablet'
    : window.innerWidth > 479
    ? 'phablet'
    : 'phone';
};

window.addEventListener('resize', updateWindowWidth);

export default updateWindowWidth();
