const yes_no_options = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

const booking_state_options = [
  { value: 'quoted', label: 'Quoted' },
  { value: 'approved', label: 'Approved' },
  { value: 'booked', label: 'Booked' },
  { value: 'active', label: 'Active' },
  { value: 'expired', label: 'Expired' },
  { value: 'cancelled', label: 'Cancelled' }
];
const edits_status_options = [
  { value: 'none', label: 'None' },
  { value: 'not_submitted', label: 'Yes, Not Submitted' },
  { value: 'pending', label: 'Yes, Pending' },
  { value: 'completed', label: 'Yes, Completed' },
  { value: 'approved', label: 'Yes, Approved' },
  { value: null, label: '' }
];

const rate_status_options = [
  { value: 'purchase_order', label: 'Purchase Order' },
  { value: 'partial_payment', label: 'Partial Payment' },
  { value: 'paid_in_full', label: 'Paid in Full' },
  { value: null, label: 'None' }
];

const org_status_options = [
  { value: 'interested', label: 'Interested' },
  { value: 'customer', label: 'Customer' },
  { value: 'not_not_contact', label: 'Do Not Contact' }
];

const org_group_options = [
  { value: 'arts_council', label: 'Arts Council' },
  { value: 'arts_center', label: 'Arts Center' },
  { value: 'collegiate', label: 'Collegiate' },
  { value: 'community_theatre', label: 'Community Theatre' },
  { value: 'elementary', label: 'Elementary' },
  { value: 'high_school', label: 'High School' },
  { value: 'junior_high_school', label: 'Junior High School' },
  { value: 'professional_theatre', label: 'Professional Theatre' },
  { value: 'religious', label: 'Religious' },
  { value: 'youth_theatre', label: 'Youth Theatre' },
  { value: 'other', label: 'Other' }
];

const address_type_options = [
  { value: 'business', label: 'Business' },
  { value: 'residential', label: 'Residential' }
];

const color_options = [
  { value: null, label: 'None' },
  { value: 'red', label: 'Red' },
  { value: 'blue', label: 'Blue' },
  { value: 'green', label: 'Green' },
  { value: 'yellow', label: 'Yellow' }
];

export {
  booking_state_options,
  yes_no_options,
  edits_status_options,
  rate_status_options,
  org_status_options,
  org_group_options,
  address_type_options,
  color_options
};
