import React from 'react';
import { Text, StyledTable } from 'components';
import { isMedia } from 'helpers';

export default class CurrentShowsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: false,
      columnExpander: '',
      selected: ''
    };
  }

  componentDidMount() {}

  render() {
    const { bookings } = this.props;
    const paginateBookedBookings =
      bookings.filter(booking => {
        return booking.state === 'booked';
      }).length > 3
        ? true
        : false;

    return (
      <div>
        <br />
        <Text large bold>
          Booked Shows
        </Text>
        <br />
        <br />
        <StyledTable
          data={bookings.filter(booking => {
            return booking.state === 'booked';
          })}
          noDataText="No current shows."
          columns={[
            {
              Header: 'Title',
              accessor: 'show.name',
              minWidth: 125
            },

            {
              Header: 'Rehearsal From',
              accessor: 'rehearsal_active_date',
              minWidth: 90,
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Production From',
              accessor: 'production_active_date',
              minWidth: 90,
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Opening',
              accessor: 'opening',
              minWidth: 90
            },
            {
              Header: 'Closing/Expiration',
              accessor: 'expiration',
              minWidth: 90
            }
          ]}
          resizable={false}
          noShadow
          sortable={false}
          showPagination={paginateBookedBookings}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          defaultPageSize={10}
          // getTrProps={(state, rowInfo, column, instance) => {
          //   return {
          //     onClick: () => {
          //       this.props.onClick(rowInfo.original.id);
          //       // this.props.history.push(`/org/shows/${rowInfo.original.id}`);
          //     }
          //   };
          // }}
        />
      </div>
    );
  }
}
