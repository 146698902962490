import React from 'react';
import styled from 'styled-components';
import { Header, FormWrapper, Text, Form, Spacer, Wrapper } from 'components';

import { getEmailPreviews } from 'data-graphql';

const EmailDiv = styled.div`
  section * {
    font-family: Arial, Helvetica, sans-serif;
  }
  p {
    line-height: 1.2em;
  }
  ol,
  ul {
    padding: 20px;
  }
`;

export default class Account extends React.Component {
  constructor() {
    super();
    this.state = {
      templates: [],
      template: ''
    };
  }

  componentDidMount() {
    this.getEmailPreviews();
  }

  handleEmailPreviewChange = template => {
    this.setState({ template });
  };

  getEmailPreviews = async () => {
    const REQ_OBJECT = `{
        sendsTo
        template
        title
        triggers
        subject
        body
    }`;

    const RESULT = await getEmailPreviews(REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      this.setState({ templates: RESULT.data.getAllEmailTemplatePreviews });
    }
  };

  render() {
    return (
      <div>
        <Header>
          <Header.Head bold huge>
            Email Previews
          </Header.Head>
        </Header>
        <Wrapper>
          <FormWrapper>
            {this.state.templates.length > 0 && (
              <Form.StyledSelect
                value={this.state.template}
                onChange={this.handleEmailPreviewChange}
                options={this.state.templates}
                isSearchable={true}
                getOptionLabel={option => option.title}
                getOptionValue={option => option.template}
                placeholder="Select an Email to Preview..."
                styles={Form.CustomStyles}
              />
            )}
          </FormWrapper>
          {this.state.template && (
            <FormWrapper>
              <Form style={{ textAlign: 'initial' }}>
                <Text large red>
                  Triggers
                </Text>
                <Spacer size="10px" />
                {this.state.template.triggers && (
                  <ul>
                    {this.state.template.triggers.map(trig => (
                      <li key={trig}>
                        <Text soft>{trig}</Text>
                      </li>
                    ))}
                  </ul>
                )}
                <Spacer size="30px" />

                <Text large red>
                  Sends To
                </Text>
                <Spacer size="10px" />
                <Text soft>{this.state.template.sendsTo}</Text>
                <Spacer size="30px" />

                <Text large red>
                  Subject
                </Text>
                <Spacer size="10px" />
                <Text soft>{this.state.template.subject}</Text>
                <Spacer size="30px" />

                <Text large red>
                  Body
                </Text>
                <Spacer size="10px" />
                <EmailDiv
                  dangerouslySetInnerHTML={{ __html: this.state.template.body }}
                />
              </Form>
            </FormWrapper>
          )}
        </Wrapper>
      </div>
    );
  }
}
