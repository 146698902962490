import styled from 'styled-components';
//import COLORS from './colors';

export default styled.li`
  list-style: none;
  display: list-item
  margin-left: 10px
  font-size: 12px
  height: 16px;
  overflow: hidden

  :before {
    content: "";
	  border: ${props =>
      `3px ${
        props.color === 'default' ? 'black' : props.color
      } solid !important;`} 
	  border-radius: 50%;
	  margin-top: 5px;
	  margin-left: -10px;
	  position: absolute;
  }
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    width: 100px;
  }
`;
