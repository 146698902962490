import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AllAdmins from './AllAdmins';
import AdminDetails from './AdminDetails';

export default props => {
  return (
    <Switch>
      <Route exact path={`${props.match.url}`} component={AllAdmins} />
      <Route exact path={`${props.match.url}/:id`} component={AdminDetails} />
    </Switch>
  );
};
