import { throttle } from 'lodash';

const throttleTest = throttle(() => {
  const thisWindow =
    window.innerWidth > 998
      ? 'desktop'
      : window.innerWidth > 768
      ? 'tablet'
      : window.innerWidth > 479
      ? 'phablet'
      : 'phone';
  return thisWindow;
}, 100);

export default throttleTest;
