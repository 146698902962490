import React from 'react';
import { Button, FormWrapper, Content, Spacer } from 'components';
import BookForm from './BookForm.js';

export default class ButtonGroup extends React.Component {
  constructor() {
    super();
    this.initialState = {
      organizations: [],
      shows: [],
      view: false
    };
    this.state = this.initialState;
  }

  componentDidMount() {}

  handleClick = e => {
    this.setState({
      view: this.state.view === e.target.value ? false : e.target.value
    });
  };

  render() {
    const { organization } = this.props;

    const handleChildChange = () => {
      //close form and call fn up to ordDetails to recall state
      this.setState(this.initialState);
      this.props.onButtonChange();
    };

    return (
      <div>
        <Button
          value="book"
          style={{ verticalAlign: '40%' }}
          onClick={this.handleClick}
        >
          Book {this.state.view === 'book' ? '-' : '+'}
        </Button>
        <Button
          value="auth"
          style={{ verticalAlign: '40%', marginLeft: '20px' }}
          onClick={this.handleClick}
        >
          Authorize {this.state.view === 'auth' ? '-' : '+'}
        </Button>
        <Content
          pose={this.state.view === 'book' ? 'open' : 'closed'}
          style={{
            overflow: this.state.view === 'create' ? 'visible' : 'hidden'
          }}
        >
          <FormWrapper>
            <BookForm
              type="book"
              organization_id={organization.id}
              onBook={handleChildChange}
            />
          </FormWrapper>
          <Spacer size="100px" />
        </Content>
        <Content
          pose={this.state.view === 'auth' ? 'open' : 'closed'}
          style={{
            overflow: this.state.view === 'create' ? 'visible' : 'hidden'
          }}
        >
          <FormWrapper>
            <BookForm
              type="auth"
              organization_id={organization.id}
              onBook={handleChildChange}
            />
          </FormWrapper>
          <Spacer size="100px" />
        </Content>
      </div>
    );
  }
}
