// import React from 'react'
import styled from 'styled-components';
import COLORS from './colors';

export default styled.textarea`
  padding: 10px 15px;
  border-radius: 0;
  border: 1px solid ${COLORS.lightGrey};
  width: 100%;
  font-size: 16px;
  &:focus {
    outline: none;
  }
`;
