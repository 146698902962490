import gql from 'graphql-tag';
import graphql from './graphql';

export async function createAuthorization(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation CreateAuthorization($input: CreateAuthorizationInput) {
          createAuthorization(input: $input)
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function createEditAuthorization(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation CreateEditAuthorization($input: CreateEditingAuthorizationInput) {
          createEditAuthorization(input: $input)
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function updateAuthorization(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation UpdateAuthorization($input: UpdateAuthorizationInput) {
          updateAuthorization(input: $input)
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function removeAuthorization(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation RemoveAuthorization($bookingId: UUID!, $userId: UUID!, $deviceId: UUID!) {
          removeAuthorization(bookingId: $bookingId, userId: $userId, deviceId: $deviceId)
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}
