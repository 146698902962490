import gql from 'graphql-tag';
import graphql from './graphql';

export async function getShows(VARS, REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
        query GetShows($page: Int!, $search: String, $archived: Boolean) {
          getShows(
            input: { page: $page, search: $search, archived: $archived }
          ) 
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function getAllShows(REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
        query GetAllShows {
          getAllShows 
          ${REQ_OBJECT}
        }
      `,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function getAllQuotableShows(REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
        query GetAllQuotableShows {
          getAllQuotableShows 
          ${REQ_OBJECT}
        }
      `,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function getShow(VARS, REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
        query GetShow($id: UUID!) {
          getShow(id: $id) 
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function createShow(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation CreateShow($input: CreateShowInput!) {
          createShow(input: $input)
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function updateShow(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation UpdateShow($id: UUID!, $input: UpdateShowInput!) {
          updateShow(id: $id, input: $input)
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function createSetlist(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation CreateSetlist($input: CreateSetlistInput!) {
          createSetlist(input: $input)
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function deleteSetlist(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation DeleteSetlist($id: UUID!) {
          deleteSetlist(id: $id)
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function generateRehearsalEditCodes(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation GenerateRehearsalEditCodes($show_id: UUID!) {
          generateRehearsalEditCodes(show_id: $show_id)
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function markShowEditable(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation markShowEditable($show_id: UUID!) {
          markShowEditable(show_id: $show_id)
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}
