import posed from 'react-pose';

const Content = posed.div({
  closed: {
    height: 0,
    transition: {
      duration: 300
    }
  },
  open: {
    height: 'auto',
    transition: {
      duration: 300
    }
  }
});

export { Content };
