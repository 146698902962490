import React from 'react';
import styled from 'styled-components';
import { Wrapper, FormWrapper, Spacer, Text, COLORS } from 'components';
import LogoSrc from 'assets/logo.svg';
import mtiLogo from 'assets//mtiLogoBottomAligned.png';
import { isMTI } from 'helpers';

const Logo = styled.img`
  height: 100%;
  padding: 20px;
  margin: auto;
`;

const Header = styled.div`
  height: 130px;
  width: 100%;
  background-color: ${isMTI ? COLORS.darkBlue : COLORS.dark};
  text-align: center;
  position: absolute;
  z-index: 10;
`;

const StyledFormWrapper = styled(FormWrapper)`
  width: 90%;
  text-align: left;
`;

const StyledHR = styled.hr`
  border: 1px solid ${COLORS.lightGrey};
`;

const List = styled.ul`
  list-style: disc outside none;
  margin: 10px 5px 10px 10px;
  padding-left: 1em;
`;

const Section = styled.div`
  margin: 10px 10px;
`;

export default class Privacy extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  backToLogin = () => {
    this.props.history.push('/');
  };

  render() {
    return (
      <div style={{ maxHeight: '100vh', overflowY: 'auto' }}>
        <Header>
          <Logo src={isMTI ? mtiLogo : LogoSrc} onClick={this.backToLogin} />
        </Header>
        <Wrapper style={{ marginTop: '130px' }}>
          <Spacer size="20px" />
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Text huge red>
              Mobile Application Privacy Policy
            </Text>
          </div>
          <StyledFormWrapper>
            <Text>Last Modified: October 28, 2019</Text>
            <Spacer size="20px" />
            <StyledHR />
            <Spacer size="20px" />
            <Text>
              <u>Introduction</u>
            </Text>
            <Spacer size="20px" />
            <Section>
              <Text>
                MT Pit, LLC (<b>“Company”</b> or <b>“We”</b>) respects your
                privacy and is committed to protecting it through our compliance
                with this policy. This policy describes:
              </Text>
              <List>
                <li>
                  The types of information we may collect or that you may
                  provide when you purchase, download, install, register with,
                  access, or use the MT Pit application (the “App”).
                </li>
                <li>
                  Our practices for collecting, using, maintaining, protecting,
                  and disclosing that information.
                </li>
              </List>
              <Text>
                This policy applies only to information we collect in this App,
                and in email, text, and other electronic communications sent
                through or in connection with this App.
              </Text>
              <Spacer size="10px" />
              <Text>This policy DOES NOT apply to information that:</Text>
              <List>
                <li>
                  We collect offline or on any other Company apps or websites,
                  including websites you may access through this App.
                </li>
                <li>
                  You provide to or is collected by any third party (see{' '}
                  <i>Third-Party Information Collection</i>).
                </li>
              </List>
              <Text>
                Our websites and apps, and these other third parties have their
                own privacy policies, which we encourage you to read before
                providing information on or through them.
              </Text>
              <Spacer size="10px" />
              <Text>
                Please read this policy carefully to understand our policies and
                practices regarding your information and how we will treat it.
                If you do not agree with our policies and practices, do not
                download, register with, or use this App. By downloading,
                registering with, or using this App, you agree to this privacy
                policy. This policy may change from time to time (see Changes to
                Our Privacy Policy). Your continued use of this App after we
                revise this policy means you accept those changes, so please
                check the policy periodically for updates.
              </Text>
            </Section>
            <Spacer size="20px" />
            <Text>
              <u>Children Under the Age of 13</u>
            </Text>
            <Section>
              <Text>
                The App is not intended for children under 13 years of age, and
                we do not knowingly collect personal information from children
                under 13. If we learn we have collected or received personal
                information from a child under 13 without verification of
                parental consent, we will delete that information. If you
                believe we might have any information from or about a child
                under 13, please contact us at mail@themtpit.com.{' '}
              </Text>
              <Spacer size="10px" />
              <Text>
                California residents under 16 years of age may have additional
                rights regarding the collection and sale of their personal
                information. Please see Your California Privacy Rights for more
                information
              </Text>
            </Section>
            <Spacer size="20px" />
            <Text>
              <u>Information We Collect and How We Collect It</u>
            </Text>
            <Section>
              <Text>
                We collect information from and about users of our App:
              </Text>
              <List>
                <li>Directly from you when you provide it to us.</li>
                <li>Automatically when you use the App.</li>
              </List>
              <Text>
                <i>Information You Provide to Us</i>
              </Text>
              <Spacer size="10px" />
              <Text>
                When you download, register with, or use this App, we may ask
                you provide information:
              </Text>
              <List>
                <li>
                  By which you may be personally identified, such as name, email
                  address, or any other identifier by which you may be contacted
                  online or offline (<b>“personal information”</b>).
                </li>
                <li>
                  That is about you but individually does not identify you.
                </li>
              </List>
              <Text>This information includes:</Text>
              <List>
                <li>
                  Information that you provide by filling in forms in the App.
                  This includes information provided at the time of registering
                  to use the App, and if applicable,requesting further services.
                  We may also ask you for information when you report a problem
                  with the App.
                </li>
                <li>
                  Records and copies of your correspondence (including email
                  addresses and phone numbers), if you contact us.
                </li>
                <li>
                  Details of transactions you carry out through the App and of
                  the fulfillment of your orders. You may be required to provide
                  financial information before placing an order through the App.
                </li>
                <li>Your search queries on the App.</li>
              </List>
              <Text>
                <i>Automatic Information Collection</i>
              </Text>
              <Spacer size="10px" />
              <Text>
                When you download, access, and use the App, it may use
                technology to automatically collect:
              </Text>
              <List>
                <li>
                  <b>Usage Details.</b> When you access and use the App, we may
                  automatically collect certain details of your access to and
                  use of the App, including traffic data, logs, and other
                  communication data and the resources that you access and use
                  on or through the App.{' '}
                </li>
                <li>
                  <b>Device Information.</b> We may collect information about
                  your mobile device and internet connection, including the
                  device’s unique device identifier, IP address, operating
                  system, browser type, mobile network information, and the
                  device’s model number.
                </li>
                <li>
                  <b>Location Information.</b> This App does not collect
                  information about the location of your device.
                </li>
              </List>
              <Text>
                If you do not want us to collect this information do not
                download the App or delete it from your device.
              </Text>
              <Text>
                <i>Information Collection Technologies</i>
              </Text>
              <Spacer size="10px" />
              <Text>
                The technologies we use for automatic information collection may
                include:
              </Text>
              <List>
                <li>
                  <b>Cookies (or mobile cookies).</b> A cookie is a small file
                  placed on your smartphone. It may be possible to refuse to
                  accept mobile cookies by activating the appropriate setting on
                  your smartphone. However, if you select this setting you may
                  be unable to access certain parts of our App.{' '}
                </li>
                <li>
                  <b>Web Beacons.</b> Pages of the App may contain small
                  electronic files known as web beacons (also referred to as
                  clear gifs, pixel tags, and single-pixel gifs) that permit the
                  Company, for example, to count users who have visited those
                  pages and for other related app statistics (for example,
                  recording the popularity of certain app content and verifying
                  system and server integrity).
                </li>
              </List>
              <Text>
                <i>Third-Party Information Collection</i>
              </Text>
              <Spacer size="10px" />
              <Text>
                When you use the App or its content, certain third parties may
                use automatic information collection technologies to collect
                information about you or your device. These third parties may
                include:{' '}
              </Text>
              <List>
                <li>Advertisers, ad networks, and ad servers.</li>
                <li>Analytics companies.</li>
                <li>Your mobile device manufacturer.</li>
                <li>Your mobile service provider.</li>
              </List>
              <Text>
                These third parties may use tracking technologies to collect
                information about you when you use this app. The information
                they collect may be associated with your personal information or
                they may collect information, including personal information,
                about your online activities over time and across different
                websites, apps, and other online services websites. They may use
                this information to provide you with interest-based (behavioral)
                advertising or other targeted content.{' '}
              </Text>
              <Spacer size="10px" />
              <Text>
                We do not control these third parties’ tracking technologies or
                how they may be used. If you have any questions about an
                advertisement or other targeted content,you should contact the
                responsible provider directly.
              </Text>
            </Section>
            <Spacer size="20px" />
            <Text>
              <u>How We Use Your Information</u>
            </Text>
            <Section>
              <Text>
                We use information that we collect about you or that you provide
                to us, including any personal information, to:
              </Text>
              <List>
                <li>
                  Provide you with the App and its contents, and any other
                  information, products or services that you request from us.
                </li>
                <li>Fulfill any other purpose for which you provide it.</li>
                <li>
                  Give you notices about your subscription, including expiration
                  and renewal notices.
                </li>
                <li>
                  Carry out our obligations and enforce our rights arising from
                  any contracts entered into between you and us, including for
                  billing and collection.
                </li>
                <li>
                  Notify you when App updates are available, and of changes to
                  any products or services we offer or provide though it.
                </li>
              </List>
              <Text>
                The usage information we collect helps us to improve our App and
                to deliver a better and more personalized experience by enabling
                us to:
              </Text>
              <List>
                <li>Estimate our audience size and usage patterns.</li>
                <li>
                  Store information about your preferences, allowing us to
                  customize our App according to your individual interests.
                </li>
                <li>Speed up your searches.</li>
                <li>Recognize you when you use the App.</li>
              </List>
            </Section>
            <Spacer size="20px" />
            <Text>
              <u>Disclosure of Your Information</u>
            </Text>
            <Section>
              <Text>
                We may disclose aggregated information about our users, and
                information that does not identify any individual or device,
                without restriction.
              </Text>
              <Spacer size="10px" />
              <Text>
                In addition, we may disclose personal information that we
                collect or you provide:
              </Text>
              <List>
                <li>To our subsidiaries and affiliates.</li>
                <li>
                  To contractors, service providers, and other third parties we
                  use to support our business and who are bound by contractual
                  obligations to keep personal information confidential and use
                  it only for the purposes for which we disclose it to them.
                </li>
                <li>
                  To a buyer or other successor in the event of a merger,
                  divestiture, restructuring, reorganization, dissolution, or
                  other sale or transfer of some or all of the Company’s assets,
                  whether as a going concern or as part of bankruptcy,
                  liquidation, or similar proceeding, in which personal
                  information held by Company about our App users is among the
                  assets transferred.
                </li>
                <li>To fulfill the purpose for which you provide it.</li>
                <li>
                  For any other purpose disclosed by us when you provide the
                  information.
                </li>
                <li>With your consent.</li>
                <li>
                  To comply with any court order, law, or legal process,
                  including to respond to any government or regulatory request.
                </li>
                <li>
                  To enforce our rights arising from any contracts entered into
                  between you and us, including the App End User License
                  Agreement, and for billing and collection.
                </li>
                <li>
                  If we believe disclosure is necessary or appropriate to
                  protect the rights, property, or safety of the Company, our
                  customers or others. This includes exchanging information with
                  other companies and organizations for the purposes of fraud
                  protection and credit risk reduction.
                </li>
              </List>
            </Section>
            <Text>
              <u>
                Your Choices About Our Collection, Use, and Disclosure of Your
                Information
              </u>
            </Text>
            <Section>
              <Text>
                We strive to provide you with choices regarding the personal
                information you provide to us. This section describes mechanisms
                we provide for you to control certain uses and disclosures of
                over your information.{' '}
              </Text>
              <List>
                <li>
                  <b>Tracking Technologies.</b> You can set your browser to
                  refuse all or some browser cookies, or to alert you when
                  cookies are being sent. If you disable or refuse cookies or
                  block the use of other tracking technologies, some parts of
                  the App may then be inaccessible or not function properly.{' '}
                </li>
                <li>
                  We do not control third parties’ collection or use of your
                  information to serve interest-based advertising. However,
                  these third parties may provide you with ways to choose not to
                  have your information collected or used in this way. You can
                  opt out of receiving targeted ads from members of the Network
                  Advertising Initiative (<b>“NAI”</b>) on the NAI’s website.
                </li>
              </List>
              <Text>
                California residents may have additional personal information
                rights and choices. lease see{' '}
                <u>Your California Privacy Rights</u> for more information.
              </Text>
            </Section>
            <Spacer size="20px" />
            <Text>
              <u>Accessing and Correcting Your Personal Information</u>
            </Text>
            <Section>
              <Text>
                You can review and change your personal information by logging
                into the App and visiting your account profile page.
              </Text>
              <Spacer size="10px" />
              <Text>
                You may also send us an email at mail@themtpit.com to request
                access to, correct, or delete any personal information that you
                have provided to us. We cannot delete your personal information
                except by also deleting your user account. We may not
                accommodate a request to change information if we believe the
                change would violate any law or legal requirement or cause the
                information to be incorrect.
              </Text>
              <Spacer size="10px" />
              <Text>
                California residents may have additional personal information
                rights and choices. Please see Your California Privacy Rights
                for more information.
              </Text>
            </Section>
            <Spacer size="20px" />
            <Text>
              <u>Your California Privacy Rights</u>
            </Text>
            <Section>
              <Text>
                If you are a California resident, California law may provide you
                with additional rights regarding our use of your personal
                information. To learn more about your California privacy rights,
                visit https://www.oag.ca.gov/privacy/ccpa.{' '}
              </Text>
            </Section>
            <Spacer size="20px" />
            <Text>
              <u>Data Security</u>
            </Text>
            <Section>
              <Text>
                We have implemented measures designed to secure your personal
                information from accidental loss and from unauthorized access,
                use, alteration, and disclosure. All information you provide to
                us is stored on our secure servers behind firewalls. Any payment
                transactions be encrypted using SSL technology.
              </Text>
              <Spacer size="10px" />
              <Text>
                The safety and security of your information also depends on you.
                Where we have given you (or where you have chosen) a password
                for access to certain parts of our App, you are responsible for
                keeping this password confidential. We ask you not to share your
                password with anyone. We urge you to be careful about giving out
                information in public areas of the App like message boards. The
                information you share in public areas may be viewed by any user
                of the App.
              </Text>
              <Spacer size="10px" />
              <Text>
                Unfortunately, the transmission of information via the internet
                and mobile platforms is not completely secure. Although we do
                our best to protect your personal information, we cannot
                guarantee the security of your personal information transmitted
                through our App. Any transmission of personal information is at
                your own risk. We are not responsible for circumvention of any
                privacy settings or security measures we provide.
              </Text>
            </Section>
            <Spacer size="20px" />
            <Text>
              <u>Changes to Our Privacy Policy</u>
            </Text>
            <Section>
              <Text>
                We may update our privacy policy from time to time. If we make
                material changes to how we treat our users’ personal
                information, we will post the new privacy policy on this page.
              </Text>
              <Spacer size="10px" />
              <Text>
                The date the privacy policy was last revised is identified at
                the top of the page. You are responsible for ensuring we have an
                up-to-date active and deliverable email address for you and for
                periodically visiting this privacy policy to check for any
                changes.
              </Text>
            </Section>
            <Spacer size="20px" />
            <Text>
              <u>Contact Information</u>
            </Text>
            <Section>
              <Text>
                To ask questions or comment about this privacy policy and our
                privacy practices, contact us at:
              </Text>
              <Spacer size="10px" />
              <Text>mail@themtpit.com</Text>
              <Spacer size="10px" />
              <Text>or via our number:</Text>
              <Spacer size="10px" />
              <Text>(801) 883-9933</Text>
            </Section>
          </StyledFormWrapper>
        </Wrapper>
      </div>
    );
  }
}
