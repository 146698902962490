import React from 'react';
import { Button, FormWrapper, Form } from 'components';
import { updateUser } from 'data-graphql';
import { toast } from 'react-toastify';

export default class Activate extends React.Component {
  activateAccount = async () => {
    const VARS = {
      id: this.props.user.id,
      input: {
        archived: false
      }
    };

    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    const RESULT = await updateUser(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.props.onChange();
    }
  };

  render() {
    return (
      <FormWrapper>
        <Form>
          <Button large negative onClick={this.activateAccount}>
            Activate Account
          </Button>
        </Form>
      </FormWrapper>
    );
  }
}
