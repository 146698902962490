import React from 'react';
import { Text, Button, Form, Spacer, FlexBox, Icon } from 'components';
import { toast } from 'react-toastify';
import { createShow } from 'data-graphql';

export default class CreateOrgForm extends React.Component {
  constructor() {
    super();
    this.initialState = {
      name: '',
      notes: '',
      creating: false
    };
    this.state = this.initialState;
  }

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value });
  };

  close = () => {
    this.setState(this.initialState);
    this.props.onChange();
  };

  submit = async () => {
    if (!this.state.name) {
      return toast.error('Name field must be completed.', { autoclose: false });
    }

    const VARS = {
      input: {
        name: this.state.name,
        notes: this.state.notes || null
      }
    };

    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    this.setState(prevState => ({
      creating: !prevState.creating
    }));

    const RESULT = await createShow(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.close();
    } else {
      this.setState(prevState => ({
        creating: !prevState.creating
      }));
    }
  };

  render() {
    return (
      <Form>
        <FlexBox justify="flex-end">
          <Icon onClick={this.close} name="close" />
        </FlexBox>
        <Text red large>
          Create A Show
        </Text>
        <Spacer size="10px" />
        <Form.InputWrapper>
          <Form.Input
            name="name"
            onChange={this.handleChange}
            value={this.state.name}
            placeholder="Show Name"
          />
        </Form.InputWrapper>
        <Spacer size="10px" />
        <Form.InputWrapper>
          <Form.TextArea
            name="notes"
            onChange={this.handleChange}
            value={this.state.notes}
            placeholder="Notes"
          />
        </Form.InputWrapper>

        <Spacer size="10px" />
        <Button onClick={this.submit}>
          {this.state.creating ? 'Creating...' : 'Create'}
        </Button>
      </Form>
    );
  }
}
