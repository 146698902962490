import React from 'react';
import {
  Wrapper,
  Pagination,
  Input,
  Header,
  Button,
  Menu,
  StyledTable,
  StyledSlideDown,
  FormWrapper,
  SearchIcon,
  CloseIcon,
  Content,
  media,
  FlexBox,
  COLORS
} from 'components';
import styled from 'styled-components';
import { getShows } from 'data-graphql';
import CreateShowForm from './components/CreateShowForm';
import { isMedia, formatDate } from 'helpers';
import Spinner from 'react-spinkit';

const InputWrapper = styled.form`
  width: 300px;
  ${media.phone`
    width: 100%;
  `};
`;

const ShowsDetailsTable = styled(StyledTable)`
  .rt-td {
    flex: 1 0 auto !important;
  }
`;

export default class Shows extends React.Component {
  constructor() {
    super();
    this.state = {
      shows: [],
      currentPage: 1,
      totalPages: 1,
      search: '',
      archived: false,
      createShowsOpen: false,
      menuOpen: false,
      loading: false
    };
  }

  componentDidMount() {
    this.getComponentShows();
  }

  getComponentShows = async () => {
    this.setState({ loading: true });
    const VARS = {
      page: this.state.currentPage,
      search: this.state.search || undefined,
      archived: this.state.archived || undefined
    };

    const REQ_OBJECT = `{
      currentPage
      totalPages
      shows {
        id
        name
        org_count
        archived_at
        foreign_show_id
        master_setlist {
          track_count
        }
      }
    }`;

    const RESULT = await getShows(VARS, REQ_OBJECT);
    this.setState({
      shows: RESULT.data.getShows.shows,
      currentPage: RESULT.data.getShows.currentPage,
      totalPages: RESULT.data.getShows.totalPages,
      loading: false
    });
  };

  handleSearchChange = e => {
    this.setState({ search: e.target.value }, this.search(e));
  };

  search = e => {
    e.preventDefault();

    this.setState({ currentPage: 1 }, this.getComponentShows);
  };

  clearSearch = e => {
    e.preventDefault();
    this.setState({ search: '', currentPage: 1 }, this.getComponentShows);
  };

  toggleArchived = () => {
    this.setState(
      {
        archived: !this.state.archived,
        menuOpen: this.state.menuOpen === 'create' ? false : 'create'
      },
      this.getComponentShows
    );
  };

  handleChildChange = () => {
    this.setState({ createShowsOpen: false }, this.getComponentShows);
  };

  openMenu = () => {
    this.setState({
      menuOpen: this.state.menuOpen === 'create' ? false : 'create'
    });
  };

  openShows = () => {
    this.setState({
      createShowsOpen: this.state.createShowsOpen === true ? false : true
    });
  };

  render() {
    const { menuOpen } = this.state;
    const columns = [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'MTI Show ID',
        accessor: 'foreign_show_id'
      },
      {
        Header: 'Tracks',
        accessor: 'master_setlist.track_count',
        Cell: props => <span className="number">{props.value}</span>
      },
      {
        Header: 'Organizations',
        accessor: 'org_count'
      },
      {
        Header: 'Archive Date',
        accessor: 'archived_at',
        show: this.state.archived,
        Cell: row => <div>{formatDate(row.value, 'LL')}</div>
      }
    ];
    const { createShowsOpen } = this.state;
    return (
      <div>
        <Header>
          <Header.Head bold huge>
            Shows
            <Button onClick={this.openShows} header>
              Create a Show {createShowsOpen ? '-' : '+'}
            </Button>
          </Header.Head>
          {isMedia() === 'phone' ? (
            <>
              <Button darkRed menu onClick={this.openMenu}>
                {this.state.archived ? 'Archived' : 'Active'}{' '}
                {menuOpen === 'create' ? '-' : '+'}
              </Button>
              <Content
                pose={menuOpen === 'create' ? 'open' : 'closed'}
                style={{
                  overflow: menuOpen === 'create' ? 'visible' : 'hidden'
                }}
              >
                <Menu>
                  <button
                    onClick={this.toggleArchived}
                    active={!this.state.archived}
                  >
                    Active
                  </button>
                  <button
                    onClick={this.toggleArchived}
                    active={this.state.archived}
                  >
                    Archived
                  </button>
                </Menu>
              </Content>
            </>
          ) : (
            <Menu>
              <button
                onClick={this.toggleArchived}
                active={!this.state.archived}
              >
                Active
              </button>
              <button
                onClick={this.toggleArchived}
                active={this.state.archived}
              >
                Archived
              </button>
            </Menu>
          )}
        </Header>
        <Wrapper>
          <InputWrapper onSubmit={this.search}>
            <Input
              placeholder="Search"
              onChange={this.handleSearchChange}
              value={this.state.search}
              icon={
                this.state.search.length < 1 ? (
                  <SearchIcon onClick={this.search} />
                ) : (
                  <CloseIcon onClick={this.clearSearch} />
                )
              }
            />
          </InputWrapper>
          {this.state.loading ? (
            <FlexBox justify="space-evenly" style={{ alignContent: 'center' }}>
              <Spinner
                name="three-bounce"
                color={COLORS.darkRed}
                fadeIn="none"
              />
            </FlexBox>
          ) : (
            <>
              <StyledSlideDown>
                {this.state.createShowsOpen && (
                  <FormWrapper>
                    <CreateShowForm onChange={this.handleChildChange} />
                  </FormWrapper>
                )}
              </StyledSlideDown>
              <br />

              <ShowsDetailsTable
                data={this.state.shows}
                resizeable={false}
                columns={columns}
                resizable={false}
                sortable={false}
                showPagination={false}
                showPaginationTop={false}
                showPaginationBottom={false}
                showPageSizeOptions={false}
                minRows={1}
                pageSize={this.state.shows.length}
                getTrProps={(state, rowInfo) => {
                  return {
                    onClick: () => {
                      this.props.history.push(
                        `/admin/shows/${rowInfo.original.id}`
                      );
                    }
                  };
                }}
              />

              {this.state.totalPages > 1 && (
                <Pagination
                  onPageChange={currentPage => {
                    this.setState({ currentPage }, this.getComponentShows);
                  }}
                  currentPage={this.state.currentPage}
                  totalPages={this.state.totalPages}
                />
              )}
            </>
          )}
        </Wrapper>
      </div>
    );
  }
}
