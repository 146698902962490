import React from 'react';
import styled from 'styled-components';
import COLORS from '../../colors.js';

const Cell = styled.td`
  padding: 25px;
  background: ${COLORS.white};
  color: ${COLORS.black};
  text-align: center;
  &:first-of-type {
    text-align: left;
  }
`;

export default props => {
  const hovering = props.hovering;
  let toDisplay = props.children;
  if (hovering && props.hiddenChild !== undefined) {
    toDisplay = props.hiddenChild;
  }

  return <Cell>{toDisplay}</Cell>;
};
