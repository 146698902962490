import React from 'react';
import { Text, Button, Form, Spacer } from 'components';
import { toast } from 'react-toastify';
// import styled from 'styled-components';
import { createBooking, getAllShows } from 'data-graphql';
import { yes_no_options, booking_state_options } from 'helpers';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { estimateDates } from 'helpers';
import { formatDate } from '../../../../helpers/formatting';

export default class BookForm extends React.Component {
  constructor() {
    super();
    this.initialState = {
      prodTracks: false,
      opening: '',
      rehearsal_active_date: '',
      expiration: '',
      production_active_date: '',
      delivery: null,
      value: '',
      shows: [],
      notes: null,
      show: null,
      booking: false
    };
    this.state = this.initialState;
  }

  componentDidMount() {
    this.setSelectValues();
    this.getShows();
  }

  close = () => {
    this.setState(this.initialState);
    this.props.onBook();
    this.getShows();
  };

  submit = async () => {
    if (
      !this.state.show ||
      !this.state.show.id ||
      !this.state.bookingState ||
      !this.state.prodTracks ||
      !this.state.opening ||
      !this.state.expiration
    ) {
      return toast.error('All fields must be completed.', { autoclose: false });
    }

    const payload = {
      show_id: this.state.show.id,
      organization_id: this.props.organization_id,
      is_production_access: this.state.prodTracks.value,
      state: this.state.bookingState.value,
      opening: this.state.opening,
      expiration: this.state.expiration,
      rehearsal_active_date: this.state.rehearsal_active_date,
      production_active_date: this.state.production_active_date,
      //delivery: this.state.delivery,
      notes: this.state.notes
    };

    const VARS = {
      input: payload
    };

    const REQ_OBJECT = `{
      code
      success
      message
      booking {
        state
        id
      }
    }`;

    this.setState(prevState => ({
      booking: !prevState.booking
    }));

    const RESULT = await createBooking(VARS, REQ_OBJECT);

    if (RESULT.data) {
      toast.success(`${RESULT.data.createBooking.message}`);
      this.close();
    } else {
      this.setState(prevState => ({
        booking: !prevState.booking
      }));
    }
  };

  getShows = async () => {
    const REQ_OBJECT = `{
      name
      id
    }`;

    const RESULT = await getAllShows(REQ_OBJECT);

    this.setState({ shows: RESULT.data.getAllShows });
  };

  handleShowChange = show => {
    this.setState({ show });
  };

  handleBookingChange = bookingState => {
    this.setState({ bookingState });
  };

  handleProdTracksChange = prodSelect => {
    this.setState({
      prodTracks: prodSelect
    });
  };

  handleNotesChange = e => {
    const value = e.target.value;
    this.setState({ notes: value });
  };

  handleDateChange = (selectedDay, modifiers, dayPickerInput) => {
    let returnObj = {};

    returnObj[dayPickerInput.props.name] = selectedDay
      ? moment(selectedDay).format('YYYY-MM-DD')
      : null;

    if (dayPickerInput.props.name === 'opening') {
      returnObj = estimateDates(returnObj, selectedDay);
    }

    const closingSetting =
      dayPickerInput.props.name === 'opening'
        ? moment(returnObj.opening)
        : null;
    closingSetting &&
      this.setState({ expirationDate: new Date(closingSetting) });

    this.setState(returnObj);
  };

  setSelectValues = () => {
    const today = this.props.type === 'book' ? '' : new Date();
    const state =
      this.props.type === 'book'
        ? { value: 'booked', label: 'Booked' }
        : { value: 'active', label: 'Active' };

    this.setState({
      bookingState: state,
      opening: formatDate(today, 'YYYY-MM-DD'),
      rehearsal_active_date: formatDate(today, 'YYYY-MM-DD'),
      production_active_date: formatDate(today, 'YYYY-MM-DD')
    });
  };

  render() {
    if (!this.state.bookingState && this.state.shows.length === 0) return null;

    const booking_options = booking_state_options.filter(state => {
      if (this.props.type === 'book') {
        return state.value === 'booked' || state.value === 'active';
      } else {
        return state.value === 'active';
      }
    });
    return (
      <Form>
        <Text large red>
          {this.props.type === 'book' ? 'Book' : 'Authorize'} A Show
        </Text>
        <Spacer size="10px" />

        {this.state.shows.length > 0 && (
          <Form.InputWrapper full>
            <Form.Label small full>
              Show Name
            </Form.Label>
            <Spacer size="5px" />
            <Form.StyledSelect
              value={this.state.show}
              onChange={this.handleShowChange}
              options={this.state.shows}
              isSearchable={true}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              placeholder="Start typing a show's name..."
              styles={Form.CustomStyles}
            />
          </Form.InputWrapper>
        )}
        <Spacer size="5px" />
        <Form.InputWrapper>
          <Form.Label small>Booking State...</Form.Label>
          <Spacer size="5px" />
          <Form.StyledSelect
            value={this.state.bookingState}
            onChange={this.handleBookingChange}
            options={booking_options}
            isSearchable={false}
            styles={Form.CustomStyles}
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Label small>Include Production Tracks?</Form.Label>
          <Spacer size="5px" />
          <Form.StyledSelect
            styles={Form.CustomStyles}
            value={this.state.prodTracks}
            onChange={this.handleProdTracksChange}
            options={yes_no_options}
            isSearchable={false}
          />
        </Form.InputWrapper>

        <Form.InputWrapper>
          <Spacer size="5px" />
          <Form.Label small>Opening Date</Form.Label>
          <Spacer size="5px" />
          <Form.StyleDate>
            <DayPickerInput
              value={this.state.opening}
              onDayChange={this.handleDateChange}
              name="opening"
            />
          </Form.StyleDate>
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Spacer size="5px" />
          <Form.Label small>Closing/Expiration Date</Form.Label>
          <Spacer size="5px" />
          <Form.StyleDate>
            <DayPickerInput
              value={this.state.expiration}
              dayPickerProps={{
                month: this.state.expirationDate,
                disabledDays: {
                  before: this.state.expirationDate
                }
              }}
              onDayChange={this.handleDateChange}
              name="expiration"
            />
          </Form.StyleDate>
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Spacer />
          <Form.Label small>Rehearsal Track Availability Date</Form.Label>
          <Spacer size="5px" />
          <Form.StyleDate>
            <DayPickerInput
              value={this.state.rehearsal_active_date}
              onDayChange={this.handleDateChange}
              name="rehearsal_active_date"
            />
          </Form.StyleDate>
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Spacer />
          <Form.Label small>Production Track Availability Date</Form.Label>
          <Spacer size="5px" />
          <Form.StyleDate>
            <DayPickerInput
              value={this.state.production_active_date}
              onDayChange={this.handleDateChange}
              name="production_active_date"
            />
          </Form.StyleDate>
        </Form.InputWrapper>
        <Spacer size="10px" />
        <Form.InputWrapper full>
          <Form.Label small full>
            Notes
          </Form.Label>
          <Spacer size="5px" />
          <Form.TextArea
            placeholder="Notes"
            name="notes"
            onChange={this.handleNotesChange}
          />
        </Form.InputWrapper>

        <Spacer size="10px" />

        <Button onClick={this.submit} style={{ margin: 'auto' }}>
          {this.state.booking ? 'Submitting...' : 'Submit'}
        </Button>
      </Form>
    );
  }
}
