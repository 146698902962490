import React from 'react';
import { Card, Icon, Text } from 'components';
// import styled from 'styled-components';
import { formatDate } from 'helpers';

export default class About extends React.Component {
  render() {
    return (
      <Card>
        <Icon
          name="account-circle"
          style={{ fontSize: 80, paddingBottom: 5 }}
        />
        <br />
        <Text large bold>
          {this.props.user.first_name} {this.props.user.last_name}
        </Text>
        <br />
        <Text soft>{this.props.user.username}</Text>
        <br />
        <br />
        <Text style={{ wordBreak: 'break-word' }}>{this.props.user.email}</Text>
        <br />
        <Text small soft>
          User since {formatDate(this.props.user.user_since, 'MMM D, YYYY')}
        </Text>
      </Card>
    );
  }
}
