import gql from 'graphql-tag';
import graphql from './graphql';

export async function createContact(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation CreateContact($input: CreateContactInput!) {
          createContact(input: $input)
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function updateContact(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation updateContact($id: UUID!, $input: UpdateContactInput!) {
          updateContact(id: $id, input: $input)
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function createUserFromContact(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation CreateUserFromContact($contactId: UUID!) {
          createUserFromContact(contactId: $contactId)
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}
export async function deleteContact(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation DeleteContact($id: UUID!) {
          deleteContact(id: $id)
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}
