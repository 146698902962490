import React from 'react';
import { Text, Button, Form, Spacer, FlexBox, Icon } from 'components';
import { createOrganization, isUsernameAvailable } from 'data-graphql';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

const UserNameIcons = styled.div`
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: -45px;
  z-index: 1;
`;

export default class CreateOrgForm extends React.Component {
  constructor() {
    super();
    this.initialState = {
      name: '',
      notes: '',
      firstName: '',
      lastName: '',
      password: '',
      email: '',
      username: '',
      creating: false
    };
    this.state = this.initialState;
  }

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value }, this.checkUsername);
  };

  checkUsername = async () => {
    const VARS = {
      username: this.state.username
    };
    const REQ_OBJECT = `{
      boolean
    }`;

    this.setState({ loading: true });

    const RESULT = await isUsernameAvailable(VARS, REQ_OBJECT);
    this.setState({
      username_available: RESULT.data.isUsernameAvailable,
      loading: false
    });
  };

  close = () => {
    this.setState(this.initialState);
    this.props.onCreate();
  };

  submit = async () => {
    if (
      !this.state.name ||
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.email ||
      !this.state.username
    ) {
      return toast.error('All fields must be completed.', { autoclose: false });
    }

    if (this.state.username.length < 3) {
      return toast.error('Username must be at least 3 characters long.', {
        autoclose: false
      });
    }

    if (!this.state.username_available) {
      return toast.error('Username is taken.', { autoclose: false });
    }

    if (this.state.password && this.state.password.length < 8) {
      return toast.error('Password must be at least 8 characters long.', {
        autoclose: false
      });
    }

    const payload = {
      name: this.state.name,
      notes: this.state.notes || undefined,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      password: this.state.password || undefined,
      email: this.state.email,
      username: this.state.username
    };
    const VARS = { input: payload };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    this.setState(prevState => ({
      creating: !prevState.creating
    }));

    const RESULT = await createOrganization(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.close();
    } else {
      this.setState(prevState => ({
        creating: !prevState.creating
      }));
    }
  };

  render() {
    return (
      <Form>
        <FlexBox justify="flex-end">
          <Icon onClick={this.close} name="close" />
        </FlexBox>
        <Text red large>
          Add an Organization
        </Text>
        <Spacer size="10px" />
        <Form.InputWrapper>
          <Form.Input
            name="name"
            onChange={this.handleChange}
            value={this.state.name}
            placeholder="Organization Name"
          />
        </Form.InputWrapper>

        <Spacer size="5px" />
        <Form.InputWrapper>
          <Form.TextArea
            name="notes"
            onChange={this.handleChange}
            value={this.state.notes}
            placeholder="Notes"
          />
        </Form.InputWrapper>

        <Spacer size="10px" />
        <Text red large>
          New Account Info
        </Text>
        <Spacer size="20px" />
        <Form.InputWrapper>
          <FlexBox>
            <Form.Input
              type="text"
              placeholder="Username"
              value={this.state.username}
              name="username"
              onChange={this.handleChange}
            />
            {this.state.username && (
              <UserNameIcons>
                {this.state.loading ? (
                  <ClipLoader
                    color={'#494949'}
                    size={20}
                    loading={this.state.loading}
                  />
                ) : this.state.username_available &&
                  this.state.username.length > 2 ? (
                  <Icon name="check" green />
                ) : (
                  <Icon name="delete" red />
                )}
              </UserNameIcons>
            )}
          </FlexBox>
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Input
            name="email"
            onChange={this.handleChange}
            value={this.state.email}
            placeholder="Email (to send account info)"
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Input
            name="firstName"
            onChange={this.handleChange}
            value={this.state.firstName}
            placeholder="Primary Contact First Name"
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Input
            name="lastName"
            onChange={this.handleChange}
            value={this.state.lastName}
            placeholder="Primary Contact Last Name"
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Input
            name="password"
            onChange={this.handleChange}
            value={this.state.password}
            placeholder="Temporary Password (random if empty)"
          />
        </Form.InputWrapper>

        <Spacer size="5px" />
        <Button onClick={this.submit}>
          {this.state.creating ? 'Creating...' : 'Create'}
        </Button>
      </Form>
    );
  }
}
