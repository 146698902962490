import React from 'react';
import {
  Wrapper,
  Menu,
  Card,
  Icon,
  Text,
  Button,
  Header,
  Content
} from 'components';
import { formatDate, isMedia } from 'helpers';
import { getUser, updateUser, getMe } from 'data-graphql';
import { toast } from 'react-toastify';

export default class Admins extends React.Component {
  constructor() {
    super();
    this.state = {
      user: undefined,
      activeTab: 'About',
      menuOpen: false
    };
  }

  componentDidMount() {
    this.getComponentUser();
    this.getCurrentUser();
  }

  getCurrentUser = async () => {
    const REQ_OBJECT = `{
      email
      first_name
      last_name
      account_type
    }`;

    const RESULT = await getMe(REQ_OBJECT);

    this.setState({
      type: RESULT.data.getMe.account_type
    });
  };

  getComponentUser = async () => {
    const VARS = { id: this.props.match.params.id };
    const REQ_OBJECT = `{
      id
      username
      email
      phone_number
      first_name
      last_name
      account_type
      client_name
      is_archived
      user_since
    }`;

    const RESULT = await getUser(VARS, REQ_OBJECT);

    this.setState({
      user: RESULT.data.getUser
    });
  };

  toggleArchived = async () => {
    const VARS = {
      id: this.props.match.params.id,
      input: {
        archived: this.state.user.is_archived ? false : true
      }
    };
    await this.updateUser(VARS);
  };

  toggleSuperAdminAccess = async () => {
    const VARS = {
      id: this.props.match.params.id,
      input: {
        account_type:
          this.state.user.account_type === 'super_admin'
            ? 'admin'
            : 'super_admin'
      }
    };
    await this.updateUser(VARS);
  };

  updateUser = async VARS => {
    const REQ_OBJECT = `{
      code
      success
      message
      user {
        id
        username
        email
        phone_number
        first_name
        last_name
        account_type
        client_name
        is_archived
        user_since
      }
    }`;

    const RESULT = await updateUser(VARS, REQ_OBJECT);

    if (RESULT.data) {
      toast.success(RESULT.data.message);
      this.setState({
        user: RESULT.data.updateUser.user
      });
    }
  };

  openMenu = () => {
    this.setState({
      menuOpen: this.state.menuOpen === 'create' ? false : 'create'
    });
  };

  menuSelect = e => {
    this.setState({
      activeTab: e.target.value,
      menuOpen: this.state.menuOpen === 'create' ? false : 'create'
    });
  };

  render() {
    const { menuOpen } = this.state;
    if (!this.state.user || !this.state.type) return null;
    return (
      <div>
        <Header>
          <Header.Head bold huge>
            {this.state.user.first_name} {this.state.user.last_name}
            {this.state.type === 'super_admin' && (
              <Button onClick={this.toggleSuperAdminAccess} header>
                {this.state.user.account_type === 'super_admin'
                  ? 'Revoke'
                  : 'Grant'}{' '}
                Super Admin Access
              </Button>
            )}
            {this.state.user.account_type === 'super_admin' && (
              <div
                {...(isMedia() === 'phone'
                  ? {}
                  : { style: { marginTop: -15 } })}
              >
                <Text soft small>
                  Super Admin
                </Text>
              </div>
            )}
          </Header.Head>
          {isMedia() === 'phone' ? (
            <>
              <Button darkRed menu onClick={this.openMenu}>
                {this.state.activeTab} {menuOpen === 'create' ? '-' : '+'}
              </Button>
              <Content
                pose={menuOpen === 'create' ? 'open' : 'closed'}
                style={{
                  overflow: menuOpen === 'create' ? 'visible' : 'hidden'
                }}
              >
                <Menu>
                  <button
                    value="About"
                    onClick={this.menuSelect}
                    active={this.state.activeTab === 'About'}
                  >
                    About
                  </button>
                  <button
                    value="Archive"
                    onClick={this.menuSelect}
                    active={this.state.activeTab === 'Archive'}
                  >
                    {this.state.user.is_archived ? 'Restore' : 'Archive'}
                  </button>
                </Menu>
              </Content>
            </>
          ) : (
            <Menu>
              <button
                value="About"
                onClick={this.menuSelect}
                active={this.state.activeTab === 'About'}
              >
                About
              </button>
              <button
                value="Archive"
                onClick={this.menuSelect}
                active={this.state.activeTab === 'Archive'}
              >
                {this.state.user.is_archived ? 'Restore' : 'Archive'}
              </button>
            </Menu>
          )}
        </Header>

        <Wrapper>
          {this.state.activeTab === 'About' && (
            <Card highlighted={this.state.user.account_type === 'super_admin'}>
              <Icon
                name="account-circle"
                style={{ fontSize: 80, paddingBottom: 5 }}
              />
              <br />
              <Text large bold>
                {this.state.user.first_name} {this.state.user.last_name}
              </Text>
              <br />
              <Text soft>{this.state.user.username}</Text>
              <br />
              <br />
              <Text style={{ wordBreak: 'break-word' }}>
                {this.state.user.email}
              </Text>
              <br />
              <Text small soft>
                Admin since{' '}
                {formatDate(this.state.user.user_since, 'MMM D, YYYY')}
              </Text>
            </Card>
          )}
          {this.state.activeTab === 'Archive' && (
            <div {...(isMedia() === 'phone' ? {} : { width: 500 })}>
              <Text huge>
                Are you sure you want to{' '}
                {this.state.user.is_archived ? 'restore' : 'archive'} this
                account?
              </Text>
              <br />
              <br />
              <Button large negative onClick={this.toggleArchived}>
                {this.state.user.is_archived ? 'Restore' : 'Archive'} Account
              </Button>
            </div>
          )}
        </Wrapper>
      </div>
    );
  }
}
