import React from 'react';
import { Wrapper, Menu, Content, Button } from 'components';
import Header from 'components/Header';
import Shows from './components/Shows.js';
import About from './components/About.js';
import Devices from './components/Devices.js';
import Emails from './components/Emails.js';
import Lock from './components/Lock.js';
import Activate from './components/Activate';
import Password from './components/Password.js';
import { getUser } from 'data-graphql';
import { isMedia } from 'helpers';

export default class Admins extends React.Component {
  constructor() {
    super();
    this.state = {
      user: undefined,
      activeTab: 'About',
      devices: undefined,
      bookings: undefined,
      menuOpen: false
    };

    this.getComponentUser = this.getComponentUser.bind(this);
  }

  componentDidMount() {
    this.getComponentUser();
  }

  handleChildChange = () => {
    this.setState({ activeTab: 'About' });
    this.getComponentUser();
  };

  async getComponentUser() {
    const VARS = { id: this.props.match.params.id };
    const REQ_OBJECT = `{
      id
      username
      email
      phone_number
      first_name
      last_name
      account_type
      is_archived
      is_locked
      user_since
      emails {
        id
        template
        address
        subject
        body
        date_sent
        times_sent
      }
      devices {
        id
        type
        description
        date_registered
      }
      all_active_authorizations {
        booking {
          id
          show {
            name
          }
          organization {
            name
          }
          opening
          expiration
          rehearsal_active_date
          production_active_date
          rate
          is_production_access
          edits_status
          rate_status
          notes
          notes_color
          rate
          state
          is_downloaded
        }
        device {
          type
          description
        }
      }
      active_rehearsal_codes {
        id
        show {
          name
        }
        organization {
          name
        }
        opening
        expiration
        rehearsal_active_date
        production_active_date
        rate
        is_production_access
        edits_status
        rate_status
        notes
        notes_color
        rate
        state
        is_downloaded
      }
    }`;

    const RESULT = await getUser(VARS, REQ_OBJECT);

    const bookings = RESULT.data.getUser.active_rehearsal_codes.map(booking => {
      booking.userProdAccess = false;
      booking.deviceName = 'Active Rehearsal Code';
      return booking;
    });
    const authBookings = RESULT.data.getUser.all_active_authorizations.map(
      auth => {
        auth.booking.userProdAccess = true;
        auth.booking.deviceName = `${auth.device.type} - ${auth.device.description}`;
        return auth.booking;
      }
    );

    this.setState({
      user: RESULT.data.getUser,
      devices: RESULT.data.getUser.devices,
      bookings: [...authBookings, ...bookings]
    });
  }

  menuOpen = () => {
    this.setState({
      menuOpen: this.state.menuOpen === 'open' ? false : 'open'
    });
  };

  menuSelect = e => {
    this.setState({
      activeTab: e.target.value,
      menuOpen: this.state.menuOpen === 'open' ? false : 'open'
    });
  };

  render() {
    const { menuOpen } = this.state;
    if (!this.state.user) return null;
    const activeTab = this.state.activeTab;

    return this.state.user.is_archived ? (
      <div>
        <Header>
          <Header.Head bold huge>
            {this.state.user.first_name} {this.state.user.last_name}
          </Header.Head>
          <Menu>
            <button active={true}>Activate</button>
            <button />
            {/* Extra button so that menu will render */}
          </Menu>
        </Header>

        <Wrapper>
          <Activate
            history={this.props.history}
            user={this.state.user}
            onChange={this.getComponentUser}
          />
        </Wrapper>
      </div>
    ) : (
      <div>
        <Header>
          <Header.Head bold huge>
            {this.state.user.first_name} {this.state.user.last_name}
          </Header.Head>
          {isMedia() === 'phone' ? (
            <>
              <Button darkRed menu onClick={this.menuOpen}>
                {this.state.activeTab} {menuOpen === 'open' ? '-' : '+'}
              </Button>
              <Content
                pose={menuOpen === 'open' ? 'open' : 'closed'}
                style={{
                  overflow: menuOpen === 'open' ? 'visible' : 'hidden'
                }}
              >
                <Menu>
                  <button
                    value="Shows"
                    onClick={this.menuSelect}
                    active={this.state.activeTab === 'Shows'}
                  >
                    Shows
                  </button>
                  <button
                    value="About"
                    onClick={this.menuSelect}
                    active={this.state.activeTab === 'About'}
                  >
                    About
                  </button>
                  <button
                    value="Devices"
                    onClick={this.menuSelect}
                    active={this.state.activeTab === 'Devices'}
                  >
                    Devices
                  </button>
                  <button
                    value="Emails"
                    onClick={this.menuSelect}
                    active={this.state.activeTab === 'Emails'}
                  >
                    Emails
                  </button>
                  <button
                    value="Lock"
                    onClick={this.menuSelect}
                    active={this.state.activeTab === 'Lock'}
                  >
                    {this.state.user.is_locked
                      ? 'Unlock Account'
                      : 'Lock Account'}
                  </button>
                  <button
                    value="Password"
                    onClick={this.menuSelect}
                    active={this.state.activeTab === 'Password'}
                  >
                    Reset Password
                  </button>
                </Menu>
              </Content>
            </>
          ) : (
            <Menu>
              <button
                value="Shows"
                onClick={this.menuSelect}
                active={this.state.activeTab === 'Shows'}
              >
                Shows
              </button>
              <button
                value="About"
                onClick={this.menuSelect}
                active={this.state.activeTab === 'About'}
              >
                About
              </button>
              <button
                value="Devices"
                onClick={this.menuSelect}
                active={this.state.activeTab === 'Devices'}
              >
                Devices
              </button>
              <button
                value="Emails"
                onClick={this.menuSelect}
                active={this.state.activeTab === 'Emails'}
              >
                Emails
              </button>
              <button
                value="Lock"
                onClick={this.menuSelect}
                active={this.state.activeTab === 'Lock'}
              >
                {this.state.user.is_locked ? 'Unlock Account' : 'Lock Account'}
              </button>
              <button
                value="Password"
                onClick={this.menuSelect}
                active={this.state.activeTab === 'Password'}
              >
                Reset Password
              </button>
            </Menu>
          )}
        </Header>

        <Wrapper>
          {activeTab === 'Shows' ? (
            <Shows user={this.state.user} bookings={this.state.bookings} />
          ) : activeTab === 'About' ? (
            <About user={this.state.user} />
          ) : activeTab === 'Devices' ? (
            <Devices
              user={this.state.user}
              devices={this.state.devices}
              onChange={this.getComponentUser}
            />
          ) : activeTab === 'Emails' ? (
            <Emails user={this.state.user} onChange={this.getComponentUser} />
          ) : activeTab === 'Lock' ? (
            <Lock
              history={this.props.history}
              user={this.state.user}
              onChange={this.getComponentUser}
            />
          ) : activeTab === 'Password' ? (
            <Password
              user={this.state.user}
              onPassChange={this.handleChildChange}
            />
          ) : null}
        </Wrapper>
      </div>
    );
  }
}
