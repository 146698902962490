import React from 'react';
import { Text, StyledTable, Button } from 'components';
import { formatDate } from 'helpers';
import { toast } from 'react-toastify';
import { removeAllDevicesFromUser, removeDeviceFromUser } from 'data-graphql';

export default class Devices extends React.Component {
  close = () => {
    this.props.onChange();
  };

  removeAllDevices = async () => {
    const VARS = { userId: this.props.user.id };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;
    const RESULT = await removeAllDevicesFromUser(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.close();
    }
  };

  removeDevice = async id => {
    if (!id) {
      return toast.error('Select a Device.', { autoclose: false });
    }
    const VARS = { userId: this.props.user.id, deviceId: id };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;
    const RESULT = await removeDeviceFromUser(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.close();
    }
  };

  render() {
    const { devices } = this.props;
    const showPagination = devices && devices.length > 3 ? true : false;
    return (
      <div>
        <Button
          onClick={() => {
            this.removeAllDevices();
          }}
          style={{ marginBottom: '16px' }}
        >
          Remove all Devices
        </Button>
        <StyledTable
          data={devices}
          noDataText="No Devices Found"
          resizeable={false}
          columns={[
            {
              Header: 'Name',
              minWidth: 120,
              Cell: row => {
                return (
                  <div>
                    <Text>{row.original.description}</Text>
                    <br />
                    <Text small soft>
                      Device ID: {row.original.id}
                    </Text>
                  </div>
                );
              }
            },
            {
              Header: 'Type',
              accessor: 'type'
            },
            {
              Header: 'Registered',
              id: 'date_registered',
              accessor: row => row.date_registered,
              Cell: c => (
                <span>
                  {c.original.date_registered &&
                    formatDate(c.original.date_registered, 'MMM D, YYYY')}
                </span>
              )
            },
            {
              Header: 'Actions',
              Cell: row => {
                return (
                  <div>
                    <Button
                      value={row.id}
                      onClick={() => {
                        this.removeDevice(row.original.id);
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                );
              }
            }
          ]}
          resizable={false}
          sortable={false}
          showPagination={showPagination}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
        />
      </div>
    );
  }
}
