import React from 'react';
import styled from 'styled-components';
import {
  Button,
  StyledTable,
  ExpanderSpan,
  Text,
  Form,
  Modal,
  SendEmailForm
} from 'components';
import { toast } from 'react-toastify';
import { formatDate, isMedia } from 'helpers';
import { resendEmail } from 'data-graphql';

const StyledTableWithDrop = styled(StyledTable)`
  .rt-td {
    overflow: visible;
  }
`;

const SendWrapper = styled.div`
  background: white;
`;

const SubStyledTable = styled(StyledTableWithDrop)`
  width: 97%;
  margin: -5px auto 5px auto;
  background: white;
  .rt-tr {
    margin: 0px 10px
    border-bottom: 1px solid #eee
    &:hover {
      box-shadow: none !important
  }
`;

export default class EmailsOrgTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      contacts_modal: false
    };
  }
  close = () => {
    this.props.onChange();
  };

  resendEmail = async e => {
    const id = e.target.value;
    const refresh = e.target.name === 'refresh' ? true : false;
    if (!id) {
      return toast.error('Select a contact.', { autoclose: false });
    }
    const VARS = { id: id, refresh_contacts: refresh, address: null };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;
    const RESULT = await resendEmail(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.close();
    }
  };

  handleSendChange = async (sendChoice, meta) => {
    if (sendChoice.value !== 'select_contact') {
      const VARS = {
        id: meta.name,
        refresh_contacts: sendChoice.value === 'resend_current' ? true : false
      };
      const REQ_OBJECT = `{
        code
        success
        message
      }`;
      const RESULT = await resendEmail(VARS, REQ_OBJECT);

      if (RESULT.data && RESULT.data.success) {
        toast.success(`${RESULT.data.message}`);
        this.close();
      }
    } else {
      await this.compileContacts();
      this.setState({ contacts_modal: true, email_to_send: meta.name });
    }
  };

  sendToEmail = async () => {
    const contacts = this.state.contact.map(contact => {
      return contact.email;
    });
    const VARS = {
      id: this.state.email_to_send,
      refresh_contacts: false,
      address: contacts
    };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;
    const RESULT = await resendEmail(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.setState({ contacts_modal: false, email_to_send: '', contact: '' });
      this.close();
    }
  };

  closeEmailOptions = () => {
    this.setState({ contacts_modal: false, email_to_send: '', contact: '' });
  };
  handleContactChange = contact => {
    this.setState({ contact });
  };

  compileContacts = () => {
    const contacts = this.props.organization.contacts
      ? this.props.organization.contacts
      : this.props.organization.active_contacts;
    this.props.booking &&
      this.props.booking.quote_email &&
      contacts.push({
        email: this.props.booking.quote_email,
        full_name: `${this.props.booking.quote_first_name} ${this.props.booking.quote_last_name}`
      });
    this.setState({ email_contact_options: contacts });
  };

  render() {
    const { organization } = this.props;
    const emails = this.props.emails;
    const showPagination = emails && emails.length > 15 ? true : false;
    const send_options = [
      { value: 'resend', label: 'Resend' },
      { value: 'resend_current', label: 'Resend (all current contacts)' },
      { value: 'select_contact', label: 'Select Contacts' }
    ];
    return (
      <div>
        <Modal
          open={this.state.contacts_modal}
          onClose={this.closeEmailOptions}
          closeOnOverlayClick
        >
          <div style={{ padding: '20px' }}>
            <div style={{ textAlign: 'center' }}>
              <Text huge red>
                Select Contacts to Include
              </Text>
            </div>
            <hr />
            <Form.InputWrapper>
              <Form.Label>Available Contacts</Form.Label>
              <Form.StyledSelect
                value={this.state.contact}
                onChange={this.handleContactChange}
                options={this.state.email_contact_options}
                isSearchable={true}
                isMulti
                getOptionLabel={option =>
                  `${option.full_name}, ${option.email}`
                }
                getOptionValue={option => option.email}
                placeholder="Start typing..."
                styles={Form.CustomStyles}
              />
            </Form.InputWrapper>
            <Form.InputWrapper flex>
              <Button
                style={{ marginLeft: '25px', marginTop: '15px' }}
                onClick={this.sendToEmail}
              >
                Send
              </Button>
            </Form.InputWrapper>
          </div>
        </Modal>
        <SendWrapper>
          <SendEmailForm organization={organization} />
        </SendWrapper>
        <br />
        <StyledTableWithDrop
          data={emails}
          noDataText="No Emails Found"
          columns={[
            {
              Header: 'Date',
              id: 'date_sent',
              accessor: row => row.date_sent,
              width: 125,
              maxWidth: 125,
              Cell: c => (
                <span>
                  {c.original.date_sent &&
                    formatDate(c.original.date_sent, 'MMM D, YYYY hh:mm:ssa')}
                </span>
              )
            },
            {
              Header: 'Address',
              accessor: 'address',
              width: 200,
              maxWidth: 300,
              Cell: row => {
                const addresses = row.value.split(',');
                return (
                  <>
                    {addresses.map(address => {
                      return (
                        <div key={address}>
                          <Text small>{address}</Text>
                        </div>
                      );
                    })}
                  </>
                );
              }
            },
            {
              Header: 'Subject',
              accessor: 'subject',
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Sent',
              accessor: 'times_sent',
              show: isMedia() !== 'phone',
              maxWidth: 75
            },
            {
              Header: 'Actions',
              width: 100,
              maxWidth: 120,
              show: false,
              Cell: row => {
                return (
                  <div>
                    <Button
                      style={{
                        padding: '5px 10px',
                        fontSize: '12px',
                        fontColor: 'white'
                      }}
                      value={row.original.id}
                      name="original"
                      onClick={this.resendEmail}
                    >
                      Resend
                    </Button>
                  </div>
                );
              }
            },
            {
              // width: 140,
              // maxWidth: 160,
              Cell: row => {
                return (
                  <div style={{ overflow: 'visible' }}>
                    <Form.InputWrapper full>
                      <Form.Label>Resend Options</Form.Label>
                      <Form.StyledSelect
                        value={this.state.send_action}
                        onChange={this.handleSendChange}
                        options={send_options}
                        isSearchable={false}
                        name={row.original.id}
                        placeholder="Choose Resend Option"
                        menuPosition="fixed"
                        styles={Form.CustomStyles}
                      />
                    </Form.InputWrapper>
                    {/* <Button
                      style={{
                        padding: '5px 8px',
                        fontSize: '12px',
                        fontColor: 'white'
                      }}
                      value={row.original.id}
                      name="refresh"
                      onClick={this.resendEmail}
                    >
                      Resend <br />
                      (Current Contact)
                    </Button> */}
                  </div>
                );
              }
            },
            {
              Header: 'Edit',
              expander: true,
              width: 65,
              maxWidth: 65,
              minWidth: 65,
              Expander: ({ isExpanded }) => (
                <ExpanderSpan
                  {...(isExpanded ? { open: true } : { closed: true })}
                />
              )
            }
          ]}
          resizable={false}
          sortable={false}
          showPagination={showPagination}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          //getTrProps={getTrPropsFn}
          SubComponent={row => {
            const booking = row.original.booking ? true : false;
            return (
              <SubStyledTable
                noShadow
                data={row.original.emails}
                noDataText="No Emails Found"
                columns={[
                  {
                    Header: 'Date Sent',
                    id: 'date_sent',
                    accessor: row => row.date_sent,
                    width: 120,
                    maxWidth: 120,
                    Cell: c => (
                      <span>
                        {c.original.date_sent &&
                          formatDate(
                            c.original.date_sent,
                            'MMM D, YYYY hh:mm:ssa'
                          )}
                      </span>
                    )
                  },
                  {
                    Header: 'Code',
                    accessor: 'booking.rehearsal_code',
                    width: 110,
                    maxWidth: 120,
                    show: booking,
                    Cell: row => {
                      return (
                        row.original.booking && (
                          <span>{row.original.booking.rehearsal_code}</span>
                        )
                      );
                    }
                  },
                  {
                    Header: 'Address',
                    accessor: 'address',
                    width: 200,
                    maxWidth: 300,
                    Cell: row => {
                      const addresses = row.value.split(',');
                      return (
                        <>
                          {addresses.map(address => {
                            return (
                              <div key={address}>
                                <Text small>{address}</Text>
                              </div>
                            );
                          })}
                        </>
                      );
                    }
                  },
                  {
                    Header: 'Subject',
                    accessor: 'subject',
                    show: isMedia() !== 'phone'
                  },
                  {
                    Header: 'Actions',
                    // width: 100,
                    // maxWidth: 120,
                    Cell: row => {
                      return (
                        <div>
                          {/* <Button
                            style={{
                              padding: '5px 10px',
                              fontSize: '12px',
                              fontColor: 'white'
                            }}
                            value={row.original.id}
                            name="original"
                            onClick={this.resendEmail}
                          >
                            Resend
                          </Button> */}
                          <Form.InputWrapper full>
                            <Form.Label>Resend Options</Form.Label>
                            <Form.StyledSelect
                              value={this.state.send_action}
                              onChange={this.handleSendChange}
                              options={send_options}
                              isSearchable={false}
                              name={row.original.id}
                              placeholder="Choose Resend Option"
                              styles={Form.CustomStyles}
                            />
                          </Form.InputWrapper>
                        </div>
                      );
                    }
                  },
                  {
                    width: 140,
                    maxWidth: 160,
                    show: false,
                    Cell: row => {
                      return (
                        <div>
                          <Button
                            style={{
                              padding: '5px 10px',
                              fontSize: '12px',
                              fontColor: 'white'
                            }}
                            value={row.original.id}
                            name="refresh"
                            onClick={this.resendEmail}
                          >
                            Resend <br />
                            (Current Contact)
                          </Button>
                        </div>
                      );
                    }
                  }
                ]}
                resizable={false}
                sortable={false}
                showPagination={showPagination}
                showPaginationTop={false}
                showPaginationBottom={true}
                showPageSizeOptions={false}
                minRows={1}
              />
            );
          }}
        />
      </div>
    );
  }
}
