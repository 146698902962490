import { Text } from 'components';
import COLORS from './colors';
import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 80px;
  flex-shrink: 0;
  background: ${COLORS.white};
`;
Header.Head = styled(Text)`
  padding: 0 0 30px 40px;
  color: ${COLORS.darkRed};
`;

export default Header;
