import React from 'react';
import { StyledTable, Icon } from 'components';

export default class Shows extends React.Component {
  render() {
    const { bookings } = this.props;
    return (
      <StyledTable
        data={bookings.filter(item => (item.userProdAccess && item.state === 'active'))}
        noDataText="No shows."
        resizeable={false}
        columns={[
          {
            Header: 'Name',
            accessor: 'show.name',
            minWidth: 100
          },
          {
            Header: 'Organization',
            accessor: 'organization.name',
            minWidth: 100
          },
          {
            Header: 'Device',
            accessor: 'deviceName',
            minWidth: 100
          },
          {
            Header: 'Downloaded',
            accessor: 'is_downloaded',
            Cell: row => (
              <Icon
                name="cloud-download"
                style={{
                  cursor: 'pointer',
                  fontSize: '23px',
                  color: row.value === true ? '#333' : '#9181815e',
                  fontWeight: 'bold' //row.value === true ? 'bold' : 'normal'
                }}
              />
            )
          },
          {
            Header: 'Production Access?',
            accessor: 'userProdAccess',
            minWidth: 100,
            Cell: props =>
              props.value === true && (
                <Icon
                  name="check-box"
                  style={{
                    cursor: 'pointer',
                    fontSize: '23px',
                    fontWeight: '800'
                  }}
                />
              )
          }
        ]}
        resizable={false}
        sortable={false}
        showPagination={false}
        showPaginationTop={false}
        showPaginationBottom={true}
        showPageSizeOptions={false}
        minRows={1}
        defaultPageSize={25}
      />
    );
  }
}
