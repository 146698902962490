import React from 'react';
import { Icon, Text, StyledTable } from 'components';
import { isMedia } from 'helpers';
import moment from 'moment';

export default class Organizations extends React.Component {
  render() {
    const { bookings } = this.props;

    return (
      <div>
        <Text large red>
          {' '}
          Current{' '}
        </Text>
        <StyledTable
          data={bookings
            .filter(booking => booking.state === 'active')
            .sort((a, b) =>
              a.expiration > b.expiration
                ? 1
                : b.expiration > a.expiration
                ? -1
                : 0
            )}
          resizable={false}
          noDataText="No current productions."
          sortable={false}
          showPagination={
            bookings.filter(booking => booking.state === 'active').length > 20
          }
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          getTrProps={(state, rowInfo) => {
            return {
              onClick: () => {
                this.props.onNav(rowInfo.original.organization.id);
              }
            };
          }}
          columns={[
            {
              Header: 'Name',
              accessor: 'organization.name',
              width: 250
            },
            {
              Header: 'Authorized',
              accessor: 'rehearsal_active_date',
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Closing/Expiration',
              accessor: 'expiration'
            },
            {
              Header: 'Production?',
              accessor: 'is_production_access',
              Cell: props =>
                props.value ? (
                  <Icon
                    name="check-box"
                    style={{
                      cursor: 'pointer',
                      fontSize: '23px',
                      fontWeight: '800'
                    }}
                  />
                ) : (
                  <span>Not Included</span>
                )
            }
          ]}
        />
        <Text large red>
          {' '}
          Booked{' '}
        </Text>
        <StyledTable
          data={bookings
            .filter(booking => booking.state === 'booked')
            .sort((a, b) =>
              a.expiration > b.expiration
                ? 1
                : b.expiration > a.expiration
                ? -1
                : 0
            )}
          resizeable={false}
          noDataText="No booked productions."
          resizable={false}
          sortable={false}
          showPagination={
            bookings.filter(booking => booking.state === 'booked').length > 20
          }
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          columns={[
            {
              Header: 'Name',
              accessor: 'organization.name',
              width: 250
            },
            {
              Header: 'Rehearsal',
              accessor: 'rehearsal_active_date',
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Production',
              accessor: 'production_active_date',
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Opening',
              accessor: 'opening'
            },
            {
              Header: 'Closing/Expiration',
              accessor: 'expiration'
            }
          ]}
        />
        <Text large red>
          {' '}
          Approved{' '}
        </Text>
        <StyledTable
          data={bookings
            .filter(booking => booking.state === 'approved')
            .filter(
              booking => booking.expiration > moment().format('YYYY-MM-DD')
            )
            .sort((a, b) =>
              a.expiration > b.expiration
                ? 1
                : b.expiration > a.expiration
                ? -1
                : 0
            )}
          resizeable={false}
          noDataText="No approved productions."
          resizable={false}
          sortable={false}
          showPagination={
            bookings
              .filter(booking => booking.state === 'approved')
              .filter(
                booking => booking.expiration > moment().format('YYYY-MM-DD')
              ).length > 20
          }
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          columns={[
            {
              Header: 'Name',
              accessor: 'organization.name',
              width: 250
            },
            {
              Header: 'Rehearsal',
              accessor: 'rehearsal_active_date',
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Production',
              accessor: 'production_active_date',
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Opening',
              accessor: 'opening'
            },
            {
              Header: 'Closing/Expiration',
              accessor: 'expiration'
            }
          ]}
        />
        <Text large red>
          {' '}
          Quoted{' '}
        </Text>
        <StyledTable
          data={bookings
            .filter(booking => booking.state === 'quoted')
            .filter(
              booking => booking.expiration > moment().format('YYYY-MM-DD')
            )
            .sort((a, b) =>
              a.expiration > b.expiration
                ? 1
                : b.expiration > a.expiration
                ? -1
                : 0
            )}
          resizeable={false}
          noDataText="No quoted productions."
          resizable={false}
          sortable={false}
          showPagination={
            bookings
              .filter(booking => booking.state === 'quoted')
              .filter(
                booking => booking.expiration > moment().format('YYYY-MM-DD')
              ).length > 20
          }
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          columns={[
            {
              Header: 'Name',
              accessor: 'organization.name',
              width: 250
            },
            {
              Header: 'Rehearsal',
              accessor: 'rehearsal_active_date',
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Production',
              accessor: 'production_active_date',
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Opening',
              accessor: 'opening'
            },
            {
              Header: 'Closing/Expiration',
              accessor: 'expiration'
            }
          ]}
        />
        <Text large red>
          {' '}
          Expired{' '}
        </Text>
        <StyledTable
          data={bookings
            .filter(
              booking => booking.expiration < moment().format('YYYY-MM-DD')
            )
            .sort((a, b) =>
              b.expiration > a.expiration
                ? 1
                : a.expiration > b.expiration
                ? -1
                : 0
            )}
          resizeable={false}
          noDataText="No expired productions."
          resizable={false}
          sortable={false}
          showPagination={
            bookings.filter(
              booking => booking.expiration < moment().format('YYYY-MM-DD')
            ).length > 20
          }
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          columns={[
            {
              Header: 'Name',
              accessor: 'organization.name',
              width: 250
            },
            {
              Header: 'Booking State',
              accessor: 'state'
            },
            {
              Header: 'Rehearsal',
              accessor: 'rehearsal_active_date',
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Production',
              accessor: 'production_active_date',
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Opening',
              accessor: 'opening'
            },
            {
              Header: 'Closing/Expiration',
              accessor: 'expiration'
            }
          ]}
        />
      </div>
    );
  }
}
