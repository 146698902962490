import React from 'react';
import { updateContact, deleteContact } from 'data-graphql';
import { Text, Button, Form, Spacer, Icon } from 'components';
import { yes_no_options } from 'helpers';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  margin: auto 5px;
  position: relative;
  bottom: 4px;
  left: 35%;
`;

export default class EditsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false
    };
  }

  componentDidMount() {
    this.setInitialValues();
    //this.setSelectValues();
  }

  close = () => {
    this.setState(this.initialState);
    this.props.onChange();
  };

  submit = async () => {
    const payload = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      position: this.state.position,
      email: this.state.email,
      phone_number: this.state.phone_number,
      phone_number_two: this.state.phone_number_two,
      active: this.state.active.value,
      is_account_admin: this.state.is_account_admin
        ? this.state.is_account_admin.value
        : false
    };
    const VARS = { id: this.state.id, input: payload };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    this.setState(prevState => ({
      saving: !prevState.saving
    }));

    const RESULT = await updateContact(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.close();
    } else {
      this.setState(prevState => ({
        saving: !prevState.saving
      }));
    }
  };

  delete = async () => {
    const VARS = { id: this.state.id };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    const RESULT = await deleteContact(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.close();
    }
  };

  handleActiveChange = active => {
    this.setState({ active });
  };

  handleAdminChange = admin => {
    this.setState({ is_account_admin: admin });
  };

  handleChange = e => {
    const value =
      e.target.type === 'radio' || e.target.type === 'checkbox'
        ? e.target.checked
        : e.target.value;
    this.setState({ [e.target.name]: value });
  };

  setInitialValues = () => {
    const { original } = this.props.row;
    // eslint-disable-next-line
    for (const key in original) {
      if (original[key] === false) {
        original[key] = false;
      } else if (!original[key]) {
        original[key] = '';
      }
    }
    this.setState(this.props.row.original, this.setSelectValues);
  };

  setSelectValues = () => {
    const active = yes_no_options.filter(
      item => item.value === this.state.active
    )[0];
    this.setState({
      active: active
    });
    const admin = yes_no_options.filter(
      item => item.value === this.state.is_account_admin
    )[0];
    this.setState({
      is_account_admin: admin
    });
  };

  render() {
    if (!this.state.id) return null;
    return (
      <div
        style={{
          margin: '-12px 10px 15px 10px',
          textAlign: 'left',
          backgroundColor: 'white',
          padding: '20px',
          boxShadow:
            'rgba(0, 0, 0, 0.21) 0px 5px 6px -3px, rgba(0, 0, 0, 0.21) 5px 1px 7px -5px, rgba(0, 0, 0, 0.21) -5px 0px 7px -5px'
        }}
      >
        <Text red large style={{ width: '50%', display: 'inline-block' }}>
          Edit {this.state.first_name} {this.state.last_name}
        </Text>
        <StyledButton onClick={this.submit}>
          {this.state.saving ? 'Saving...' : 'Save'}
        </StyledButton>
        <StyledButton negative onClick={this.delete}>
          <Icon name="bin" style={{ fontWeight: '600' }} />
        </StyledButton>
        <Spacer size="10px" />
        <hr />
        <Spacer size="10px" />

        <Form.InputWrapper>
          <Form.Input
            name="first_name"
            onChange={this.handleChange}
            value={this.state.first_name}
            placeholder="Contact First Name"
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Input
            name="last_name"
            onChange={this.handleChange}
            value={this.state.last_name}
            placeholder="Contact Last Name"
          />
        </Form.InputWrapper>
        <Spacer size="10px" />
        <Form.InputWrapper>
          <Form.Input
            name="position"
            onChange={this.handleChange}
            value={this.state.position}
            placeholder="Position"
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Input
            name="email"
            onChange={this.handleChange}
            value={this.state.email}
            placeholder="Email (to send account info)"
          />
        </Form.InputWrapper>
        <Spacer size="10px" />
        <Form.InputWrapper>
          <Form.Input
            name="phone_number"
            onChange={this.handleChange}
            value={this.state.phone_number}
            placeholder="Phone"
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Input
            name="phone_number_two"
            onChange={this.handleChange}
            value={this.state.phone_number_two}
            placeholder="Secondary Phone"
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Label small>Is Active?</Form.Label>
          <Spacer size="5px" />
          <Form.StyledSelect
            styles={Form.CustomStyles}
            value={this.state.active}
            onChange={this.handleActiveChange}
            options={yes_no_options}
            isSearchable={false}
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Label small>Is Account Admin?</Form.Label>
          <Spacer size="5px" />
          <Form.StyledSelect
            styles={Form.CustomStyles}
            value={this.state.is_account_admin}
            onChange={this.handleAdminChange}
            options={yes_no_options}
            isSearchable={false}
          />
        </Form.InputWrapper>
        <Spacer size="20px" />
        <br />
      </div>
    );
  }
}
