import React from 'react';
import { Text, Button, Form, Spacer, FlexBox, Icon } from 'components';
import { toast } from 'react-toastify';
import { updateShow } from 'data-graphql';
import { yes_no_options, isMTI } from 'helpers';

export default class CreateOrgForm extends React.Component {
  constructor() {
    super();
    this.initialState = {
      name: '',
      notes: '',
      foreign_show_id: '',
      saving: false,
      is_quote_eligible: undefined
    };
    this.state = this.initialState;
  }

  componentDidMount() {
    this.setState(
      {
        name: this.props.show.name || '',
        notes: this.props.show.notes || '',
        foreign_show_id:
          this.props.show.foreign_show_id !== null
            ? this.props.show.foreign_show_id
            : ''
      },
      this.setSelectValues
    );
  }

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value });
  };

  close = () => {
    this.setState(this.initialState);
    this.props.onChange();
  };

  submit = async () => {
    const payload = {};
    if (this.state.name) payload.name = this.state.name;
    if (this.state.notes) payload.notes = this.state.notes;
    if (this.state.is_quote_eligible)
      payload.is_quote_eligible = this.state.is_quote_eligible.value;
    if (this.state.foreign_show_id && this.state.foreign_show_id.length > 0)
      payload.foreign_show_id = this.state.foreign_show_id;

    const VARS = {
      id: this.props.show.id,
      input: payload
    };

    const REQ_OBJECT = `{
      code
      success
      message
      show {
        foreign_show_id
      }
    }`;

    this.setState(prevState => ({
      saving: !prevState.saving
    }));

    const RESULT = await updateShow(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.close();
    } else {
      this.setState(prevState => ({
        saving: !prevState.saving
      }));
    }
  };

  handleEligibleChange = eligibleSelect =>
    this.setState({ is_quote_eligible: eligibleSelect });

  setSelectValues = () => {
    if (!isMTI) {
      const quoteEligible = yes_no_options.filter(
        item => item.value === this.props.show.is_quote_eligible
      )[0];

      this.setState({
        is_quote_eligible: quoteEligible
      });
    }
  };

  render() {
    const { show } = this.props;
    return (
      <Form>
        <FlexBox justify="flex-end">
          <Icon onClick={this.close} name="close" />
        </FlexBox>
        <Text red large>
          Edit {show.name}
        </Text>
        <Spacer size="10px" />
        <Form.InputWrapper>
          <Form.Label>Show Name</Form.Label>
          <Form.Input
            name="name"
            onChange={this.handleChange}
            value={this.state.name}
          />
        </Form.InputWrapper>
        <Spacer size="10px" />
        <Form.InputWrapper>
          {!isMTI ? (
            <>
              <Form.Label>Display in Quote Feed?</Form.Label>
              <Form.StyledSelect
                styles={Form.CustomStyles}
                value={this.state.is_quote_eligible}
                onChange={this.handleEligibleChange}
                options={yes_no_options}
                isSearchable={false}
              />
            </>
          ) : (
            <>
              <Form.Label>MTI Show ID</Form.Label>
              <Form.Input
                name="foreign_show_id"
                onChange={this.handleChange}
                value={this.state.foreign_show_id}
              />
            </>
          )}
        </Form.InputWrapper>
        <Spacer size="10px" />
        <Form.InputWrapper>
          <Form.Label>Notes</Form.Label>
          <Form.TextArea
            name="notes"
            onChange={this.handleChange}
            value={this.state.notes}
          />
        </Form.InputWrapper>

        <Spacer size="10px" />
        <Button onClick={this.submit}>
          {this.state.saving ? 'Saving...' : 'Save'}
        </Button>
      </Form>
    );
  }
}
