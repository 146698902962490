import React from 'react';
import { Text, TextArea } from 'components';
import COLORS from './colors';
import styled from 'styled-components';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import { default as media } from './screenSize';

const Form = styled.div`
  width: 100%;
  margin: 0px auto;
  text-align: center;
`;
Form.InputWrapper = styled.div`
  width: ${props => {
    if (props.third) {
      return '32%';
    } else if (props.full) {
      return '100%';
    } else if (props.flex) {
      return 'inherit';
    } else {
      return '49%';
    }
  }};
  margin: 10px auto;
  display: inline-block;
  ${media.tablet`
    width: 100%;
  `};
`;

Form.Input = styled.input`
  padding: 10px 15px;
  border-radius: 0;
  border: 1px solid ${COLORS.lightGrey};
  font-size: 16px;
  &:focus {
    outline: none;
  }
  width: 95%;
  border-radius: 0px;
  margin: auto 10px;
  background-color: ${props =>
    props.green ? 'green' : props.red ? 'red' : '#eee'};
`;

Form.StyleDate = styled.div`
  .DayPickerInput {
    width: 100%;
  }

  .DayPickerInput input {
    padding: 10px 15px;
    font-size: 16px;
    width: 95%;
    background-color: #eee;
    border: 2px solid #eee;
    margin: auto 10px;
  }
  .DayPicker-Wrapper {
    overflow: visible;
  }
`;

Form.StyledSelect = styled(Select)`
  background-color: '#eee';
`;

Form.StyledAsyncSelect = styled(AsyncSelect)`
  background-color: '#eee';
`;

Form.TextArea = styled(TextArea)`
  background-color: ${COLORS.lightGrey};
  width: 95%;
  margin: auto 10px;
`;

Form.Label = styled(Text)`
  margin: ${props => (props.full ? 'auto 25px' : 'auto 10px')};
  color: #3c3c3c;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex: auto 1;
  flex-direction: row;
`;

Form.CustomStyles = {
  option: (provided, state) => {
    const { data, isFocused, isSelected, selectProps } = state;
    return {
      ...provided,
      border: 'none',
      'border-radius': '0px',
      'background-color':
        data.value === 'paid_in_full' && isFocused
          ? '#00800082'
          : data.value === 'partial_payment' && isFocused
          ? '#ff000082'
          : data.value === 'purchase_order' && isFocused
          ? '#0000ff72'
          : data.value === null && selectProps.rateStatus && isFocused
          ? '#49494982'
          : '#eee',
      color: isSelected ? '#6e1406' : '#918181'
      //padding: 20,
    };
  },
  control: (provided, state) => ({
    ...provided,
    border: state.selectProps.error ? '1px solid red' : 'none',
    'border-radius': '0px',
    'background-color':
      state.selectProps.rateStatus === 'paid_in_full'
        ? '#00800082'
        : state.selectProps.rateStatus === 'partial_payment'
        ? '#ff000082'
        : state.selectProps.rateStatus === 'purchase_order'
        ? '#0000ff82'
        : state.selectProps.rateStatus === null
        ? '#49494982'
        : '#eee',
    width: '95%',
    padding: '2px 10px !important',
    'font-size': '1em',
    margin: 'auto'
  }),
  menu: provided => ({
    ...provided,
    border: 'none',
    'border-radius': '0px',
    'background-color': '#eee',
    padding: '3px 10px !important',
    'font-size': '1em',
    width: '95%',
    'margin-left': '50%',
    transform: 'translate(-50%)'
  })
};

//custom checkbox styling

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 25px;
  height: 25px;
  background: ${props => (props.checked ? COLORS.darkRed : '#eee')}
  border-radius: 0px;
  border: 1px solid ${COLORS.darkRed}
  // border: 1px solid ${COLORS.grey}
  transition: all 150ms;
  // ${HiddenCheckbox}:focus + & {
  //   box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.21);
  // }
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
`;

Form.CheckBox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Form;
