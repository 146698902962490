import React from 'react';
import { Button, StyledTable } from 'components';
import { toast } from 'react-toastify';
import { formatDate, isMedia } from 'helpers';
import { resendEmail } from 'data-graphql';

export default class Emails extends React.Component {
  close = () => {
    this.props.onChange();
  };

  resendEmail = async id => {
    if (!id) {
      return toast.error('Select a contact.', { autoclose: false });
    }
    const VARS = { id: id };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;
    const RESULT = await resendEmail(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.close();
    }
  };

  render() {
    const { user } = this.props;
    const emails = user.emails;
    const showPagination = emails && emails.length > 10 ? true : false;

    return (
      <div>
        <StyledTable
          data={emails}
          noDataText="No Emails Found"
          resizeable={false}
          columns={[
            {
              Header: 'Date',
              id: 'date_sent',
              accessor: row => row.date_sent,
              Cell: c => (
                <span>
                  {c.original.date_sent &&
                    formatDate(c.original.date_sent, 'MMM D, YYYY hh:mm:ssa')}
                </span>
              )
            },
            {
              Header: 'Address',
              accessor: 'address'
            },
            {
              Header: 'Subject',
              accessor: 'subject',
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Sent',
              accessor: 'times_sent',
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Actions',
              Cell: row => {
                return (
                  <div>
                    <Button
                      value={row.id}
                      onClick={() => {
                        this.resendEmail(row.original.id);
                      }}
                    >
                      Resend
                    </Button>
                  </div>
                );
              }
            }
          ]}
          resizable={false}
          sortable={false}
          showPagination={showPagination}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          defaultPageSize={10}
          minRows={1}
        />
      </div>
    );
  }
}
