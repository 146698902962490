import React, { useState } from 'react';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';
import { Text, Spacer, Icon } from 'components';

const QuestionWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 28px;
  background-color: white;
  margin-bottom: 25px;
  cursor: pointer;
  &:hover {
    box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.21);
  }
  @media (max-width: 425px) {
    padding: 24px 18px;
  }
  @media (max-width: 320px) {
    padding: 16px 12px;
  }
`;

const Question = styled(Text)`
  font-family: 'Proxima Nova Bold', sans-serif;
  @media (max-width: 768px) {
    font-size: 18px;
  }
  @media (max-width: 425px) {
    font-size: 16px;
  }
`;

const Answer = styled(Text)`
  line-height: 25px;
`;

const Dropdown = styled.div`
  transition: 0.2s;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  display: ${({ state }) => (state === 'exited' ? 'none' : 'block')};
`;

const Rotate = styled.div`
  transform: ${({ state }) =>
    state === 'entered' ? 'rotate(-180deg)' : 'rotate(0)'};
  transition: 0.2s;
  margin-left: 20px;
`;

const UL = styled.ul`
  margin-left: 20px;
`;

const LI = styled.li`
  line-height: 32px;
  @media (max-width: 479px) {
    font-size: 0.75em;
  }
`;

const ClickableFlex = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 33px 0;
`;

const Accordion = ({ question, answer, listItems, search }) => {
  const [dropdown, setDropdown] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);

  const toggleDropdown = () => setDropdown(!dropdown);

  let searched = question.includes(search);

  if (!searched) {
    return null;
  }

  return (
    <QuestionWrapper>
      <ClickableFlex onClick={toggleDropdown}>
        <Question large>{question}</Question>
        <Transition in={dropdown} timeout={200}>
          {state => (
            <Rotate state={state}>
              <Icon name="arrow-down-3" darkBlue />
            </Rotate>
          )}
        </Transition>
      </ClickableFlex>
      <Transition
        in={dropdown}
        timeout={200}
        onEntered={() => setShowAnswer(true)}
        onExit={() => setShowAnswer(false)}
      >
        {state => (
          <Dropdown state={state}>
            {showAnswer && (
              <>
                <Answer>{answer}</Answer>
                <Spacer size="24px" />
                {listItems && (
                  <>
                    <UL>
                      {listItems.map((item, i) => (
                        <LI key={i}>{item}</LI>
                      ))}
                    </UL>
                    <Spacer size="24px" />
                  </>
                )}
              </>
            )}
          </Dropdown>
        )}
      </Transition>
    </QuestionWrapper>
  );
};

export default Accordion;
