import React from 'react';
import { Text, StyledTable } from 'components';
export default class CurrentShowsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: false,
      columnExpander: '',
      selected: ''
    };
  }

  componentDidMount() {}

  render() {
    const { bookings } = this.props;
    const paginateExpiredBookings =
      bookings.filter(booking => {
        return booking.state === 'expired';
      }).length > 3
        ? true
        : false;

    return (
      <div>
        <br />
        <Text large bold>
          Expired Shows
        </Text>
        <br />
        <br />
        <StyledTable
          data={bookings
            .filter(booking => {
              return booking.state === 'expired';
            })
            .sort((a, b) =>
              b.expiration > a.expiration
                ? 1
                : a.expiration > b.expiration
                ? -1
                : 0
            )}
          noShadow
          noDataText="No current shows."
          columns={[
            {
              Header: 'Title',
              accessor: 'show.name',
              minWidth: 125
            },
            {
              Header: 'Expired',
              accessor: 'expiration',
              minWidth: 90
            }
          ]}
          resizable={false}
          sortable={false}
          showPagination={paginateExpiredBookings}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          defaultPageSize={10}
        />
      </div>
    );
  }
}
