import gql from 'graphql-tag';
import graphql from './graphql';

//// USERS /////
export async function getUsers(VARS, REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
        query GetUsers(
          $page: Int!
          $archived: Boolean
          $admins: Boolean
          $search: String
        ) {
          getUsers(
            input: {
              page: $page
              search: $search
              archived: $archived
              admins: $admins
            }
          ) 
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function getUser(VARS, REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
        query GetUser($id: UUID!) {
          getUser(id: $id)
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function updateUser(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation UpdateUser($id: UUID!, $input: UpdateUserInput!) {
          updateUser(id: $id, input: $input) 
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function createAdmin(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation CreateAdmin($input: CreateAdminInput!) {
          createAdmin(input: $input)
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function isUsernameAvailable(VARS) {
  try {
    return await graphql().query({
      query: gql`
        query IsUsernameAvailable($username: String!) {
          isUsernameAvailable(username: $username)
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function getUserDevices(VARS, REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
        query GetUserDevicesByUsername($username: String!) {
          getUserDevicesByUsername(username: $username)
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function removeDeviceFromUser(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation RemoveDeviceFromUser($userId: UUID!, $deviceId: UUID!) {
          removeDeviceFromUser(userId: $userId, deviceId: $deviceId)
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function removeAllDevicesFromUser(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation RemoveAllDevicesFromUser($userId: UUID!) {
          removeAllDevicesFromUser(userId: $userId)
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

///// ME (current user) /////
export async function getMe(REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
        query GetMe {
          getMe
            ${REQ_OBJECT}
        }
      `,
      //variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function updateMe(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation UpdateMe($input: UpdateMeInput!) {
          updateMe(input: $input)
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function resetUserPassword(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation ResetUserPassword($id: UUID!, $input: ResetUserPasswordInput) {
          resetUserPassword(id: $id, input: $input)
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}
