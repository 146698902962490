import axios from 'axios';
import { toast } from 'react-toastify';

export default (toastifyErrors = true) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'x-auth-token': window.localStorage.getItem('token'),
      'x-platform-id': process.env.REACT_APP_API_TOKEN,
      'x-client-id': process.env.REACT_APP_CLIENT_TOKEN
    }
  });
  instance.interceptors.response.use(
    response => {
      return response.data;
    },
    error => {
      if (toastifyErrors) {
        if (error.response && error.response.data) {
          if (error.response.data.validation) {
            toast.error(error.response.data.validation[0].message, {
              autoclose: false
            });
          } else if (error.response.data.message) {
            const message = error.response.data.message;
            const autoClose = message.length > 200 ? 15000 : 5000;
            toast.error(message, { autoClose });
          } else {
            toast.error('Server Error!', { autoclose: false });
          }
        } else {
          toast.error('Server Error!', { autoclose: false });
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
