import styled from 'styled-components';
import COLORS from './colors';

export default styled.label`
background: ${props => {
  if (props.disabled) {
    return COLORS.grey;
  } else if (props.black) {
    return COLORS.black;
  } else {
    return props.negative ? COLORS.red : COLORS.yellow;
  }
}};
border: none;
padding: ${props => (props.large ? '15px 30px' : '5px 15px')};
border-radius: ${props => {
  if (props.straight) return '0';
  if (props.large) return '3px';
  return '3px';
}};
color: ${props => {
  if (props.disabled) {
    return COLORS.lightGrey;
  } else if (props.black) {
    return COLORS.white;
  } else {
    return props.negative ? COLORS.white : COLORS.white;
  }
}};
font-size: 16px;
font-weight: ${props => (props.large ? 'bold' : 'normal')};
${props => {
  if (!props.disabled) return 'cursor: pointer;';
}}
width: ${props => (props.fluid ? '100%' : 'auto')};
&:hover { background: ${props => {
  if (!props.disabled && !props.black) {
    return props.negative ? COLORS.lighterRed : COLORS.darkerYellow;
  }
}};
}
&:focus {
  outline: none;
}
`;
