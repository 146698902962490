import React from 'react';
import { Text, Button, Form, Spacer } from 'components';
import { mergeOrganizations, getOrganizations } from 'data-graphql';

import { toast } from 'react-toastify';
// import styled from 'styled-components';

export default class BookForm extends React.Component {
  constructor() {
    super();
    this.initialState = {
      inputValue: '',
      organization: undefined,
      merging: false
    };
    this.state = this.initialState;
  }

  componentDidMount() {}

  close = () => {
    this.setState(this.initialState);
    this.props.onChange();
  };

  submit = async () => {
    const VARS = {
      fromOrgId: this.state.organization.value,
      toOrgId: this.props.organization.id
    };

    const REQ_OBJECT = `{
      message
      success
      code
      organization {
        id
        name
      }
    }`;

    this.setState(prevState => ({
      merging: !prevState.merging
    }));

    const RESULT = await mergeOrganizations(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(RESULT.data.message);
      this.close();
    } else {
      this.setState(prevState => ({
        merging: !prevState.merging
      }));
    }
  };

  handleAssignOrgInput = newValue => {
    const inputValue = newValue;
    this.setState({ inputValue });

    return inputValue;
  };

  handleOrgChange = organization => {
    this.setState({ organization: organization });
  };

  searchOrganizations = async inputValue => {
    const VARS = {
      search: inputValue,
      page: 1
    };
    const REQ_OBJECT = `{
      organizations{
        name
        id
      }
    }`;
    const RESULT = await getOrganizations(VARS, REQ_OBJECT);
    const options = RESULT.data.getOrganizations.organizations.map(item => {
      return { value: item.id, label: item.name };
    });
    return options;
  };

  render() {
    const { organization } = this.props;
    return (
      <Form>
        <Text large red>
          Merge
        </Text>

        <Spacer size="15px" />
        <Text style={{ width: '66%', display: 'inline-block' }}>
          Select an organization to merge its Contacts, Quotes, Rehearsal Codes,
          and all Shows (Past, Present, and Future) into {organization.name}.
        </Text>
        <Spacer size="15px" />
        <Text red style={{ width: '66%', display: 'inline-block' }}>
          All other information (emails sent, address, phone number, etc) will
          be lost, and the selected organization will be removed.
        </Text>
        <Spacer size="20px" />

        <Form.InputWrapper>
          <Form.Label red>Assign to Organization</Form.Label>
          <Form.StyledAsyncSelect
            cacheOptions
            loadOptions={this.searchOrganizations}
            onInputChange={this.handleAssignOrgInput}
            defaultOptions={true}
            value={this.state.organization}
            onChange={this.handleOrgChange}
            placeholder="Start typing an organization's name..."
            styles={Form.CustomStyles}
          />
        </Form.InputWrapper>

        <Spacer size="10px" />
        <Button large onClick={this.submit} style={{ margin: 'auto' }}>
          {this.state.merging ? 'Merging...' : 'Merge'}
        </Button>
      </Form>
    );
  }
}
