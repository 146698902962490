import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Button, Segment, COLORS, Spacer } from 'components';
// import { isMTI } from 'helpers';
import styled from 'styled-components';
import LogoSrc from 'assets//mtiLogoBottomAligned.png';
import { Link } from 'react-router-dom';

const GlobalStyle = createGlobalStyle`
  body {
    overflow: visible
  }
`;

const Body = styled.div`
  min-height: 100vh;
  overflow: scroll;
  background: linear-gradient(${COLORS.darkBlue}, ${COLORS.lightBlue});
`;
// linear-gradient(#000000, ${COLORS.red});
const Form = styled.form`
  width: 400px;
  max-width: 100%;
  margin: auto;
  padding-top: 100px;
`;
const Logo = styled.img`
  width: 100%;
  padding: 30px 40px;
`;

// const StyledA = styled.a`
//   display: block;
//   width: 100%;
//   height: 50px;
//   padding: 15px
//   color: white;
//   text-decoration: none;
//   background-color: ${COLORS.yellow};
//   &:hover {
//     background-color: ${COLORS.hoverYellow};
//   }
// `;

const LogoWrapper = styled.div`
  height: 173px;
  width: 100%;
`;

export default class Login extends React.Component {
  // constructor() {
  //   super();
  // }

  render() {
    return (
      <Body>
        <Form>
          <LogoWrapper>
            <Logo src={LogoSrc} />
          </LogoWrapper>
          <Segment>
            <div
              style={{ textAlign: 'center', width: '400px', maxWidth: '100%' }}
            >
              <Link to="/rehearsal">
                <Button large fluid>
                  Rehearsal Track Access
                </Button>
              </Link>
              <Spacer size="10px" />

              <Link to="/production">
                <Button large fluid>
                  Organization Login/Production Tracks
                </Button>
              </Link>
              <Spacer size="10px" />

              <Link to="/faq">
                <Button large fluid>
                  FAQs
                </Button>
              </Link>
              <Spacer size="10px" />

              <Link to="/macapp">
                <Button large fluid>
                  Qlab/Mac App
                </Button>
              </Link>
            </div>
          </Segment>
        </Form>
        <Spacer size="50px" />
        <GlobalStyle />
      </Body>
    );
  }
}
