import React from 'react';
import {
  Wrapper,
  Menu,
  Card,
  Icon,
  SearchIcon,
  CloseIcon,
  Header,
  Text,
  Input,
  Pagination,
  Button,
  FormWrapper,
  Content,
  media,
  FlexBox,
  COLORS
} from 'components';
import styled from 'styled-components';
import { getUsers } from 'data-graphql';
import { formatDate, isMedia } from 'helpers';
import CreateAdminForm from './components/CreateAdminForm.js';
import Spinner from 'react-spinkit';

const InputWrapper = styled.div`
  width: 49%
  margin: 20px auto
  display: inline-block
  width: 300px;
  padding-bottom: 20px;
  ${media.phone`
    width: 100%;
  `};
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${media.tablet`
    justify-content: center;
  `}
`;

export default class Admins extends React.Component {
  constructor() {
    super();
    this.state = {
      users: [],
      currentPage: 1,
      totalPages: 1,
      showingArchived: false,
      search: '',
      view: false,
      menuOpen: false,
      loading: false
    };
  }

  componentDidMount() {
    this.getComponentUsers();
  }

  getComponentUsers = async () => {
    this.setState({ loading: true });
    const VARS = {
      page: this.state.currentPage,
      search: this.state.search || undefined,
      admins: true,
      archived: this.state.showingArchived
    };
    const REQ_OBJECT = `{
      currentPage
      totalPages
      users {
        id
        username
        email
        phone_number
        first_name
        last_name
        account_type
        has_liked_facebook
        user_since
      }
    }`;

    const RESULT = await getUsers(VARS, REQ_OBJECT);

    this.setState({
      users: RESULT.data.getUsers.users,
      currentPage: RESULT.data.getUsers.currentPage,
      totalPages: RESULT.data.getUsers.totalPages,
      loading: false
    });
  };

  toggleArchived = () => {
    this.setState(
      {
        showingArchived: !this.state.showingArchived,
        menuOpen: this.state.menuOpen === 'create' ? false : 'create',
        currentPage: 1
      },
      this.getComponentUsers
    );
  };

  changePage = val => {
    this.setState({ currentPage: val }, this.getComponentUsers);
  };

  search = e => {
    e.preventDefault();

    this.setState(
      {
        currentPage: 1
      },
      this.getComponentUsers
    );
  };

  clearSearch = e => {
    e.preventDefault();
    this.setState({ search: '', currentPage: 1 }, this.getComponentUsers);
  };

  handleChildChange = () => {
    //close form and call fn up to ordDetails to recall state
    this.setState({ view: false });
    this.getComponentUsers();
  };

  openMenu = () => {
    this.setState({
      menuOpen: this.state.menuOpen === 'create' ? false : 'create'
    });
  };

  openForm = () => {
    this.setState({ view: this.state.view === 'create' ? false : 'create' });
  };

  handleSearchChange = e => {
    this.setState(
      {
        search: e.target.value
      },
      this.search(e)
    );
  };

  render() {
    const { view, menuOpen } = this.state;

    return (
      <div>
        <Header>
          <Header.Head bold huge>
            Admin Accounts
            <Button onClick={this.openForm} header>
              Create Admin {view === 'create' ? '-' : '+'}
            </Button>
          </Header.Head>
          {isMedia() === 'phone' ? (
            <>
              <Button darkRed menu onClick={this.openMenu}>
                {this.state.showingArchived ? 'Archived' : 'Active'}{' '}
                {menuOpen === 'create' ? '-' : '+'}
              </Button>
              <Content
                pose={menuOpen === 'create' ? 'open' : 'closed'}
                style={{
                  overflow:
                    this.state.menuOpen === 'create' ? 'visible' : 'hidden'
                }}
              >
                <Menu>
                  <button
                    onClick={this.toggleArchived}
                    active={!this.state.showingArchived}
                  >
                    Active
                  </button>
                  <button
                    onClick={this.toggleArchived}
                    active={this.state.showingArchived}
                  >
                    Archived
                  </button>
                </Menu>
              </Content>
            </>
          ) : (
            <Menu>
              <button
                onClick={this.toggleArchived}
                active={!this.state.showingArchived}
              >
                Active
              </button>
              <button
                onClick={this.toggleArchived}
                active={this.state.showingArchived}
              >
                Archived
              </button>
            </Menu>
          )}
        </Header>
        <Wrapper>
          <InputWrapper onSubmit={this.search}>
            <Input
              placeholder="Search"
              onChange={this.handleSearchChange}
              value={this.state.search}
              icon={
                this.state.search.length < 1 ? (
                  <SearchIcon onClick={this.search} />
                ) : (
                  <CloseIcon onClick={this.clearSearch} />
                )
              }
            />
          </InputWrapper>
          {this.state.loading ? (
            <FlexBox justify="space-evenly" style={{ alignContent: 'center' }}>
              <Spinner
                name="three-bounce"
                color={COLORS.darkRed}
                fadeIn="none"
              />
            </FlexBox>
          ) : (
            <>
              <Content
                pose={view === 'create' ? 'open' : 'closed'}
                style={{ overflow: view === 'create' ? 'visible' : 'hidden' }}
              >
                <FormWrapper>
                  <CreateAdminForm onCreate={this.handleChildChange} />
                </FormWrapper>
              </Content>

              <CardWrapper>
                {this.state.users.map(user => (
                  <Card
                    clickable
                    key={user.id}
                    highlighted={user.account_type === 'super_admin'}
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.history.push(`/admin/admins/${user.id}`)
                    }
                  >
                    <Icon
                      name="account-circle"
                      style={{
                        fontSize: 80,
                        paddingBottom: 5
                      }}
                    />
                    <br />
                    <Text large bold>
                      {user.first_name} {user.last_name}
                    </Text>
                    <br />
                    <Text soft>{user.username}</Text>
                    <br />
                    <br />
                    <Text style={{ wordBreak: 'break-word' }}>
                      {user.email}
                    </Text>
                    <br />
                    <Text small soft>
                      Admin since{' '}
                      {formatDate(user.user_since, 'MMM D, YYYY', false)}
                    </Text>
                  </Card>
                ))}
              </CardWrapper>

              {this.state.totalPages > 1 && (
                <Pagination
                  onPageChange={this.changePage}
                  currentPage={this.state.currentPage}
                  totalPages={this.state.totalPages}
                />
              )}
            </>
          )}
        </Wrapper>
      </div>
    );
  }
}
