import gql from 'graphql-tag';
import graphql from './graphql';

export async function getSignedUrlForTrackUpload(VARS, REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
        query GetSignedUrlForTrackUpload($input: SignedTrackInput!) {
            getSignedUrlForTrackUpload(input: $input) 
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function addTrackToManualSetlist(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation AddTrackToManualSetlist($booking_id: UUID!, $track_id: UUID!) {
          addTrackToManualSetlist(booking_id: $booking_id, track_id: $track_id) 
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function removeTrackFromManualSetlist(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation RemoveTrackFromManualSetlist($booking_id: UUID!, $track_id: UUID!) {
          removeTrackFromManualSetlist(booking_id: $booking_id, track_id: $track_id) 
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function copyAllTracksToBooking(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation CopyAllTracksToBookingSetlist($booking_id: UUID!, $setlist_id: UUID!) {
          copyAllTracksToBookingSetlist(booking_id: $booking_id, setlist_id: $setlist_id) 
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function deleteTrack(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation DeleteTrack($id: UUID!) {
          deleteTrack(id: $id) 
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function updateTrack(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation UpdateTrack($id: UUID!, $input: UpdateTrackInput!) {
          updateTrack(id: $id, input: $input) 
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function replaceTrack(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation ReplaceThisTrack($id: UUID!, $setlist_id: UUID!, $input: [CreateTrackInput!]!) {
          replaceTrack(id: $id, setlist_id: $setlist_id, input: $input) 
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function makeTrackInactive(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation MakeTrackInactive($track_id: UUID!, $setlist_id: UUID!) {
          makeTrackInactive(track_id: $track_id, setlist_id: $setlist_id) 
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function changeSetlistOfTrack(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation changeSetlistOfTrack($track_id: UUID!, $current_setlist_id: UUID!, $target_setlist_id: UUID!) {
          changeSetlistOfTrack(track_id: $track_id, current_setlist_id: $current_setlist_id, target_setlist_id: $target_setlist_id) 
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function getTrackUrl(VARS, REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
        query getSignedUrlforTrackPlayback ($track_id: UUID!) {
          getSignedUrlforTrackPlayback(track_id: $track_id) 
        ${REQ_OBJECT}
      }
    `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function duplicateTrack(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation duplicateTrack($track_id: UUID!, $setlist_id: UUID!, $booking_id: UUID!) {
          duplicateTrack(track_id: $track_id, setlist_id: $setlist_id, booking_id: $booking_id) 
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}
