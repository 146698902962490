import React, { useState } from 'react';
import styled from 'styled-components';
import COLORS from './colors';

const Wrapper = styled.div`
  width: ${props => (props.half ? '50%' : '100%')}
  display: ${props => (props.half ? 'inline-block' : 'block')}
  margin: ${props => (props.half ? '10px 0px' : '0px 0px')}
  position: relative;
`;

const Input = styled.input`
  padding: 10px 15px;
  border-radius: 0;
  border: 1px solid ${COLORS.lightGrey};
  width: ${props => (props.half ? '90%' : '100%')};
  margin-left: ${props => (props.half ? '5%' : '0%')};
  background-color: ${props => (props.form ? COLORS.lightGrey : 'white')}
  font-size: 16px;
  &:focus {
    outline: none;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 15px;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

const DualIconWrapper = styled.div`
  position: absolute;
  right: 38px;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

export default props => {
  const { type, ...otherProps } = props
  const [passwordHidden, setPasswordHidden] = useState(true)
  const actualType = type === 'password' && !passwordHidden ? 'text' : type

  return (
    <Wrapper {...props}>
      <Input {...otherProps} type={actualType} />
      {props.icon && <IconWrapper>{props.icon}</IconWrapper>}
      {props.type === 'password' && (
        props.icon ? (
          <DualIconWrapper>
            {passwordHidden ? (
              <i className="fa fa-eye-slash" onClick={() => setPasswordHidden(false)} />
            ) : (
                <i className="fa fa-eye" onClick={() => setPasswordHidden(true)} />
              )}
          </DualIconWrapper>
        ) : (
            <IconWrapper>
              {passwordHidden ? (
                <i className="fa fa-eye-slash" onClick={() => setPasswordHidden(false)} />
              ) : (
                  <i className="fa fa-eye" onClick={() => setPasswordHidden(true)} />
                )}
            </IconWrapper>
          )
      )}
    </Wrapper>
  );
};
