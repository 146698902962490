import React from 'react';
import { toast } from 'react-toastify';
import {
  Wrapper,
  Header,
  Button,
  Menu,
  FormWrapper,
  ExpanderSpan,
  StyledTable,
  Spacer,
  Content,
  Input,
  SearchIcon,
  CloseIcon,
  Pagination,
  media,
  COLORS,
  FlexBox
} from 'components';
import { getBookings, updateBooking } from 'data-graphql';
import { capitalize, isMedia, formatDate } from 'helpers';
import MoreInfo from './components/MoreInfo.js';
import CreateQuote from './components/CreateQuoteForm.js';
import styled from 'styled-components';
import Spinner from 'react-spinkit';

const InputWrapper = styled.form`
  width: 300px;
  ${media.phone`
    width: 100%;
  `};
`;

export default class Organizations extends React.Component {
  constructor() {
    super();
    this.state = {
      bookings: [],
      currentPage: 1,
      totalPages: 1,
      search: '',
      expanded: false,
      menu: 'All Quotes',
      menuOpen: false,
      loading: false
    };
  }

  componentDidMount() {
    this.getComponentBookings();
  }

  handleSearchChange = e => {
    this.setState({ search: e.target.value }, this.search(e));
  };

  search = e => {
    e.preventDefault();

    this.setState({ currentPage: 1 }, this.getComponentBookings);
  };

  clearSearch = e => {
    e.preventDefault();
    this.setState({ search: '', currentPage: 1 }, this.getComponentBookings);
  };

  approveQuote = async e => {
    const VARS = {
      id: e.target.value,
      input: {
        state: 'approved'
      }
    };

    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    const RESULT = await updateBooking(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.message) {
      toast.success(`${RESULT.data.message}`);
      this.getComponentBookings();
    }
  };

  bookQuote = async e => {
    const VARS = {
      id: e.target.value,
      input: {
        state: 'booked'
      }
    };

    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    const RESULT = await updateBooking(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.message) {
      toast.success(`${RESULT.data.message}`);
      this.getComponentBookings();
    }
  };

  getComponentBookings = async () => {
    this.setState({ loading: true });
    const VARS = {
      page: this.state.currentPage,
      search: this.state.search || undefined,
      states:
        this.state.menu === 'All Quotes'
          ? ['quoted', 'approved', 'booked', 'active', 'expired', 'cancelled']
          : this.state.menu === 'booked'
          ? ['booked', 'active']
          : this.state.menu
    };

    const REQ_OBJECT = `{
      currentPage
      totalPages
      bookings {
        id
        rehearsal_code
        state
        rehearsal_active_date
        production_active_date
        opening
        expiration
        is_production_access
        rate
        rate_status
        edits_status
        authorized_device_max
        notes
        notes_color
        notes_client
        quote_organization_name
        quote_city
        quote_state
        quote_country
        quote_first_name
        quote_last_name
        quote_phone_number
        quote_email
        quote_avg_price
        quote_avg_attend
        quote_performances
        quote_color
        educational
        is_contract_ready
        is_license_ready
        is_payment_ready
        created_at
        client_name
        booked_at
        emails_sent {
          id
          template
          subject
          date_sent
        }
        show {
          id
          name
        }
        organization {
          id
          name
          account_number
          contacts {
            email
            full_name
          }
        }
      }
    }`;

    const RESULT = await getBookings(VARS, REQ_OBJECT);
    const bookings = RESULT.data.getBookings.bookings.map(booking => {
      booking.created_date = formatDate(booking.created_at, 'MM-DD-YYYY');
      booking.created_time = formatDate(booking.created_at, 'hh:mm A');
      return booking;
    });

    this.setState({
      bookings: bookings,
      currentPage: RESULT.data.getBookings.currentPage,
      totalPages: RESULT.data.getBookings.totalPages,
      createQuote: false,
      loading: false
    });
  };

  openMenu = () => {
    this.setState({
      menuOpen: this.state.menuOpen === 'create' ? false : 'create'
    });
  };

  createQuote = () => {
    this.setState({
      createQuote: this.state.createQuote === true ? false : true
    });
  };

  menuSelect = e => {
    this.setState(
      {
        menu: e.target.value,
        menuOpen: this.state.menuOpen === 'create' ? false : 'create',
        currentPage: 1
      },
      this.getComponentBookings
    );
  };

  render() {
    const { menuOpen } = this.state;
    return (
      <div>
        <Header>
          <Header.Head bold huge style={{ textAlign: 'left' }}>
            QUOTES
            <Button onClick={this.createQuote} header>
              Create Quote {this.state.createQuote === true ? '-' : '+'}
            </Button>
          </Header.Head>
          {isMedia() === 'phone' ? (
            <>
              <Button darkRed menu onClick={this.openMenu}>
                {this.state.menu} {menuOpen === 'create' ? '-' : '+'}
              </Button>
              <Content
                pose={this.state.menuOpen === 'create' ? 'open' : 'closed'}
                style={{
                  overflow:
                    this.state.menuOpen === 'create' ? 'visible' : 'hidden'
                }}
              >
                <Menu>
                  <button
                    value="All Quotes"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'All Quotes'}
                  >
                    All Quotes
                  </button>
                  <button
                    value="quoted"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'quoted'}
                  >
                    Not Approved
                  </button>
                  <button
                    value="approved"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'approved'}
                  >
                    Approved
                  </button>
                  <button
                    value="booked"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'booked'}
                  >
                    Booked
                  </button>
                  <button
                    value="expired"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'expired'}
                  >
                    Expired
                  </button>
                  <button
                    value="cancelled"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'cancelled'}
                  >
                    Cancelled
                  </button>
                </Menu>
              </Content>
            </>
          ) : (
            <Menu>
              <button
                value="All Quotes"
                onClick={this.menuSelect}
                active={this.state.menu === 'All Quotes'}
              >
                All Quotes
              </button>
              <button
                value="quoted"
                onClick={this.menuSelect}
                active={this.state.menu === 'quoted'}
              >
                Not Approved
              </button>
              <button
                value="approved"
                onClick={this.menuSelect}
                active={this.state.menu === 'approved'}
              >
                Approved
              </button>
              <button
                value="booked"
                onClick={this.menuSelect}
                active={this.state.menu === 'booked'}
              >
                Booked
              </button>
              <button
                value="expired"
                onClick={this.menuSelect}
                active={this.state.menu === 'expired'}
              >
                Expired
              </button>
              <button
                value="cancelled"
                onClick={this.menuSelect}
                active={this.state.menu === 'cancelled'}
              >
                Cancelled
              </button>
            </Menu>
          )}
        </Header>

        <Wrapper>
          <InputWrapper onSubmit={this.search}>
            <Input
              placeholder="Search"
              onChange={this.handleSearchChange}
              value={this.state.search}
              icon={
                this.state.search.length < 1 ? (
                  <SearchIcon onClick={this.search} />
                ) : (
                  <CloseIcon onClick={this.clearSearch} />
                )
              }
            />
          </InputWrapper>
          {this.state.loading ? (
            <FlexBox justify="space-evenly" style={{ alignContent: 'center' }}>
              <Spinner
                name="three-bounce"
                color={COLORS.darkRed}
                fadeIn="none"
              />
            </FlexBox>
          ) : (
            <>
              <Content
                pose={this.state.createQuote === true ? 'open' : 'closed'}
                style={{
                  overflow:
                    this.state.createQuote === true ? 'visible' : 'hidden'
                }}
              >
                <FormWrapper>
                  <CreateQuote onChange={this.getComponentBookings} />
                </FormWrapper>
              </Content>
              <br />

              <StyledTable
                data={this.state.bookings
                  .filter(booking => {
                    if (this.state.menu === 'All Quotes') {
                      return booking;
                    } else if (this.state.menu === 'booked') {
                      return booking.state === 'booked' || 'active';
                    } else {
                      return booking.state === this.state.menu;
                    }
                  })
                  .sort((a, b) => {
                    if (this.state.menu === 'expired') {
                      return b.expiration > a.expiration
                        ? 1
                        : a.expiration > b.expiration
                        ? -1
                        : 0;
                    }
                  })}
                noDataText="There are no quotes for this month."
                showPagination={
                  this.state.bookings.filter(booking => {
                    if (this.state.menu === 'All Quotes') {
                      return booking;
                    } else {
                      return booking.state === this.state.menu;
                    }
                  }).length > 10
                }
                resizable={false}
                sortable={false}
                showPaginationTop={false}
                showPaginationBottom={false}
                showPageSizeOptions={false}
                minRows={1}
                pageSize={
                  this.state.bookings.filter(booking => {
                    if (this.state.menu === 'All Quotes') {
                      return booking;
                    } else if (this.state.menu === 'booked') {
                      return booking.state === 'booked' || 'active';
                    } else {
                      return booking.state === this.state.menu;
                    }
                  }).length
                }
                //defaultPageSize={10}
                filterable={false}
                columns={[
                  {
                    Header: '',
                    getProps: (state, rowInfo) => {
                      return {
                        style: {
                          padding: '0px',
                          background:
                            rowInfo && rowInfo.original.quote_color
                              ? rowInfo.original.quote_color === 'gold'
                                ? COLORS.yellow
                                : rowInfo.original.quote_color === 'purple'
                                ? 'MediumPurple'
                                : `${rowInfo.original.quote_color}`
                              : 'sienna'
                        }
                      };
                    },
                    width: 8,
                    maxWidth: 8,
                    minWidth: 5
                  },
                  {
                    Header: 'Show',
                    accessor: 'show.name',
                    Cell: row => {
                      return (
                        <div style={{ textAlign: 'left' }}>
                          <p>${row.original.rate}</p>
                          <p>{row.value}</p>
                          <p>{row.original.created_date}</p>
                          <p>{row.original.created_time}</p>
                        </div>
                      );
                    }
                  },
                  {
                    Header: 'Organization',
                    accessor: 'organization.name',
                    show: isMedia() !== 'phone',
                    Cell: row => {
                      return (
                        <div style={{ textAlign: 'left' }}>
                          <p>
                            {row.value
                              ? row.value
                              : row.original.quote_organization_name
                              ? capitalize(row.original.quote_organization_name)
                              : ''}
                          </p>
                          <p>
                            Account #:{' '}
                            {row.original.organization
                              ? row.original.organization.account_number
                              : ''}
                          </p>
                          <Spacer size="15px" />
                          <p style={{ fontStyle: 'italic' }}>
                            {row.original.educational ? 'Educational' : ''}
                          </p>
                        </div>
                      );
                    }
                  },
                  {
                    expander: true,
                    minWidth: 100,
                    width: 150,
                    maxWidth: 200,
                    show: isMedia() !== 'phone',
                    Expander: row => {
                      return (
                        <div>
                          {row.original.state === 'approved' && (
                            <Button value={row.original.id}>Book Show</Button>
                          )}
                          {row.original.state === 'booked' && (
                            <Button disabled>Booked</Button>
                          )}
                          {this.state.menu === 'expired' &&
                            row.original.booked_at && (
                              <Button disabled>Booked</Button>
                            )}
                        </div>
                      );
                    }
                  },
                  {
                    Header: 'Actions',
                    show: isMedia() !== 'phone',
                    Cell: row => {
                      return (
                        <div>
                          {row.original.state === 'quoted' && (
                            <Button
                              value={row.original.id}
                              onClick={this.approveQuote}
                            >
                              Approve
                            </Button>
                          )}
                          {row.original.state === 'approved' && (
                            <Button disabled>Approved</Button>
                          )}
                          {row.original.state === 'booked' && (
                            <Button disabled>Approved</Button>
                          )}

                          {row.original.state === 'expired' && (
                            <Button disabled>Expired</Button>
                          )}
                          {row.original.state === 'cancelled' && (
                            <Button disabled>Cancelled</Button>
                          )}
                          {row.original.state === 'active' && (
                            <Button disabled>Active</Button>
                          )}
                        </div>
                      );
                    },
                    maxWidth: 200
                  },
                  {
                    Header: 'More',
                    expander: true,
                    minWidth: 80,
                    width: 80,
                    Expander: ({ isExpanded }) => (
                      <ExpanderSpan
                        {...(isExpanded ? { open: true } : { closed: true })}
                      />
                    )
                  }
                ]}
                getTrProps={(state, rowInfo) => {
                  if (!rowInfo) return {};
                  return {
                    style: {
                      boxShadow:
                        typeof state.expanded[rowInfo.index] === 'object'
                          ? '0 2px 6px rgba(0, 0, 0, 0.21)'
                          : 'none'
                    }
                  };
                }}
                SubComponent={row => {
                  return (
                    <MoreInfo row={row} onClose={this.getComponentBookings} />
                  );
                }}
              />

              {this.state.totalPages > 1 && (
                <Pagination
                  onPageChange={currentPage => {
                    this.setState({ currentPage }, this.getComponentBookings);
                  }}
                  currentPage={this.state.currentPage}
                  totalPages={this.state.totalPages}
                />
              )}
            </>
          )}
        </Wrapper>
      </div>
    );
  }
}
