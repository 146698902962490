import gql from 'graphql-tag';
import graphql from './graphql';

export async function getEmailPreviews(REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
        query getAllEmailTemplatePreviews {
          getAllEmailTemplatePreviews
          ${REQ_OBJECT}
        }
      `,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function resendEmail(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation ResendEmail($id: UUID!, $refresh_contacts: Boolean, $address: [String]) {
          resendEmail(id: $id, refresh_contacts: $refresh_contacts, address: $address)
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function sendEmail(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation SendEmail($organization_id: UUID, $booking_id: UUID, $template: String, $contacts: [String]) {
          sendEmail(organization_id: $organization_id, booking_id: $booking_id, template: $template, contacts: $contacts)
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function sendBroadcast(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation SendShowBroadcastEmail($showId: UUID!, $message: String) {
          sendShowBroadcastEmail(showId: $showId, message: $message)
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}
