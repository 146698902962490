import React from 'react';
import {
  Text,
  Icon,
  Button,
  StyledTable,
  FormWrapper,
  Content,
  ExpanderSpan
} from 'components';
import styled from 'styled-components';
import { getTrPropsFn, isMedia, isMTI } from 'helpers';
import CreateContactForm from './CreateContactForm';
import AccountContactForm from './AccountContactForm';
import ContactEditsForm from './ContactEditsForm';

const InactiveTable = styled(StyledTable)`
  .rt-td {
    color: #f1efef;
  }
`;
const ExecTable = styled(StyledTable)`
  .rt-td,
  .rt-thead .rt-tr {
    text-align: left;
  }
`;

export default class ContactsOrgTable extends React.Component {
  constructor() {
    super();
    this.state = {
      createContact: false,
      createUser: false
    };
  }

  handleCreateContact = () => {
    this.setState({ createContact: false, createUser: false });
    this.props.onChange();
  };

  openContact = () => {
    this.setState({
      createContact: this.state.createContact === 'open' ? false : 'open'
    });
  };

  openAccount = () => {
    this.setState({
      createUser: this.state.createUser === 'open' ? false : 'open'
    });
  };

  render() {
    const { organization } = this.props;
    const { active_contacts, inactive_contacts } = organization;

    return (
      <div>
        <div>
          <Button onClick={this.openContact} style={{ verticalAlign: '40%' }}>
            Contact {this.state.createContact === 'open' ? '-' : '+'}
          </Button>
          <Button
            onClick={this.openAccount}
            style={{ verticalAlign: '40%', marginLeft: 10 }}
          >
            Account {this.state.createUser === 'open' ? '-' : '+'}
          </Button>
        </div>
        <br />
        <Content
          pose={this.state.createContact === 'open' ? 'open' : 'closed'}
          style={{
            overflow: this.state.createContact === 'open' ? 'visible' : 'hidden'
          }}
        >
          <FormWrapper>
            <CreateContactForm
              organization_id={organization.id}
              onChange={this.handleCreateContact}
            />
          </FormWrapper>
        </Content>
        <Content
          pose={this.state.createUser === 'open' ? 'open' : 'closed'}
          style={{
            overflow: this.state.createUser === 'open' ? 'visible' : 'hidden'
          }}
        >
          <FormWrapper>
            <AccountContactForm
              organization_id={organization.id}
              activeContacts={active_contacts}
              onChange={this.handleCreateContact}
            />
          </FormWrapper>
        </Content>
        <StyledTable
          data={active_contacts}
          noDataText="No active contacts."
          columns={[
            {
              Header: 'Name',
              accessor: 'full_name'
            },
            {
              Header: 'Position',
              accessor: 'position',
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Email',
              accessor: 'email',
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Phone',
              accessor: 'phone_number',
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Phone #2',
              accessor: 'phone_number_two',
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Acct. Admin',
              accessor: 'is_account_admin',
              Cell: props =>
                props.value === true && (
                  <Icon
                    name="check-box"
                    style={{
                      cursor: 'pointer',
                      fontSize: '23px',
                      fontWeight: '800'
                    }}
                  />
                )
            },
            {
              Header: 'Edit',
              accessor: 'id',
              expander: true,
              width: 65,
              maxWidth: 65,
              minWidth: 65,
              Expander: ({ isExpanded }) => (
                <ExpanderSpan
                  {...(isExpanded ? { open: true } : { closed: true })}
                />
              )
            }
          ]}
          resizable={false}
          sortable={false}
          showPagination={active_contacts.length > 3 ? true : false}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          defaultPageSize={3}
          getTrProps={getTrPropsFn}
          SubComponent={row => (
            <ContactEditsForm row={row} onChange={this.handleCreateContact} />
          )}
        />
        <br />
        <Text large bold>
          Inactive
        </Text>
        <br />
        <InactiveTable
          data={inactive_contacts}
          noDataText="No inactive contacts."
          columns={[
            {
              Header: 'Name',
              accessor: 'full_name'
            },
            {
              Header: 'Position',
              accessor: 'position'
            },
            {
              Header: 'Email',
              accessor: 'email'
            },
            {
              Header: 'Phone',
              accessor: 'phone_number'
            },
            {
              Header: 'Phone #2',
              accessor: 'phone_number_two'
            },
            {
              Header: 'Acct. Admin',
              accessor: 'is_account_admin',
              Cell: props =>
                props.value === true && (
                  <Icon
                    name="check-box"
                    style={{
                      cursor: 'pointer',
                      fontSize: '23px',
                      fontWeight: '800'
                    }}
                  />
                )
            },
            {
              Header: 'Edit',
              accessor: 'id',
              expander: true,
              width: 65,
              maxWidth: 65,
              minWidth: 65,
              Expander: ({ isExpanded }) => (
                <ExpanderSpan
                  {...(isExpanded ? { open: true } : { closed: true })}
                />
              )
            }
          ]}
          resizable={false}
          sortable={false}
          showPagination={inactive_contacts.length > 3 ? true : false}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          defaultPageSize={3}
          getTrProps={getTrPropsFn}
          SubComponent={row => (
            <ContactEditsForm row={row} onChange={this.handleCreateContact} />
          )}
        />
        {isMTI && (
          <>
            <br />
            <Text large bold>
              Account Executive
            </Text>
            <br />
            <ExecTable
              noShadow
              data={[organization.executive]}
              noDataText="No Account Executive Listed."
              columns={[
                {
                  Header: 'Name',
                  accessor: 'name',
                  maxWidth: 350
                },
                {
                  Header: 'Email',
                  accessor: 'email'
                }
                // {
                //   Header: 'Edit',
                //   accessor: 'id',
                //   expander: true,
                //   width: 65,
                //   maxWidth: 65,
                //   minWidth: 65,
                //   Expander: ({ isExpanded }) => (
                //     <ExpanderSpan
                //       {...(isExpanded ? { open: true } : { closed: true })}
                //     />
                //   )
                // }
              ]}
              resizable={false}
              sortable={false}
              showPagination={false}
              showPaginationTop={false}
              showPaginationBottom={false}
              showPageSizeOptions={false}
              minRows={1}
              defaultPageSize={3}
              // getTrProps={getTrPropsFn}
              // SubComponent={row => (
              //   <ExecEditsForm row={row} onChange={this.handleCreateContact} />
              // )}
            />
          </>
        )}
      </div>
    );
  }
}
