// import React from 'react'
import styled from 'styled-components';
import COLORS from './colors';
import { default as media } from './screenSize';

export default styled.div`
  width: 225px;
  position: relative
  background: ${props => (props.is_locked ? '#facaca' : 'white')};
  border-radius: 0px;
  padding: ${props => (props.device ? '20px 40px' : '25px 10px')};
  text-align: center;
  box-shadow: ${props => props.device && '0 1px 4px 1px rgba(0, 0, 0, 0.18)'};
  margin-bottom: 10px;
  margin-right: 10px;
  color: ${props => (props.highlighted ? COLORS.yellow : COLORS.grey)}
  transition: box-shadow .3s ease;

  cursor: ${props => (props.clickable ? 'pointer' : null)};
  &:hover {
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.21);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.21);
  }

  ${media.tablet`
    width: 215px;
  `}

  ${media.phone`
    width: 100%;
    margin-right: 0px;
  `};
`;

//hove state highlight yellow was replaced with box shadow
//background: ${props => (props.clickable ? COLORS.yellow : null)};
// ${props =>
//   props.highlighted ? COLORS.lighterYellow : COLORS.white};
