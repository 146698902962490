import React from 'react';
import {
  Icon,
  Text,
  Button,
  StyledTable,
  ExpanderSpan,
  Spacer,
  StyledBullet
} from 'components';
import EditsForm from './EditsForm.js';
import { getTrPropsFn, capitalize, isMedia, isMTI } from 'helpers';
import Popover from 'react-awesome-popover';
import 'react-awesome-popover/build/index.css';
import moment from 'moment';

export default class CurrentShowsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: false,
      columnExpander: '',
      selected: ''
    };
  }

  componentDidMount() {}

  navTracks = row => {
    this.props.showTracks(row.original.id);
  };

  render() {
    const { organization } = this.props;
    const today = new Date();
    const paginateActiveBookings =
      organization.bookings.filter(booking => {
        return booking.state === 'active';
      }).length > 3
        ? true
        : false;

    const handleSubComponentChange = () => {
      this.props.onChange();
    };

    return (
      <div>
        <br />
        <Text large bold>
          Current Shows
        </Text>
        <br />
        <br />
        <StyledTable
          data={organization.bookings.filter(booking => {
            return booking.state === 'active';
          })}
          noDataText="No current shows."
          columns={[
            {
              Header: 'Title',
              accessor: 'show.name',
              minWidth: 125,
              Cell: row => {
                return (
                  <div
                    onClick={() => {
                      this.navTracks(row);
                    }}
                  >
                    <Text style={{ display: 'inline-block' }}>{row.value}</Text>
                    <Spacer size="2px" />
                    {row.original.notes && (
                      <>
                        <ul>
                          <Popover
                            arrow={false}
                            position="right"
                            action="hover"
                          >
                            <StyledBullet color={row.original.notes_color}>
                              <Text small>
                                {'  '}
                                {row.original.notes}
                              </Text>
                            </StyledBullet>

                            <Text small>{row.original.notes}</Text>
                          </Popover>
                        </ul>
                      </>
                    )}
                  </div>
                );
              }
            },
            {
              Header: 'Code',
              accessor: 'rehearsal_code',
              Cell: row => (
                <>
                  <div>{row.value}</div>
                  <div>{row.original.rehearsal_edit_code}</div>
                </>
              ),
              minWidth: 120,
              maxWidth: 120,
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Opening',
              accessor: 'opening',
              minWidth: 90,
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Closing',
              accessor: 'expiration',
              minWidth: 90,
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Prod Access',
              accessor: 'is_production_access',
              minWidth: 75,
              show: isMedia() !== 'phone',
              Cell: row => (
                <>
                  {((row.value === true &&
                    moment(row.original.production_active_date).isSameOrBefore(
                      today
                    )) ||
                    row.value === false) && (
                    <Icon
                      name={row.value === true ? 'check-box' : 'delete'}
                      style={
                        row.value === true
                          ? {
                              cursor: 'pointer',
                              fontSize: '23px',
                              fontWeight: '800'
                            }
                          : {
                              cursor: 'pointer',
                              fontSize: '20px',
                              fontWeight: '400'
                            }
                      }
                    />
                  )}
                  {row.value === true &&
                    moment(row.original.production_active_date).isAfter(
                      today
                    ) && <Text>{row.original.production_active_date}</Text>}
                </>
              )
            },

            {
              Header: 'Edits',
              accessor: 'edits_status',
              minWidth: 122,
              show: isMTI ? false : true,
              Cell: row => (
                <Button noHover>
                  {row.value ? capitalize(row.value).replace('_', ' ') : 'None'}
                </Button>
              )
            },
            {
              Header: 'Rate',
              accessor: 'rate',
              minWidth: 91,
              width: 100,
              show: isMTI ? false : true,
              Cell: row => (
                <Button status={row.original.rate_status || 'default'} noHover>
                  {row.value || `Rate`}
                </Button>
              )
            },
            {
              Header: 'Downloaded',
              accessor: 'is_downloaded',
              show: isMedia() !== 'phone',
              Cell: row => (
                <Icon
                  name="cloud-download"
                  style={{
                    cursor: 'pointer',
                    fontSize: '23px',
                    color: row.value === true ? '#333' : '#9181815e',
                    fontWeight: 'bold' //row.value === true ? 'bold' : 'normal'
                  }}
                />
              )
            },
            {
              Header: 'Edit',
              expander: true,
              width: 65,
              maxWidth: 65,
              minWidth: 65,
              Expander: ({ isExpanded }) => (
                <ExpanderSpan
                  {...(isExpanded ? { open: true } : { closed: true })}
                />
              )
            }
          ]}
          resizable={false}
          sortable={false}
          showPagination={paginateActiveBookings}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          defaultPageSize={10}
          getTrProps={getTrPropsFn}
          //this is one subcomponent form
          SubComponent={row => {
            return (
              <EditsForm
                row={row}
                organization={this.props.organization}
                onChange={handleSubComponentChange}
              />
            );
          }}
          /*
          //this is how we set up different subcomponents based on cell clicked
          */
          // Handle the cell click event to get the column info
          // getTdProps={(state, rowInfo, column, instance) => {
          //   return {
          //     onClick: (e, handleOriginal) => {
          //       if (column.expander) {
          //         this.setState({ columnExpander: column.Header }, () => {
          //           setTimeout(() => {}, 20);
          //           if (handleOriginal) {
          //             handleOriginal();
          //           }
          //         });
          //       }
          //     }
          //   };
          // }}
          //render based on column info
          // SubComponent={row => {
          //   return (
          //     <div>
          //       {this.state.columnExpander == 'Rate' && <RateForm row={row} />}
          //       {this.state.columnExpander == 'Actions' &&  <ConfirmForm row={row} />}
          //     </div>
          //   );
          // }}
        />
      </div>
    );
  }
}
