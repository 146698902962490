import moment from 'moment';
import { isMTI } from './companyContext';

const formatDate = (datetime, format) => {
  return moment(datetime).format(format);
};

const capitalize = string => {
  return string[0].toUpperCase() + string.slice(1);
};

const formatPhone = number => {
  const cleaned = ('' + number).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

const estimateDates = (returnObj, selectedDay) => {
  const today = moment().format();
  returnObj.rehearsal_active_date = moment(today).format('YYYY-MM-DD');
  if (!isMTI) {
    returnObj.production_active_date = moment(selectedDay).format('YYYY-MM-DD');
  } else {
    returnObj.production_active_date = moment(selectedDay)
      .subtract(2, 'months')
      .isBefore(today)
      ? moment(today)
          .add(1, 'day')
          .format('YYYY-MM-DD')
      : moment(selectedDay)
          .subtract(2, 'weeks')
          .format('YYYY-MM-DD');
  }
  return returnObj;
};

const estimateRate = (
  isProduction,
  averagePrice,
  averageAttendance,
  performances,
  isEducational
) => {
  const clamp = (amount, min, max) => Math.min(Math.max(amount, min), max)

  const rehearsalPrice = 300
  const minimumFee = 600
  const educationalCap = 1350
  const nonEducationalCap = 3100

  if (!isProduction) return rehearsalPrice

  let estimate = averagePrice * averageAttendance * performances * 0.1
  estimate -= estimate % 50
  estimate = Math.floor(estimate)

  const cap = isEducational ? educationalCap : nonEducationalCap
  return clamp(estimate, minimumFee, cap)
}

export { formatDate, capitalize, formatPhone, estimateDates, estimateRate };
