// import React from 'react'
import styled from 'styled-components';
import COLORS from './colors';
import { default as media } from './screenSize';

export default styled.span`
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-size: ${props => {
    if (props.huge) {
      return '32px';
    } else if (props.large) {
      return '20px';
    } else if (props.small) {
      return '12px';
    } else {
      return '16px';
    }
  }};
  color: ${props => {
      if (props.white) {
        return COLORS.white;
      } else if (props.soft) {
        return COLORS.grey;
      } else if (props.red) {
        return COLORS.darkRed;
      } else if (props.lightBlue) {
        return COLORS.lightBlue;
      } else if (props.darkBlue) {
        return COLORS.darkBlue;
      } else {
        return COLORS.black;
      }
    }}
    ${media.phone`
    font-size: ${props => {
      if (props.huge) {
        return '1.5em';
      } else if (props.large) {
        return '1.3em';
      } else if (props.small) {
        return '.90em';
      } else {
        return '.75em';
      }
    }};
  `};
`;
