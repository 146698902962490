import React from 'react';
import styled from 'styled-components';
import { Icon } from 'components';
import COLORS from './colors';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(41, 30, 30, 0.9);
  z-index: 100;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  display: ${props => (props.open ? 'flex' : 'none')};
`;

const Modal = styled.div`
  background: ${COLORS.white};
  border-radius: 5px;
  color: ${COLORS.dark};
  opacity: 1;
  width: ${props => (props.width ? props.width : '70%')};
  padding: 30px;
  margin: 70px 0;
  position: relative;
`;

const CloseWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  color: ${COLORS.black};
  font-size: 16px;
  font-weight: 800;
`;

export default props => {
  document.body.style.overflowY = props.open ? 'hidden' : 'auto';

  function onOverlayClick() {
    if (props.closeOnOverlayClick) {
      props.onClose();
    }
  }

  function onModalClick(e) {
    e.stopPropagation();
  }

  return (
    <Overlay open={props.open} onClick={onOverlayClick}>
      <Modal {...props} onClick={onModalClick}>
        <CloseWrapper onClick={props.onClose}>
          <Icon name="close" />
        </CloseWrapper>
        {props.children}
      </Modal>
    </Overlay>
  );
};
