// import React from 'react'
import styled from 'styled-components';
import COLORS from './colors';
import { default as media } from './screenSize';
//BANNER height: 90vh
export default styled.div`
  width: 100%;
  background: ${COLORS.lightGrey};
  padding: 40px;
  transition: all 1s ease-in;
  ${media.tablet`
    padding: ${props => (props.small ? '10px' : '25px')};
    height: 70vh;
    overflow: scroll;
  `};
`;
