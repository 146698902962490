import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import COLORS from '../../components/colors';
import {
  Text,
  FlexBox,
  Input,
  SearchIcon,
  CloseIcon,
  Spacer
} from 'components';
import { isMedia } from 'helpers';
import Accordion from './Accordion.js';
import MTILogo from '../../assets/mtiLogoBottomAligned.png';

const Body = styled.div`
  background: linear-gradient(${COLORS.darkRed}, ${COLORS.lightBlue});
  overflow: scroll;
`;

const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 24px 130px;
  @media (max-width: 992px) {
    padding: 24px 80px;
  }
  @media (max-width: 768px) {
    padding: 24px 40px;
  }
  @media (max-width: 425px) {
    padding: 24px 20px;
  }
  height: 100vh;
`;

const InputWrapper = styled(FlexBox)`
  width: 50%;
  margin: 16px 0 100px 0;
  @media (max-width: 992px) {
    width: 75%;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 16px 0 75px 0;
  }
  @media (max-width: 425px) {
    margin: 16px 0 50px 0;
  }
`;

const StyledLink = styled.a`
  color: ${COLORS.lightBlue};
  text-decoration: none;
`;

const Tab = styled(Text)`
  cursor: pointer;
  &.hover {
    border-bottom: 2px solid #e9d96d;
  }
  @media (max-width: 992px) and (min-width: 826px) {
    font-size: 16px;
  }
  @media (max-width: 550px) {
    font-size: 16px;
  }
  @media (max-width: 360px) {
    margin-bottom: 16px;
  }
`;

const ActiveTab = styled(Text)`
  color: ${COLORS.yellow};
  border-bottom: 2px solid ${COLORS.yellow};
  cursor: pointer;
  @media (max-width: 992px) and (min-width: 826px) {
    font-size: 16px;
  }
  @media (max-width: 550px) {
    font-size: 16px;
  }
  @media (max-width: 360px) {
    margin-bottom: 16px;
  }
`;

const Logo = styled.img`
  max-width: 300px;
  width: 100%;
  height: auto;
  @media (max-width: 768px) {
    max-width: 250px;
  }
`;

const SearchText = styled(Text)`
  margin-left: 16px;
`;

const NavFlex = styled(FlexBox)`
  width: 50%;
  margin-top: 50px;
  @media (max-width: 1200px) {
    width: 55%;
  }
  @media (max-width: 826px) {
    width: 100%;
    margin-top: 25px;
  }
  @media (max-width: 360px) {
    flex-direction: column;
  }
`;

const HeaderFlex = styled(FlexBox)`
  @media (max-width: 826px) {
    justify-content: center;
  }
`;

const LogoWrapper = styled.div`
  height: 106px;
  width: 100%;
`;

export default function FAQ() {
  const [search, setSearch] = useState('');
  const [faqs, setFaqs] = useState(true);
  const [windowSize, setWindowSize] = useState(isMedia());

  const clearSearch = () => setSearch('');
  const handleChange = e => setSearch(e.target.value);
  const showFaqs = () => setFaqs(true);
  const showVideos = () => setFaqs(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(isMedia());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Body>
      <Container>
        <FlexBox direction="column" align="center">
          <HeaderFlex wrap align="center" justify="space-between">
            <Link to="/">
              <LogoWrapper>
                <Logo src={MTILogo} alt="" />
              </LogoWrapper>
            </Link>
            <NavFlex justify="space-between">
              {faqs ? (
                <>
                  <ActiveTab large white onClick={showFaqs}>
                    FAQS
                  </ActiveTab>
                  <Tab large white onClick={showVideos}>
                    Video Tutorials
                  </Tab>
                  <StyledLink
                    target="_blank"
                    href="https://www.mtishows.com/performance-accompaniment-recording-quick-start-guide-additional-faqs"
                  >
                    <Tab large white>
                      PAR Quick Start Guide
                    </Tab>
                  </StyledLink>
                </>
              ) : (
                <>
                  <Tab large white onClick={showFaqs}>
                    FAQS
                  </Tab>
                  <ActiveTab large white onClick={showVideos}>
                    Video Tutorials
                  </ActiveTab>
                  <StyledLink href="https://www.mtishows.com/performance-accompaniment-recording-quick-start-guide-additional-faqs">
                    <Tab large white>
                      PAR Quick Start Guide
                    </Tab>
                  </StyledLink>
                </>
              )}
            </NavFlex>
          </HeaderFlex>
          {faqs && (
            <>
              <Spacer size={windowSize === 'phone' ? '24px' : '48px'} />
              <InputWrapper direction="column" width="50%">
                <SearchText white>
                  Search for commonly asked questions.
                </SearchText>
                <Spacer size="8px" />
                <Input
                  placeholder="Search"
                  onChange={handleChange}
                  value={search}
                  icon={
                    search.length < 1 ? (
                      <SearchIcon />
                    ) : (
                      <CloseIcon onClick={clearSearch} />
                    )
                  }
                />
              </InputWrapper>
              <Accordion
                search={search}
                question={'Mac App Permissions'}
                answer={
                  <>
                    <p>
                      On macOS Catalina and newer, it is required to enable Full
                      Disk Access and Screen Recording permissions in order to
                      use the app. This allows us to protect tracks from getting
                      illegally obtained. This access is not utilized for any
                      other purpose. The app will monitor the filesystem and the
                      names of windows you have open. The contents of your files
                      and windows are not accessed and your screen is not
                      recorded. If we determine that you are attempting to
                      access tracks from other windows besides MTI Player, you
                      will be reported.
                    </p>
                  </>
                }
              />
              <Accordion
                search={search}
                question={
                  "Why do I need a personal username? Why can't I login on the MTI Player app with my organization username?"
                }
                answer={
                  <>
                    <p>
                      Personal usernames are used to keep track of the devices
                      authorized to play production tracks, as well as clients
                      that need a way to have access to multiple shows
                      simultaneously.
                    </p>
                    <br />
                    <p>
                      Please note that they will only work on the MTI Player
                      app.
                    </p>
                    <br />
                    <p>
                      Organization accounts allow each organization to manage
                      their own account on our website,{' '}
                      <StyledLink href="http://player.mtishows.com/">
                        player.mtishows.com
                      </StyledLink>
                      . Here, on the desktop website, you will have a number of
                      more advanced options.
                    </p>
                  </>
                }
                listItems={[
                  'Play rehearsal tracks for any shows you are contracted for',
                  'Access cue/edit sheets',
                  'Access the copyright information',
                  'View rehearsal codes for shows your organization is currently contracted for',
                  'Change organization contacts',
                  'Update organization contacts',
                  'Authorize individual users/devices for access to production tracks'
                ]}
              />
              <Accordion
                search={search}
                question={
                  "I can't create or login with a personal username on my Android/PC/Laptop."
                }
                answer={
                  <>
                    <p>
                      The personal username feature is only available on
                      iPhones, iPads, and iPod Touches. It can be used for
                      accessing production tracks and downloading multiple
                      shows.
                    </p>
                    <br />
                    <p>
                      Android devices, PCs, and Laptops can only access
                      rehearsal music due to security reasons.
                    </p>
                  </>
                }
              />
              <Accordion
                search={search}
                question={'My tracks disappeared from the app.'}
                answer={
                  <p>
                    {`Anytime our app senses a Wi-Fi connection, it connects with
                    our servers to get updates on your tracks. When you have
                    your production tracks on your device, we strongly advise
                    that you place your device on 'Airplane Mode', in the
                    General Settings app. If your Wi-Fi is on, but your device
                    enters an area with very little or weak Wi-Fi signal, our
                    app will attempt and fail to communicate with the MTI Player
                    server. This will cause the app to hide the tracks, thinking
                    the tracks shouldn't be on the app. To get the tracks back
                    onto the app, you will need to go to a strong Wi-Fi area and
                    re-download your tracks. Once they are re-downloaded, please
                    put your device on airplane mode anytime you plan on opening
                    the MTI Player app.`}
                  </p>
                }
              />
              <Accordion
                search={search}
                question={
                  "I can't login on the app with the username and password the MTI Player emailed me."
                }
                answer={
                  <>
                    <p>
                      {`If you are your organization's contact, the username and
                      password that MTI emails to you is for logging in on the
                      MTI Player website ONLY. It will NOT work on the app.`}
                    </p>
                    <br />
                    <p>
                      {`To log in on the app, either log in with your Rehearsal
                      Code, or create a personal login from the "Need an
                      Account" link at the bottom of the login screen.`}
                    </p>
                  </>
                }
              />
              <Accordion
                search={search}
                question={
                  "My organization info isn't letting me login on the website."
                }
                answer={
                  <p>
                    If you are on a school Wi-Fi network, that may be the issue.
                    School Wi-Fi networks can often have firewalls that don't
                    work well with our app.
                  </p>
                }
              />
              <Accordion
                search={search}
                question={"The app is telling me 'Invalid Username/Password'."}
                answer={
                  <>
                    <p>
                      Please make sure you are being case sensitive and have a
                      strong Wi-Fi connection first. Also make sure to avoid
                      school Wi-Fi networks. School Wi-Fi networks can often
                      have firewalls that don't work well with our app. If your
                      username and password are still not working, you will need
                      to reset your username/password, or create a new one. You
                      can do this on the login screen of the app.
                    </p>
                    <br />
                    <p>
                      Please click on "Forgot Username / Password". If you need
                      to reset your personal password, fill in the "Username"
                      box, and click "Submit". An email with a link to reset the
                      password will be sent to the email address associated with
                      the username. If you've forgotten your username, fill in
                      the "email" box, and click "Submit". An email containing
                      your username will be sent to the email address associated
                      with your username.
                    </p>
                  </>
                }
              />
              <Accordion
                search={search}
                question={"I don't know where to find my organization info."}
                answer={
                  <p>
                    Your organization login information is located in the email
                    sent to your organization's contact when the organization
                    first licensed the Performance Accompaniment Recordings. If
                    you no longer have access to that email, you can contact the
                    MTI Player support team at player@mtishows.com, and we can
                    give you your organization username. We only have access to
                    what we originally sent out to you, so if you've changed the
                    organization password and can't remember it, you'll need to
                    do a password reset.
                  </p>
                }
              />
              <Accordion
                search={search}
                question={
                  'Every time I try to login on the app, I receive a LOGIN ERROR bubble.'
                }
                answer={
                  <p>
                    This means you need to get to a stronger Wi-Fi location.
                    Once you've logged in on the app and have downloaded your
                    tracks, we strongly recommend that you STAY logged in on the
                    app. There is no need to log out until your performance is
                    over. You also will no longer need a Wi-Fi signal to play
                    your tracks in the app.
                  </p>
                }
              />
              <Accordion
                search={search}
                question={"I can't find/don't have a personal username."}
                answer={
                  <>
                    <p>
                      You only need a personal username if you are trying to
                      access multiple shows or are trying to access complete
                      production tracks. If you don't need the app for those
                      purposes, please leave the username/password boxes empty,
                      and log in with the Rehearsal Code given to you by your
                      organization.
                    </p>
                    <br />
                    <p>
                      While we do supply the organization login info for logging
                      in on the MTI Player WEBSITE, you personally will need to
                      create a personal username for logging in on the APP. When
                      creating a personal username and password, please write it
                      down for future reference, as the MTI Player does not keep
                      record of your personal username and password.
                    </p>
                  </>
                }
              />
              <Accordion
                search={search}
                question={"A track's gone missing from my show."}
                answer={
                  <p>
                    If one or a few tracks have gone missing from your show's
                    playlist, someone with access to your organization's login
                    info has logged in on the organization account at{' '}
                    <StyledLink href="http://player.mtishows.com/">
                      player.mtishows.com
                    </StyledLink>{' '}
                    and accidentally pressed "Don't Use" next to a track. To put
                    the missing track(s) back into your playlist, scroll to the
                    bottom of the track list on the website, and you'll find the
                    unused tracks in a section at the bottom of the web page.
                    Click on the "Use" button, and they will reappear back in
                    your show playlist.
                  </p>
                }
              />
              <Accordion
                search={search}
                question={'Where I can find the cue/edit sheets?'}
                answer={
                  <p>
                    Cue and Edit sheets are located on your organization's
                    account on{' '}
                    <StyledLink href="http://player.mtishows.com/">
                      player.mtishows.com
                    </StyledLink>
                    . Log in with your organization's username/password, click
                    on your show's title, and you'll be taken to the show's
                    documents page, where you can download the sheets.
                  </p>
                }
              />
              <Accordion
                search={search}
                question={'I forgot personal/organization usernames/passwords.'}
                answer={
                  <p>
                    Please click on "Forgot Username / Password" located on the
                    login screen. If you need to reset your personal password,
                    fill in the "username" box, and click "Submit". If you've
                    forgotten your username, fill in the "email" box, and click
                    "Submit". In each case, an email will be sent to you
                    containing either your username info or a link to reset your
                    password.
                  </p>
                }
              />
              <Accordion
                search={search}
                question={'School Wi-Fi networks?'}
                answer={
                  <p>
                    Many school Wi-Fi networks have firewalls set in place that
                    don't work well with the MTI Player app. Depending on the
                    firewalls your school has setup, you may need to download
                    the app and/or tracks on a different, personal Wi-Fi
                    network.
                  </p>
                }
              />
              <Accordion
                search={search}
                question={
                  'My app says the tracks will expire on the last day. Will I still have them for that last performance?'
                }
                answer={
                  <p>
                    The tracks will not leave the app until midnight on the last
                    night of your performance. If you'd like any kind of
                    reassurance, please open the app on the device on the
                    morning of your last performance, and you'll be given the
                    number of hours you have left. If for any reason your show
                    is not there, you can call us on our emergency line (for
                    in-production use only).
                  </p>
                }
              />
              <Accordion
                search={search}
                question={
                  'I want to get rid of one of the devices I authorized to play production tracks and authorize a different device. How do I do that?'
                }
                answer={
                  <p>
                    To de-authorize a device, you must get on the internet and
                    go to{' '}
                    <StyledLink href="http://player.mtishows.com/">
                      player.mtishows.com
                    </StyledLink>
                    . Please make sure you've logged in with your organization
                    username/password. Click on your show's title. You'll be
                    taken to a documents page. You'll see tabs listed for
                    "Documents, Production Access, and Tracks". Click on
                    production access. You'll see any and all devices that have
                    been authorized access to production tracks for this
                    particular show. You'll see an option to click
                    "De-authorize" on the right side of the computer. Make sure
                    you de-authorize the correct device. If you de-authorize the
                    wrong device, you can reauthorize it by clicking "Authorize
                    User", and typing that device's personal username, selecting
                    the device, and reauthorizing it again. After you've
                    de-authorized the intended device, you're good to go.
                  </p>
                }
              />
              <Accordion
                search={search}
                question={
                  'I had some tracks edited, but neither the edited or original versions of those tracks are now in my playlist.'
                }
                answer={
                  <p>
                    Once you receive an email notifying you that the edits are
                    completed, you will need to refresh your device in a strong
                    WiFi area to download the new tracks. Edited tracks will
                    have your organization's initials at the end of the track
                    title.
                  </p>
                }
              />
              <Accordion
                search={search}
                question={
                  'Do I need to wait to make edits until I get production tracks?'
                }
                answer={
                  <p>
                    When we edit tracks, we are editing both rehearsal and
                    production versions. We highly recommend submitting edit
                    requests as early as possible. Your requests will be done on
                    both versions of the tracks.
                  </p>
                }
              />
              <Accordion
                search={search}
                question={'The app kicked me out/is freezing. '}
                answer={<p>There are a few reasons this may occur.</p>}
                listItems={[
                  'This can be due to not having updated the MTI Player app for a while.',
                  "This can also be caused if you have too many programs open on your device. We recommend closing any apps that are not being used. After you've closed everything you'd like closed (including MTI), reopen the MTI, login, and you should be good to go.",
                  'Lastly, sometimes due to having your device on for so long can cause a lot of cache to be built up, which can cause the device to keep freezing. For this, we recommend you completely/manually turn off the device and turn it back on again (NOTE: This is different from restarting the device). After turning the device back on again, you should be good to go.'
                ]}
              />
            </>
          )}{' '}
          {!faqs && (
            <>
              <Spacer size="100px" />
              <Accordion
                search={search}
                question={'Introduction to the MTI Player App'}
                answer={
                  <iframe
                    title={'Introduction to the MTI Player App'}
                    width="100%"
                    height="550px"
                    src="https://www.youtube.com/embed/-mLmOyOG43I"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                }
              />
              <Accordion
                search={search}
                question={'Introduction to the MTI Dashboard'}
                answer={
                  <iframe
                    title={'Introduction to the MTI Dashboard'}
                    width="100%"
                    height="550"
                    src="https://www.youtube.com/embed/UYMXQojWHAY"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                }
              />
              <Accordion
                search={search}
                question={'Rehearsal Track Access for IOS'}
                answer={
                  <iframe
                    title={'Rehearsal Track Access for IOS'}
                    width="100%"
                    height="550"
                    src="https://www.youtube.com/embed/0kjGewRG10g"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                }
              />
              <Accordion
                search={search}
                question={'Rehearsal Track Access for Android'}
                answer={
                  <iframe
                    title="Rehearsal Track Access for Android"
                    width="100%"
                    height="550"
                    src="https://www.youtube.com/embed/ugREfjUfok0"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                }
              />
              <Accordion
                search={search}
                question={'IOS Application Overview'}
                answer={
                  <iframe
                    title="IOS Application Overview"
                    width="100%"
                    height="550"
                    src="https://www.youtube.com/embed/rIHDLAVs6gE"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                }
              />
              <Accordion
                search={search}
                question={'Production Track Access'}
                answer={
                  <iframe
                    title="Production Track Access"
                    width="100%"
                    height="550"
                    src="https://www.youtube.com/embed/KU4ngTPCw3g"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                }
              />
              <Accordion
                search={search}
                question={'QLab'}
                answer={
                  <iframe
                    title="QLab"
                    width="100%"
                    height="550"
                    src="https://www.youtube.com/embed/5ZEitoPTziE"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                }
              />
              <Accordion
                search={search}
                question={'Streaming Access'}
                answer={
                  <iframe
                    title="Streaming Access"
                    width="100%"
                    height="550"
                    src="https://www.youtube.com/embed/xpu6IXzw0fA"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                }
              />
              <Accordion
                search={search}
                question={'Track Management'}
                answer={
                  <iframe
                    title="Track Management"
                    width="100%"
                    height="550"
                    src="https://www.youtube.com/embed/81TbOYmDzPM"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                }
              />
            </>
          )}
        </FlexBox>
      </Container>
    </Body>
  );
}
