import React from 'react';
import {
  Header,
  Button,
  Menu,
  Content,
  FormWrapper,
  Text,
  Wrapper
} from 'components';
import {
  getShow,
  generateRehearsalEditCodes,
  markShowEditable
} from 'data-graphql';
import {
  EditShowForm,
  Tracks,
  Documents,
  Organizations,
  Email,
  Archive
} from './components';
import { isMedia, isMTI } from 'helpers';
import { toast } from 'react-toastify';

export default class Shows extends React.Component {
  constructor() {
    super();
    this.state = {
      show: undefined,
      tracks: [],
      bookings: [],
      // currentPage: 1,
      // totalPages: 1,
      // search: '',
      menu: 'Tracks',
      editShowsOpen: false,
      menuOpen: false
    };
  }

  componentDidMount() {
    this.getComponentShow();
  }

  getComponentShow = async () => {
    const VARS = { id: this.props.match.params.id };
    const REQ_OBJECT = `{
      id
      name
      notes
      edits_doc_url
      cue_doc_url
      client_name
      org_count
      is_archived
      is_quote_eligible
      has_editable_tracks
      edit_codes_generated
      ${isMTI ? 'foreign_show_id' : ''}
      bookings_count(states: [quoted, approved, booked, active])
      bookings(states: [quoted, approved, booked, active]) {
        id
        state
        rehearsal_active_date
        production_active_date
        opening
        expiration
        is_production_access
        organization {
          id
          name
          active_contacts_count          
        }
      }
      master_setlist {
        id
        name
        type
        version_number
        track_count
        tracks {
          id
          updated_at
          track_name
          time_formatted
          time
          cue_number
          has_rehearsal
          has_production
          production_location
          rehearsal_location
          midi_location
        }
      }
      alt_setlists {
        id
        type
        name
        version_number
        track_count
        tracks {
          id
          updated_at
          track_name
          time_formatted
          time
          cue_number
          has_rehearsal
          has_production
          production_location
          rehearsal_location
          midi_location
        }
      }
      inactive_tracks {
        id
        updated_at
        track_name
        time_formatted
        time
        cue_number
        has_rehearsal
        has_production
        production_location
        rehearsal_location
        midi_location
        deleted_at
        active_setlist_count
      }
    }`;

    const RESULT = await getShow(VARS, REQ_OBJECT);
    if (RESULT.data && RESULT.data.success) {
      let tracks = [];
      let bookings = [];
      const show = RESULT.data.getShow;

      if (show.master_setlist) {
        tracks = show.master_setlist.tracks ? show.master_setlist.tracks : [];
        show.alt_setlists.unshift(show.master_setlist);
      }
      if (show.bookings) {
        bookings = show.bookings ? show.bookings : [];
      }
      const inactiveTracks = {
        name: 'Inactive Tracks',
        id: '123456',
        tracks: show.inactive_tracks
      };
      show.alt_setlists.push(inactiveTracks);
      this.setState({
        show: show,
        tracks: tracks.sort((a, b) =>
          a.cue_number > b.cue_number ? 1 : b.cue_number > a.cue_number ? -1 : 0
        ),
        setlists: show.alt_setlists,
        bookings: bookings
      });
    }
  };

  handleChildChange = async menu => {
    menu = menu ? menu : 'Tracks';
    this.setState({ editShowsOpen: false });

    await this.getComponentShow();
    this.setState({ menu: menu });
  };

  handleChildNav = id => {
    this.props.history.push(`/admin/organizations/${id}`);
  };

  editShowsOpen = () => {
    this.setState({
      editShowsOpen: this.state.editShowsOpen === true ? false : true
    });
  };

  menuOpen = () => {
    this.setState({
      menuOpen: this.state.menuOpen === 'open' ? false : 'open'
    });
  };

  menuSelect = e => {
    this.setState({
      menu: e.target.value,
      menuOpen: this.state.menuOpen === 'open' ? false : 'open'
    });
  };

  countContacts = () => {
    if (this.state.bookings.length > 0) {
      return Array.from(
        new Set(this.state.bookings.map(booking => booking.organization.id))
      ) // create distinct set of organizations from ids
        .map(
          distinctOrgId =>
            this.state.bookings.find(
              booking => booking.organization.id === distinctOrgId
            ).organization.active_contacts_count
        ) // use distinct ids to get active_contacts_count for org
        .reduce((acc, a) => acc + a); // add active_contacts_counts together
    } else {
      return [];
    }
  };

  generateRehearsalCodes = async () => {
    const VARS = { show_id: this.state.show.id };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    const RESULT = await generateRehearsalEditCodes(VARS, REQ_OBJECT);
    console.log(RESULT);
    if (RESULT.data.success) {
      toast.success('Rehearsal Codes successfully generated');
      this.getComponentShow();
    }
  };

  makeShowEditable = async () => {
    const VARS = { show_id: this.state.show.id };
    const REQ_OBJECT = `{
      code
      success
      message
      show {
        id
        has_editable_tracks
      }
    }`;

    const RESULT = await markShowEditable(VARS, REQ_OBJECT);
    console.log(RESULT);
    if (RESULT.data.success) {
      toast.success('Show available in Pro app for Beta');
      this.getComponentShow();
    }
  };

  render() {
    const { menuOpen } = this.state;
    if (!this.state.show) return null;
    const { editShowsOpen } = this.state;
    return (
      <div>
        <Header>
          <Header.Head bold huge>
            {this.state.show.name}
            <Button onClick={this.editShowsOpen} header>
              Edit Show {editShowsOpen ? '-' : '+'}
            </Button>
            {!this.state.show.has_editable_tracks && (
              <Button onClick={this.makeShowEditable} header>
                Release for Beta
              </Button>
            )}
            {!this.state.show.edit_codes_generated && (
              <Button onClick={this.generateRehearsalCodes} header>
                Release for Launch
              </Button>
            )}
            {this.state.show.notes && (
              <div style={{ marginTop: -15 }}>
                <Text soft small>
                  {this.state.show.notes}
                </Text>
              </div>
            )}

            {this.state.show.has_editable_tracks && (
              <div style={{ marginLeft: '10px' }}>
                <Text red>
                  Available on Pro app:{' '}
                  {`${
                    this.state.show.edit_codes_generated ? 'Launched' : 'Beta'
                  }`}
                </Text>
              </div>
            )}
          </Header.Head>
          {isMedia() === 'phone' ? (
            <>
              <Button darkRed menu onClick={this.menuOpen}>
                {this.state.menu} {menuOpen === 'open' ? '-' : '+'}
              </Button>
              <Content
                pose={menuOpen === 'open' ? 'open' : 'closed'}
                style={{ overflow: menuOpen === 'open' ? 'visible' : 'hidden' }}
              >
                <Menu>
                  <button
                    value="Tracks"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'Tracks'}
                  >
                    Tracks
                  </button>
                  <button
                    value="Documents"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'Documents'}
                  >
                    Documents
                  </button>
                  <button
                    value="Organizations"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'Organizations'}
                  >
                    Organizations
                  </button>
                  <button
                    value="Email"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'Email'}
                  >
                    Email
                  </button>
                  <button
                    value="Archive"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'Archive'}
                  >
                    {this.state.show.is_archived ? 'Restore' : 'Archive'} Show
                  </button>
                </Menu>
              </Content>
            </>
          ) : (
            <Menu>
              <button
                value="Tracks"
                onClick={this.menuSelect}
                active={this.state.menu === 'Tracks'}
              >
                Tracks
              </button>
              <button
                value="Documents"
                onClick={this.menuSelect}
                active={this.state.menu === 'Documents'}
              >
                Documents
              </button>
              <button
                value="Organizations"
                onClick={this.menuSelect}
                active={this.state.menu === 'Organizations'}
              >
                Organizations
              </button>
              <button
                value="Email"
                onClick={this.menuSelect}
                active={this.state.menu === 'Email'}
              >
                Email
              </button>
              <button
                value="Archive"
                onClick={this.menuSelect}
                active={this.state.menu === 'Archive'}
              >
                {this.state.show.is_archived ? 'Restore' : 'Archive'} Show
              </button>
            </Menu>
          )}
        </Header>
        <Wrapper>
          <Content
            pose={this.state.editShowsOpen ? 'open' : 'closed'}
            style={{
              overflow: this.state.editShowsOpen ? 'visible' : 'hidden'
            }}
          >
            <FormWrapper>
              <EditShowForm
                show={this.state.show}
                onChange={this.handleChildChange}
              />
            </FormWrapper>
          </Content>
          {this.state.menu === 'Tracks' && (
            <Tracks
              show={this.state.show}
              setlists={this.state.setlists}
              tracks={this.state.tracks}
              onChange={this.handleChildChange}
            />
          )}
          {this.state.menu === 'Documents' && (
            <Documents
              show={this.state.show}
              onChange={this.handleChildChange}
            />
          )}
          {this.state.menu === 'Organizations' && (
            <Organizations
              bookings={this.state.bookings}
              onChange={this.handleChildChange}
              onNav={this.handleChildNav}
            />
          )}
          {this.state.menu === 'Email' && (
            <Email
              org_count={this.state.show.org_count}
              show={this.state.show}
              total_contacts={this.countContacts}
            />
          )}
          {this.state.menu === 'Archive' && (
            <Archive
              history={this.props.history}
              show_id={this.state.show.id}
              org_count={this.state.show.org_count}
              bookings_count={this.state.show.bookings_count}
              is_archived={this.state.show.is_archived}
              onChange={this.handleChildChange}
            />
          )}
        </Wrapper>
      </div>
    );
  }
}
