import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Segment, COLORS, Text, Spacer } from 'components';
import {isMTI} from 'helpers';
import styled from 'styled-components';
import LogoSrc from 'assets/logo.svg';
import MTILogo from 'assets//mtiLogoBottomAligned.png';

const GlobalStyle = createGlobalStyle`
  body {
    overflow: visible
  }
`;

const Body = styled.div`
  min-height: 100vh;
  background: linear-gradient(
    ${isMTI ? COLORS.darkBlue : '#000000'},
    ${isMTI ? COLORS.lightBlue : COLORS.red}
  );
  overflow: scroll;
`;
const Form = styled.form`
  width: 500px;
  max-width: 100%;
  margin: auto;
  padding-top: 100px;
`;
const Logo = styled.img`
  width: 100%;
  padding: 30px 40px;
`;

export default class Maintenance extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <Body>
        <div
          style={{
            width: '100%',
            position: 'fixed',
            top: '0px',
            backgroundColor: `${COLORS.lighterYellow}`,
            color: `${COLORS.black}`,
            padding: '10px 25px',
            textAlign: 'center'
          }}
        >
          <Text>
            The {`${isMTI? 'MTI Player' : 'MT Pit Dashboard'}`} is currently undergoing site maintenance.
            {/* <Spacer size="10px" /> */} Please return later to access the
            full site.
            {/* <Spacer size="10px" /> */} For immediate assistance, call The {`${isMTI? 'MTI Player' : 'MT Pit'}`} directly.{' '}
          </Text>
        </div>
        <Form>
          <Logo src={isMTI ? MTILogo : LogoSrc} />
          <Segment>
            <div style={{ textAlign: 'center' }}>
              <a
                rel="noopener noreferrer"
                href={`${isMTI ? 'https://mtishows.com' : process.env.REACT_APP_MARKETING_URL}`}
                style={{
                  textDecoration: 'underline',
                  textDecorationColor: `${COLORS.black}`
                }}
              >
                <Text>Back to {`${isMTI ? 'mtishows.com' : 'themtpit.com'}`}</Text>
              </a>
            </div>
          </Segment>
        </Form>
        <Spacer size="50px" />
        <GlobalStyle />
      </Body>
    );
  }
}
