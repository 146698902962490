import React from 'react';
import styled from 'styled-components';
import COLORS from './colors';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Card = styled.div`
  cursor: pointer;
  background: ${props => (props.active ? COLORS.darkerYellow : COLORS.white)};
  border: 1px solid ${COLORS.lightGrey};
  min-width: 30px;
  padding: 5px;
  text-align: center;
  color: ${props => (props.active ? COLORS.white : COLORS.darkerYellow)};
  &:hover {
    background: ${COLORS.yellow};
    color: ${COLORS.white};
  }
`;

export default props => {
  const pages = [];
  if (props.alphabetical) {
    pages.push(
      '#',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z'
    );
  } else {
    pages.push(props.currentPage);
    const maxLength = props.totalPages > 10 ? 10 : props.totalPages;
    let i = 0; // Infinite loop safety.
    while (pages.length < maxLength && i < 15) {
      i++;
      if (pages[0] - 1 > 0) {
        pages.unshift(pages[0] - 1);
      }
      if (pages.length < 10 && pages[pages.length - 1] < props.totalPages) {
        pages.push(pages[pages.length - 1] + 1);
      }
    }
  }

  return (
    <Wrapper>
      {pages.map(val => (
        <Card
          onClick={() => props.onPageChange(val)}
          active={props.currentPage === val}
          key={val}
        >
          {val}
        </Card>
      ))}
    </Wrapper>
  );
};
