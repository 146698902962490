import styled from 'styled-components';
import COLORS from './colors';
import ReactTable from 'react-table';

// Modified version of react-table

export default styled(ReactTable)`
  width: 100%;
  border-collapse: collapse;
  border: none;

  .rt-table {
    background-color: transparent;
    border: none;
  }
  
  .rt-thead .rt-th {
    border-right: none
  }

  .rt-tr {
    border: none;
    margin: 10px;
    transition: box-shadow 0.3s ease;
    cursor: ${props => (!props.noShadow ? 'pointer' : 'default')}
    &:hover {
      box-shadow: ${props =>
        !props.noShadow && `0 2px 6px rgba(0, 0, 0, 0.21) !important`};
    }
  }
  .rt-tr-group {
    border-bottom: none;
  }

  .rt-th {
    color: ${COLORS.grey};
    padding-bottom: 10px;
    font-weight: bold;
    test-align: center;
    white-space: normal;
    &:first-of-type {
      text-align: left;
    }
    &:focus {
      outline: none !important;
    }
  }

  .rt-td {
    background: ${COLORS.white};
    color: ${COLORS.black};
    width: auto;
    text-align: center;
    display: inline;
    word-wrap: break-word;
    white-space: normal;
    padding: 15px;
    &:first-of-type {
      text-align: left;
    }
  }
  .rt-noData {
    padding: 12px;
    top: 65%;
    height: 40%;
  }

  .rt-tbody .rt-tr-group {
    border: none;
  }
  .rt-thead .rt-tr {
    margin: 0px 10px;
    box-shadow: none !important;
  }
  .rt-thead.-header {
    box-shadow: none !important;
  }

  .-pagination {
    box-shadow: none;
    border-top: none;
  }
  .pagination-bottom {
    margin: 10px;
    background-color: ${COLORS.white};
  }
  .-pagigination .-previous,
  .-pagination .-next {
    background-color: ${COLORS.lightGrey};
  }
`;
