import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  justify-content: ${props => (props.justify ? props.justify : 'flex-start')};
  align-items: ${props => (props.align ? props.align : 'stretch')};
  flex-wrap: ${props => (props.wrap ? 'wrap' : 'nowrap')};
  width: ${props => (props.width ? props.width : '100%')};
`;
