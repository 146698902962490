import React from 'react';
import { Form, Spacer } from 'components';
import styled from 'styled-components';

const StyledForm = styled(Form)`
  width: 90%;
  text-align: left;
  margin-top: -20px;
`;

export default props => (
  <StyledForm>
    <Spacer size="10px" />
    <Form.InputWrapper full>
      <Form.StyledAsyncSelect
        cacheOptions
        loadOptions={props.search}
        onInputChange={props.handleAssignInput}
        defaultOptions={true}
        value={props.value}
        onChange={props.handleChange}
        placeholder={props.placeholder}
        styles={Form.CustomStyles}
      />
    </Form.InputWrapper>
    <Spacer size="30px" />
  </StyledForm>
);
