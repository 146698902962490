import React from 'react';
import { Icon, Text, StyledTable } from 'components';
import { isMedia } from 'helpers';
import moment from 'moment';

export default class CurrentShowsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: false,
      columnExpander: '',
      selected: ''
    };
  }

  componentDidMount() {}

  render() {
    const { bookings } = this.props;
    const today = new Date();
    const paginateActiveBookings =
      bookings.filter(booking => {
        return booking.state === 'active';
      }).length > 3
        ? true
        : false;
    const hasEditCodes =
      bookings.filter(booking => booking.rehearsal_edit_code).length > 0;
    return (
      <div>
        <br />
        <Text large bold>
          Current Shows
        </Text>
        <br />
        <br />
        <StyledTable
          data={bookings.filter(booking => {
            return booking.state === 'active';
          })}
          noDataText="No current shows."
          columns={[
            {
              Header: 'Title',
              accessor: 'show.name',
              minWidth: 125
            },
            {
              Header: 'Code',
              accessor: 'rehearsal_code',
              minWidth: 95,
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Pro App Code',
              accessor: 'rehearsal_edit_code',
              minWidth: 95,
              show: isMedia() !== 'phone' && hasEditCodes
            },
            {
              Header: 'Opening',
              accessor: 'opening',
              minWidth: 90,
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Closing',
              accessor: 'expiration',
              minWidth: 90,
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Prod Acccess',
              accessor: 'is_production_access',
              minWidth: 75,
              Cell: row => (
                <>
                  {((row.value === true &&
                    moment(row.original.production_active_date).isSameOrBefore(
                      today
                    )) ||
                    row.value === false) && (
                    <Icon
                      name={row.value === true ? 'check-box' : 'delete'}
                      style={
                        row.value === true
                          ? {
                              cursor: 'pointer',
                              fontSize: '23px',
                              fontWeight: '800'
                            }
                          : {
                              cursor: 'pointer',
                              fontSize: '20px',
                              fontWeight: '400'
                            }
                      }
                    />
                  )}
                  {row.value === true &&
                    moment(row.original.production_active_date).isAfter(
                      today
                    ) && <Text>{row.original.production_active_date}</Text>}
                </>
              )
            }
          ]}
          resizable={false}
          sortable={false}
          showPagination={paginateActiveBookings}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          defaultPageSize={10}
          getTrProps={(state, rowInfo) => {
            return {
              onClick: () => {
                this.props.onClick(rowInfo.original.id);
                // this.props.history.push(`/org/shows/${rowInfo.original.id}`);
              }
            };
          }}
        />
      </div>
    );
  }
}
