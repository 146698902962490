import React from 'react';
import { default as Icon } from './Icon';

const SearchIcon = props => (
  <Icon name="search" style={{ cursor: 'pointer' }} onClick={props.onClick} />
);

const CloseIcon = props => (
  <Icon name="close" style={{ cursor: 'pointer' }} onClick={props.onClick} />
);

export { SearchIcon, CloseIcon };
