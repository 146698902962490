import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AllShows from './AllShows.js';
import ShowDetails from './ShowDetails.js';

export default props => {
  return (
    <Switch>
      <Route exact path={`${props.match.url}`} component={AllShows} />
      <Route exact path={`${props.match.url}/:id`} component={ShowDetails} />
    </Switch>
  );
};
