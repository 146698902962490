import React from 'react';
import styled from 'styled-components';
import {
  Wrapper,
  Button,
  FormWrapper,
  Form,
  Spacer,
  Text,
  COLORS
} from 'components';
import { http } from 'data-graphql';
import LogoSrc from 'assets/logo.svg';
import mtiLogo from 'assets//mtiLogoBottomAligned.png';
import { toast } from 'react-toastify';
import { isMedia, isMTI } from 'helpers';
import { default as media } from '../../components/screenSize';

const Logo = styled.img`
  height: 100%;
  padding: 20px;
  margin: auto;
`;

const Header = styled.div`
  height: 130px;
  width: 100%;
  background-color: ${isMTI ? COLORS.darkBlue : COLORS.dark};
  text-align: center;
  position: absolute;
  z-index: 10;
`;

const StyledFormWrapper = styled(FormWrapper)`
  width: 45%;
  ${media.phone`
    width: 100%;
  `}
`;

const StyledHR = styled.hr`
  border: 1px solid ${COLORS.lightGrey};
`;

export default class Documents extends React.Component {
  constructor() {
    super();
    this.state = {
      tracks: []
    };
  }

  componentDidMount() {
    this.getInfo();
  }

  handleChange = e => {
    const value = e.target.value;
    this.setState({ [e.target.name]: value });
  };

  tryMTPitiOS = () => {
    var userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
      window.location = 'themtpit://';
      setTimeout(function() {
        window.location =
          'https://itunes.apple.com/us/app/themtpit/id925097028?mt=8';
      }, 1000);
    } else {
      window.location =
        'https://itunes.apple.com/us/app/themtpit/id925097028?mt=8';
    }
  };

  tryMTIiOS = () => {
    var userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
      window.location = 'mtiplayer://';
      setTimeout(function() {
        window.location =
          'https://itunes.apple.com/us/app/mti-player/id1241407591?mt=8';
      }, 1000);
    } else {
      window.location =
        'https://itunes.apple.com/us/app/mti-player/id1241407591?mt=8';
    }
  };

  goToTracks = () => {
    http()
      .get(
        `/rehearsal/get-info-for-code?rehearsalCode=${this.state.rehearsalCode}`
      )
      .then(resp => {
        if (resp.success) {
          this.props.history.push(
            `/rehearsal/stream/${this.state.rehearsalCode}`
          );
        }
      });
  };

  addRehearsalCodeToUser = () => {
    http()
      .post(`/rehearsal/add-code-to-user`, {
        username: this.state.username,
        rehearsalCode: this.state.rehearsalCode
      })
      .then(resp => {
        if (resp.success) {
          toast.success(`${resp.message}`);
        }
      });
  };

  getInfo = async () => {
    if (this.props.match.params.id) {
      http()
        .get(
          `/rehearsal/get-info-for-code?rehearsalCode=${this.props.match.params.id}`
        )
        .then(resp => {
          this.setState({
            orgName: resp.result.orgName,
            show: resp.result.showName,
            rehearsalCode: this.props.match.params.id
          });
        });
    }
  };

  backToLogin = () => {
    this.props.history.push('/');
  };

  render() {
    return (
      <div style={{ maxHeight: '100vh', overflowY: 'auto' }}>
        <Header>
          <Logo src={isMTI ? mtiLogo : LogoSrc} onClick={this.backToLogin} />
        </Header>
        <Wrapper style={{ marginTop: '130px' }}>
          <Spacer size="20px" />
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Text huge red>
              REHEARSAL TRACK ACCESS
            </Text>
          </div>
          <div
            {...(isMedia() === 'phone'
              ? {
                  style: {
                    display: 'flex',
                    flexDirection: 'column'
                  }
                }
              : {
                  style: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly'
                  }
                })}
          >
            <StyledFormWrapper>
              <Text huge red>
                App Access
              </Text>
              <Spacer size="20px" />
              <StyledHR />
              <Form>
                <Spacer size="20px" />
                <Text large red>
                  iOS Devices
                </Text>
                <Spacer size="20px" />

                {this.state.orgName && this.state.show ? (
                  <Text>
                    Enter your username below to access <br />
                    Rehearsal Tracks for {this.state.orgName}&apos;s
                    <br /> production of {this.state.show} through the app.
                  </Text>
                ) : (
                  <Text>
                    Enter your username and Rehearsal Code <br /> below to
                    access Rehearsal Tracks through the app.
                  </Text>
                )}

                <Spacer size="10px" />
                <Form.InputWrapper>
                  <Form.Label>Personal Username</Form.Label>
                  <Form.Input
                    type="text"
                    value={this.state.username}
                    name="username"
                    onChange={this.handleChange}
                    autoCorrect="off"
                    autoCapitalize="none"
                  />
                  <br />
                  <Form.Label>Rehearsal Code</Form.Label>
                  <Form.Input
                    type="text"
                    value={this.state.rehearsalCode}
                    name="rehearsalCode"
                    onChange={this.handleChange}
                    autoCorrect="off"
                    autoCapitalize="none"
                  />
                </Form.InputWrapper>
                <Spacer size="20px" />
                <Button large flat onClick={this.addRehearsalCodeToUser}>
                  Add Code
                </Button>
                <Spacer size="20px" />
                <Text>
                  If you do not yet have a personal account,
                  <br /> first download the app through the App Store <br />
                  {/* TODO: MTI link when MTI skinned */}
                  (click{' '}
                  <Text
                    style={{
                      textDecoration: 'underline',
                      textDecorationColor: `${COLORS.black}`,
                      cursor: 'pointer'
                    }}
                    onClick={isMTI ? this.tryMTIiOS : this.tryMTPitiOS}
                  >
                    here
                  </Text>{' '}
                  to open), and then create your account in the app. <br />
                  Then return here and enter your username.{' '}
                </Text>
                <Spacer size="10px" />
                <Text red bold>
                  Once tracks are downloaded through the app,
                  <br /> an internet connection is no longer needed.
                </Text>
              </Form>
              <Spacer size="20px" />
              <StyledHR />
              <Form>
                <Spacer size="20px" />
                <Text large red>
                  Android Devices
                </Text>
                <Spacer size="20px" />
                {/* TODO: MTI link when MTI skinned */}

                <Text>
                  The Android app can be downloaded{' '}
                  <a
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      textDecorationColor: `${COLORS.black}`
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      isMTI
                        ? 'https://play.google.com/store/apps/details?id=com.mtishows.mtiplayer'
                        : 'https://play.google.com/store/apps/details?id=com.themtpit.themtpit'
                    }
                  >
                    <Text>here.</Text>
                  </a>
                  <br />
                  Once downloaded enter your Rehearsal Code directly <br />
                  into the app to download the tracks.
                </Text>

                <Spacer size="20px" />
                <Text red>
                  Once tracks are downloaded through the app,
                  <br /> an internet connection is no longer needed.
                </Text>
              </Form>
            </StyledFormWrapper>
            <StyledFormWrapper style={{ height: 'fit-content' }}>
              <Form>
                <Text huge red>
                  Streaming Access
                </Text>
                <Spacer size="20px" />
                <StyledHR />
                <Spacer size="20px" />
                <Text large>Any Device</Text>
                <Spacer size="20px" />

                <Text>
                  Tracks can only be streamed via an internet connection <br />
                  through this method.
                </Text>

                <Spacer size="10px" />
                <Form.InputWrapper>
                  <Form.Label>Rehearsal Code</Form.Label>
                  <Form.Input
                    type="text"
                    value={this.state.rehearsalCode}
                    name="rehearsalCode"
                    onChange={this.handleChange}
                    autoCorrect="off"
                    autoCapitalize="none"
                  />
                </Form.InputWrapper>
                <Spacer size="20px" />
                <Button large flat onClick={this.goToTracks}>
                  Stream Tracks
                </Button>
              </Form>
            </StyledFormWrapper>
          </div>
        </Wrapper>
      </div>
    );
  }
}
