// import React from 'react';
import styled from 'styled-components';
import COLORS from '../colors.js';
import HoverRow from './HoverRow/index';
import HoverCell from './HoverCell/index';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

Table.Head = styled.thead``;

Table.Body = styled.tbody``;

Table.Row = styled.tr`
  border: solid 2px ${COLORS.lightGrey};
`;

Table.Cell = styled.td`
  padding: 20px;
  background: ${COLORS.white};
  color: ${COLORS.black};
  text-align: center;
  word-wrap: break-word;
  &:first-of-type {
    text-align: left;
  }
`;

Table.HeadCell = styled.th`
  color: ${COLORS.grey};
  padding-bottom: 10px;
  font-weight: bold;
  test-align: center;
  &:first-of-type {
    text-align: left;
  }
`;

Table.HoverRow = HoverRow;
Table.HoverCell = HoverCell;

export default Table;
