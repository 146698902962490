import styled from 'styled-components';
import COLORS from './colors';
import { default as media } from './screenSize';

export default styled.button`
  background: ${props => {
    if (props.disabled) {
      return COLORS.grey;
    } else if (props.black) {
      return COLORS.black;
    } else if (props.status) {
      if (props.status === 'paid_in_full') {
        return 'green';
      } else if (props.status === 'partial_payment') {
        return 'red';
      } else if (props.status === 'purchase_order') {
        return 'blue';
      } else {
        return COLORS.black;
      }
    } else {
      return props.negative ? COLORS.red : COLORS.yellow;
    }
  }};
  border: none;
  padding: ${props => (props.large ? '15px 30px' : '5px 15px')};
  border-radius: ${props => {
    if (props.straight) return '0';
    if (props.large) return '3px';
    return '3px';
  }};
  color: ${props => {
    if (props.disabled) {
      return COLORS.lightGrey;
    } else if (props.black) {
      return COLORS.white;
    } else {
      return props.negative ? COLORS.white : COLORS.white;
    }
  }};
  font-size: 16px;
  font-weight: ${props => (props.large ? 'bold' : 'normal')};
  ${props => {
    if (!props.disabled) return 'cursor: pointer;';
  }}
  width: ${props => (props.fluid ? '100%' : 'auto')};
  ${props => props.header && `vertical-align: 40%; margin-left: 50px;`}
  ${props => props.noHover && `cursor: default`}
  ${props =>
    !props.noHover &&
    `&:hover { background: ${props => {
      if (!props.disabled && !props.status) {
        return props.negative
          ? COLORS.dark
          : props.black
          ? COLORS.lightBlack
          : COLORS.hoverYellow;
      }
    }};
  }`}
  &:focus {
    outline: none;
  }
  ${media.phone`
    background: ${props => (props.darkRed ? COLORS.darkRed : COLORS.yellow)};
    }};

    ${props => {
      return (
        !props.noHover &&
        `&:hover { background: ${
          props.negative
            ? COLORS.dark
            : props.black
            ? COLORS.lightBlack
            : COLORS.hoverYellow
        }};`
      );
    }}
    
    padding: ${props => props.menu && '16px'};
  `}
`;
