import gql from 'graphql-tag';
import graphql from './graphql';

export async function createBooking(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation CreateBooking($input: CreateBookingInput!) {
          createBooking(input: $input) 
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function updateBooking(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation UpdateBooking($id: UUID!, $input: UpdateBookingInput!) {
          updateBooking(id: $id, input: $input) 
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function acceptBookingTerms(VARS, REQ_OBJECT) {
  try {
    return await graphql().mutate({
      mutation: gql`
        mutation AcceptMTIBookingTerms($id: UUID!, $input: AcceptBookingTermsInput!) {
          acceptMTIBookingTerms(id: $id, input: $input) 
            ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

// export async function removeAuthorization(VARS, REQ_OBJECT) {
//   try {
//     return await graphql().mutate({
//       mutation: gql`
//         mutation removeAuthorization($booking_id: UUID!) {
//           removeAuthorization(booking_id: $booking_id)
//             ${REQ_OBJECT}
//         }
//       `,
//       variables: VARS,
//       fetchPolicy: 'no-cache'
//     });
//   } catch (e) {
//     return e;
//   }
// }

export async function getBookings(VARS, REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
        query getBookings(
          $page: Int!
          $states: [BookingState]
          $search: String
        ) {
          getBookings(
            input: {
              page: $page
              search: $search
              states: $states
            }
          ) 
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function getBookingForCode(VARS, REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
        query getBookingForCode(
          $rehearsalCode: String! 
        ) {
          getBookingForCode(
            rehearsalCode: $rehearsalCode
          ) 
          ${REQ_OBJECT}
        }
      `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function getBooking(VARS, REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
          query GetBooking($id: UUID!) {
            getBooking(
              id: $id
            ) 
            ${REQ_OBJECT}
          }
        `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function getBookingsForOrg(VARS, REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
          query GetBookingsForOrg($orgId: UUID!, $state: BookingState) {
            getBookingsForOrg(
              orgId: $orgId
              state: $state 
            ) 
            ${REQ_OBJECT}
          }
        `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}

export async function getSetlistsForBooking(VARS, REQ_OBJECT) {
  try {
    return await graphql().query({
      query: gql`
          query GetSetlistsForBooking($id: UUID!) {
            getSetlistsForBooking(
              id: $id
            ) 
            ${REQ_OBJECT}
          }
        `,
      variables: VARS,
      fetchPolicy: 'no-cache'
    });
  } catch (e) {
    return e;
  }
}
