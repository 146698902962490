import React from 'react';
import {
  Header,
  Text,
  Icon,
  Button,
  StyledTable,
  Spacer,
  Wrapper,
  Content,
  FormWrapper,
  ExpanderSpan,
  FlexBox,
  COLORS
} from 'components';
import { getMe } from 'data-graphql';
import { getTrPropsFn, isMedia, isMTI } from 'helpers';
import styled from 'styled-components';
import { CreateContact, EditContact } from './components';
import Spinner from 'react-spinkit';

const InactiveTable = styled(StyledTable)`
  .rt-td {
    color: #f1efef;
  }
`;
const ExecTable = styled(StyledTable)`
  .rt-td,
  .rt-thead .rt-tr {
    text-align: left;
  }
`;
export default class ContactsOrgTable extends React.Component {
  constructor() {
    super();
    this.state = {
      active_contacts: [],
      inactive_contacts: [],
      organization_id: ''
    };
  }

  async componentDidMount() {
    await this.getCurrentUser();
  }

  getCurrentUser = async () => {
    const REQ_OBJECT = `{
      email
      first_name
      last_name
      organization {
        id
        name
        ${
          isMTI
            ? `executive {
              id
              name
              email
            }`
            : ''
        }
        active_contacts: contacts(active: true) {
          id
          first_name
          last_name
          position
          email
          phone_number
          phone_number_two
          active
          is_account_admin
          full_name
        }
        inactive_contacts: contacts(active: false) {
          id
          first_name
          last_name
          position
          email
          phone_number
          phone_number_two
          active
          is_account_admin
          full_name
        }
      }
    }`;

    const RESULT = await getMe(REQ_OBJECT);
    this.setState({
      active_contacts: RESULT.data.getMe.organization.active_contacts || [],
      inactive_contacts: RESULT.data.getMe.organization.inactive_contacts || [],
      executives: RESULT.data.getMe.organization.executive || [],
      organization_id: RESULT.data.getMe.organization.id
    });
  };

  handleCreateContact = () => {
    this.setState({ createContact: false });
    this.getCurrentUser();
  };

  createContactForm = () => {
    this.setState({
      createContact: this.state.createContact === 'open' ? false : 'open'
    });
  };

  render() {
    if (!this.state.active_contacts.length > 0) return null;
    return (
      <div>
        <Header>
          <Header.Head bold huge style={{ textAlign: 'left' }}>
            Contacts
            <Button
              onClick={this.createContactForm}
              style={{ verticalAlign: '40%', marginLeft: 50 }}
            >
              Contact {this.state.createContact === 'open' ? '-' : '+'}
            </Button>
          </Header.Head>
        </Header>
        <Spacer size="20px" />

        {!this.state.organization_id && (
          <Wrapper>
            <FlexBox justify="space-evenly" style={{ alignContent: 'center' }}>
              <Spinner
                name="three-bounce"
                color={COLORS.darkRed}
                fadeIn="none"
              />
            </FlexBox>
          </Wrapper>
        )}

        {this.state.organization_id && (
          <Wrapper>
            <Content
              pose={this.state.createContact === 'open' ? 'open' : 'closed'}
              style={{
                overflow:
                  this.state.createContact === 'open' ? 'visible' : 'hidden'
              }}
            >
              <FormWrapper>
                <CreateContact
                  active_contacts={this.state.active_contacts}
                  organization_id={this.state.organization_id}
                  onChange={this.handleCreateContact}
                />
              </FormWrapper>
            </Content>
            <StyledTable
              data={this.state.active_contacts}
              noDataText="No active contacts."
              columns={[
                {
                  Header: 'Name',
                  accessor: 'full_name'
                },
                {
                  Header: 'Position',
                  accessor: 'position',
                  show: isMedia() !== 'phone'
                },
                {
                  Header: 'Email',
                  accessor: 'email',
                  show: isMedia() !== 'phone'
                },
                {
                  Header: 'Phone',
                  accessor: 'phone_number',
                  show: isMedia() !== 'phone'
                },
                {
                  Header: 'Phone #2',
                  accessor: 'phone_number_two',
                  show: isMedia() !== 'phone'
                },
                {
                  Header: 'Acct. Admin',
                  accessor: 'is_account_admin',
                  Cell: props =>
                    props.value === true && (
                      <Icon
                        name="check-box"
                        style={{
                          cursor: 'pointer',
                          fontSize: '23px',
                          fontWeight: '800'
                        }}
                      />
                    ) // Custom cell components!
                },
                {
                  Header: 'Edit',
                  accessor: 'id',
                  expander: true,
                  width: 65,
                  maxWidth: 65,
                  minWidth: 65,
                  Expander: ({ isExpanded }) => (
                    <ExpanderSpan
                      {...(isExpanded ? { open: true } : { closed: true })}
                    />
                  )
                }
              ]}
              showPagination={
                this.state.active_contacts.length > 3 ? true : false
              }
              resizable={false}
              sortable={false}
              showPaginationTop={false}
              showPaginationBottom={true}
              showPageSizeOptions={false}
              minRows={1}
              defaultPageSize={3}
              getTrProps={getTrPropsFn}
              SubComponent={row => (
                <EditContact row={row} onChange={this.handleCreateContact} />
              )}
            />
            <br />
            <Text large bold>
              Inactive
            </Text>
            <br />
            <InactiveTable
              data={this.state.inactive_contacts}
              noDataText="No inactive contacts."
              columns={[
                {
                  Header: 'Name',
                  accessor: 'full_name'
                },
                {
                  Header: 'Position',
                  accessor: 'position'
                },
                {
                  Header: 'Email',
                  accessor: 'email'
                },
                {
                  Header: 'Phone',
                  accessor: 'phone_number'
                },
                {
                  Header: 'Phone #2',
                  accessor: 'phone_number_two'
                },
                {
                  Header: 'Acct. Admin',
                  accessor: 'is_account_admin',
                  Cell: props =>
                    props.value === true && (
                      <Icon
                        name="check-box"
                        style={{
                          cursor: 'pointer',
                          fontSize: '23px',
                          fontWeight: '800'
                        }}
                      />
                    )
                },
                {
                  Header: 'Edit',
                  accessor: 'id',
                  expander: true,
                  width: 65,
                  maxWidth: 65,
                  minWidth: 65,
                  Expander: ({ isExpanded }) => (
                    <ExpanderSpan
                      {...(isExpanded ? { open: true } : { closed: true })}
                    />
                  )
                }
              ]}
              showPagination={
                this.state.inactive_contacts.length > 3 ? true : false
              }
              resizable={false}
              sortable={false}
              showPaginationTop={false}
              showPaginationBottom={true}
              showPageSizeOptions={false}
              minRows={1}
              defaultPageSize={3}
              getTrProps={getTrPropsFn}
              SubComponent={row => (
                <EditContact row={row} onChange={this.handleCreateContact} />
              )}
            />
            {isMTI && (
              <>
                <br />
                <Text large bold>
                  Account Executive
                </Text>
                <br />
                <ExecTable
                  noShadow
                  data={[this.state.executives]}
                  noDataText="No Account Executive Listed."
                  columns={[
                    {
                      Header: 'Name',
                      accessor: 'name',
                      maxWidth: 350
                    },
                    {
                      Header: 'Email',
                      accessor: 'email'
                    }
                    // {
                    //   Header: 'Edit',
                    //   accessor: 'id',
                    //   expander: true,
                    //   width: 65,
                    //   maxWidth: 65,
                    //   minWidth: 65,
                    //   Expander: ({ isExpanded }) => (
                    //     <ExpanderSpan
                    //       {...(isExpanded ? { open: true } : { closed: true })}
                    //     />
                    //   )
                    // }
                  ]}
                  resizable={false}
                  sortable={false}
                  showPagination={false}
                  showPaginationTop={false}
                  showPaginationBottom={false}
                  showPageSizeOptions={false}
                  minRows={1}
                  defaultPageSize={3}
                  // getTrProps={getTrPropsFn}
                  // SubComponent={row => (
                  //   <ExecEditsForm row={row} onChange={this.handleCreateContact} />
                  // )}
                />
              </>
            )}
          </Wrapper>
        )}
      </div>
    );
  }
}
