import React from 'react';
import {
  Wrapper,
  Menu,
  Card,
  Icon,
  Header,
  Text,
  Input,
  Pagination,
  Content,
  Button,
  FlexBox,
  COLORS
} from 'components';
import styled from 'styled-components';
import { getUsers } from 'data-graphql';
import { formatDate, isMedia } from 'helpers';
import Spinner from 'react-spinkit';

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InputWrapper = styled.form`
  width: 300px;
  padding-bottom: 20px;
`;

export default class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      users: [],
      currentPage: 1,
      totalPages: 1,
      showingArchived: false,
      search: '',
      menuOpen: false,
      loading: false
    };
  }

  componentDidMount() {
    this.getComponentUsers();
  }

  getComponentUsers = async () => {
    this.setState({ loading: true });
    const VARS = {
      page: this.state.currentPage,
      search: this.state.search || undefined,
      admins: false,
      archived: this.state.showingArchived
    };
    const REQ_OBJECT = `{
      currentPage
      totalPages
      users {
        id
        username
        email
        phone_number
        first_name
        last_name
        account_type
        has_liked_facebook
        client_name
        is_locked
        is_archived
        user_since
      }
    }`;

    const RESULT = await getUsers(VARS, REQ_OBJECT);

    this.setState({
      users: RESULT.data.getUsers.users,
      currentPage: RESULT.data.getUsers.currentPage,
      totalPages: RESULT.data.getUsers.totalPages,
      loading: false
    });
  };

  toggleArchived = () => {
    this.setState(
      {
        showingArchived: !this.state.showingArchived,
        menuOpen: this.state.menuOpen === 'create' ? false : 'create'
      },
      this.getComponentUsers
    );
  };

  search = e => {
    e.preventDefault();

    this.setState(
      {
        currentPage: 1
      },
      this.getComponentUsers
    );
  };

  openMenu = () => {
    this.setState({
      menuOpen: this.state.menuOpen === 'create' ? false : 'create'
    });
  };

  handleSearchChange = e => {
    this.setState(
      {
        search: e.target.value
      },
      this.search(e)
    );
  };

  render() {
    const { menuOpen } = this.state;
    return (
      <div>
        <Header>
          <Header.Head bold huge>
            Users
          </Header.Head>
          {isMedia() === 'phone' ? (
            <>
              <Button darkRed menu onClick={this.openMenu}>
                {this.state.showingArchived ? 'Archived' : 'Active'}{' '}
                {menuOpen === 'create' ? '-' : '+'}
              </Button>
              <Content
                pose={menuOpen === 'create' ? 'open' : 'closed'}
                style={{
                  overflow: menuOpen === 'create' ? 'visible' : 'hidden'
                }}
              >
                <Menu>
                  <button
                    onClick={this.toggleArchived}
                    active={!this.state.showingArchived}
                  >
                    Active
                  </button>
                  <button
                    onClick={this.toggleArchived}
                    active={this.state.showingArchived}
                  >
                    Archived
                  </button>
                </Menu>
              </Content>
            </>
          ) : (
            <Menu>
              <button
                onClick={this.toggleArchived}
                active={!this.state.showingArchived}
              >
                Active
              </button>
              <button
                onClick={this.toggleArchived}
                active={this.state.showingArchived}
              >
                Archived
              </button>
            </Menu>
          )}
        </Header>
        <Wrapper>
          <InputWrapper onSubmit={this.search}>
            <Input
              placeholder="Search"
              onChange={this.handleSearchChange}
              value={this.state.search}
              icon={
                <Icon
                  name="search"
                  style={{ cursor: 'pointer' }}
                  onClick={this.search}
                />
              }
            />
          </InputWrapper>
          {this.state.loading ? (
            <FlexBox justify="space-evenly" style={{ alignContent: 'center' }}>
              <Spinner
                name="three-bounce"
                color={COLORS.darkRed}
                fadeIn="none"
              />
            </FlexBox>
          ) : (
            <>
              <CardWrapper>
                {this.state.users.map(user => (
                  <Card
                    clickable
                    is_locked={user.is_locked}
                    key={user.id}
                    onClick={() =>
                      this.props.history.push(`/admin/users/${user.id}`)
                    }
                  >
                    {user.is_locked && (
                      <Icon
                        name="lock-close"
                        style={{
                          position: 'absolute',
                          top: '10px',
                          left: '195px',
                          color: 'red',
                          fontSize: 20,
                          paddingBottom: 5,
                          fontWeight: 'bold'
                        }}
                      />
                    )}
                    <br />
                    <Icon
                      name="account-circle"
                      style={{ fontSize: 80, paddingBottom: 5 }}
                    />
                    <br />
                    <Text large bold>
                      {user.first_name} {user.last_name}
                    </Text>
                    <br />
                    <Text soft>{user.username}</Text>
                    <br />
                    <br />
                    <Text style={{ wordBreak: 'break-word' }}>
                      {user.email}
                    </Text>
                    <br />
                    <Text small soft>
                      Member since {formatDate(user.user_since, 'MMM D, YYYY')}
                    </Text>
                  </Card>
                ))}
              </CardWrapper>

              {this.state.totalPages > 1 && (
                <Pagination
                  onPageChange={currentPage => {
                    this.setState({ currentPage }, this.getComponentUsers);
                  }}
                  currentPage={this.state.currentPage}
                  totalPages={this.state.totalPages}
                />
              )}
            </>
          )}
        </Wrapper>
      </div>
    );
  }
}
