import styled from 'styled-components';
import COLORS from './colors';

export default styled.span` 
  ::after {
    content: '';
    position: relative;
    width: 0;
    height: 0;
    right: 0px;
    top: auto;
    vertical-align: middle;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${COLORS.darkerYellow};
    display: inline-block
    transform: ${props => {
      if (props.open) {
        return 'translateY(-50%) rotateX(180deg)';
      } else if (props.closed) {
        return 'translateY(-50%) rotateX(0deg)';
      } else {
        return 'translateY(-50%) rotateX(180deg)';
      }
    }}
`;
