import React from 'react';
import { Table, Text, Spacer } from 'components';
import styled from 'styled-components';
import { formatDate } from 'helpers';

const TableLeft = styled(Table.Cell)`
  text-align: left;
  font-weight: bold;
`;
const TableRight = styled(Table.Cell)`
  text-align: Right;
  text-transform: capitalize;
`;

export default class AboutOrgTable extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { organization } = this.props;
    return (
      <div>
        <Spacer size="5px" />
        <Table>
          <Table.Body>
            <Table.Row>
              <TableLeft> Account # </TableLeft>
              <TableRight> {organization.account_number} </TableRight>
            </Table.Row>
            <Table.Row>
              <TableLeft> Website </TableLeft>
              <TableRight style={{ textTransform: 'none' }}>
                {organization.website}
              </TableRight>
            </Table.Row>
            <Table.Row>
              <TableLeft>
                Address
                <Spacer size="5px" />
                <Text small style={{ textTransform: 'capitalize' }}>
                  {organization.address_type}
                </Text>
              </TableLeft>

              <TableRight>
                {organization.address}
                <Spacer size="5px" />
                {organization.state &&
                  `${organization.city}, ${organization.state} ${organization.zip}`}
              </TableRight>
            </Table.Row>
            {organization.notes && (
              <Table.Row>
                <TableLeft>Notes</TableLeft>
                <TableRight>{organization.notes}</TableRight>
              </Table.Row>
            )}
            <Table.Row>
              <TableLeft>Status</TableLeft>
              <TableRight>{organization.status}</TableRight>
            </Table.Row>
            <Table.Row>
              <TableLeft>Group</TableLeft>
              <TableRight>
                {organization.group && organization.group.split('_').join(' ')}
              </TableRight>
            </Table.Row>
            <Table.Row>
              <TableLeft>Pricing</TableLeft>
              <TableRight>{organization.pricing}</TableRight>
            </Table.Row>
            <Table.Row>
              <TableLeft>Username</TableLeft>
              <TableRight style={{ textTransform: 'lowercase' }}>
                {organization.username}
              </TableRight>
            </Table.Row>
            <Table.Row>
              <TableLeft>Member Since</TableLeft>
              <TableRight>
                {formatDate(organization.created_at, 'LL')}
              </TableRight>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}
