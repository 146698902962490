// import React from 'react'
import styled from 'styled-components';
import characterCodes from './character_codes.json';
import COLORS from './colors';

export default styled.span`
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: ${props =>
    props.green
      ? 'green'
      : props.red
      ? 'red'
      : props.darkBlue
      ? COLORS.darkBlue
      : 'inherit'};
  &:before {
    content: "${props => characterCodes[props.name]}";
  }
`;
