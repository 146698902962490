import { css } from 'styled-components';

const size = {
  phone: '479px',
  phablet: '599px',
  tablet: '769px',
  small_screen: '1024px',
  screen: '1200px'
};

export default Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media only screen and (max-width: ${size[label]}) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});
