import React from 'react';
import { Form, Spacer, Text } from 'components';
import styled from 'styled-components';

const StyledForm = styled(Form)`
  width: 90%;
  text-align: left;
  margin-top: -20px;
`;

export default class CheckSelect extends React.Component {
  constructor() {
    super();
    this.initialState = {};
    this.state = this.initialState;
  }
  UNSAFE_componentWillReceiveProps() {
    this.setState(this.initialState);
  }

  handleCheckboxChange = event => {
    this.setState({ [event.target.name]: event.target.checked });
    this.props.onCheck(event.target.name, event.target.checked);
  };

  render() {
    if (this.props.reset) return null;
    return (
      <StyledForm>
        <Spacer size="10px" />

        {this.props.options.map(option => (
          <Form.InputWrapper key={option.key} full>
            <label>
              <Form.CheckBox
                name={this.props.prefix + option.value}
                checked={this.state[this.props.prefix + option.value]}
                onChange={this.handleCheckboxChange}
              />
            </label>
            <Text>{option.label}</Text>
          </Form.InputWrapper>
        ))}

        <Spacer size="30px" />
      </StyledForm>
    );
  }
}
