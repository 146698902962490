import React from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  StyledTable,
  Spacer,
  Text,
  FormWrapper,
  Form,
  FlexBox,
  Icon
} from 'components';
import {
  removeAuthorization,
  getUserDevices,
  createEditAuthorization,
  updateAuthorization
} from 'data-graphql';
import { windowSize } from 'helpers';

export default class Documents extends React.Component {
  constructor() {
    super();
    this.state = {
      auths: [],
      grantAccess: true
    };
  }

  updateAuth = async auth => {
    const VARS = {
      input: {
        booking_id: this.props.booking_id,
        user_id: auth.user.id,
        device_id: auth.device.id,
        is_edit_rehearsal: false
      }
    };

    const REQ_OBJECT = `{
      code
      message
      success
      authorization {
        is_edit_rehearsal
      }
    }`;
    const RESULT = await updateAuthorization(VARS, REQ_OBJECT);
    if (RESULT.data)
      toast.success(`${RESULT.data.updateAuthorization.message}`);

    this.props.onChange();
  };

  deauthorize = async auth => {
    const VARS = {
      bookingId: this.props.booking_id,
      userId: auth.user.id,
      deviceId: auth.device.id
    };
    const REQ_OBJECT = `{
      code
      message
      success
    }`;

    const RESULT = await removeAuthorization(VARS, REQ_OBJECT);

    if (RESULT.data)
      toast.success(`${RESULT.data.removeAuthorization.message}`);

    this.props.onChange();
  };

  componentDidMount() {}

  getUserDevices = async e => {
    await this.handleChange(e);
    const VARS = {
      username: this.state.username
    };
    const REQ_OBJECT = `{
      user {
        id
        username
      }
      devices {
        id
        description
        type
      }
    }`;

    const RESULT = await getUserDevices(VARS, REQ_OBJECT);

    const { getUserDevicesByUsername } = RESULT.data;
    if (RESULT.data.getUserDevicesByUsername) {
      this.setState({
        devices: getUserDevicesByUsername.devices,
        user_to_auth: getUserDevicesByUsername.user.id
      });
    } else {
      this.setState({
        devices: [],
        user_to_auth: undefined
      });
    }
  };

  authorizeDevice = async () => {
    if (!this.state.user_to_auth || !this.state.device) {
      return toast.error('Please select a user and a device to authorize.', {
        autoclose: false
      });
    }

    const VARS = {
      input: {
        user_id: this.state.user_to_auth,
        device_id: this.state.device.id,
        rehearsal_edit_code: this.props.rehearsal_edit_code
      }
    };

    const REQ_OBJECT = `{
      message
      success
    }`;

    const RESULT = await createEditAuthorization(VARS, REQ_OBJECT);
    if (RESULT.data && RESULT.data.success) {
      toast.success(RESULT.data.message);
      this.props.onChange();
    }
  };

  closeDevices = () => {
    this.setState({ menu: 'Production Access', devicesOpen: false });
  };

  handleDeviceChange = device => {
    this.setState({ device });
  };

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value });
  };

  render() {
    const { auths } = this.props;
    const paginateAuths = auths.length > 10 ? true : false;
    const style =
      windowSize === 'phone'
        ? { fontSize: '0.85em', margin: '0px 10px' }
        : { margin: '0px 10px' };
    return (
      <div style={style}>
        <Text large red>
          Rehearsal Track Editing in Pro App{' '}
        </Text>
        <Spacer size="20px" />
        {this.state.grantAccess && (
          <FormWrapper>
            <FlexBox
              justify="flex-end"
              style={{ marginLeft: '20px', marginTop: '-20px' }}
            >
              <Icon
                name="close"
                style={{ cursor: 'pointer' }}
                onClick={this.closeDevices}
              />
            </FlexBox>
            <Form.InputWrapper third>
              <Form.Label>
                {this.state.user_to_auth
                  ? 'Personal Username: valid!'
                  : 'Personal Username:'}
              </Form.Label>
              <Form.Input
                type="text"
                placeholder="Personal Username"
                value={this.state.username}
                name="username"
                onChange={this.getUserDevices}
                // style={usernameStyle}
              />
            </Form.InputWrapper>
            {this.state.devices && this.state.devices.length > 0 && (
              <Form.InputWrapper third>
                <Form.Label>Select A Device</Form.Label>
                <Form.StyledSelect
                  value={this.state.device}
                  onChange={this.handleDeviceChange}
                  options={this.state.devices.filter(
                    device => device.type === 'iPad'
                  )}
                  isSearchable={true}
                  getOptionLabel={option => option.description}
                  getOptionValue={option => option.id}
                  placeholder="Start typing..."
                  styles={Form.CustomStyles}
                />
              </Form.InputWrapper>
            )}
            {(!this.state.devices || this.state.devices.length < 1) && (
              <Form.InputWrapper third>
                <Form.Label>This user has no available devices</Form.Label>
              </Form.InputWrapper>
            )}
            <Form.InputWrapper third>
              <Form.Label>Pro app Code</Form.Label>
              <Form.Input
                type="text"
                placeholder="Pro app Code"
                value={this.props.rehearsal_edit_code}
                name="rehearsal_edit_code"
                // onChange={this.getUserDevices}
                // style={usernameStyle}
              />
            </Form.InputWrapper>
            <Spacer size="10px" />
            <Button
              disabled={!this.state.devices || this.state.devices.length < 1}
              noHover={!this.state.devices || this.state.devices.length < 1}
              onClick={
                !this.state.devices || this.state.devices.length < 1
                  ? null
                  : this.authorizeDevice
              }
            >
              Allow Rehearsal Editing
            </Button>
          </FormWrapper>
        )}
        <Spacer size="20px" />
        <StyledTable
          data={auths}
          noDataText="No current Pro app access."
          columns={[
            {
              Header: 'Personal Username',
              accessor: 'user.username'
            },
            {
              Header: 'Device',
              accessor: 'device.description'
            },
            {
              Header: 'Actions',
              Cell: row => (
                <>
                  {this.props.production_access && (
                    <Button
                      style={style}
                      onClick={() => {
                        this.updateAuth(row.original);
                      }}
                    >
                      Grant Production Access
                    </Button>
                  )}
                  <Button
                    style={style}
                    onClick={() => {
                      this.deauthorize(row.original);
                    }}
                  >
                    Revoke Access
                  </Button>
                </>
              )
            }
          ]}
          resizable={false}
          sortable={false}
          showPagination={paginateAuths}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          defaultPageSize={10}
        />
      </div>
    );
  }
}
