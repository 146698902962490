import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  50% {
    color: transparent;
  }
`;

// const loaderDots = styled.div`
//   .loader_dot {
//     animation: 1s ${blink} infinite;
//   }
//   .loader_dot:nth-child(2) {
//     animation-delay: 250ms;
//   }
//   .loader_dot:nth-child(3) {
//     animation-delay: 500ms;
//   }
// `;

const dotOne = styled.span`
  animation: 1s ${blink} infinite;
  color: red;
`;
const dotTwo = styled.span`
  animation: 1.25s ${blink} infinite;
`;
const dotThree = styled.span`
  animation: 1.5s ${blink} infinite;
`;

// const Loader = () => (
//   <loaderDots>
//     <dotOne>.</dotOne>
//     <dotTwo>.</dotTwo>
//     <dotThree>.</dotThree>
//   </loaderDots>
// );
export { dotOne, dotTwo, dotThree };
