import React from 'react';

import { Icon, Table, FileLabel, Spacer } from 'components';
import axios from 'axios';
import { getShow } from 'data-graphql';
import { toast } from 'react-toastify';

export default class Documents extends React.Component {
  constructor() {
    super();
    this.state = {
      show: undefined,
      tracks: [],
      editDocsOpen: false
    };
  }

  handleCuesSelectedFile = event => {
    this.setState(
      {
        selectedFile: event.target.files[0]
      },
      this.handleCuesUpload
    );
  };

  handleEditsSelectedFile = event => {
    this.setState(
      {
        selectedFile: event.target.files[0]
      },
      this.handleEditsUpload
    );
  };

  handleCuesUpload = async () => {
    const VARS = {
      id: this.props.show.id
    };
    const REQ_OBJECT = `{
      cues_doc_upload_url(filename: "${this.state.selectedFile.name}")
    }`;

    const RESULT = await getShow(VARS, REQ_OBJECT);
    const signedURL = RESULT.data.getShow.cues_doc_upload_url;
    this.uploadDoc(signedURL);
  };

  handleEditsUpload = async () => {
    const VARS = {
      id: this.props.show.id
    };
    const REQ_OBJECT = `{
      edits_doc_upload_url(filename: "${this.state.selectedFile.name}")
    }`;

    const RESULT = await getShow(VARS, REQ_OBJECT);
    const signedURL = RESULT.data.getShow.edits_doc_upload_url;
    this.uploadDoc(signedURL);
  };

  uploadDoc = async signedUrl => {
    const res = await axios.put(signedUrl, this.state.selectedFile, {
      headers: {
        'Content-Type': this.state.selectedFile.type
      }
    });
    if (res.status === 200) {
      let check = setInterval(this.checkProgress, 1500);
      this.setState({ check: check });
      setTimeout(() => {
        clearInterval(this.state.check);
      }, 60000);
    }
  };

  checkProgress = async () => {
    const VARS = {
      id: this.props.show.id
    };
    const REQ_OBJECT = `{
      cue_doc_url
      edits_doc_url
    }`;

    const RESULT = await getShow(VARS, REQ_OBJECT);
    const { edits_doc_url, cue_doc_url } = RESULT.data.getShow;
    if (
      edits_doc_url !== this.props.show.edits_doc_url ||
      cue_doc_url !== this.props.show.cue_doc_url
    ) {
      toast.success(`Document Uploaded`);
      clearInterval(this.state.check);
      this.props.onChange('Documents');
    }
  };

  render() {
    const { show } = this.props;

    return (
      <div>
        <div>
          <FileLabel htmlFor="cueInput" style={{ marginRight: '24px' }}>
            Upload Cues
            <input
              type="file"
              id="cueInput"
              onChange={this.handleCuesSelectedFile}
              style={{
                opacity: '0',
                position: 'absolute',
                pointerEvents: 'none'
              }}
            />
          </FileLabel>
          <FileLabel htmlFor="editInput">
            Upload Edits
            <input
              type="file"
              id="editInput"
              onChange={this.handleEditsSelectedFile}
              style={{
                opacity: '0',
                position: 'absolute',
                pointerEvents: 'none'
              }}
            />
          </FileLabel>
        </div>

        <Spacer size="20px" />
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell>Document</Table.HeadCell>
              <Table.HeadCell>Download</Table.HeadCell>
            </Table.Row>
          </Table.Head>
          {show && (
            <Table.Body>
              <Table.Row>
                <Table.Cell>{show.name} Edits</Table.Cell>
                <Table.Cell>
                  <a
                    href={show.edits_doc_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      name="download-harddrive"
                      style={{
                        color: 'black',
                        cursor: 'pointer',
                        fontSize: '23px',
                        fontWeight: '800'
                      }}
                    />
                  </a>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{show.name} Cues</Table.Cell>
                <Table.Cell>
                  <a
                    href={show.cue_doc_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      name="download-harddrive"
                      style={{
                        color: 'black',
                        cursor: 'pointer',
                        fontSize: '23px',
                        fontWeight: '800'
                      }}
                    />
                  </a>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
        </Table>
      </div>
    );
  }
}
