import React, { Component } from 'react';
import styled from 'styled-components';
import COLORS from '../../colors.js';

const Row = styled.tr`
  border: solid 2px ${COLORS.lightGrey};
  &:hover td {
    background: ${COLORS.lighterYellow};
  }
`;

export default class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovering: false
    };

    this.handleHoverChange = this.handleHoverChange.bind(this);
  }

  handleHoverChange(hovering) {
    this.setState({ hovering: hovering });
  }

  render() {
    const { children } = this.props;
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { hovering: this.state.hovering })
    );

    return (
      <Row
        onMouseOver={() => this.handleHoverChange(true)}
        onMouseOut={() => this.handleHoverChange(false)}
      >
        {childrenWithProps}
      </Row>
    );
  }
}
