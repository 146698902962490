import React from 'react'
import styled from 'styled-components'
import { Wrapper, Button, Spacer, StyledTable, Text, COLORS } from 'components'
import { getBookingForCode, getTrackUrl } from 'data-graphql'
import { isMTI } from 'helpers'
import LogoSrc from 'assets/logo.svg'
import mtiLogo from 'assets//mtiLogoBottomAligned.png'

const Logo = styled.img`
  height: 100%;
  padding: 20px;
  margin: auto;
`

const Header = styled.div`
  height: 130px;
  width: 100%;
  background-color: ${isMTI ? COLORS.darkBlue : COLORS.dark};
  text-align: center;
  position: absolute;
  z-index: 10;
`

export default class Documents extends React.Component {
  constructor () {
    super()
    this.state = {
      tracks: [],
      show: '',
      organization: '',
      newUrl: false
    }
  }

  componentDidMount () {
    this.getTracks()
  }

  async getTracks () {
    const VARS = {
      rehearsalCode: this.props.match.params.id
    }
    const REQ_OBJECT = `{
      rehearsal_tracks {
        cue_number
        track_name
        time
        time_formatted
        location
        track_id
      }
      show {
        name
      }
      organization {
        name
      }
    }`

    const RESULT = await getBookingForCode(VARS, REQ_OBJECT)
    if (RESULT.data) {
      this.setState({
        tracks: RESULT.data.getBookingForCode.rehearsal_tracks.sort((a, b) =>
          a.cue_number > b.cue_number ? 1 : b.cue_number > a.cue_number ? -1 : 0
        ),
        show: RESULT.data.getBookingForCode.show.name,
        organization: RESULT.data.getBookingForCode.organization.name
      })
    }
  }

  goBack = () => {
    this.props.history.push(`/rehearsal/${this.props.match.params.id}`)
  }

  preventMenu = e => {
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  getTrackUrl = async row => {
    if (!row) {
      return this.state.tracks.map(track => {
        track.location = null
        track.play = false
        this.forceUpdate()
      })
    }

    const audio = new Audio(row.original.location)
    const playPromise = audio.play()
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          audio.pause()
        })
        .catch(error => {
          console.log(error)
        })
    }

    const VARS = {
      track_id: row.original.track_id
    }
    const REQ_OBJECT = `{
      success
      code
      track {
        track_id
        location
      }
    }`

    const RESULT = await getTrackUrl(VARS, REQ_OBJECT)

    if (RESULT.data) {
      this.state.tracks.map(track => {
        if (
          track.track_id ===
          RESULT.data.getSignedUrlforTrackPlayback.track.track_id
        ) {
          track.location =
            RESULT.data.getSignedUrlforTrackPlayback.track.location
          track.play = true
          return track
        } else {
          track.play = false
        }
      })
      this.forceUpdate()

      const player = document.getElementById(row.original.track_id)

      player.play()
      this.updateBar()
    }
  }

  updateBar () {
    setTimeout(() => {
      const audio = document.querySelector('audio')
      const bar = document.querySelector('#progressbar div')
      const time = document.querySelector('#progresstime')
      if (!audio || !bar) return
      const position = audio.currentTime / audio.duration
      bar.style.transform = `scaleX(${position})`
      if (audio.duration) {
        const current = [
          Math.floor(audio.currentTime / 60),
          String(Math.floor(audio.currentTime % 60)).padStart(2, '0')
        ].join(':')
        const total = [
          Math.floor(audio.duration / 60),
          String(Math.floor(audio.duration % 60)).padStart(2, '0')
        ].join(':')
        time.innerText = `${current} / ${total}`
      } else {
        time.innerText = '-:-- / -:--'
      }
      this.updateBar()
    }, 100) // keep calling this while audio exists
  }

  pause () {
    const audio = document.querySelector('audio')
    const label = document.querySelector('#pause')
    if (audio.paused) {
      audio.play()
      label.innerText = '||'
      return
    }
    audio.pause()
    label.innerText = '▶'
  }

  render () {
    const { show, organization } = this.state

    return (
      <div style={{ maxHeight: '100vh', overflowY: 'auto' }}>
        <Header>
          <Logo src={isMTI ? mtiLogo : LogoSrc}/>
          <Button
            style={{ position: 'absolute', top: '20px', right: '20px' }}
            onClick={this.goBack}
          >
            Logout
          </Button>
        </Header>
        <Wrapper style={{ marginTop: '130px' }}>
          <Spacer size="20px"/>
          <Text huge red>
            {show}
          </Text>
          <Spacer size="20px"/>
          <Text large red>
            {organization}
          </Text>
          <Spacer size="20px"/>
          <StyledTable
            data={this.state.tracks}
            noDataText="No tracks found."
            columns={[
              {
                Header: 'Name',
                accessor: 'cue_number',
                maxWidth: 100,
                Cell: row => (
                  <div style={{ padding: '10px 5px', textAlign: 'left' }}>
                    <Text large>{row.value}</Text>
                  </div>
                )
              },
              {
                accessor: 'track_name',
                Cell: row => (
                  <div style={{ padding: '10px 5px', textAlign: 'left' }}>
                    <Text large>{row.value}</Text>
                  </div>
                )
              },
              {
                accessor: 'location',
                maxWidth: 350,
                Cell: row => {
                  if (!row.original.play) {
                    return (
                      <div>
                        <Button
                          onClick={() => this.getTrackUrl(row)}>
                          <div style={{ width: '20px', lineHeight: '25px' }}>▶</div>
                        </Button>
                      </div>
                    )
                  }
                  return (
                    <div>
                      <Button black onClick={() => this.getTrackUrl(null)}>
                        <div style={{ width: 20, lineHeight: '25px' }}>◼</div>
                      </Button>
                      <Button style={{ marginLeft: 8 }} onClick={this.pause}>
                        <div style={{ width: 20, lineHeight: '25px' }} id="pause">||</div>
                      </Button>
                      <div id="progressbar"
                           onClick={(e) => {
                             const x = e.pageX
                             const { left } = e.currentTarget.getBoundingClientRect()
                             const percent = (x - left) / 300
                             const audio = document.querySelector('audio')
                             audio.currentTime = audio.duration * percent
                             this.updateBar()
                           }}
                           style={{
                             width: '300px',
                             height: '20px',
                             display: 'inline-block',
                             background: 'rgba(0, 0, 0, .2)',
                             marginTop: '8px',
                           }}>
                        <div style={{
                          width: '300px',
                          height: '100%',
                          background: 'rgba(0, 0, 0, .4)',
                          transformOrigin: 'left',
                          transform: 'scaleX(0)',
                          transition: 'all .3s',
                        }}></div>
                      </div>
                      <div id="progresstime"></div>


                      <audio
                        style={{ display: 'none' }}
                        onContextMenu={this.preventMenu}
                        className="react-audio-player"
                        title={row.original.track_name}
                        id={row.original.track_id}
                        src={row.value}
                        autoPlay={false}
                        controlsList="nodownload"
                        preload="metadata"
                        onPlay={() => {}}
                        onEnded={() => this.getTrackUrl(null)}
                        onError={e => {
                          console.log('Play error', e)
                          this.getTrackUrl(row)
                        }}
                        controls
                      />

                      {/* <ReactAudioPlayer
                        title={row.original.track_name}
                        src={row.value}
                        autoPlay={false}
                        controlsList="nodownload"
                        preload="metadata"
                        controls
                      /> */}
                    </div>
                  )
                }
              }
            ]}
            resizable={false}
            sortable={false}
            showPagination={false}
            showPaginationTop={false}
            showPaginationBottom={true}
            showPageSizeOptions={false}
            minRows={1}
            defaultPageSize={1000}
          />
        </Wrapper>
      </div>
    )
  }
}
