import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
  updateBooking,
  getOrganizations,
  createOrganization,
  isUsernameAvailable,
  http,
  resendEmail,
  getAllQuotableShows,
  createContact,
  updateContact,
  getOrganization
} from 'data-graphql';
import {
  Text,
  Button,
  Form,
  Spacer,
  Icon,
  FlexBox,
  Modal,
  SendEmailForm
} from 'components';
import { yes_no_options, capitalize, formatPhone, estimateRate } from 'helpers';
import { toast } from 'react-toastify';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { ClipLoader } from 'react-spinners';
import ReactTooltip from 'react-tooltip';

const StyledButton = styled(Button)`
  margin: auto 5px;
  position: relative;
  bottom: 4px;
`;

const UserNameIcons = styled.div`
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: -45px;
  z-index: 1;
`;

export default class MoreInfo extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      bookingState: undefined,
      quote_city: '',
      quote_state: '',
      quote_country: '',
      quote_first_name: '',
      quote_last_name: '',
      quote_phone_number: '',
      quote_email: '',
      quote_avg_price: '',
      quote_avg_attend: '',
      quote_performances: '',
      quote_color: '',
      quote_organization_name: '',
      notes: '',
      opening: '',
      expiration: '',
      rate: '',
      educational: undefined,
      is_production_access: undefined,
      show: undefined,
      inputValue: '',
      organization: undefined,
      username: '',
      createOrg: false,
      saving: false,
      loading: false,
      username_available: true,
      is_contract_ready: undefined,
      is_license_ready: undefined,
      is_payment_ready: undefined,
      email_options: false
    };
    this.state = this.initialState;
  }

  componentDidMount() {
    this.setInitialValues();
    this.getShows();
  }

  close = () => {
    this.setState(this.initialState);
    this.props.onClose();
  };

  submitBooking = async () => {
    const payload = {};

    if (this.state.quote_first_name)
      payload.quote_first_name = this.state.quote_first_name;
    if (this.state.quote_last_name)
      payload.quote_last_name = this.state.quote_last_name;
    if (this.state.quote_city) payload.quote_city = this.state.quote_city;
    if (this.state.quote_state) payload.quote_state = this.state.quote_state;
    if (this.state.quote_country)
      payload.quote_country = this.state.quote_country;
    if (this.state.quote_email) payload.quote_email = this.state.quote_email;
    if (this.state.quote_email) payload.quote_email = this.state.quote_email;
    if (this.state.quote_phone_number)
      payload.quote_phone_number = this.state.quote_phone_number;
    if (this.state.show.id) payload.show_id = this.state.show.id;
    if (this.state.opening) payload.opening = this.state.opening;
    if (this.state.expiration) payload.expiration = this.state.expiration;
    if (this.state.rehearsal_active_date)
      payload.rehearsal_active_date = this.state.rehearsal_active_date;
    if (this.state.rehearsal_active_date)
      payload.rehearsal_active_date = this.state.rehearsal_active_date;
    // if (this.state.production_active_date)
    payload.production_active_date = this.state.production_active_date;
    if (this.state.quote_avg_price)
      payload.quote_avg_price = parseFloat(this.state.quote_avg_price);
    if (this.state.quote_avg_attend)
      payload.quote_avg_attend = parseFloat(this.state.quote_avg_attend);
    if (this.state.quote_performances)
      payload.quote_performances = parseFloat(this.state.quote_performances);
    if (this.state.is_production_access)
      payload.is_production_access = this.state.is_production_access.value;
    if (this.state.educational)
      payload.educational = this.state.educational.value;
    if (this.state.rate) payload.rate = parseFloat(this.state.rate);
    if (this.state.notes) payload.notes = this.state.notes;
    if (this.state.is_contract_ready)
      payload.is_contract_ready = this.state.is_contract_ready.value;
    if (this.state.is_license_ready)
      payload.is_license_ready = this.state.is_license_ready.value;
    if (this.state.is_payment_ready)
      payload.is_payment_ready = this.state.is_payment_ready.value;

    payload.state = this.state.authorize
      ? 'booked'
      : this.props.row.original.state;
    const VARS = {
      id: this.props.row.original.id,
      input: payload
    };
    const REQ_OBJECT = `{
      code,
      success,
      message,
      booking {
        id
        show {
          name
        }
        opening
        expiration
        rehearsal_active_date
        production_active_date
        rate
        is_production_access
        educational
        is_contract_ready
        is_license_ready
        is_payment_ready
        edits_status
        notes
        rate
        state
        is_downloaded
      }
    }`;

    this.setState(prevState => ({
      saving: !prevState.saving
    }));

    const RESULT = await updateBooking(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.close();
    } else {
      this.setState(prevState => ({
        saving: !prevState.saving
      }));
    }
  };

  getShows = async () => {
    const REQ_OBJECT = `{
      name
      id
    }`;

    const RESULT = await getAllQuotableShows(REQ_OBJECT);
    this.setState({ shows: RESULT.data.getAllQuotableShows });
  };

  bookQuote = async () => {
    const confirm = window.confirm(
      'Are you sure you want to authorize this quote?'
    );
    confirm && this.setState({ authorize: true }, this.submitBooking);
  };

  createOrgFromQuote = async () => {
    if (!this.state.username) {
      toast.error('Username is required.', { autoclose: false });
    }

    if (this.state.password && this.state.password.length < 8) {
      return toast.error('Password must be at least 8 characters long.', {
        autoclose: false
      });
    }
    const payload = {
      name: this.state.quote_organization_name,
      username: this.state.username,
      first_name: this.state.quote_first_name,
      last_name: this.state.quote_last_name,
      email: this.state.quote_email,
      notes: this.state.notes || '',
      password: this.state.password || undefined
    };
    if (this.state.quote_city) payload.city = this.state.quote_city;
    if (this.state.quote_state) payload.state = this.state.quote_state;
    if (this.state.quote_country) payload.country = this.state.quote_country;
    if (this.state.quote_address) payload.address = this.state.quote_address;

    const VARS = {
      input: payload
    };

    const REQ_OBJECT = `{
      code
      success
      message
      organization {
        id
        account_number
        name
      }
    }`;

    const RESULT = await createOrganization(VARS, REQ_OBJECT);
    if (RESULT.data.success && RESULT.data.message) {
      toast.success(RESULT.data.message);
      const { organization } = RESULT.data.createOrganization;
      organization.value = organization.id;
      this.setState(
        { organizationToAssign: organization },
        await this.assignOrg
      );
    }
  };

  searchOrganizations = async inputValue => {
    const VARS = {
      search: inputValue,
      page: 1
    };
    const REQ_OBJECT = `{
      organizations{
        name
        id
        account_number
      }
     
    }`;
    const RESULT = await getOrganizations(VARS, REQ_OBJECT);
    const options = RESULT.data.getOrganizations.organizations.map(item => {
      return {
        value: item.id,
        label: item.name,
        account_number: item.account_number
      };
    });
    return options;
  };

  checkUsername = async () => {
    const VARS = {
      username: this.state.username
    };
    const REQ_OBJECT = `{
      boolean
    }`;

    this.setState({ loading: true });

    const RESULT = await isUsernameAvailable(VARS, REQ_OBJECT);
    this.setState({
      username_available: RESULT.data.isUsernameAvailable,
      loading: false
    });
  };

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value }, this.estimateNewRate);
  };

  handleCheckboxChange = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleAssignOrgInput = newValue => {
    const inputValue = newValue;
    this.setState({ inputValue });

    return inputValue;
  };

  handleBookingChange = bookingState => {
    this.setState({ bookingState });
  };

  handleProdTracksChange = prodSelect => {
    this.setState(
      {
        is_production_access: prodSelect
      },
      this.estimateNewRate
    );
  };

  handleShowChange = show => {
    this.setState({ show });
  };

  handleContractChange = change => {
    this.setState({ is_contract_ready: change });
  };
  handleLicenseChange = change => {
    this.setState({ is_license_ready: change });
  };
  handlePaymentChange = change => {
    this.setState({ is_payment_ready: change });
  };

  estimateNewRate = () => {
    const rate = estimateRate(
      this.state.is_production_access.value,
      this.state.quote_avg_price,
      this.state.quote_avg_attend,
      this.state.quote_performances,
      this.state.educational.value
    );
    this.setState({ rate: rate });
  };

  handleEducationalChange = editSelect => {
    this.setState(
      {
        educational: editSelect
      },
      this.estimateNewRate
    );
  };

  handleRateChange = e => {
    const value = e.target.value;
    this.setState({ rate: value });
  };

  handleOrgChange = organization => {
    this.setState({ organizationToAssign: organization });
  };

  handleNotesChange = notes => {
    this.setState({ notes: notes });
  };

  handleDateChange = (selectedDay, modifiers, dayPickerInput) => {
    let returnObj = {};

    returnObj[dayPickerInput.props.name] = selectedDay
      ? moment(selectedDay).format('YYYY-MM-DD')
      : null;

    const closingSetting =
      dayPickerInput.props.name === 'opening'
        ? moment(returnObj.opening)
        : null;
    closingSetting &&
      this.setState({ expirationDate: new Date(closingSetting) });

    this.setState(returnObj);
  };

  createNewOrgContact = async () => {
    const VARS = { id: this.state.organizationToAssign.value };
    const REQ_OBJECT = `{
        id
        name
        active_contacts: contacts(active: true) {
          id
          full_name
          first_name
          last_name
          email
          position
          phone_number
          phone_number_two
          is_account_admin
          active
        }
        
      }`;

    const {
      data: {
        getOrganization: { active_contacts }
      }
    } = await getOrganization(VARS, REQ_OBJECT);
    const contactMatch = active_contacts?.find(
      contact => contact.email === this.state.quote_email
    );

    const contactPayload = {
      first_name: this.state.quote_first_name,
      last_name: this.state.quote_last_name,
      email: this.state.quote_email,
      phone_number: this.state.quote_phone_number,
      is_account_admin: true
    };

    if (!contactMatch) {
      contactPayload.organization_id = this.state.organizationToAssign.value;
      contactPayload.position = 'New Organization Contact';
      const VARS = { input: contactPayload };
      const REQ_OBJECT = `{
      code
      success
      message
    }`;

      const RESULT = await createContact(VARS, REQ_OBJECT);

      if (RESULT.data && RESULT.data.success) {
        toast.success(`${RESULT.data.message}`);
        // this.close();
      }
    } else if (!contactMatch.is_account_admin) {
      const VARS = { id: contactMatch.id, input: contactPayload };
      const REQ_OBJECT = `{
      code
      success
      message
    }`;

      const RESULT = await updateContact(VARS, REQ_OBJECT);

      if (RESULT.data && RESULT.data.success) {
        toast.success(`${RESULT.data.message}`);
        this.close();
      }
    } else if (contactMatch.is_account_admin) {
      toast.success(`The quote contact is already the account admin.`);
      // this.close();
    }
  };

  assignOrg = async () => {
    this.createNewOrgContact();
    const VARS = {
      id: this.props.row.original.id,
      input: {
        organization_id: this.state.organizationToAssign.value,
        state: this.state.bookingState
          ? this.state.bookingState.value
          : this.props.row.original.state
      }
    };

    const REQ_OBJECT = `{
      code
      success
      message
      booking {
        organization {
          name
          account_number
          id
        }
      }
    }`;

    const RESULT = await updateBooking(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.props.onClose();
      this.setState({
        organization: RESULT.data.updateBooking.booking.organization
      });
    }
  };

  setInitialValues = () => {
    const booking = this.props.row.original;
    // eslint-disable-next-line
    for (const key in booking) {
      if (booking[key] === false) {
        booking[key] = false;
      } else if (!booking[key]) {
        if (key.includes('active_date')) {
          booking[key] = null;
        } else {
          booking[key] = '';
        }
      }
    }
    this.setState(booking, this.setSelectValues);
  };

  setSelectValues = () => {
    const prodTracks = yes_no_options.filter(
      item => item.value === this.state.is_production_access
    )[0];
    const edu = yes_no_options.filter(
      item => item.value === this.state.educational
    )[0];
    const contract = yes_no_options.filter(
      item => item.value === this.state.is_contract_ready
    )[0];
    const license = yes_no_options.filter(
      item => item.value === this.state.is_license_ready
    )[0];
    const payment = yes_no_options.filter(
      item => item.value === this.state.is_payment_ready
    )[0];

    this.setState({
      is_production_access: prodTracks,
      educational: edu,
      is_contract_ready: contract,
      is_license_ready: license,
      is_payment_ready: payment
    });
  };

  archiveQuote = async () => {
    const confirm = window.confirm(
      'Are you sure you want to delete this Quote?'
    );

    if (!confirm) return false;

    const VARS = {
      id: this.props.row.original.id,
      input: {
        state: 'archived'
      }
    };

    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    const RESULT = await updateBooking(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.message) {
      toast.success(`${RESULT.data.message}`);
      this.props.onClose();
    }
  };

  onTabChange = index => {
    if (index === 1) {
      this.setState({ organizationToAssign: '' });
    } else {
      this.setState({ username: '' });
    }
  };

  getHistory = () => {
    let { id } = this.state;

    http()
      .get(`/reports/booking-history/${id}`)
      .then(res => {
        if (res) {
          this.downloadCsvFile(res);
        }
      })
      .catch(err => {
        toast.error(err);
      });
  };

  downloadCsvFile = csvText => {
    const element = document.createElement('a');
    const file = new Blob([csvText], { type: 'text/csv' });
    element.href = URL.createObjectURL(file);
    element.download = `data_export_${moment().format('YYYY-MM-DD')}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    toast.success('Downloading report!');
  };

  resendEmail = () => {
    this.setState({ email_options: true });
  };

  closeEmailOptions = () => {
    this.setState({ email_options: false });
  };

  sendEmail = async e => {
    e.stopPropagation();

    const id = e.target.dataset.value;

    if (!id) {
      return toast.error('Select a contact.', { autoclose: false });
    }
    const VARS = { id: id, refresh_contacts: false };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;
    const RESULT = await resendEmail(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.close();
    }
  };

  render() {
    if (!this.state.show) return null;
    if (!this.state.shows) return null;
    return (
      <div
        style={{
          margin: '-12px 10px 15px 10px',
          textAlign: 'left',
          backgroundColor: 'white',
          padding: '20px',
          boxShadow:
            'rgba(0, 0, 0, 0.21) 0px 5px 6px -3px, rgba(0, 0, 0, 0.21) 5px 1px 7px -5px, rgba(0, 0, 0, 0.21) -5px 0px 7px -5px'
        }}
      >
        <Modal
          open={this.state.email_options}
          onClose={this.closeEmailOptions}
          closeOnOverlayClick
        >
          <div style={{ padding: '20px' }}>
            <div style={{ textAlign: 'center' }}>
              <Text huge red>
                Select an Email to Send
              </Text>
            </div>
            <Spacer size="10px" />
            <hr />
            <Spacer size="20px" />
            <SendEmailForm
              organization={this.state.organization}
              booking={this.props.row.original}
            />

            <Spacer size="20px" />
            <hr />
            <Spacer size="20px" />
            <Text>Emails that have been sent:</Text>
            <Spacer size="10px" />
            <FlexBox direction="column" style={{ flexWrap: 'wrap' }}>
              {this.state.emails_sent
                .sort((a, b) =>
                  a.date_sent > b.date_sent
                    ? 1
                    : b.date_sent > a.date_sent
                    ? -1
                    : 0
                )
                .map(email => (
                  <Button
                    key={email.id}
                    style={{ marginBottom: '10px' }}
                    data-value={email.id}
                    onClick={this.sendEmail}
                    large
                  >
                    <Text
                      data-value={email.id}
                      onClick={this.sendEmail}
                      large
                      white
                    >
                      {capitalize(email.template.replace(/_/gi, ' '))}
                    </Text>
                  </Button>
                ))}
              {this.state.emails_sent.length === 0 && (
                <Text large black>
                  No emails have been sent.
                </Text>
              )}
            </FlexBox>
          </div>
        </Modal>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}
        >
          <Text red large style={{ marginRight: 'auto' }}>
            {this.state.organization
              ? this.state.organization.name
              : this.state.quote_organization_name
              ? capitalize(this.state.quote_organization_name)
              : ''}
          </Text>

          {this.props.row.original.state === 'approved' && (
            <>
              {/* <div>
                <Text red>Confirm Details before Authorizing</Text>
              </div> */}
              <StyledButton
                negative
                onClick={this.bookQuote}
                data-tip="Confirm Details before Authorizing"
              >
                {this.state.saving ? 'Authorizing...' : 'Authorize'}
              </StyledButton>
              <ReactTooltip
                place="top"
                type="error"
                effect="solid"
                multiline={true}
              />
            </>
          )}

          <StyledButton onClick={this.submitBooking}>
            {this.state.saving ? 'Saving...' : 'Save Edits'}
          </StyledButton>
          <StyledButton black onClick={this.getHistory}>
            History
          </StyledButton>
          <StyledButton black onClick={this.resendEmail}>
            Resend Email
          </StyledButton>
          <StyledButton negative onClick={this.archiveQuote}>
            <Icon name="bin" style={{ fontWeight: '600' }} />
          </StyledButton>
        </div>

        <Spacer size="10px" />
        {this.state.organization && (
          <div>
            <Text>Account #: {this.state.organization.account_number}</Text>
          </div>
        )}
        {!this.state.organization && (
          <>
            <Tabs onSelect={this.onTabChange}>
              <TabList>
                <Tab>Assign to Existing Org</Tab>
                <Tab>Create New Org From Quote</Tab>
              </TabList>

              <TabPanel>
                <Form.InputWrapper full>
                  <FlexBox align="flex-end">
                    <FlexBox width="60%" direction="column">
                      <Form.Label red>Assign to Organization</Form.Label>
                      <Form.StyledAsyncSelect
                        cacheOptions
                        loadOptions={this.searchOrganizations}
                        onInputChange={this.handleAssignOrgInput}
                        defaultOptions={true}
                        value={this.state.organizationToAssign}
                        onChange={this.handleOrgChange}
                        placeholder="Start typing an organization's name..."
                        styles={Form.CustomStyles}
                        getOptionLabel={option =>
                          `${option.label} - #${option.account_number}`
                        }
                      />
                    </FlexBox>

                    <Button
                      style={{ height: '40px' }}
                      black
                      onClick={this.assignOrg}
                    >
                      Assign
                    </Button>
                  </FlexBox>
                </Form.InputWrapper>
              </TabPanel>
              <TabPanel>
                <FlexBox justify="space-between">
                  <Form.InputWrapper>
                    <FlexBox>
                      <Form.Input
                        type="text"
                        placeholder="Username"
                        value={this.state.username}
                        name="username"
                        onChange={this.handleChange}
                      />

                      {this.state.username && (
                        <UserNameIcons>
                          {this.state.loading ? (
                            <ClipLoader
                              color={'#494949'}
                              size={20}
                              loading={this.state.loading}
                            />
                          ) : this.state.username_available &&
                            this.state.username.length > 2 ? (
                            <Icon name="check" green />
                          ) : (
                            <Icon name="delete" red />
                          )}
                        </UserNameIcons>
                      )}
                    </FlexBox>
                  </Form.InputWrapper>
                  <Form.InputWrapper>
                    <Form.Input
                      name="password"
                      onChange={this.handleChange}
                      value={this.state.password}
                      placeholder="Temporary Password (random if empty)"
                    />
                  </Form.InputWrapper>

                  <Button
                    style={{ height: '41px' }}
                    black
                    header
                    onClick={this.createOrgFromQuote}
                  >
                    Create
                  </Button>
                </FlexBox>
              </TabPanel>
            </Tabs>

            <Spacer size="15px" />
            <hr />
            <Form.InputWrapper third>
              <Form.Label small>First Name</Form.Label>
              <Spacer size="5px" />
              <Form.Input
                type="text"
                name="quote_first_name"
                value={
                  this.state.quote_first_name ||
                  this.state.organization.first_name
                }
                onChange={this.handleChange}
              />
            </Form.InputWrapper>
            <Form.InputWrapper third>
              <Form.Label small>Last Name</Form.Label>
              <Spacer size="5px" />
              <Form.Input
                type="text"
                name="quote_last_name"
                value={this.state.quote_last_name}
                onChange={this.handleChange}
              />
            </Form.InputWrapper>
            <Spacer size="10px" />
            <Form.InputWrapper third>
              <Form.Label small>City</Form.Label>
              <Spacer size="5px" />
              <Form.Input
                type="text"
                name="quote_city"
                value={this.state.quote_city}
                onChange={this.handleChange}
              />
            </Form.InputWrapper>
            <Form.InputWrapper third>
              <Form.Label small>State</Form.Label>
              <Spacer size="5px" />
              <Form.Input
                type="text"
                name="quote_state"
                value={this.state.quote_state}
                onChange={this.handleChange}
              />
            </Form.InputWrapper>
            <Form.InputWrapper third>
              <Form.Label small>Country</Form.Label>
              <Spacer size="5px" />
              <Form.Input
                type="text"
                name="quote_country"
                value={this.state.quote_country}
                onChange={this.handleChange}
              />
            </Form.InputWrapper>
            <Spacer size="10px" />
            <Form.InputWrapper third>
              <Form.Label small>Email</Form.Label>
              <Spacer size="5px" />
              <Form.Input
                type="text"
                name="quote_email"
                value={this.state.quote_email}
                onChange={this.handleChange}
              />
            </Form.InputWrapper>
            <Form.InputWrapper third>
              <Form.Label small>Phone Number</Form.Label>
              <Spacer size="5px" />
              <Form.Input
                type="text"
                name="quote_phone_number"
                value={formatPhone(this.state.quote_phone_number) || ''}
                onChange={this.handleChange}
              />
            </Form.InputWrapper>
            <Spacer size="10px" />
          </>
        )}
        <hr />
        <Spacer size="10px" />
        {/* <Form.InputWrapper third>
          <Form.Label small>Name of Show</Form.Label>
          <Spacer size="5px" />
          <Form.Input
            type="text"
            name="show"
            value={this.state.show.name}
            onChange={this.handleChange}
          /> */}
        {this.state.shows.length > 0 && (
          <Form.InputWrapper third>
            <Form.Label>Show&apos;s Name</Form.Label>
            <Form.StyledSelect
              value={this.state.show}
              onChange={this.handleShowChange}
              options={this.state.shows}
              isSearchable={true}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              placeholder="Start typing..."
              styles={Form.CustomStyles}
            />
          </Form.InputWrapper>
        )}
        {/* </Form.InputWrapper> */}
        <Spacer size="5px" />
        <Form.InputWrapper third>
          <Form.Label small> Opening Date </Form.Label>
          <Spacer size="5px" />
          <Form.StyleDate>
            <DayPickerInput
              value={this.state.opening}
              onDayChange={this.handleDateChange}
              name="opening"
            />
          </Form.StyleDate>
        </Form.InputWrapper>
        <Form.InputWrapper third>
          <Form.Label small> Closing/Expiration Date </Form.Label>
          <Spacer size="5px" />
          <Form.StyleDate>
            <DayPickerInput
              value={this.state.expiration}
              dayPickerProps={{
                month: this.state.expirationDate,
                disabledDays: {
                  before: this.state.expirationDate
                }
              }}
              onDayChange={this.handleDateChange}
              name="expiration"
            />
          </Form.StyleDate>
        </Form.InputWrapper>
        <Form.InputWrapper third>
          <Form.Label small>Average Ticket Price</Form.Label>
          <Spacer size="5px" />
          <Form.Input
            type="number"
            name="quote_avg_price"
            value={this.state.quote_avg_price}
            onChange={this.handleChange}
          />
        </Form.InputWrapper>

        <Spacer size="5px" />
        <Form.InputWrapper third>
          <Form.Label small> Rehearsal Track Availability Date </Form.Label>
          <Spacer size="5px" />
          <Form.StyleDate>
            <DayPickerInput
              value={this.state.rehearsal_active_date}
              onDayChange={this.handleDateChange}
              name="rehearsal_active_date"
            />
          </Form.StyleDate>
        </Form.InputWrapper>
        <Form.InputWrapper third>
          <Form.Label small> Production Track Availability Date </Form.Label>
          <Spacer size="5px" />
          <Form.StyleDate>
            <DayPickerInput
              value={this.state.production_active_date}
              onDayChange={this.handleDateChange}
              name="production_active_date"
            />
          </Form.StyleDate>
        </Form.InputWrapper>
        <Form.InputWrapper third>
          <Form.Label small>Average Audience Attendance</Form.Label>
          <Spacer size="5px" />
          <Form.Input
            type="number"
            name="quote_avg_attend"
            value={this.state.quote_avg_attend}
            onChange={this.handleChange}
          />
        </Form.InputWrapper>
        <Spacer size="5px" />
        <Form.InputWrapper third>
          <Form.Label small>Include Production Tracks?</Form.Label>
          <Spacer size="5px" />
          <Form.StyledSelect
            styles={Form.CustomStyles}
            value={this.state.is_production_access}
            onChange={this.handleProdTracksChange}
            options={yes_no_options}
            isSearchable={false}
          />
        </Form.InputWrapper>
        <Form.InputWrapper third>
          <Form.Label small>Educational?</Form.Label>
          <Spacer size="5px" />
          <Form.StyledSelect
            styles={Form.CustomStyles}
            value={this.state.educational}
            onChange={this.handleEducationalChange}
            options={yes_no_options}
            isSearchable={false}
          />
        </Form.InputWrapper>

        <Form.InputWrapper third>
          <Form.Label small>Number of Performances</Form.Label>
          <Spacer size="5px" />
          <Form.Input
            type="number"
            name="quote_performances"
            value={this.state.quote_performances}
            onChange={this.handleChange}
          />
        </Form.InputWrapper>
        <Form.InputWrapper third>
          <Form.Label small>Contract Ready?</Form.Label>
          <Spacer size="5px" />
          <Form.StyledSelect
            styles={Form.CustomStyles}
            value={this.state.is_contract_ready}
            onChange={this.handleContractChange}
            options={yes_no_options}
            isSearchable={false}
          />
        </Form.InputWrapper>
        <Form.InputWrapper third>
          <Form.Label small>License Ready?</Form.Label>
          <Spacer size="5px" />
          <Form.StyledSelect
            styles={Form.CustomStyles}
            value={this.state.is_license_ready}
            onChange={this.handleLicenseChange}
            options={yes_no_options}
            isSearchable={false}
          />
        </Form.InputWrapper>
        <Form.InputWrapper third>
          <Form.Label small>Payment Ready?</Form.Label>
          <Spacer size="5px" />
          <Form.StyledSelect
            styles={Form.CustomStyles}
            value={this.state.is_payment_ready}
            onChange={this.handlePaymentChange}
            options={yes_no_options}
            isSearchable={false}
          />
        </Form.InputWrapper>
        <Form.InputWrapper third>
          <Form.Label small>Rate</Form.Label>
          <Spacer size="5px" />
          <Form.Input
            type="number"
            value={this.state.rate}
            onChange={this.handleRateChange}
          />
        </Form.InputWrapper>
        <Spacer size="10px" />

        <hr />
        <Spacer size="10px" />
        <Form.InputWrapper full>
          <Form.Label small>Notes</Form.Label>
          <Spacer size="5px" />
          <Form.TextArea
            name="notes"
            value={this.state.notes}
            onChange={this.handleChange}
          />
        </Form.InputWrapper>
        <Spacer size="20px" />
        <br />
      </div>
    );
  }
}
