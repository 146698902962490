import React from 'react';
import { Text, Button, Form, Spacer, FormWrapper } from 'components';
import { toast } from 'react-toastify';
import { createSetlist } from 'data-graphql';
//import { yes_no_options } from 'helpers';

export default class CreateSetlistForm extends React.Component {
  constructor() {
    super();
    this.initialState = {
      name: '',
      type: ''
    };
    this.state = this.initialState;
  }

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value }, this.checkUsername);
  };

  close = () => {
    this.setState(this.initialState);
    this.props.onChange();
  };

  submit = async () => {
    if (!this.state.name) {
      return toast.error('Name is required.', { autoclose: false });
    }

    const VARS = {
      input: {
        name: this.state.name,
        show_id: this.props.show.id,
        type: 'alternate'
      }
    };
    const REQ_OBJECT = `{
      code
      success
      message
      setlist {
        id
        name
        type
        tracks {
          id
          updated_at
          track_name
          time_formatted
          cue_number
          has_rehearsal
          has_production
        }
      }
    }`;
    const RESULT = await createSetlist(VARS, REQ_OBJECT);
    if (RESULT.data.success && RESULT.data.message) {
      toast.success(RESULT.message);
      this.close();
    }
  };

  render() {
    return (
      <FormWrapper>
        <Form>
          <Text large red>
            CREATE SETLIST
          </Text>
          <Spacer size="10px" />
          <Form.InputWrapper>
            <Form.Input
              type="text"
              placeholder="Name"
              value={this.state.name}
              name="name"
              onChange={this.handleChange}
            />
          </Form.InputWrapper>

          <Spacer size="10px" />
          <Button onClick={this.submit}>CREATE</Button>
        </Form>
      </FormWrapper>
    );
  }
}
