import React from 'react';
import { Text, Button, Form, Spacer, Icon, FlexBox } from 'components';
import { toast } from 'react-toastify';
import { createAdmin, isUsernameAvailable, getMe } from 'data-graphql';
import { yes_no_options } from 'helpers';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

const UserNameIcons = styled.div`
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: -45px;
  z-index: 1;
`;

export default class CreateAdminForm extends React.Component {
  constructor() {
    super();
    this.initialState = {
      first_name: '',
      last_name: '',
      email: '',
      username: '',
      account_type: false,
      username_available: true,
      creating: false,
      loading: false
    };
    this.state = this.initialState;
  }

  componentDidMount() {
    this.getCurrentUser();
  }

  getCurrentUser = async () => {
    const REQ_OBJECT = `{
      email
      first_name
      last_name
      account_type
    }`;

    const RESULT = await getMe(REQ_OBJECT);

    this.setState({
      user: RESULT.data.getMe
    });
  };

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value }, this.checkUsername);
  };

  close = () => {
    this.setState(this.initialState);
    this.props.onCreate();
  };

  submit = async () => {
    if (
      !this.state.first_name ||
      !this.state.last_name ||
      !this.state.email ||
      !this.state.username
    ) {
      return toast.error('All fields must be completed.', { autoclose: false });
    }

    if (this.state.username.length < 3) {
      return toast.error('Username must be at least 3 characters long.', {
        autoclose: false
      });
    }

    if (!this.state.username_available) {
      return toast.error('Username is taken.', { autoclose: false });
    }

    const VARS = {
      input: {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        username: this.state.username,
        account_type: this.state.account_type.value ? 'super_admin' : 'admin'
      }
    };

    const REQ_OBJECT = `{
      code
      success
      message
      user {
        id
        first_name
        last_name
        email
      }
    }`;

    this.setState(prevState => ({
      creating: !prevState.creating
    }));

    const RESULT = await createAdmin(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.createAdmin.message}`);
      this.close();
    } else {
      this.setState(prevState => ({
        creating: !prevState.creating
      }));
    }
  };

  checkUsername = async () => {
    const VARS = {
      username: this.state.username
    };
    const REQ_OBJECT = `{
      boolean
    }`;

    this.setState({ loading: true });

    const RESULT = await isUsernameAvailable(VARS, REQ_OBJECT);
    this.setState({
      username_available: RESULT.data.isUsernameAvailable,
      loading: false
    });
  };

  handleAdminChange = adminSelect => {
    this.setState({
      account_type: adminSelect
    });
  };

  render() {
    if (!this.state.user) return null;
    return (
      <Form>
        <FlexBox justify="flex-end">
          <Icon onClick={this.close} name="close" />
        </FlexBox>
        <Text large red>
          CREATE ADMIN
        </Text>
        <Spacer size="10px" />
        <Form.InputWrapper>
          <Form.Input
            type="text"
            placeholder="First Name"
            value={this.state.first_name}
            name="first_name"
            onChange={this.handleChange}
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Input
            type="text"
            placeholder="Last Name"
            value={this.state.last_name}
            name="last_name"
            onChange={this.handleChange}
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Input
            type="text"
            placeholder="Email"
            value={this.state.email}
            name="email"
            onChange={this.handleChange}
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <FlexBox>
            <Form.Input
              type="text"
              placeholder="Username"
              value={this.state.username}
              name="username"
              onChange={this.handleChange}
            />
            {this.state.username && (
              <UserNameIcons>
                {this.state.loading ? (
                  <ClipLoader
                    color={'#494949'}
                    size={20}
                    loading={this.state.loading}
                  />
                ) : this.state.username_available &&
                  this.state.username.length > 2 ? (
                  <Icon name="check" green />
                ) : (
                  <Icon name="delete" red />
                )}
              </UserNameIcons>
            )}
          </FlexBox>
        </Form.InputWrapper>
        {this.state.user.account_type === 'super_admin' && (
          <Form.InputWrapper>
            <Form.StyledSelect
              styles={Form.CustomStyles}
              value={this.state.account_type}
              onChange={this.handleAdminChange}
              options={yes_no_options}
              isSearchable={false}
              placeholder="Grant Super-Admin Access?"
            />
          </Form.InputWrapper>
        )}

        <Spacer size="10px" />
        <Button onClick={this.submit}>
          {this.state.creating ? 'CREATING...' : 'CREATE'}
        </Button>
      </Form>
    );
  }
}
