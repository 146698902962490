import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Input, Button, Segment, Icon, COLORS, Text, Spacer } from 'components';
import http from 'data-graphql/http';
import { isMTI } from 'helpers';
import styled from 'styled-components';
import LogoSrc from 'assets/logo.svg';
import MTILogo from 'assets//mtiLogoBottomAligned.png';
import { Link } from 'react-router-dom';

const GlobalStyle = createGlobalStyle`
  body {
    overflow: visible
  }
`;

const Body = styled.div`
  min-height: 100vh;
  overflow: scroll;
  background: linear-gradient(
    ${isMTI ? COLORS.darkBlue : '#000000'},
    ${isMTI ? COLORS.lightBlue : COLORS.red}
  );
`;
// linear-gradient(#000000, ${COLORS.red});
const Form = styled.form`
  width: 400px;
  max-width: 100%;
  margin: auto;
  padding-top: 100px;
`;
const Logo = styled.img`
  width: 100%;
  padding: 30px 40px;
`;

const StyledLink = styled(Link)`
  text-decoration-color: ${COLORS.black};
`;

export default class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: ''
    };
  }

  login(e) {
    e.preventDefault();

    http()
      .post('/signin', {
        username: this.state.username,
        password: this.state.password
      })
      .then(res => {
        res.result.temporaryPasswordUsed &&
          window.localStorage.setItem(
            'tempPassword',
            res.result.temporaryPasswordUsed
          );
        window.localStorage.setItem('token', res.result.token);
        window.localStorage.setItem('type', res.result.account_type);
        const portal = res.result.account_type.includes('admin')
          ? 'admin'
          : 'org';
        this.props.history.push(`${portal}/shows`);
      })
      .catch(e => {
        return e;
      });
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    return (
      <Body>
        <Form onSubmit={this.login.bind(this)}>
          <Logo src={isMTI ? MTILogo : LogoSrc} />
          {isMTI && (
            <div style={{ textAlign: 'center', padding: '10px 5px' }}>
              <Text large white>
                Organization Login / Production Tracks
              </Text>
            </div>
          )}
          <Segment>
            <Input
              value={this.state.username}
              name="username"
              onChange={this.handleChange}
              placeholder="Username"
              icon={<Icon name="person" />}
            />
            <br />
            <Input
              value={this.state.password}
              name="password"
              onChange={this.handleChange}
              placeholder="Password"
              type="password"
              icon={<Icon name="lock-close" />}
            />
            <div style={{ textAlign: 'center' }}>
              <StyledLink to="/forgot">
                <Text small>Forgot your organization login?</Text>
              </StyledLink>
            </div>

            <Spacer size="20px" />

            <Button large fluid>
              Sign In
            </Button>
            <Spacer size="20px" />
            {!isMTI && (
              <div style={{ textAlign: 'center' }}>
                <a
                  rel="noopener noreferrer"
                  href={`${process.env.REACT_APP_MARKETING_URL}faqs/`}
                  style={{
                    textDecoration: 'underline',
                    textDecorationColor: `${COLORS.black}`
                  }}
                >
                  <Text>FAQs</Text>
                </a>
                <Spacer size="10px" />

                <StyledLink to="/rehearsal">
                  <Text>Rehearsal Track Access</Text>
                </StyledLink>
                <Spacer size="10px" />

                <a
                  rel="noopener noreferrer"
                  href={`${process.env.REACT_APP_MARKETING_URL}`}
                  style={{
                    textDecoration: 'underline',
                    textDecorationColor: `${COLORS.black}`
                  }}
                >
                  <Text>Back to TheMTPit.com</Text>
                </a>
              </div>
            )}
          </Segment>
        </Form>
        <Spacer size="50px" />
        <GlobalStyle />
      </Body>
    );
  }
}
