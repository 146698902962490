import React from 'react';
import { Input, Button, Segment, Icon, COLORS, Text, Spacer } from 'components';
import http from 'data-graphql/http';
import styled from 'styled-components';
import LogoSrc from 'assets/logo.svg';
import MTILogo from 'assets//mtiLogoBottomAligned.png';
import { toast } from 'react-toastify';
import { isMTI } from 'helpers';

const Body = styled.div`
  min-height: 100vh;
  background: linear-gradient(
    ${isMTI ? COLORS.darkBlue : '#000000'},
    ${isMTI ? COLORS.lightBlue : COLORS.red}
  );
`;
const Form = styled.form`
  width: 400px;
  max-width: 100%;
  margin: auto;
  padding-top: 100px;
`;
const Logo = styled.img`
  width: 100%;
  padding: 30px 40px;
`;

export default class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      resetToken: '',
      password: '',
      confirmPassword: ''
    };
  }

  componentDidMount() {
    this.setState({
      resetToken: this.props.match.params.id
      // dataLoaded: true
    });
  }

  resetPassword(e) {
    e.preventDefault();

    if (!this.state.password || !this.state.confirmPassword) {
      return toast.error('Please enter a password!', { autoclose: false });
    }

    if (this.state.password !== this.state.confirmPassword) {
      return toast.error('Passwords do not match!', { autoclose: false });
    }

    http()
      .post('/reset-password', {
        token: this.state.resetToken,
        password: this.state.password
      })
      .then(res => {
        if (res.success && res.message) {
          toast.success(res.message);
          this.props.history.push('/');
        }
      })
      .catch(e => {
        return e;
      });
  }

  backToLogin = () => {
    this.props.history.push('/');
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    return (
      <Body>
        <Form onSubmit={this.resetPassword.bind(this)}>
          <Logo src={isMTI ? MTILogo : LogoSrc} onClick={this.backToLogin} />
          <div style={{ textAlign: 'center' }}>
            <Text white>Reset your Password</Text>
          </div>

          <Spacer size="20px" />
          <Segment>
            <Input
              value={this.state.password}
              name="password"
              onChange={this.handleChange}
              placeholder="New Password"
              type="password"
              icon={<Icon name="lock-close" />}
            />
            <br />
            <Input
              value={this.state.confirmPassword}
              name="confirmPassword"
              onChange={this.handleChange}
              placeholder="Confirm Password"
              type="password"
              icon={<Icon name="lock-close" />}
            />

            <Spacer size="20px" />

            <Button large fluid>
              Reset Password
            </Button>
          </Segment>
        </Form>
        <Spacer size="50px" />
      </Body>
    );
  }
}
