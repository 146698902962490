import { isMTI } from 'helpers';

export default {
  lighterYellow: `${isMTI ? '#F1EFEF' : '#fbdd84'}`,
  yellow: `${isMTI ? '#2196f3' : '#ebae29'}`,
  darkerYellow: `${isMTI ? '#183457' : '#ebae29'}`,
  dark: '#a30109',
  black: '#494949',
  darkRed: `${isMTI ? '#183457' : '#6e1406'}`,
  white: '#FFFFFF',
  lightGrey: '#f6f6f6',
  mediumGrey: '#a9a9a9',
  grey: `${isMTI ? '#818691' : '#918181'}`,
  red: '#D92E1C', //old red '#D92E1C',
  lighterRed: '#e2311d',
  hoverYellow: `${isMTI ? '#196DB3' : '#F2C955'}`,
  lightBlack: '#6b6b6b',
  //MTI
  lightBlue: '#3495ce',
  darkBlue: '#183457'
};
