import React, { Component } from 'react';
import styled from 'styled-components';
import COLORS from './colors';
import { Icon } from 'components';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const InputWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
`;

const Input = styled.div`
  padding: 10px 15px;
  border-radius: 0;
  border: 1px solid ${COLORS.lightGrey};
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  background: ${COLORS.yellow};
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 15px;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Options = styled.div`
  width: 100%;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.lightGrey};
  display: ${props => (props.visible ? 'block' : 'none')};
  position: absolute;
  z-index: 10;
  max-height: 175px;
  overflow-y: auto;
`;

const Option = styled.div`
  border-top: 1px solid ${COLORS.lightGrey};
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background: ${COLORS.grey};
  }
`;

export default class extends Component {
  constructor() {
    super();

    this.state = {
      optionsVisible: false
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  show() {
    this.setState({ optionsVisible: true });
    document.addEventListener('click', this.hide);
  }

  hide() {
    this.setState({ optionsVisible: false });
    document.removeEventListener('click', this.hide);
  }

  UNSAFE_componentWillUnmount() {
    document.removeEventListener('click', this.hide);
  }

  render() {
    const value =
      this.props.options.find(o => o.value === this.props.selected) &&
      this.props.options.find(o => o.value === this.props.selected).text;

    return (
      <Wrapper>
        <InputWrapper onClick={this.show}>
          <Input>{value || this.props.placeholder}</Input>
          <IconWrapper>
            <Icon name="arrow-down-3" />
          </IconWrapper>
        </InputWrapper>
        <Options visible={this.state.optionsVisible}>
          {this.props.options.map(option => (
            <Option
              onClick={() => this.props.onChange(option.value)}
              key={option.value}
            >
              {option.text}
            </Option>
          ))}
        </Options>
      </Wrapper>
    );
  }
}
