import React from 'react';
import { Form, Spacer } from 'components';
import styled from 'styled-components';

const StyledForm = styled(Form)`
  width: 90%;
  text-align: left;
  margin-top: -20px;
`;

export default props => (
  <StyledForm>
    <Spacer size="10px" />

    <Form.InputWrapper full>
      <Form.Label small>{props.form_label}</Form.Label>
      <Form.Input
        type={props.type}
        name={props.name}
        onChange={props.handleChange}
      />
    </Form.InputWrapper>

    <Spacer size="30px" />
  </StyledForm>
);
