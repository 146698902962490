import React from 'react';
import {
  Header,
  FormWrapper,
  Text,
  Button,
  Spacer,
  Form,
  Wrapper,
  Icon,
  FlexBox,
  Modal
} from 'components';
import { toast } from 'react-toastify';
import { address_type_options, windowSize } from 'helpers';
import {
  getMe,
  updateMe,
  updateOrganization,
  isUsernameAvailable
} from 'data-graphql';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import ReactTooltip from 'react-tooltip';

const UserNameIcons = styled.div`
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  z-index: 1;
  position: relative;
  bottom: 28px;
  left: 88%;
`;

export default class Account extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      website: '',
      initialWebsite: '',
      address: '',
      initialAddress: '',
      city: '',
      initialCity: '',
      state: '',
      initialState: '',
      zip: '',
      initialZip: '',
      currentPassword: '',
      initialCurrentPassword: '',
      newPassword: '',
      initialNewPassword: '',
      address_type: undefined,
      initial_address_type: undefined,
      submitting: false,
      organizationName: '',
      initialOrganizationName: '',
      initialUsername: '',
      username: '',
      loading: false,
      username_available: true,
      tempPassword: false
    };

    this.textInput = React.createRef();
  }

  focus = () => {
    this.setState({ tempPassword: false });
    this.textInput.current.focus();
  };

  async componentDidMount() {
    window.addEventListener('beforeunload', this.handleWindowClose);
    await this.getCurrentUser();

    const addressType = address_type_options.filter(
      item => item.value === this.state.address_type
    )[0];

    const tempPassword = window.localStorage.getItem('tempPassword');

    tempPassword && this.setState({ tempPassword: true });

    this.setState({ address_type: addressType });
  }

  handleWindowClose = e => {
    e.preventDefault();
    const temp = window.localStorage.getItem('tempPassword');
    if (temp) {
      window.localStorage.removeItem('tempPassword');
      window.localStorage.removeItem('token');
    }
  };

  getCurrentUser = async () => {
    const REQ_OBJECT = `{
      email
      first_name
      last_name
      username
      organization {
        id
        name
        website
        address
        city
        state
        zip
        address_type
      }
      
    }`;

    const RESULT = await getMe(REQ_OBJECT);
    this.setState({
      firstName: RESULT.data.getMe.first_name,
      lastName: RESULT.data.getMe.last_name || '',
      email: RESULT.data.getMe.email || '',
      website: RESULT.data.getMe.organization.website || '',
      initialWebsite: RESULT.data.getMe.organization.website || '',
      address: RESULT.data.getMe.organization.address || '',
      initialAddress: RESULT.data.getMe.organization.address || '',
      city: RESULT.data.getMe.organization.city || '',
      initialCity: RESULT.data.getMe.organization.city || '',
      zip: RESULT.data.getMe.organization.zip || '',
      initialZip: RESULT.data.getMe.organization.zip || '',
      state: RESULT.data.getMe.organization.state || '',
      initialState: RESULT.data.getMe.organization.state || '',
      organization_id: RESULT.data.getMe.organization.id,
      address_type: RESULT.data.getMe.organization.address_type || '',
      initial_address_type: RESULT.data.getMe.organization.address_type || '',
      organizationName: RESULT.data.getMe.organization.name || '',
      initialOrganizationName: RESULT.data.getMe.organization.name || '',
      initialUsername: RESULT.data.getMe.username,
      username: RESULT.data.getMe.username
    });
  };

  submit = async e => {
    e.preventDefault();

    if (
      this.state.initialOrganizationName &&
      this.state.initialOrganizationName === this.state.organizationName &&
      (this.state.initialUsername &&
        this.state.initialUsername === this.state.username) &&
      (this.state.initialWebsite &&
        this.state.initialWebsite === this.state.website) &&
      (this.state.initialAddress &&
        this.state.initialAddress === this.state.address) &&
      this.state.initialCity === this.state.city &&
      this.state.initialState === this.state.state &&
      this.state.initialZip === this.state.zip &&
      //this.state.initial_address_type === this.state.address_type.value &&
      this.state.initialCurrentPassword === this.state.currentPassword &&
      this.state.initialNewPassword === this.state.newPassword
    ) {
      return toast.error('No changes were made.', { autoclose: false });
    }

    await this.updateUser();
    await this.updateOrg();
    if (this.state.userData && this.state.orgData)
      toast.success('Updated successfully.');
  };

  updateUser = async () => {
    if (this.state.newPassword && this.state.newPassword.length < 8) {
      this.setState(prevState => ({
        submitting: !prevState.submitting
      }));
      return toast.error('Password must be at least 8 characters long.', {
        autoclose: false
      });
    }

    const VARS = {
      input: {
        ...(this.state.firstName && { first_name: this.state.firstName }),
        ...(this.state.lastName && { last_name: this.state.lastName }),
        ...(this.state.email && { email: this.state.email }),
        ...(this.state.currentPassword && {
          current_password: this.state.currentPassword
        }),
        ...(this.state.newPassword && { password: this.state.newPassword }),
        ...(this.state.username !== this.state.initialUsername && {
          username: this.state.username
        })
      }
    };

    const REQ_OBJECT = `{
      code
      success
      message
      user {
        first_name
        last_name
        email
        token
        username
      }
    }`;

    this.setState(prevState => ({
      submitting: !prevState.submitting
    }));

    const RESULT = await updateMe(VARS, REQ_OBJECT);

    if (RESULT.data) {
      this.setState({
        firstName: RESULT.data.updateMe.user.first_name,
        lastName: RESULT.data.updateMe.user.last_name || '',
        email: RESULT.data.updateMe.user.email,
        username: RESULT.data.updateMe.user.username,
        userData: true
      });
      window.localStorage.setItem('token', RESULT.data.updateMe.user.token);
      if (this.state.newPassword) {
        window.localStorage.removeItem('tempPassword');
      }
    } else {
      this.setState(prevState => ({
        submitting: !prevState.submitting
      }));
    }
    this.setState({
      newPassword: '',
      currentPassword: ''
    });
  };

  updateOrg = async () => {
    const payload = {};

    if (this.state.website) payload.website = this.state.website;
    if (this.state.address) payload.address = this.state.address;
    if (this.state.city) payload.city = this.state.city;
    if (this.state.state) payload.state = this.state.state;
    if (this.state.zip) payload.zip = this.state.zip;
    if (this.state.address_type)
      payload.address_type = this.state.address_type.value;

    const VARS = { id: this.state.organization_id, input: payload };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    const RESULT = await updateOrganization(VARS, REQ_OBJECT);
    if (RESULT.data) {
      this.setState({
        orgData: true,
        submitting: !this.state.submitting
      });
    }
  };

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value });
  };

  handleUsernameChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value }, this.checkUsername);
  };

  checkUsername = async () => {
    const VARS = {
      username: this.state.username
    };
    const REQ_OBJECT = `{
      boolean
    }`;

    this.setState({ loading: true });

    const RESULT = await isUsernameAvailable(VARS, REQ_OBJECT);

    this.setState({
      username_available: RESULT.data.isUsernameAvailable,
      loading: false
    });
  };

  handleAddressTypeChange = address => {
    this.setState({ address_type: address });
  };

  render() {
    if (!this.state.organization_id) return null;

    return (
      <div>
        <Modal
          open={this.state.tempPassword}
          onClose={this.focus}
          closeOnOverlayClick
          width="45%"
        >
          <FlexBox direction="column" justify="center" align="center">
            <Text large red>
              You have logged in with a temporary password
            </Text>
            <Spacer size="20px" />
            <Button onClick={this.focus}>Reset Password</Button>
          </FlexBox>
        </Modal>
        <Header>
          <Header.Head bold huge>
            Update Your Account
          </Header.Head>
        </Header>
        <Wrapper>
          <FormWrapper>
            <Text red large>
              About Your Organization
            </Text>
            <FlexBox
              direction={windowSize === 'desktop' ? 'row' : 'column-reverse'}
            >
              <Form>
                <Spacer size="10px" />
                <Form.InputWrapper full>
                  <Form.Label>Organization Name</Form.Label>
                  <Form.Input
                    name="organizationName"
                    onChange={this.handleChange}
                    value={this.state.organizationName}
                    placeholder="Organization Name"
                  />
                </Form.InputWrapper>
                <Spacer size="5px" />
                <Form.InputWrapper full>
                  <FlexBox direction="column">
                    <Form.Label>Username</Form.Label>

                    <Form.Input
                      name="username"
                      onChange={this.handleUsernameChange}
                      value={this.state.username}
                      placeholder="Username"
                    />
                    {this.state.initialUsername !== this.state.username && (
                      <UserNameIcons>
                        {this.state.loading ? (
                          <ClipLoader
                            color={'#494949'}
                            size={20}
                            loading={this.state.loading}
                          />
                        ) : this.state.username_available &&
                          this.state.username.length > 2 ? (
                          <Icon name="check" green />
                        ) : (
                          <Icon name="delete" red />
                        )}
                      </UserNameIcons>
                    )}
                  </FlexBox>
                </Form.InputWrapper>
                <Form.InputWrapper full>
                  <Form.Label>Organization Admin Email</Form.Label>
                  <Form.Input
                    disabled={true}
                    value={this.state.email}
                    style={{ opacity: '.6', cursor: 'not-allowed' }}
                    data-tip="The admin email address can be <br/> changed by updating the account admin."
                  />
                  <ReactTooltip
                    place="bottom"
                    type="warning"
                    effect="solid"
                    multiline={true}
                  />
                </Form.InputWrapper>
                {windowSize === 'phone' ? (
                  <Spacer size="10px" />
                ) : (
                  <Spacer size="55px" />
                )}
                <Text red large>
                  Change Password
                </Text>
                <Spacer size="10px" />
                <Form.InputWrapper full>
                  <Form.Label>Current Password</Form.Label>

                  <Form.Input
                    type="password"
                    name="currentPassword"
                    onChange={this.handleChange}
                    value={this.state.currentPassword}
                    placeholder="Current Password"
                    ref={this.textInput}
                  />
                </Form.InputWrapper>
                <Spacer size="5px" />
                <Form.InputWrapper full>
                  <Form.Label>New Password</Form.Label>

                  <Form.Input
                    type="password"
                    name="newPassword"
                    onChange={this.handleChange}
                    value={this.state.newPassword}
                    placeholder="New Password"
                  />
                </Form.InputWrapper>
              </Form>
              <Form>
                <Spacer size="10px" />
                <Form.InputWrapper full>
                  <Form.Label>Website</Form.Label>

                  <Form.Input
                    name="website"
                    onChange={this.handleChange}
                    value={this.state.website}
                    placeholder="Website"
                  />
                </Form.InputWrapper>
                <Spacer size="5px" />
                <Form.InputWrapper full>
                  <Form.Label>Address</Form.Label>

                  <Form.Input
                    name="address"
                    onChange={this.handleChange}
                    value={this.state.address}
                    placeholder="Address"
                  />
                </Form.InputWrapper>
                <Spacer size="5px" />
                <Form.InputWrapper full>
                  <Form.Label>City</Form.Label>

                  <Form.Input
                    name="city"
                    onChange={this.handleChange}
                    value={this.state.city}
                    placeholder="City"
                  />
                </Form.InputWrapper>
                <Spacer size="5px" />
                <Form.InputWrapper full>
                  <Form.Label>State</Form.Label>

                  <Form.Input
                    name="state"
                    onChange={this.handleChange}
                    value={this.state.state}
                    placeholder="State"
                  />
                </Form.InputWrapper>
                <Spacer size="5px" />
                <Form.InputWrapper full>
                  <Form.Label>Zip</Form.Label>

                  <Form.Input
                    name="zip"
                    onChange={this.handleChange}
                    value={this.state.zip}
                    placeholder="Zip"
                  />
                </Form.InputWrapper>
                <Spacer size="5px" />
                <Form.InputWrapper full>
                  <Form.Label>Address Type</Form.Label>
                  <Form.StyledSelect
                    value={this.state.address_type}
                    onChange={this.handleAddressTypeChange}
                    options={address_type_options}
                    isSearchable={false}
                    styles={Form.CustomStyles}
                  />
                </Form.InputWrapper>
                <Spacer size="20px" />
              </Form>
            </FlexBox>
            <Button large onClick={this.submit}>
              {this.state.submitting ? 'Submitting...' : 'Submit'}
            </Button>
          </FormWrapper>
        </Wrapper>
      </div>
    );
  }
}
