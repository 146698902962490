import React from 'react';
import {
  Header,
  FormWrapper,
  Text,
  Button,
  Spacer,
  Form,
  Wrapper,
  Icon
} from 'components';
import { toast } from 'react-toastify';
import { getMe, updateMe, isUsernameAvailable } from 'data-graphql';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

const UserNameIcons = styled.div`
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  z-index: 1;
  position: relative;
  bottom: 27px;
  left: 88%;
`;

//MTI

export default class Account extends React.Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      initialFirstName: '',
      lastName: '',
      initialLastName: '',
      email: '',
      initialEmail: '',
      currentPassword: '',
      newPassword: '',
      submitting: false,
      initialUsername: '',
      username: ''
    };
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleWindowClose);
    this.getCurrentUser();
    const tempPassword = window.localStorage.getItem('tempPassword');
    tempPassword && toast.success('Must Change Password');
  }

  handleWindowClose = e => {
    e.preventDefault();
    const temp = window.localStorage.getItem('tempPassword');
    if (temp) {
      window.localStorage.removeItem('tempPassword');
      window.localStorage.removeItem('token');
    }
  };

  getCurrentUser = async () => {
    const REQ_OBJECT = `{
      username
      email
      first_name
      last_name
    }`;

    const RESULT = await getMe(REQ_OBJECT);

    this.setState({
      firstName: RESULT.data.getMe.first_name,
      initialFirstName: RESULT.data.getMe.first_name,
      lastName: RESULT.data.getMe.last_name || '',
      initialLastName: RESULT.data.getMe.last_name || '',
      email: RESULT.data.getMe.email,
      initialEmail: RESULT.data.getMe.email,
      initialUsername: RESULT.data.getMe.username,
      username: RESULT.data.getMe.username
    });
  };

  submit = async e => {
    e.preventDefault();

    const tempPassword = window.localStorage.getItem('tempPassword');

    if (
      this.state.initialFirstName === this.state.firstName &&
      this.state.initialLastName === this.state.lastName &&
      this.state.initialEmail === this.state.email &&
      this.state.initialUsername === this.state.username &&
      !this.state.currentPassword.length > 0 &&
      !this.state.newPassword.length > 0
    ) {
      return toast.error('No changes were made.', { autoClose: false });
    }

    if (
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.email ||
      !this.state.username ||
      (tempPassword && !this.state.currentPassword) ||
      (tempPassword && !this.state.newPassword)
    ) {
      return toast.error('Please complete all required fields.', {
        autoClose: false
      });
    }

    if (this.state.newPassword && this.state.newPassword.length < 8) {
      return toast.error('Password must be at least 8 characters long.', {
        autoclose: false
      });
    }

    const VARS = {
      input: {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        username: this.state.username,
        current_password: this.state.currentPassword,
        password: this.state.newPassword
      }
    };

    const REQ_OBJECT = `{
      code
      success
      message
      user {
        username
        first_name
        last_name
        email
        token
      }
    }`;

    this.setState(prevState => ({
      submitting: !prevState.submitting
    }));

    const RESULT = await updateMe(VARS, REQ_OBJECT);

    if (RESULT.data) {
      this.setState({
        firstName: RESULT.data.updateMe.user.first_name,
        initialFirstName: RESULT.data.updateMe.user.first_name,
        lastName: RESULT.data.updateMe.user.last_name || '',
        initialLastName: RESULT.data.updateMe.user.last_name || '',
        email: RESULT.data.updateMe.user.email,
        initialEmail: RESULT.data.updateMe.user.email,
        username: RESULT.data.updateMe.user.username,
        initialUsername: RESULT.data.updateMe.user.username
      });
      window.localStorage.removeItem('tempPassword');
      window.localStorage.setItem('token', RESULT.data.updateMe.user.token);
      toast.success('Updated successfully.');
    }
    this.setState({
      newPassword: '',
      currentPassword: ''
    });

    this.setState(prevState => ({
      submitting: !prevState.submitting
    }));
  };

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value });
  };

  handleUsernameChange = e => {
    this.setState({ [e.target.name]: e.target.value }, this.checkUsername);
  };

  checkUsername = async () => {
    const VARS = {
      username: this.state.username
    };
    const REQ_OBJECT = `{
      boolean
    }`;

    this.setState({ loading: true });

    const RESULT = await isUsernameAvailable(VARS, REQ_OBJECT);
    this.setState({
      username_available: RESULT.data.isUsernameAvailable,
      loading: false
    });
  };

  render() {
    return (
      <div>
        <Header>
          <Header.Head bold huge>
            Update Your Account
          </Header.Head>
        </Header>
        <Wrapper>
          <FormWrapper>
            <Form>
              <Text red large>
                Account Info
              </Text>
              <Spacer size="10px" />
              <Form.InputWrapper>
                <Form.Label>First Name</Form.Label>
                <Form.Input
                  name="firstName"
                  onChange={this.handleChange}
                  value={this.state.firstName}
                  placeholder="First Name"
                />
              </Form.InputWrapper>
              <Spacer size="5px" />
              <Form.InputWrapper>
                <Form.Label>Last Name</Form.Label>

                <Form.Input
                  name="lastName"
                  onChange={this.handleChange}
                  value={this.state.lastName}
                  placeholder="Last Name"
                />
              </Form.InputWrapper>
              <Spacer size="5px" />
              <Form.InputWrapper>
                <Form.Label>Email</Form.Label>

                <Form.Input
                  name="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                  placeholder="Email"
                />
              </Form.InputWrapper>
              <Spacer size="5px" />
              <Form.InputWrapper>
                <Form.Label>Username</Form.Label>

                <Form.Input
                  name="username"
                  onChange={this.handleUsernameChange}
                  value={this.state.username}
                  placeholder="Username"
                />
                {this.state.initialUsername !== this.state.username && (
                  <UserNameIcons>
                    {this.state.loading ? (
                      <ClipLoader
                        color={'#494949'}
                        size={20}
                        loading={this.state.loading}
                      />
                    ) : this.state.username_available &&
                      this.state.username.length > 2 ? (
                      <Icon name="check" green />
                    ) : (
                      <Icon name="delete" red />
                    )}
                  </UserNameIcons>
                )}
              </Form.InputWrapper>

              <Spacer size="20px" />
              <Text red large>
                Change Password
              </Text>
              <Spacer size="10px" />
              <Form.InputWrapper>
                <Form.Label>Current Password</Form.Label>

                <Form.Input
                  type="password"
                  name="currentPassword"
                  onChange={this.handleChange}
                  value={this.state.currentPassword}
                  placeholder="Current Password"
                />
              </Form.InputWrapper>
              <Spacer size="5px" />
              <Form.InputWrapper>
                <Form.Label>New Password</Form.Label>

                <Form.Input
                  type="password"
                  name="newPassword"
                  onChange={this.handleChange}
                  value={this.state.newPassword}
                  placeholder="New Password"
                />
              </Form.InputWrapper>

              <Spacer size="5px" />
              <Button large onClick={this.submit}>
                {this.state.submitting ? 'Submitting...' : 'Submit'}
              </Button>
            </Form>
          </FormWrapper>
        </Wrapper>
      </div>
    );
  }
}
