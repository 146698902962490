import React from 'react';
import {
  Wrapper,
  Pagination,
  Input,
  SearchIcon,
  CloseIcon,
  Header,
  Text,
  Button,
  StyledTable,
  FormWrapper,
  StyledSlideDown,
  media,
  FlexBox,
  COLORS
} from 'components';
import styled from 'styled-components';
import { getOrganizations } from 'data-graphql';
import CreateOrganizationForm from './components/CreateOrganizationForm.js';
import { isMedia } from 'helpers';
import Spinner from 'react-spinkit';

const InputWrapper = styled.form`
  width: 300px;
  ${media.phone`
    width: 100%;
  `};
`;

const FilterLink = styled(Text)`
  cursor: pointer;
  display: inline-block;
  margin: 10px 40px 20px 5px;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;

export default class Organizations extends React.Component {
  constructor() {
    super();
    this.state = {
      organizations: null,
      currentPage: 1,
      totalPages: 1,
      search: '',
      firstLetter: undefined,
      withExpiringShows: false,
      withBookedShows: false,
      createOrganizationModalOpen: false,
      createOrg: false,
      loading: false
    };
  }

  componentDidMount() {
    this.getComponentOrganizations();
  }

  getComponentOrganizations = async () => {
    this.setState({ loading: true });
    const VARS = {
      page: this.state.currentPage,
      search: this.state.search || null,
      withExpiringShows: this.state.withExpiringShows || null,
      withBookedShows: this.state.withBookedShows || null,
      firstLetter: this.state.firstLetter || null
    };
    console.log(VARS);
    const RES_OBJECT = `{
      currentPage
      totalPages
      organizations {
        id
        name
        city
        state
        account_number
        show_count
      }
    }`;

    const RESULT = await getOrganizations(VARS, RES_OBJECT);
    if (RESULT.data) {
      this.setState({
        organizations: RESULT.data.getOrganizations.organizations,
        currentPage: RESULT.data.getOrganizations.currentPage,
        totalPages: RESULT.data.getOrganizations.totalPages,
        loading: false
      });
    }
  };

  search = e => {
    e.preventDefault();

    this.setState(
      {
        currentPage: 1
      },
      this.getComponentOrganizations
    );
  };

  clearSearch = e => {
    e.preventDefault();
    this.setState(
      { search: '', currentPage: 1 },
      this.getComponentOrganizations
    );
  };

  handleChildChange = () => {
    this.setState({ createOrg: false });
    this.getComponentOrganizations();
  };

  openCreateForm = () => {
    this.setState({
      createOrg: this.state.createOrg === 'create' ? false : 'create'
    });
  };

  expiringShows = e => {
    let bool = e.target.getAttribute('name') === 'withExpiring' ? true : false;
    this.setState(
      {
        withExpiringShows: bool
      },
      this.getComponentOrganizations
    );
  };

  bookedShows = e => {
    console.log(e);
    let bool = e.target.getAttribute('name') === 'withBooked' ? true : false;
    console.log(bool);
    this.setState(
      {
        withBookedShows: bool
      },
      this.getComponentOrganizations
    );
  };

  viewAll = () => {
    this.setState(
      {
        withBookedShows: false,
        withExpiringShows: false
      },
      this.getComponentOrganizations
    );
  };

  handleSearchChange = e => {
    this.setState(
      {
        search: e.target.value
      },
      this.search(e)
    );
  };

  render() {
    if (!this.state.organizations) {
      return <Spinner name="double-bounce" />;
    } else {
      const { organizations, createOrg } = this.state;
      return (
        <div>
          <Header>
            <Header.Head bold huge style={{ textAlign: 'left' }}>
              Organizations
              <Button onClick={this.openCreateForm} header>
                Create an Organization {createOrg === 'create' ? '-' : '+'}
              </Button>
            </Header.Head>
          </Header>
          <Wrapper>
            <InputWrapper onSubmit={this.search}>
              <Input
                placeholder="Search"
                onChange={this.handleSearchChange}
                value={this.state.search}
                icon={
                  this.state.search.length < 1 ? (
                    <SearchIcon onClick={this.search} />
                  ) : (
                    <CloseIcon onClick={this.clearSearch} />
                  )
                }
              />
            </InputWrapper>
            {this.state.loading ? (
              <FlexBox justify="space-evenly">
                <Spinner
                  name="three-bounce"
                  color={COLORS.darkRed}
                  fadeIn="none"
                />
              </FlexBox>
            ) : (
              <>
                <StyledSlideDown>
                  {createOrg === 'create' && (
                    <FormWrapper>
                      <CreateOrganizationForm
                        onCreate={this.handleChildChange}
                      />
                    </FormWrapper>
                  )}
                </StyledSlideDown>
                <Pagination
                  alphabetical
                  onPageChange={letter => {
                    if (
                      this.state.firstLetter &&
                      this.state.firstLetter === letter
                    ) {
                      // clear first letter
                      this.setState(
                        { firstLetter: null },
                        this.getComponentOrganizations
                      );
                    } else {
                      this.setState(
                        { firstLetter: letter },
                        this.getComponentOrganizations
                      );
                    }
                  }}
                  currentPage={this.state.firstLetter}
                />

                <FilterLink
                  soft
                  style={{
                    textDecoration: !this.state.withExpiringShows && 'underline'
                  }}
                  onClick={this.viewAll}
                >
                  View All
                </FilterLink>
                <FilterLink
                  name="withExpiring"
                  soft
                  style={{
                    textDecoration: this.state.withExpiringShows && 'underline'
                  }}
                  onClick={this.expiringShows}
                >
                  Has Shows Expiring Soon
                </FilterLink>
                <FilterLink
                  name="withBooked"
                  soft
                  style={{
                    textDecoration: this.state.withBookedShows && 'underline'
                  }}
                  onClick={this.bookedShows}
                >
                  Has Shows Booked
                </FilterLink>
                <StyledTable
                  data={organizations}
                  noDataText="There are no organizations"
                  columns={[
                    {
                      Header: 'Name',
                      accessor: 'name',
                      minWidth: 100
                    },
                    {
                      Header: 'Location',
                      accessor: 'city',
                      show: isMedia() !== 'phone',
                      minWidth: 100,
                      Cell: row => {
                        return (
                          <div>
                            <span className="class-for-name">
                              {row.original.city
                                ? `${row.original.city}, ${row.original.state}`
                                : row.original.state
                                ? `${row.original.state}`
                                : 'none listed'}
                            </span>
                          </div>
                        );
                      }
                    },
                    {
                      Header: 'Shows',
                      accessor: 'show_count',
                      minWidth: 100
                    },
                    {
                      Header: 'Account #',
                      accessor: 'account_number',
                      minWidth: 100
                    }
                  ]}
                  resizable={false}
                  sortable={false}
                  showPagination={false}
                  showPaginationTop={false}
                  showPaginationBottom={false}
                  showPageSizeOptions={false}
                  pageSize={organizations.length}
                  minRows={1}
                  getTrProps={(state, rowInfo) => {
                    return {
                      onClick: () => {
                        this.props.history.push(
                          `/admin/organizations/${rowInfo.original.id}`
                        );
                      }
                    };
                  }}
                />

                {this.state.totalPages > 1 && (
                  <Pagination
                    onPageChange={currentPage => {
                      this.setState(
                        { currentPage },
                        this.getComponentOrganizations
                      );
                    }}
                    currentPage={this.state.currentPage}
                    totalPages={this.state.totalPages}
                  />
                )}
              </>
            )}
          </Wrapper>
        </div>
      );
    }
  }
}
