import React from 'react';
import { Text, StyledTable } from 'components';
import { isMedia } from 'helpers';

export default class CurrentShowsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: false,
      columnExpander: '',
      selected: ''
    };
  }

  componentDidMount() {}

  render() {
    const { bookings } = this.props;
    const paginatedQuotedBookings =
      bookings.filter(booking => {
        return booking.state === 'quoted' || booking.state === 'approved';
      }).length > 3
        ? true
        : false;

    return (
      <div>
        <br />
        <Text large bold>
          Quoted Shows
        </Text>
        <br />
        <br />
        <StyledTable
          data={bookings.filter(booking => {
            return booking.state === 'quoted' || booking.state === 'approved';
          })}
          noDataText="No current shows."
          noShadow
          columns={[
            {
              Header: 'Title',
              accessor: 'show.name',
              minWidth: 125
            },
            {
              Header: 'Opening',
              accessor: 'opening',
              minWidth: 90,
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Closing/Expiration',
              accessor: 'expiration',
              minWidth: 90,
              show: isMedia() !== 'phone'
            },
            {
              Header: 'Status',
              accessor: 'state',
              minWidth: 90
            },
            {
              Header: 'Rate',
              accessor: 'rate',
              minWidth: 90
            }
          ]}
          resizable={false}
          sortable={false}
          showPagination={paginatedQuotedBookings}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          minRows={1}
          defaultPageSize={10}
        />
      </div>
    );
  }
}
