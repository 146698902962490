import React from 'react';
import { Form, Button, Text, FormWrapper, Spacer } from 'components';
import { toast } from 'react-toastify';
import { updateShow } from 'data-graphql';

export default class Archive extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  toggleArchived = async () => {
    const VARS = {
      id: this.props.show_id,
      input: { archived: !this.props.is_archived }
    };

    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    const RESULT = await updateShow(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.props.history.push('/admin/shows');
    }
  };

  render() {
    return (
      <div>
        <FormWrapper>
          <Form>
            <Text large red>
              Are you sure you want to{' '}
              {this.props.is_archived ? 'restore' : 'archive'} this show?
            </Text>
            <Spacer size="10px" />
            {!this.props.is_archived && (
              <React.Fragment>
                <Text large>
                  This show currently has a total of {this.props.bookings_count}{' '}
                  Bookings that are Quoted, Approved, Booked, or Active.
                </Text>
                <Spacer size="20px" />
              </React.Fragment>
            )}

            <Button negative onClick={this.toggleArchived}>
              {this.props.is_archived ? 'Restore' : 'Archive'} Show
            </Button>
          </Form>
        </FormWrapper>
      </div>
    );
  }
}
