import React from 'react';
import { sendEmail, getEmailPreviews } from 'data-graphql';
import { Form, Button, FlexBox } from 'components';
import { toast } from 'react-toastify';
import moment from 'moment';

export default class SendEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    await this.getEmailPreviews();
    await this.compileContacts();
  }

  getEmailPreviews = async () => {
    const REQ_OBJECT = `{
        template
        title
        subject
        type
    }`;

    const RESULT = await getEmailPreviews(REQ_OBJECT);

    const quoteEmails = RESULT.data.getAllEmailTemplatePreviews
      .filter(email => email.type !== 'user')
      .sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0));
    if (RESULT.data && RESULT.data.success) {
      this.compileContacts();
      this.setState({ templates: quoteEmails });
    }
  };

  sendQuoteEmail = async () => {
    const contacts = this.state.contact.map(contact => {
      return contact.email;
    });
    const booking_id = this.props.booking
      ? this.props.booking.id
      : this.state.booking
      ? this.state.booking.id
      : null;
    const VARS = {
      organization_id: this.props.organization.id || null,
      booking_id: booking_id,
      template: this.state.email.template,
      contacts: contacts
    };

    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    const RESULT = await sendEmail(VARS, REQ_OBJECT);
    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
    }
  };

  compileContacts = () => {
    let contacts = [];
    if (this.props.organization) {
      contacts = this.props.organization.contacts
        ? this.props.organization.contacts
        : this.props.organization.active_contacts;
    }
    this.props.booking &&
      this.props.booking.quote_email &&
      contacts.push({
        email: this.props.booking.quote_email,
        full_name: `${this.props.booking.quote_first_name} ${this.props.booking.quote_last_name}`
      });
    this.setState({ email_contact_options: contacts });
  };

  handleEmailChange = email => {
    this.setState({ email });
  };
  handleContactChange = contact => {
    this.setState({ contact });
  };
  handleBookingChange = booking => {
    this.setState({ booking });
  };

  render() {
    return (
      <FlexBox
        align="center"
        justify="center"
        style={{ padding: '20px', alignContent: 'center' }}
      >
        <Form.InputWrapper flex>
          <Form.Label>Email Templates</Form.Label>
          <Form.StyledSelect
            value={this.state.selectedEmail}
            onChange={this.handleEmailChange}
            options={this.state.templates}
            isSearchable={true}
            getOptionLabel={option => option.title}
            getOptionValue={option => option.template}
            placeholder="Start typing..."
            styles={Form.CustomStyles}
          />
        </Form.InputWrapper>
        {!this.props.booking && (
          <Form.InputWrapper flex>
            <Form.Label>Booking</Form.Label>
            <Form.StyledSelect
              value={this.state.booking}
              onChange={this.handleBookingChange}
              options={this.props.organization.bookings}
              isSearchable={true}
              getOptionLabel={option =>
                `${option.show.name}, ${moment(option.opening).format(
                  'MM-DD-YYYY'
                )}`
              }
              getOptionValue={option => option.id}
              placeholder="Start typing..."
              styles={Form.CustomStyles}
            />
          </Form.InputWrapper>
        )}
        <Form.InputWrapper flex>
          <Form.Label>Available Contacts</Form.Label>
          <Form.StyledSelect
            value={this.state.selectedContacts}
            onChange={this.handleContactChange}
            options={this.state.email_contact_options}
            isSearchable={true}
            isMulti
            getOptionLabel={option => `${option.full_name}, ${option.email}`}
            getOptionValue={option => option.email}
            placeholder="Start typing..."
            styles={Form.CustomStyles}
          />
        </Form.InputWrapper>
        <Form.InputWrapper flex>
          <Button
            style={{ marginLeft: '25px', marginTop: '15px' }}
            onClick={this.sendQuoteEmail}
          >
            Send
          </Button>
        </Form.InputWrapper>
      </FlexBox>
    );
  }
}
