import React from 'react';
import { Text, Button, FormWrapper, Form, Spacer } from 'components';
import { updateUser } from 'data-graphql';
import { toast } from 'react-toastify';

export default class Lock extends React.Component {
  lockAccount = async () => {
    const VARS = {
      id: this.props.user.id,
      input: {
        locked: !this.props.user.is_locked
      }
    };

    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    const RESULT = await updateUser(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.props.onChange();
    }
  };

  archiveAccount = async () => {
    const VARS = {
      id: this.props.user.id,
      input: {
        archived: true
      }
    };

    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    const RESULT = await updateUser(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.message}`);
      this.props.history.push('/admin/users');
    }
  };

  render() {
    return (
      <FormWrapper>
        <Form>
          <Text style={{ width: '80%', display: 'inline-block' }}>
            Locking an account will prevent the user from logging in to both the
            website and the app. They will receive a message stating that their
            account has been locked.
          </Text>
          <Spacer size="15px" />
          <Text>Accounts can be locked or unlocked at any time.</Text>
          <Spacer size="20px" />
          <Button large negative onClick={this.lockAccount}>
            {this.props.user.is_locked ? 'Unlock' : 'Lock'} Account
          </Button>
          {this.props.user.is_locked && (
            <React.Fragment>
              <Spacer size="20px" />
              <Button large negative onClick={this.archiveAccount}>
                Archive Account
              </Button>
            </React.Fragment>
          )}
        </Form>
      </FormWrapper>
    );
  }
}
